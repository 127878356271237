import { Box, Button, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { Link } from 'react-router-dom';
import { viewActiveOrders, viewOrderHistory } from './../services/vendors';
import  moment  from 'moment';
import { ColorButton } from "../styles/button";
import TodayRunsheetsPickup from "../PickUpOperations/TodayRunsheetPickup";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { FormTextField, SearchTextField } from "../styles/textField";




const OrderHistoryMobile = () => {

  const [today,setToday]=useState([])
  const [value,setValue]=useState(new Date())
  var todays = new Date();
  var dd = String(todays.getDate()).padStart(2, "0");
  var mm = String(todays.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = todays.getFullYear();

  todays = yyyy + "-" + mm + "-" + dd;

  useEffect(()=>{
    const getPacketList = async () => {
        const { data } = await viewOrderHistory(todays);
        console.log("data: ", data);
        const sortedData = data.sort((a, b) => b.createdAt - a.createdAt);
        setToday(sortedData);
      };
      getPacketList();
  },[])

  React.useEffect(() => {
    let today = new Date(value);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    const getPacketList = async () => {
      const { data } = await viewOrderHistory(today);
      console.log("data: ", data);
      const sortedData = data.sort((a, b) => b.createdAt - a.createdAt);
      setToday(sortedData);
    };

    getPacketList();
  }, [value]);


  return (
    <>
      {/* <AppBarDA/> */}
      <div style={{ padding: "10px" }}>
        <Card sx={{ padding: "10px" }}>
        
        <div class="containerDA">
          <Typography variant="heading6" fontWeight={600} mb={1}>
            Order History
          </Typography>
          <Link to="/rover/vendor/vendorDeliveryRequest" className="link-style">
<ColorButton variant="contained" sx={{margin:0}}>New Request</ColorButton></Link>
        </div>
        <MobileDatePicker
              // minDate={new Date()}

              inputFormat="dd/MM/yyyy"
              focused
              renderInput={(params) => (
                <SearchTextField
                  {...params}
                  size="small"
                  fullWidth
                  placeholder="Select Date"
                  variant="outlined"
                />
              )}
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              //  {id? disabled={!edit}:null}
              //   name={item.name}
              fullWidth
              //   value={formik.values[item.name]}
              //   onChange={(value) =>
              //     formik.setFieldValue("deliveryDate", value, true)
              //   }
              // onChange={formik.handleChange}
            />
          {today.map((item, i) => (
            <React.Fragment key={i}>
              <Link to={`/vendor/vendorOrderDetails/${item?.vendorBookingId}`} >
              <Box
                sx={{
                  marginBottom: "8px",
                  marginTop:"15px",
                  borderRadius: "12px",
                  backgroundColor: "#F4F8FF",
                  paddingLeft:"10px"
                }}
              >
                <Grid container >
                  <Grid item xs={1.5} sx={{  padding:"5px",}}>
                    <div
                      style={{
                        backgroundColor:item?.vendorOrderStatus==="Cancelled"?"#B10000":"#44AD1F" ,
                        borderRadius: "12px",
                        height: "25px",
                        width: "25px",
                        textAlign:"center",
                        marginTop:"5px",
                        color:"#fff "
                      }}
                    >
                      {i + 1}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{lineHeight:1, color:"#11142D", padding:"5px"}}>
                      <Typography variant="body2" fontWeight={600}>{item.customerName}</Typography>
                      <Typography variant="body2" fontWeight={400}>{item.deliveryAddress}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4.5} sx={{color:"#11142D"}}>
                    <div style={{backgroundColor:item?.vendorOrderStatus==="Cancelled"?"#B10000":"#44AD1F"  , borderRadius: " 0px 12px 12px 0px" ,padding:"5px"}}>

                        <Typography variant="caption" sx={{color:"white", fontSize:12, padding:1, textAlign:"center",borderRadius:"12px"}} fontWeight={600}>{"  "+item?.vendorOrderStatus?.toUpperCase()+"  "}</Typography>
                        <div>

                      <Typography variant="caption" sx={{color:"white"}} fontSize={10} >{moment(item?.createdAt).format("DD MM YYYY, hh:mm A") }</Typography>
                        </div>
                        </div>
                      <div>
                    </div>

                  </Grid>
                </Grid>
              </Box>
              </Link>
              
            </React.Fragment>
          ))}
        </Card>
      </div>
    </>
  );
};

export default OrderHistoryMobile;
