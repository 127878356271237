import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, Card, Box, Grid, Divider, Modal } from "@mui/material";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getPacketImageDelivery,
  getPacketsById,
  getRunsheetPacketInformation,
} from "../services/packets";
import { getPacketHistory } from "./../services/packets";
import config from "../config.json"

const PacketInfo = () => {
  const { ids } = useParams();
  const { id } = useParams();
  const { runsheetId } = useParams();


  console.log("id: ", id);
  console.log("ids: ", ids);
  console.log("runsheetId: ", runsheetId);


  const [packetInfo, setPacketInfo] = useState();
  const [packetHistory, setPacketHistory] = useState();
  const [daImage, setDAImage]=useState()

  async function getImg(api) {
    const tokens = localStorage.getItem("token");
    const token=JSON.parse(tokens).jwttoken;
    console.log('token: ', token);
    const response = await fetch(
      config.apiUrl + "/roverapi/v1/getimage" + api,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const imageBlob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(imageBlob);
    reader.onloadend = () => {
      const base64data = reader.result;
      console.log("base64data: ", base64data);
      setDAImage(base64data);
    
    };
  }
  useEffect(() => {
    const getRUnsheetPacketHistory = async () => {
      const { data } = await getPacketHistory(ids);
      console.log("data: of packet ", data);
      setPacketHistory(data)
      // getImage();
    };


    const getImage=async()=>{

      const {data}=await getPacketImageDelivery(ids);
      if(data){
        // getImg(data)
      }

    }

    const getPacketDetailsById = async () => {
      const { data } =id? await getPacketsById(id, ids): await getRunsheetPacketInformation(runsheetId, ids);
      if (data) {
        setPacketInfo(data)
        getImg(data.daUploadedImage)
        
        getRUnsheetPacketHistory();

      }
    };

    getPacketDetailsById();
  }, []);

  
  const [open, setOpen] = useState(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    
    boxShadow: 24,
    p: 2  ,
  };

  const val = [
    { title: "Tracking Id", value: packetInfo?.trackingNumber },
    { title: "Bag Id", value: "BG67987GH" },
    { title: "Shipping Date", value: moment(packetInfo?.createdAt).format("dddd, Do MMMM YYYY")  },
    { title: "Estimated Date", value: moment( packetInfo?.deliveryDate).format("dddd, Do MMMM YYYY")  },
    { title: "Scheduled Delivery Date", value:  moment(packetInfo?.deliveryDate ).format("dddd, Do MMMM YYYY") },
    { title: "Status", value: packetInfo?.packageStatus},
    { title: "Reason", value: "NA" },
    { title: "Delivery Agent", value: packetInfo?.assignPerson },
    { title: "Carrier", value: packetInfo?.carrier},
    // { title: "Scheduled Delivery Date", value: "15 Sep 2022" },
    // { title: "Status", value: "" },
    // { title: "Reason", value: "" },
    // { title: "DA", value: "" },
  ];


  return (
    <>
   
    
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        Packet Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Card
            sx={{
              margin: "8px 15px 15px 15px",
              py: 3,
              px: 5,
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F7F7FC",
                borderRadius: "8px 8px 0px 0px",
                boxShadow: "none",
              }}
              px="14px"
              py="10px"
              fontWeight="600"
            >
              Shipment Information
            </Box>
            {val.map((item, i) => 
              item.title==="Status"?
              (
                <div key={i} style={{ padding: 11 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body2" fontWeight="600">
                      {item.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">{item.value}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                  <>
                            <FontAwesomeIcon
                              icon="fa-solid fa-image"
                              style={{
                                color: "#FF6A1A",
                                height: "20px",
                                // margin: "17px 25px 0px 20px",
                                marginRight:"25px"
                              }}
                              onClick={()=>setOpen(true)}
                            />
                            <Modal
  open={open}
  onClose={()=>setOpen(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Packet Image
    </Typography>
    <img src={daImage} style={{width:470}} alt={item?.title}/>
    {/* <img src={"data:image/jpeg;base64," +packetInfo?.deliveryimageblob}  alt="daImageofPacket" style={{width:470}}/>  */}
    {/* You can't directly append base64 string to image source. First you have to decode it using Buffer then append that data in the src. */}
  </Box>
</Modal>
                            {/* <a href={"data:image/jpeg;base64," +daImage}  download={item.title}>

                            <FontAwesomeIcon
                              icon="fa-solid fa-download"
                              style={{ color: "#FF6A1A", height: "16px" }}
                              />
                              </a> */}
                          </>
                  </Grid>
                </Grid>
                <Divider />
              </div>
              ): (
                <div key={i} style={{ padding: 11 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" fontWeight="600">
                        {item.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{item.value}</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </div>
              )
            )}
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            sx={{
              margin: "8px 15px 15px 15px",
              py: 3,
              px: 5,
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F7F7FC",
                borderRadius: "8px 8px 0px 0px",
                boxShadow: "none",
              }}
              px="14px"
              py="10px"
              fontWeight="600"
            >
              Order Information
            </Box>
            <div style={{ padding: "10px 0px" }}>
              <Typography
                sx={{ color: "#FF6A1A" }}
                variant="body1"
                fontWeight="600"
              >
                Customer Info
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography
                    // sx={{ color: "#FF6A1A" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#92929D", textAlign: "right" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    {packetInfo?.customerName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    // sx={{ color: "#FF6A1A" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    Address
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#92929D", textAlign: "right" }}
                    variant="body2"
                    fontWeight="600"
                  >
                  {packetInfo?.address}, {packetInfo?.city}, {packetInfo?.state},({packetInfo?.postalCode
})
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ padding: "5px 0px" }}>
              <Typography
                sx={{ color: "#FF6A1A" }}
                variant="body1"
                fontWeight="600"
              >
                Shipper Info
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography
                    // sx={{ color: "#FF6A1A" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#92929D", textAlign: "right" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    {packetInfo?.carrier}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    // sx={{ color: "#FF6A1A" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    Address
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#92929D", textAlign: "right" }}
                    variant="body2"
                    fontWeight="600"
                  >
                  {packetInfo?.returnAddress}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ padding: "5px 0px" }}>
              <Typography
                sx={{ color: "#FF6A1A" }}
                variant="body1"
                fontWeight="600"
              >
                Order Info
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography
                    // sx={{ color: "#FF6A1A" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    Order ID
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#92929D", textAlign: "right" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    {packetInfo?.orderId}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    // sx={{ color: "#FF6A1A" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    Order Amount
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#92929D", textAlign: "right" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    {packetInfo?.productCost}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    // sx={{ color: "#FF6A1A" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    Payment Status
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#92929D", textAlign: "right" }}
                    variant="body2"
                    fontWeight="600"
                  >
                   {packetInfo?.paymentMode}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    // sx={{ color: "#FF6A1A" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    Order Status
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#92929D", textAlign: "right" }}
                    variant="body2"
                    fontWeight="600"
                  >
                   {packetInfo?.packageStatus}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ padding: "5px 0px" }}>
              <Typography
                sx={{ color: "#FF6A1A" }}
                variant="body1"
                fontWeight="600"
              >
                Additional Info
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography
                    // sx={{ color: "#FF6A1A" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    Dimension
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#92929D", textAlign: "right" }}
                    variant="body2"
                    fontWeight="600"
                  >
                   {packetInfo?.height}cms x
                   {packetInfo?.width}cmcmsx
                   {packetInfo?.length}cms 
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    // sx={{ color: "#FF6A1A" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    Weight
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#92929D", textAlign: "right" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    {packetInfo?.weight} kgsi
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    // sx={{ color: "#FF6A1A" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    Delivery Type
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#92929D", textAlign: "right" }}
                    variant="body2"
                    fontWeight="600"
                  >
                    Standard
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
      </Grid>
      <Card
        sx={{
          margin: "8px 15px 15px 15px",
          py: 3,
          px: 5,
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            // backgroundColor: "#F7F7FC",
            borderRadius: "8px 8px 0px 0px",
            boxShadow: "none",
          }}
          px="14px"
          py="10px"
          fontWeight="600"
        >
          Packet History
        </Box>
        <div
          style={{
            backgroundColor: "#F7F7FC",
            borderRadius: "8px 8px 0px 0px",
            boxShadow: "none",
            padding: "14px 10px",
            fontWeight: "600",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={3}>
              Date
            </Grid>
            <Grid item xs={2}>
              Source
            </Grid>
            <Grid item xs={2}>
              Operation
            </Grid>
            <Grid item xs={2}>
              Handed By
            </Grid>
            <Grid item xs={3}>
              Remark
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            boxShadow: "none",
            padding: "14px 10px",
            fontWeight: "400",
          }}
        >
          <Grid container spacing={1}>


         {packetHistory?.map((item)=>
         <>
         <Grid item xs={3}>
         {moment(item?.actionDateTime).format("DD/MMM/YYYY")}
         
       </Grid>
       <Grid item xs={2}>
         {item?.deliveryHub?item?.deliveryHub:item?.stationCode}
       </Grid>
       <Grid item xs={2}>
       {item?.actionName}
       </Grid>
       <Grid item xs={2}>
      {item?.actionby}
       </Grid>
       <Grid item xs={3}>
         NA
       </Grid>
       </>

         )   }
         
          </Grid>
        </div>
      </Card>
    </>
  );
};

export default PacketInfo;
