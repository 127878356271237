import http from "./httpService";
import config from "../config.json";


const tokens = localStorage.getItem("token");
const token=JSON.parse(tokens)?.jwttoken;

const head = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "access-control-allow-origin": "*",
    Authorization: `Bearer ${token}`,
  },
};

export function addMember(data) {
  console.log("Here, Add Member");
  return http.post(config.apiUrl + "/roverapi/v1/member", data, head);
}
export function addPermissions(data) {
  return http.post(config.apiUrl + "/roverapi/v1/roleCommon", data, head);
}

export function getAllMemberDetails() {
  return http.get(config.apiUrl + "/roverapi/v1/member", head);
}
export function checkExistingUsername(username) {
  return http.get(config.apiUrl + "/roverapi/v1/usernamecheck/"+username, head);
}
export function getMemberDetails(data) {
  return http.get(config.apiUrl + "/roverapi/v1/member/" + data, head);
}
export function getMemberPermission(data) {
  return http.get(config.apiUrl + "/roverapi/v1/roleCommon/" + data, head);
}
export function updateMemberPermission(data) {
  return http.put(config.apiUrl + "/roverapi/v1/roleCommon" , data, head);
}
export function updateMemberDetails(id,data) {
  console.log('id,data: ', id,data);
  return http.put(config.apiUrl + "/roverapi/v1/member/"+id , data, head);
}
