import {
  Card,
  Typography,
  Grid,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { useFormik } from "formik";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ColorButton } from "../styles/button";
import {
  FormAutoComplete,
  FormTextField,
  SearchTextField,
} from "../styles/textField";
// import http from "./httpService";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
//   import QuickTracking from "../QuickTracking";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Edit } from "@mui/icons-material";
import { viewAllVendorServices } from "../services/vendor";
import { addPickupPacket, getPickupPacketInfo } from "../services/pickup";
import QuickTracking from "../components/QuickTracking";
import { faDiagramSuccessor } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import {
  VendorDeliveryCharges,
  createOrUpdateVendorPacket,
} from "../services/vendors";

const VendorDeliveryRequestForm = () => {
  let { id } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  // useEffect(() => {
  //   const getValues = async () => {
  //     if (!id) return;
  //     const result = await getPickupPacketInfo(id);
  //     if (result) {
  //       console.log("data: ", result.data);
  //       setDefaultValues(result.data);
  //     }
  //   };
  //   getValues();
  // }, []);

  const initialValue = {
    customerName: "",
    customerPhone: "",
    vehicleType: "",
    orderDetails: "",
    pickupStore: "",
    deliveryAddress: "",
    deliveryDate: "",
    deliverySlot: "",
    alternatePhone: "",
    pickupDate: "",
    pickupSlot: "",
    // deliveryDistance: "",
    // deliveryCharges: "",
    takeDelChargeFromCustomer: "",
    codAmount: 0,
    // amountToCollect: 0,
    remarks: "",
  };
  let navigate = useNavigate();
  const [stations, setStations] = useState([]);
  const [deliveryHubs, setDeliveryHubs] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [edit, setEdit] = useState(false);
  const [distance, setDistance] = useState([]);
  const [charges, setCharges] = useState("");

  const distanceCharge = [
    { name: "oneToFiveCharges", value: "1-5 KMs" },
    { name: "fiveToTenCharges", value: "5-10 Kms" },
    { name: "tenToFifteenCharges", value: "10-15Kms" },
    { name: "fifteenToTwentyCharges", value: "15-20Kms" },
  ];

  useEffect(() => {
    let stationValues;

    let vendorsList;
    const getValues = async () => {
      if (!id) return;
      const result = await getPickupPacketInfo(id);
      if (result) {
        console.log("result: ", result);
        let info = result.data;
        console.log("result.data: ", result.data);
        const val = vendorsList.find(
          (item) => item.vendorCode === result.data.vendor
        );
        console.log("val: ", val);
        info.vendor = val;
        const event = new Date(result.data.deliveryDate);
        info.deliveryDate = event.toISOString();
        console.log("info: ", info);
        console.log("data: ", result.data);
        setDefaultValues(info);
      }
    };

    const getVendors = async () => {
      const { data } = await viewAllVendorServices();
      if (data) {
        vendorsList = data;
        setVendors(data);
        getValues();
      }
    };
    // getDeliveryHub();
    //!   getVendors()

    const getDistanceCharges = async () => {
      const { data } = await VendorDeliveryCharges();
      console.log("data: ", data);
      setCharges(data);
      const val = distanceCharge.filter((item) =>
        data[item.name] ? item : null
      );
      setDistance(val);
    };

    getDistanceCharges();
  }, []);
  const tokens = localStorage.getItem("token");
  const token = JSON.parse(tokens);
  console.log("token: ", token);

  const success = () => {
    toast.success("Data Submitted Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  async function submitPacketInfo(formValues) {
    
    const val = formValues;
    val.createdBy = localStorage.getItem("username");
    val.id = "";
    // val.deliveryDistance=formValues.deliveryDistance.value;
    val.vendorCode = token.vendorCode;

    console.log("val: ", val);
    try {
      const { data } = await createOrUpdateVendorPacket(val);
      if (data) {
        console.log("data: ", data);
        success();
        navigate(`/rover/vendor/activeOrders`);
      }
    } catch (exception) {
      error();
    }
  }

  const validationSchema = yup.object({
    customerName: yup.string().required(" Name is required"),
    customerPhone: yup.number().required("Number is required"),
    vehicleType: yup.string().required("Vehicle Type is required"),
    orderDetails: yup.string().required("Order Details is required"),
    pickupStore: yup.string().required("Pickup Store is Required"),
    deliveryAddress: yup.string().required("Address is required"),
    deliveryDate: yup.string().required("Delivery Date is required"),
    pickupDate: yup.string().required("Delivery Date is required"),
    deliverySlot: yup.string().required("Time is required"),
    pickupSlot: yup.string().required("Time is required"),
    // deliveryDistance: yup.object().required("Distance is required"),
    // deliveryCharges: yup.string().required("Charges Are required"),
    takeDelChargeFromCustomer: yup.string().required("This Field is required"),
    codAmount: yup.number().required("COD Amount is required"),
    // amountToCollect: yup.string().required("Amount is required"),
  });

  const updateValues = async (formData) => {
    const val = formData;
    val.id = id;
    val.createdBy = localStorage.getItem("username");
    val.vendor = formData.vendor.vendorCode;
    val.updatedAt = new Date();
    val.updatedBy = localStorage.getItem("username");
    try {
      const { data } = await addPickupPacket(val);
      if (data) {
        console.log("data: ", data);
        success();
        navigate(`/rover/user/allpickupPackets`);
        // <Navigate to={`stationList/viewStation/${data.id}`}  />
      }
    } catch (ex) {
      error();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: id ? defaultValues : initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (id) {
        console.log("values: Id available", values);
        updateValues(values);
      } else {
        console.log("values: Id nahi hai", values);
        submitPacketInfo(values);
      }
    },
  });
  
  console.log("defaultValues", formik.values);

  // useEffect(() => {
  //   console.log("Change karta hai Lawdeee");
  //   console.log(
  //     "formik.values.takeDelChargeFromCustomer: ",
  //     formik.values.takeDelChargeFromCustomer
  //   );
  //   if (formik.values.takeDelChargeFromCustomer === "Yes") {
  //     formik.values.amountToCollect =
  //       parseInt(formik.values.deliveryCharges) +
  //       parseInt(formik.values.codAmount ? formik.values.codAmount : 0);
  //   }
  //   if (formik.values.takeDelChargeFromCustomer === "No") {
  //     formik.values.amountToCollect = parseInt(formik.values.codAmount);
  //   }
  // }, [
  //   formik.values.deliveryCharges,
  //   formik.values.takeDelChargeFromCustomer,
  //   formik.values.codAmount,
  // ]);

  // console.log(">>>>>>>>>>>>>>>>.", formik.errors)


  return (
    <>
      {/* <QuickTracking /> */}
      {id ? (
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ margin: "15px 0px 0px 18px" }}
        >
          Edit Packet Information
        </Typography>
      ) : (
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ margin: "15px 0px 0px 18px" }}
        >
          Add Packet
        </Typography>
      )}

      <Card
        sx={{ margin: "8px 15px 15px 15px", py: 3, px: 5, borderRadius: "8px" }}
      >
        {id ? (
          <Box
            sx={{
              backgroundColor: "#F7F7FC",
              borderRadius: "8px 8px 0px 0px",
              boxShadow: "none",
            }}
            px="14px"
            py="10px"
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="subtitle1"
                fontWeight="600"
                sx={{ margin: "0px 0px 0px 0px", fontSize: "20px" }}
              >
                {defaultValues.customerName}
              </Typography>
              <Edit
                sx={{ color: "gray" }}
                onClick={() => {
                  setEdit(!edit);
                }}
              />
            </Box>
            <Typography
              variant="subtitle2"
              fontWeight="500"
              sx={{ margin: "0px 0px 10px 0px", lineHeight: "0.5" }}
            ></Typography>
          </Box>
        ) : null}
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            {[
              { title: "Customer Name", name: "customerName", size: 4 },
              { title: "Phone Number", name: "customerPhone", size: 4 },
              { title: "Alternate Number", name: "alternatePhone", size: 4 },
              { title: "Vehicle Type", name: "vehicleType", size: 6 },
              {
                title: "Pick-Up Store Locations",
                name: "pickupStore",
                size: 6,
              },
              { title: "Delivery Address", name: "deliveryAddress", size: 8 },
              // { title: "Delivery Distance", name: "deliveryDistance", size: 4 },
              // { title: "Delivery Charges", name: "deliveryCharges", size: 4 },
              
              { title: "COD Amount", name: "codAmount", size: 4 },
              // {
              //   title: "Amount To Be Collected",
              //   name: "amountToCollect",
              //   size: 4,
              // },

              { title: "Pickup Date", name: "pickupDate", size: 6 },
              { title: "Pickup Time Slot", name: "pickupSlot", size: 6 },
              { title: "Date To Be Delivered", name: "deliveryDate", size: 6 },
              { title: "Delivery Time Slot", name: "deliverySlot", size: 6 },
              {
                title: "Collect Delivery Charges From Customer",
                name: "takeDelChargeFromCustomer",
                size: 6,
              },
              { title: "Order Details", name: "orderDetails", size: 6 },
              { title: "Remark", name: "remarks", size: 12 },
            ].map((item) => {
              return (
                <Grid item xs={12} lg={item.size}>
                  <Typography
                    mt={1}
                    mb={1}
                    variant="subtitle2"
                    fontWeight="bold"
                  >
                    {item.title}
                  </Typography>

                  {item.name === "deliveryDate" ||
                  item.name === "pickupDate" ? (
                    <MobileDatePicker
                      focused
                      inputFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      renderInput={(params) => (
                        <SearchTextField
                          {...params}
                          size="small"
                          fullWidth
                          placeholder={item.title}
                        />
                      )}
                      //  {id? disabled={!edit}:null}
                      name={item.name}
                      fullWidth
                      value={formik.values[item.name]}
                      onChange={(value) =>
                        formik.setFieldValue(item.name, value, true)
                      }
                      // onChange={formik.handleChange}
                    />
                  ) : item.name === "deliveryDistance" ? (
                    <Autocomplete
                      // disablePortal
                      disableClearable
                      options={distance}
                      getOptionLabel={(option) => option?.value}
                      focused
                      name="deliveryDistance"
                      onChange={(event, value) => {
                        formik.setFieldValue("deliveryDistance", value);
                        formik.setFieldValue(
                          "deliveryCharges",
                          charges[value.name]
                        );
                      }}
                      // onChange={formik.handleChange}
                      value={
                        formik.values.deliveryDistance
                          ? formik.values.deliveryDistance
                          : null
                      }
                      // fullWidth
                      size="small"
                      renderInput={(params) => (
                        <FormAutoComplete
                          {...params}
                          focused
                          placeholder="Select Distance"
                          variant="outlined"
                          type="text"
                          error={
                            formik.touched.deliveryDistance &&
                            Boolean(formik.errors.deliveryDistance)
                          }
                          helperText={
                            formik.touched.deliveryDistance &&
                            formik.errors.deliveryDistance
                          }
                        />
                      )}
                    />
                  ) : item.name === "vehicleType" ? (
                    <Autocomplete
                      // disablePortal
                      disableClearable
                      options={["Three Wheeler Vehicle", "Four Wheeler Vehicle"]}
                      focused
                      name="vehicleType"
                      onChange={(event, value) => {
                        formik.setFieldValue("vehicleType", value);
                      }}
                      // onChange={formik.handleChange}
                      value={
                        formik.values.vehicleType
                          ? formik.values.vehicleType
                          : null
                      }
                      // fullWidth
                      size="small"
                      renderInput={(params) => (
                        <FormAutoComplete
                          {...params}
                          focused
                          placeholder="Vehicle Type"
                          variant="outlined"
                          type="text"
                          error={
                            formik.touched.vehicleType &&
                            Boolean(formik.errors.vehicleType)
                          }
                          helperText={
                            formik.touched.vehicleType &&
                            formik.errors.vehicleType
                          }
                        />
                      )}
                    />
                  ) : item.name === "deliverySlot" ||
                    item.name === "pickupSlot" ? (
                    <Autocomplete
                      disableClearable
                      options={["8-12 PM", "12-4 PM", "4-8 PM"]}
                      focused
                      name={item.name}
                      onChange={(event, value) => {
                        formik.setFieldValue(item.name, value);
                      }}
                      value={
                        formik.values[item.name]
                          ? formik.values[item.name]
                          : null
                      }
                      size="small"
                      renderInput={(params) => (
                        <FormAutoComplete
                          {...params}
                          focused
                          placeholder={item.title}
                          variant="outlined"
                          type="text"
                          error={
                            formik.touched[item.name] &&
                            Boolean(formik.errors[item.name])
                          }
                          helperText={
                            formik.touched[item.name] &&
                            formik.errors[item.name]
                          }
                        />
                      )}
                    />
                  ) : item.name === "takeDelChargeFromCustomer" ? (
                    <Autocomplete
                      // disablePortal
                      disableClearable
                      options={["Yes", "No"]}
                      focused
                      name="takeDelChargeFromCustomer"
                      onChange={(event, value) => {
                        formik.setFieldValue(
                          "takeDelChargeFromCustomer",
                          value
                        );
                      }}
                      // onChange={formik.handleChange}
                      value={
                        formik.values.takeDelChargeFromCustomer
                          ? formik.values.takeDelChargeFromCustomer
                          : null
                      }
                      // fullWidth
                      size="small"
                      renderInput={(params) => (
                        <FormAutoComplete
                          {...params}
                          focused
                          placeholder="Collect Delivery Charges From Customer"
                          variant="outlined"
                          type="text"
                          error={
                            formik.touched.takeDelChargeFromCustomer &&
                            Boolean(formik.errors.takeDelChargeFromCustomer)
                          }
                          helperText={
                            formik.touched.takeDelChargeFromCustomer &&
                            formik.errors.takeDelChargeFromCustomer
                          }
                        />
                      )}
                    />
                  ) : (
                    <FormTextField
                      size="small"
                      fullWidth
                      id={item.name}
                      placeholder={item.title}
                      disabled={
                        item.name === "deliveryCharges" ||
                        item.name === "amountToCollect"
                      }
                      variant="outlined"
                      type="text"
                      value={formik.values[item.name]}
                      name={item.name}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[item.name] &&
                        Boolean(formik.errors[item.name])
                      }
                      helperText={
                        formik.touched[item.name] && formik.errors[item.name]
                      }
                      // disabled={!edit}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ColorButton variant="contained" type="submit">
              Submit
            </ColorButton>
          </Box>
        </form>
      </Card>
      {/* )} */}
      {/* </Formik> */}
    </>
  );
};

export default VendorDeliveryRequestForm;
