import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Stack,
  Typography,
  Divider,
  Box,
  Avatar,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Edit } from "@mui/icons-material";
import { viewDeliveryHubServices } from "../../services/deliveryHub";

const ViewDeliveryHub = () => {
  const [role, setRole] = React.useState("");
 
  let {  id } = useParams();
  console.log("userId: ", id);
  const [deliveryHubData, setDeliveryHubData] = useState("");
  useEffect(() => {
    const tokens = localStorage.getItem("token");
    const token = JSON.parse(tokens);
    console.log("token: ", token);
    setRole(token.role);
    const getDeliveryHub = async () => {
      const { data } = await viewDeliveryHubServices(id);
      console.log("result: ", data);
      setDeliveryHubData(data);
    };
    getDeliveryHub();
  }, []);

  return (
    <div style={{ margin: "8px 15px 15px 15px" }}>
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        Delivery Hub
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Card
            sx={{
              margin: "10px 0px 0px 0px",
              py: 2,
              px: 2,
              borderRadius: "8px",
              // height:"80vh"
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F7F7FC",
                borderRadius: "8px 8px 0px 0px",
                boxShadow: "none",
              }}
              px="14px"
              py="10px"
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="600"
                  sx={{ margin: "0px 0px 0px 0px", fontSize: "20px" }}
                >
                  {deliveryHubData.deliveryHubCode}
                </Typography>
               { role==="Admin"&&<Link
                  className="link-style"
                  to={`/rover/user/deliveryHubList/editDeliveryHub/${deliveryHubData.id}`}
                >
                  <Edit />
                </Link>}
              </Box>
              <Typography
                variant="subtitle2"
                fontWeight="500"
                sx={{ margin: "0px 0px 10px 0px", lineHeight: "0.5" }}
              >
                {deliveryHubData.deliveryHubName}
              </Typography>
            </Box>
            <Box
              sx={{
                // borderRight:"2px solid #F7F7FC", borderLeft:"2px solid #F7F7FC",
                borderRadius: "0px 0px 8px 8px",
                boxShadow:
                  " 0px 1px 1px 0px rgb(0 0 0 / 9%), 0px 0px 1px 0px rgb(0 0 0 / 10%)",
              }}
            >
              <Grid container spacing={0}>
                {[
                  {
                    title: "Delivery Hub Number",
                    value: deliveryHubData.phoneNumber,
                    action: false,
                  },
                  {
                    title: "Delivery Hub Manager",
                    value: deliveryHubData.deliveryHubManager,
                    action: false,
                },
                {
                  title: "Station Name",
                  value: deliveryHubData.stationCode,
                   action: false,
                },
                  {
                    title: "Station Manager Number",
                    value: deliveryHubData.managerNumber,
                    action: false,
                  },
                  {
                    title: "Email Id",
                    value: deliveryHubData.managerEmail,
                    action: false,
                  },
                  {
                    title: "Address",
                    value: deliveryHubData.address,
                    action: false,
                  },
                  {
                    title: "Locality",
                    value: deliveryHubData.locality,
                    action: false,
                  },
                  { title: "City", value: deliveryHubData.city, action: false },
                  { title: "State", value: deliveryHubData.state, action: false },
                  {
                    title: "Pincode",
                    value: deliveryHubData.pincode,
                    action: false,
                  },
                  {
                    title: "Latitude",
                    value: deliveryHubData.latitude,
                    action: false,
                  },
                  {
                    title: "Longitude",
                    value: deliveryHubData.longitude,
                    action: false,
                  },
                ].map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle2"
                          fontWeight="500"
                          sx={{ margin: "15px 0px 15px 15px" }}
                        >
                          {item.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          fontWeight="500"
                          sx={{ margin: "15px 0px 15px 0px" }}
                        >
                          {item.value}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {item.action && (
                          <>
                            <FontAwesomeIcon
                              icon="fa-solid fa-image"
                              style={{
                                color: "#FF6A1A",
                                height: "20px",
                                margin: "17px 25px 0px 20px",
                              }}
                            />
                            <FontAwesomeIcon
                              icon="fa-solid fa-download"
                              style={{ color: "#FF6A1A", height: "16px" }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {item.title === "Sub Station" ? null : (
                          <Divider light />
                        )}
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <Card
              sx={{
                margin: "10px 0px 0px 0px",
                py: 2,
                px: 2,
                borderRadius: "8px",
                height: "50vh",
              }}
            >
              <Typography
                variant="subtitle1"
                fontWeight="600"
                sx={{ margin: "0px 0px 5px 0px" }}
              >
                Performance
              </Typography>
              <Divider />
            </Card>
            <Card
              sx={{
                margin: "18px 0px 0px 0px",
                py: 3,
                px: 5,
                borderRadius: "8px",
                height: "27.5vh",
              }}
            ></Card>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewDeliveryHub;
