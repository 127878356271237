import { Card, Grid, Typography } from "@mui/material";
import React from "react";
import QuickCashTracking from "./QuickCashTracking";

const TotalCashCollected = () => {
  return (
    <>
      <QuickCashTracking />
      <div style={{ padding: "10px" }}>
        <Card sx={{ padding: "10px" }}>
          <Typography variant="body2" fontWeight={600}>
            {" "}
            Total Cash Collected
          </Typography>
          {[
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },

            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
            {
              name: "Sudhanshu Dabral",
              id: "AMZFH4765865867",
              cash: "23456.67",
              status: "collected",
            },
          ].map((item, i) => {
            return (
              <div
                key={i}
                style={{
                  backgroundColor: "#F4F8FF",
                  margin: "10px 0px 5px 0px",
                  padding: "5px",
                  borderRadius: "10px",
                  textAlign: "center",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="caption" fontWeight={600}>
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption" fontWeight={600}>
                      {item.id}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="caption"
                      fontWeight={600}
                      sx={{
                        color:
                          item.status === "collected" ? "#44AD1F" : "#B10000",
                      }}
                    >
                      Rs. {item.cash}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </Card>
      </div>
    </>
  );
};

export default TotalCashCollected;
