import { Autocomplete, Button, Card, Grid, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { viewAllVendorServices } from "../../services/vendor";
import { ColorButton } from "../../styles/button";
import { FormAutoComplete } from "../../styles/textField";
import QuickTracking from "../QuickTracking";

const ReceiveShipmentList = () => {
  const [vendors, setVendors] = useState([]);
  useEffect(() => {
    let stationValues;
    // const getStations = async () => {
    //   const { data } = await viewAllStationServices();
    //   stationValues = data;
    //   console.log("data: ", data);
    //   if (data) {
    //     setStations(data);
    //     // if(!id) return;
    //   }
    // };
    // getStations();
    
    // const getDeliveryHub = async () => {
    //   const { data } = await viewAllDeliveryHubServices();
    //   if (data) {
    //     setDeliveryHubs(data)
    //   }
    // };
    const getVendors = async () => {
      const { data } = await viewAllVendorServices();
      if (data) {
        setVendors(data)
      }
    };
    // getDeliveryHub();
    getVendors()
  }, []);
  return (
    <>
      <Card
        sx={{
          margin: "14px 15px 15px 15px",
          py: 3,
          px: 5,
          borderRadius: "8px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction="row">
              <Autocomplete
                // disablePortal
                fullWidth
                disableClearable
                options={vendors}
               
                getOptionLabel={(option) => option.vendorCode}
                focused
                // name={item.name}
                // onChange={(event, value) => {
                //   formik.setFieldValue(item.name, value);
                // }}
                // onChange={formik.handleChange}
                // value={
                //   formik.values[item.name]
                //     ? formik.values[item.name]
                //     : null
                // }
                // fullWidth
                size="small"
                renderInput={(params) => (
                    <FormAutoComplete
                    {...params}
                    focused
                    sx={{borderRadius:"8px 0px 0px 8px",}}
                    // placeholder={item.title}
                    variant="outlined"
                    type="text"
                    // error={
                    //   formik.touched[item.name] &&
                    //   Boolean(formik.errors[item.name])
                    // }
                    // helperText={
                    //   formik.touched[item.name] &&
                    //   formik.errors[item.name]
                    // }
                  />
                )}
              />
              <ColorButton variant="contained" sx={{marginTop:0,paddingLeft:"35px", paddingRight:"35px",borderRadius:"0px 8px 8px 0px", backgroundColor:"#646E78", color:"#fff", width:"250px"}}> Select Vendor</ColorButton>
            </Stack>
          <Box mt={2}>
              <Link to="/rover/baglist" target="_blank" className="link-style">
              <ColorButton variant="contained" sx={{marginTop:0,padding:"10px 35px 10px 35px", color:"#fff", marginRight:"30px", textDecoration:"none" }}  >Scan Bag</ColorButton>
              </Link>
              <Link to="packetlist" target="_blank" className="link-style">
              <ColorButton variant="contained" sx={{marginTop:0,padding:"10px 35px 10px 35px", color:"#fff", }}  >Scan Packet</ColorButton>
              </Link>
              </Box>  
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ReceiveShipmentList;
