import axios from "axios";
import Toastify from "toastify-js";

function backToLogin() {
  window.localStorage.removeItem("token");
    window.location.href = "/rover/Login";
}

axios.interceptors.response.use(null, (error) => {
  // console.log("Helllooooooooooooooooooooooooooooooo");
  // axios.defaults.headers.Authorization['Bearer Token']=localStorage.getItem('token');
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 500;
    
  console.log("expectedError: ", expectedError);
  if (error.response && error.response.status === 401) {
    // Toastify({
    //     text: "This is a toast", 
    //     close: true,
    //     stopOnFocus: true, // Prevents dismissing of toast on hover
    //     style: {
    //         background: "linear-gradient(to right, #00b09b, #96c93d)",
    //     },
    //     onClick: function(){

    //     } // Callback after click
    // }).showToast();
    // alert();
    
    Toastify({
      text: "Token Expired, Please login Again",
      duration: 5000,
      gravity: "top", // `top` or `bottom`
      position: "left", // `left`, `center` or `right`
      className: "warn",
      stopOnFocus: true,  
      destination: "/",
      offset: {
        x: 0, // horizontal axis - can be a number or a string indicating unity. eg: '2em'
        y: 10, // vertical axis - can be a number or a string indicating unity. eg: '2em'
      },
      style: {
        background: "linear-gradient(to right, #ff0000, #fff400)",
        color: "white",
     
      },
    }).showToast();

    const myTimeout = setTimeout(backToLogin, 3000);
    myTimeout();
    
    return Promise.reject(error);
  }

  if (expectedError) {
    return Promise.reject(error);
  }

  alert("Unexpected Error Occoured", error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
