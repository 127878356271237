import { InputBase, styled, TextField,Autocomplete } from "@mui/material";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#FF6A1A",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#FF6A1A",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FF6A1A",
      
    },
    "&:hover fieldset": {
      borderColor: "#FF6A1A",
      
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF6A1A",
    },
  },
});
const FormTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#EEECFA",
  },
  '& .MuiInputBase-input': {
    border: '2px solid #EEECFA',
    borderRadius:"8px"
  }
  ,
  "& .MuiInput-underline:after": {
    borderBottomColor: "#EEECFA",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#EEECFA",
      
    },
    "&:hover fieldset": {
      borderColor: "#EEECFA",
      
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EEECFA",
    },
  },
});
const FormAutoComplete = styled(TextField)({
  "& label.Mui-focused": {
    color: "#EEECFA",
  },
  '& .MuiInputBase-input': {
    border: '0px solid #EEECFA',
    borderRadius:"8px"
  }
  ,
  "& .MuiInput-underline:after": {
    borderBottomColor: "#EEECFA",
  },
  "& .MuiOutlinedInput-root": {
    borderColor: "#EEECFA",

    "& fieldset": {
      borderColor: "#EEECFA",
      
    },
    "&:hover fieldset": {
      borderColor: "#EEECFA",
      
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EEECFA",
    },
  },
});
const SearchTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#EEECFA",
  },
  '& .MuiInputBase-input': {
    borderRadius:"0px 8px 8px 0px",
    backgroundColor:"#F7F7FC"
  }
  ,
  "& .MuiInput-underline:after": {
    borderBottomColor: "#EEECFA",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#EEECFA",
      border: '0px',
      
    },
    "&:hover fieldset": {
      borderColor: "#EEECFA",
      
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EEECFA",
    },
  },
});
const UploadTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#EEECFA",
  },
  '& .MuiInputBase-input': {
    borderRadius:"10px 0px 0px 10px",
    backgroundColor:"#F7F7FC"
  }
  ,
  "& .MuiInput-underline:after": {
    
    borderRadius:"10px 0px 0px 10px",
    borderBottomColor: "#EEECFA",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#EEECFA",
      
    borderRadius:"10px 0px 0px 10px",
     
      
    },
    "&:hover fieldset": {
      borderColor: "#EEECFA",
      
    borderRadius:"10px 0px 0px 10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EEECFA",
      
    borderRadius:"10px 0px 0px 10px",
    },
  },
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(5),
    border: '2px solid #EEECFA',
   
  },
  '& .MuiSvgIcon-root':{
    border: '2px solid #EEECFA',
   
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    // backgroundColor: theme.palette.background.paper,
    border: '2px solid #EEECFA',
    fontSize: 16,
    padding: '8px 26px 8px 5px',
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
    
      borderColor: '#EEECFA',
      },
  },
}));

export { CssTextField  };
export { FormTextField  };
export { SearchTextField  };
export { UploadTextField  };
export { BootstrapInput  };
export { FormAutoComplete  };
