import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Compressor from "compressorjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  cancelDelivery,
  getPacketsById,
  unableToDeliver,
} from "../services/packets";
import { ColorButton } from "../styles/button";
import { FormTextField, UploadTextField } from "../styles/textField";
import { packetDelivered } from "./../services/packets";
import TodayDeliveries from "./TodayDeliveries";

const PackageInformation = () => {
  let { id } = useParams();

  const [collectPayment, setCollectPayment] = React.useState(false);
  const [rejectDelivery, setRejectDelivery] = React.useState(false);
  const [confirmDelivery, setConfirmDelivery] = React.useState(false);
  const [rejectReason, setRejectReason] = React.useState("");
  const [paymentCollected, setPaymentCollected] = useState("");
  const [packet, setPacket] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [imagePacket, setImagePacket] = useState();
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const success = () => {
    toast.success("Data Submitted Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const compressFile=(e)=>{
    const file = e.target.files[0];

    if (!file) {
      return;
    }
  
    new Compressor(file, {
      quality: 0.4,
  
      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        console.log(">>>>>>>",result);
        setImagePacket(result)
      },
      error(err) {
        console.log(err.message);
      }
  })}

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      console.log("position.coords.latitude: ", position.coords.latitude);
      setLng(position.coords.longitude);
    });
  };

  useEffect(() => {
    const getPacketInfo = async () => {
      const { data } = await getPacketsById(id);
      if (data) {
        setPacket(data);
      }
    };
    getPacketInfo();
  }, []);
  const navigate = useNavigate();

  const markUndelivered = async () => {
    try {
      const data = await unableToDeliver({
        id: id,
        callStatus: rejectReason,
      });
      if (data) {
        success();
        navigate("rover/todayDeliveries");
      }
    } catch (ex) {
      error();
    }
  };

  let lati;
  let longi;
  navigator.geolocation.getCurrentPosition((position) => {
    lati = position.coords.latitude;
    longi = position.coords.longitude;
  });

  console.log(rejectReason);
  const markDelivered = async () => {
    // getLocation();
    setSubmitting(true)
   
    var form_data = new FormData();
    let formValues = {};
    formValues.id = id;
    formValues.deliveryOtp = "432156";
    formValues.deliveryLatitude = lati;
    formValues.deliveryLongitude = longi;
    formValues.deliveryLocation = packet.address;
    formValues.deliveryTo = packet.customerName;
    formValues.deliveryToRelation = "";
    formValues.amountReceived = paymentCollected;
    formValues.createdBy = localStorage.getItem("username");
    
    for (var key in formValues) {
      form_data.append(key, formValues[key]);
    }
    
    form_data.append("deliveryimageblob", imagePacket);
    try {
      const { data } = await packetDelivered(form_data);
      if (data) {
        success();
        setSubmitting(false)
        navigate("rover/todayDeliveries");
      }
    } catch (ex) {
      error();
      setSubmitting(false)
    }
  };
  if (!packet) {
    return <h6>Loading</h6>;
  }
  return (
    <>
      <Card sx={{ margin: "65px 10px 0px 10px" }}>
        <div
          style={{ backgroundColor: "#FF6A1A", color: "#FFF", padding: "10px" }}
        >
          <Typography variant="body2" fontWeight={600}>
            {packet?.customerName}
          </Typography>
          <Typography variant="caption" fontWeight={400}>
            {packet?.address},{packet.city}, {packet.state}
          </Typography>
        </div>

        <div style={{ padding: "15px" }}>
          <div
            style={{
              backgroundColor: "#F4F8FF",
              padding: "5px",
              borderRadius: "12px",
            }}
          >
            <Typography variant="body2" fontWeight={600}>
              {packet?.roverId}
            </Typography>
          </div>
          <Typography variant="body1" mt={1} fontWeight={600}>
            {packet?.paymentMode}
          </Typography>
          <Typography variant="caption" fontWeight={600}>
            Order Value:{" "}
            <span style={{ color: "#FF6A1A" }}>{packet.productCost}</span>{" "}
          </Typography>
          <Grid container spacing={2} mt={5}>
            <Grid item xs={6}>
              <div
                style={{
                  backgroundColor: "#F4F8FF",
                  textAlign: "center",
                  padding: "15px",
                  borderRadius: "12px",
                  color: "#3D3F52",
                }}
              >
                <Typography variant="body2" fontWeight={600}>
                  <a href={`tel:${packet.customerPhone}`}>
                    {/* <span style={{ color: "#3D3F52", textDecoration: "none" }}> */}
                    Call
                    {/* </span> */}
                  </a>{" "}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  backgroundColor: "#F4F8FF",
                  textAlign: "center",
                  padding: "15px",
                  borderRadius: "12px",
                }}
              >
                <a
                  href={`google.navigation:q=${packet.latitude}+${packet.longitude}`}
                >
                  <Typography variant="body2" fontWeight={600}>
                    Track
                  </Typography>
                </a>
              </div>
            </Grid>
            <Grid item xs={12}>
              {packet?.paymentMode === "Cash" && (
                <div
                  style={{
                    backgroundColor: "#F4F8FF",
                    textAlign: "center",
                    padding: "15px",
                    borderRadius: "12px",
                  }}
                  onClick={() => setCollectPayment(true)}
                >
                  <Typography variant="body2" fontWeight={600}>
                    Collect Payment
                  </Typography>
                </div>
              )}
            </Grid>
            {/* <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: "#F4F8FF",
                  textAlign: "center",
                  padding: "15px",
                  borderRadius: "12px",
                }}
                // onClick={() => markUndelivered()}
                onClick={() => setRejectDelivery(true)}
              >
                <Typography variant="body2" fontWeight={600}>
                  Rejected Delivery
                </Typography>
              </div>
            </Grid> */}
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: "#F4F8FF",
                  textAlign: "center",
                  padding: "15px",
                  borderRadius: "12px",
                }}
                onClick={() => setRejectDelivery(true)}
              >
                <Typography variant="body2" fontWeight={600}>
                  Unable To Deliver
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row">
                <UploadTextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="Upload Packet Image"
                  type="text"
                  value={imagePacket?.name}

                  // onChange={(e) => setSearch(e.currentTarget.value)}
                />
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    color: "#fff",
                    boxShadow: "none",
                  }}
                  onChange={(e) => {
                    console.log("dksgfkjah", e.target.files[0]);
                    compressFile(e);
                  }}
                >
                  Upload
                  <input
                    type="file"
                    name="picture"
                    accept="image/*"
                    capture="user"
                    hidden
                  />
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {<div
                style={{
                  backgroundColor:
                    (packet?.paymentMode === "Cash" && paymentCollected) ||
                    packet?.paymentMode === "Online"
                      ? "#F4F8FF"
                      : " #9CA3AF",
                  textAlign: "center",
                  padding: "15px",
                  borderRadius: "12px",
                }}
                onClick={() =>
                  (packet?.paymentMode === "Cash" && paymentCollected) ||
                  packet?.paymentMode === "Online"
                    ? setConfirmDelivery(true)
                    : null
                }
              >
                <Typography variant="body2" fontWeight={600}>
                  Mark Delivered
                </Typography>
              </div>}
            </Grid>
          </Grid>
        </div>
      </Card>
      <Modal
        open={collectPayment}
        onClose={() => setCollectPayment("")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="body">Expected Cash</Typography>
          <FormTextField
            size="small"
            fullWidth
            variant="outlined"
            type="text"
            value={packet.productCost}
          />
          <Typography variant="body">Cash Deposited</Typography>
          <FormTextField
            size="small"
            fullWidth
            variant="outlined"
            type="text"
            value={paymentCollected}
            onChange={(e) => setPaymentCollected(e.currentTarget.value)}
          />

          <ColorButton
            variant="contained"
            onClick={() => setCollectPayment(false)}
          >
            Submit
          </ColorButton>
        </Box>
      </Modal>
      <Modal
        open={rejectDelivery}
        onClose={() => setRejectDelivery("")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="body">Unable To Deliver Reason</Typography>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth sx={{ mt: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-simple-select-label">
                Unable To Deliver Reason
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rejectReason}
                //   size="small"
                label="Rejection Reason"
                onChange={(event) => {
                  setRejectReason(event.target.value);
                }}
              >
                <MenuItem value="Call Not Picked">Call Not Picked</MenuItem>
                <MenuItem value="Customer Not Available">
                  Customer Not Available
                </MenuItem>
                <MenuItem value="Customer Unable To Pay">
                  Customer Unable To Pay
                </MenuItem>
                <MenuItem value="Customer Rejected The Package">
                  Customer Rejected The Package
                </MenuItem>
                {/* <MenuItem value="Missing Packaging">Missing Packaging</MenuItem> */}
              </Select>
            </FormControl>
          </Box>

          <ColorButton
            variant="contained"
            onClick={() => {
              setRejectDelivery(false);
              markUndelivered();
            }}
          >
            Submit
          </ColorButton>
        </Box>
      </Modal>
      <Modal
        open={confirmDelivery}
        onClose={() => setConfirmDelivery(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="body">Confirm if Delivered</Typography>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ColorButton
              variant="outlined"
              sx={{ color: "#000", mr: 2 }}
              onClick={() => {
                setConfirmDelivery(false);
              }}
            >
              Cancel
            </ColorButton>
            <ColorButton
              variant="contained"
              onClick={() => {
                setConfirmDelivery(false);
                markDelivered();
              }}
            >
              Confirm
            </ColorButton>
          </div>
        </Box>
      </Modal>
      <Modal
        open={submitting}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="body">Submitting...</Typography>

                 </Box>
      </Modal>
    </>
  );
};

export default PackageInformation;
