import { Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {over} from 'stompjs';
import SockJS from 'sockjs-client';
import "../styles/Flippers.css"
import config from "../config.json"


const QuickTracking = () => {

const tokens=localStorage.getItem("token")

const station = JSON.parse(tokens).stationCode;  
console.log('station: ', station);
// console.log('localStorage.getItem("token"): ', localStorage.getItem("token").toString().stationCode);
var stompClient =null;
const [userData, setUserData] = useState({
  username: '',
  receivername: '',
  connected: false,
  message: '',
  total: ''
});

const [privateChats, setPrivateChats] = useState(new Map());  
const [packets, setPackets] = useState(new Map());
const [pickups, setPickups] = useState(new Map());

const [tab,setTab] =useState(station);
   
const onPrivateMessage = (payload)=>{
  console.log("PAYYYYY",payload);
  var payloadData = JSON.parse(payload.body);
  console.log('payloadData: ', payloadData);
  if(payloadData){
  setPackets(payloadData)
}
  if(privateChats.get(payloadData.senderName)){
      privateChats.get(payloadData.senderName).push(payloadData);
      setPrivateChats(new Map(privateChats));
  }else{
      let list =[];
      list.push(payloadData);
      privateChats.set(payloadData.senderName,list);
      setPrivateChats(new Map(privateChats));
  }
}

  const connect =()=>{
    let Sock = new SockJS(config.webSocket);
    stompClient = over(Sock);
    stompClient.connect({},onConnected
      // , onError
      );
      stompClient.heartbeat.outgoing = 20000; // stompClient will send heartbeats every 20000ms
    stompClient.heartbeat.incoming = 10000;
}
const onPickupMessage = (payload)=>{
  console.log("PAYYYYY",payload);
  var payloadData = JSON.parse(payload.body);
  console.log('payloadData Pickups: ', payloadData);
  if(payloadData){
  setPickups(payloadData)}
  if(privateChats.get(payloadData.senderName)){
      privateChats.get(payloadData.senderName).push(payloadData);
      setPrivateChats(new Map(privateChats));
  }else{
      let list =[];
      list.push(payloadData);
      privateChats.set(payloadData.senderName,list);
      setPrivateChats(new Map(privateChats));
  }
}

 

useEffect(()=>{
  connect();
  

},[])


const userJoin=()=>{
  var chatMessage = {
    senderName: userData.username,
    status:"JOIN"
  };
  stompClient.send("/app/message", {}, JSON.stringify(chatMessage));
}


const sendPrivateValue=()=>{

  console.log("Helooooooooooooooooooooooooooooooooooooooo");
  if (stompClient) {
    var chatMessage = {
      senderName: "DATA",
      receiverName:tab,
      message: "DATA",
      status:"MESSAGE"
    };
    
    // if(userData.username !== tab){
    //   privateChats.get(tab).push(chatMessage);
    //   setPrivateChats(new Map(privateChats));
    // }
    // stompClient.send("/app/packetsCount", {}, JSON.stringify(chatMessage), packets);
    //  stompClient.send("/app/pickupCount", {}, JSON.stringify(chatMessage));
    // setUserData({...userData,"message": ""});
    
  }
}

// function packets(payload){
//   console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",payload);
// }

// setInterval(sendPrivateValue, 10000);
// console.log('userData: ', userData);
function  onConnected() {
  console.log("WS IN HERE");
  setUserData({...userData,"connected": true});
  // stompClient.subscribe('/count/public', onMessageReceived);
  stompClient.subscribe('/user/'+station+'/packetsCount', onPrivateMessage);
  stompClient.subscribe('/user/'+station+'/pickupCount', onPickupMessage);
  // userJoin();
}



console.log("Packets", packets);

  return (
    <>
      <Box sx={{ margin: "16px 0px 0px 13px" }} p={0}>
        <Grid   container spacing={2}>
        <Grid item xs={2}>
            <Card
              sx={{
                margin: "0px 15px 0px 0px",
                py: 1.5,
                px: 1.5,
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="body2"
                fontWeight="600"
              sx={{lineHeight: "1"  }}
           >
                At Station
              </Typography>
              <Typography
                variant="h6"
                fontWeight="600"
                sx={{color:"#FF6A1A"}}
              >
                {packets?.unassignedCount}
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={2}>
            <Card
              sx={{
                margin: "0px 0px 0px 0px",
                py: 1.5,
                px: 1.5,
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="body2"
                fontWeight="600"
              sx={{lineHeight: "1"  }}
           >
               Total Deliveries  
              </Typography>
              <Typography
                variant="h6"
                fontWeight="600"
                sx={{color:"#FF6A1A"}}
              >
                {packets?.totalCount}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card
              sx={{
                margin: "0px 15px 0px 0px",
                py: 1.5,
                px: 1.5,
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="body2"
                fontWeight="600"
              sx={{lineHeight: "1"  }}
           >
                Attempted Deliveries
              </Typography>
              <Typography
                variant="h6"
                fontWeight="600"
                sx={{color:"#FF6A1A"}}
              >
                {packets.attemptedCount}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card
              sx={{
                margin: "0px 0px 0px 0px",
                py: 1.5,
                px: 1.5,
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="body2"
                fontWeight="600"
              sx={{lineHeight: "1"  }}
           >
                Remaining Deliveries
              </Typography>
              <Typography
                variant="h6"
                fontWeight="600"
                sx={{color:"#FF6A1A"}}
              >
                {packets?.pendingCount}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card
              sx={{
                margin: "0px 0px 0px 0px",
                py: 1.5,
                px: 1.5,
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="body2"
                fontWeight="600"
              sx={{lineHeight: "1"  }}
           >
               Total Pickups
              </Typography>
              <Typography
                variant="h6"
                fontWeight="600"
                sx={{color:"#FF6A1A"}}
              >
                {pickups?.totalCount}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card
              sx={{
                margin: "0px 15px 0px 0px",
                py: 1.5,
                px: 1.5,
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="body2"
                fontWeight="600"
              sx={{lineHeight: "1"  }}
           >
                Remaining Pickups
              </Typography>
              <Typography
                variant="h6"
                fontWeight="600"
                sx={{color:"#FF6A1A"}}
              >
                {pickups?.pendingCount}
              </Typography>
            </Card>
          </Grid>
          
                 </Grid>
      </Box>
    </>
  );
};

export default QuickTracking;
