import {
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { ColorButton } from "../../styles/button";
import {
  FormTextField,
  BootstrapInput,
  UploadTextField,
  FormAutoComplete,
} from "../../styles/textField";
import { Search } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as yup from "yup";
import {
  addDAService,
  checkDAUsername,
  updateDAServices,
  viewDAServices,
} from "./../../services/deliveryAssociate";
import { useNavigate, useParams } from "react-router-dom";
import { IOSSwitch } from "../../styles/Slider";
import { toast } from "react-toastify";
import { viewAllStationServices } from "../../services/station";
import config from "../../config.json";
const token = localStorage.getItem("token");

const AddDeliveryAssociate = () => {
  const initialValue = {
    title: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    address: "",
    state: "",
    city: "",
    postalCode: "",
    drivingLicenseNumber: "",
    pancardNumber: "",
    aadharNumber: "",
    vehicleRegister: "",
    vehicleType: "",
    stationCode: "",
    deliveryHub: "",
    vehicleInsurance: "",
    altMobileNumber: "",
    username: "",
    password: "",
  };
  const tokens = localStorage.getItem("token");
  const usernames = localStorage.getItem("username");
  console.log("username: ", usernames);
  const token = JSON.parse(tokens);
  console.log("token: ", token);

  let { id } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  const [status, setStatus] = useState();
  const [errorDoc, setErrorDoc] = useState(false);
  const [errorSelfie, setErrorSelfie] = useState(false);
  const [stations, setStations] = useState([]);
  const [drivingLicense, setDrivingLicense] = useState(null);
  const [aadharCard, setAadharCard] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const [selfie, setSelfie] = useState(null);
  const [bgcDocuments, setBgcDocuments] = useState(null);
  const [vehicleInsurance, setVehicleInsurance] = useState(null);
  const [cursor, setCursor] = useState("pointer");
  const [usernameCheck, setUsernameCheck] = useState();

  useEffect(() => {
    let stationValues;

    const getValues = async () => {
      if (!id) return;
      const { data } = await viewDAServices(id);
      if (data) {
        const dataValues = data;
        console.log("stationValues: ", stationValues);
        const station = stationValues.filter(
          (item) => item.stationCode === dataValues.stationCode
        );
        console.log("station: ", station);
        dataValues.stationCode = station[0];
        console.log("dataValues: ", dataValues);

        setDefaultValues(dataValues);
        // getImg(data.aadharImage,setAadharCard);
        // getImg(data.drivingLicenseImage,setDrivingLicense);
        // getImg(data.pancardImage,setPanCard);
        // getImg(data.selfie,setSelfie);
        // getImg(data.bgcDocuments,setBgcDocuments);
        getImg(data.vehicleInsurance, setVehicleInsurance);
        setStatus(data.status);
      }
    };
    const getStations = async () => {
      const { data } = await viewAllStationServices();
      stationValues = data;
      console.log("data: ", data);
      if (data) {
        setStations(data);
        stationValues = data;
        getValues();
      }
    };
    getStations();
  }, []);

  const checkUsername = async (name) => {
    if(!name) return
    console.log("name: ", name);
    const { data } = await checkDAUsername(name);
    console.log("data: ", data);
    setUsernameCheck(data);
    };

  async function getImg(api, setData) {
    const response = await fetch(
      config.apiUrl + "/roverapi/v1/getimage" + api,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const imageBlob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(imageBlob);
    reader.onloadend = () => {
      const base64data = reader.result;
      console.log("base64data: ", base64data);
      // setData(base64data);
    };
  }

  const validationSchema = yup.object({
    firstName: yup.string().required("First Name is Required"),
    lastName: yup.string().required("Last Name is required"),
    address: yup.string().required("Address is required"),
    vehicleType: yup.string().required("Vehicle Type is required"),
    mobileNumber: yup
      .string()
      .matches(/[6-9]{1}[0-9 ]{3}[0-9 ]{3}[0-9]{3}/, {
        message: "Invalid Mobile number",
        excludeEmptyString: false,
      })
      .required("Phone Number is required"),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    postalCode: yup.number().required("Postal Code is required"),
    drivingLicenseNumber: yup
      .string()
      .required("Driving License Number is required"),
    pancardNumber: yup.string().required("PAN Card Number is required"),
    aadharNumber: yup.number().required("Aadhar Number is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
    // stationCode: yup.object().required("Station Id is required"),
    // deliveryHub: yup.string().required("Delivery Hub is required"),

    // password: yup
    //   .string('Enter your password')
    //   .min(8, 'Password should be of minimum 8 characters length')
    //   .required('Password is required'),
  });
  let navigate = useNavigate();
  const success = () => {
    toast.success("Data Submitted Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const postDeliveryAssociateInformation = async (formValues) => {
    console.log("formValues: ", formValues);
    var form_data = new FormData();
    console.log(form_data);

    // farmValues.stationCode= token.stationCode
    formValues.deliveryHubCode = token.deliveryHubCode;
    formValues.stationCode = token.stationCode;
    for (var key in formValues) {
      form_data.append(key, formValues[key]);
    }

    form_data.append("drivingLicenseImage", drivingLicense);
    form_data.append("selfie", selfie);
    form_data.append("vehicleInsurance", vehicleInsurance);
    form_data.append("bgcDocuments", bgcDocuments);
    form_data.append("pancardImage", panCard);
    form_data.append("aadharImage", aadharCard);
    form_data.append("deliveryPoint", "");
    form_data.append("stationHub", "");
    // form_data.append("stationCode", token.stationCode);
    // form_data.append("deliveryHubCode", token.deliveryHubCode);
    form_data.append("role", "DA");
    form_data.append("createdBy", localStorage.getItem("username"));
    console.log(form_data);
    for (const pair of form_data.entries()) {
      console.log(`${pair[0]}, ${pair[1]}`);
    }
    console.log("sdkhfbkjbfdskj", form_data);
    try {
      const { data } = await addDAService(form_data);
      console.log("data: ", data);
      if (data) {
        success();
        setCursor("pointer");
        navigate(`/rover/user/DAList/viewDA/${data.id}`);
      }
    } catch (er) {
      error();
      setCursor("pointer");
    }
  };
  const updateDeliveryAssociateInformation = async (formValues) => {
    var form_data = new FormData();
    formValues.deliveryHubCode = token.deliveryHubCode;
    formValues.stationCode = token.stationCode;

    // formValues.stationCode = formValues.stationCode.stationCode;

    for (var key in formValues) {
      form_data.append(key, formValues[key]);
    }

    form_data.delete("id");
    form_data.delete("createdAt");
    form_data.delete("updatedBy");
    form_data.delete("updatedAt");
    form_data.set("drivingLicenseImage", drivingLicense);
    form_data.set("selfie", selfie);
    form_data.set("vehicleInsurance", vehicleInsurance);
    form_data.set("bgcDocuments", bgcDocuments);
    form_data.set("pancardImage", panCard);
    form_data.set("aadharImage", aadharCard);

    form_data.set("stationHub", "");
    form_data.set("deliveryPoint", "");
    form_data.set("role", token.role);
    form_data.set("createdBy", usernames);
    form_data.set("status", status);
    try {
      const { data } = await updateDAServices(form_data, id);
      console.log("data: ", data);
      if (data) {
        success();
        setCursor("pointer");
        navigate(`/rover/user/DAList/viewDA/${data.id}`);
      }
    } catch (ex) {
      console.log("ex: ", ex);
      if (ex) {
        error();
      }
    }
  };
  const notify = () => {
    toast.error("Please upload atleast one of the document", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: id ? defaultValues : initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      if(usernameCheck===false){
        return
      }
      if (id) {
        setCursor("wait");
        updateDeliveryAssociateInformation(values);
        return;
      }

      if (!selfie) {
        setErrorSelfie(true);
      } else {
        setErrorSelfie(false);
      }
      if (aadharCard || panCard || drivingLicense) {
        setErrorDoc(false);
        setCursor("wait");
        postDeliveryAssociateInformation(values);
      } else {
        setErrorDoc(true);
        notify();
      }
    },
  });

  return (
    <>
      {id ? (
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ margin: "15px 0px 0px 18px" }}
        >
          Edit Delivery Associate Information
        </Typography>
      ) : (
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ margin: "15px 0px 0px 18px" }}
        >
          Delivery Associate Information
        </Typography>
      )}

      <Card
        sx={{
          margin: "8px 15px 15px 15px",
          py: 3,
          px: 5,
          borderRadius: "8px",
        }}
      >
        {id ? (
          <Box
            sx={{
              backgroundColor: "#F7F7FC",
              borderRadius: "8px 8px 0px 0px",
              boxShadow: "none",
            }}
            px="14px"
            py="10px"
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="subtitle1"
                fontWeight="600"
                sx={{ margin: "0px 0px 0px 0px", fontSize: "20px" }}
              >
                {defaultValues.stationCode?.stationCode}
              </Typography>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={status}
                    onChange={() => {
                      status === 0 ? setStatus(1) : setStatus(0);
                    }}
                  />
                }
                label={
                  status ? (
                    <Typography
                      variant="subtitle1"
                      fontWeight="600"
                      sx={{
                        margin: "0px 0px 0px 0px",
                        fontSize: "16px",
                        color: "green",
                      }}
                    >
                      Active
                    </Typography>
                  ) : (
                    <Typography
                      variant="subtitle1"
                      fontWeight="600"
                      sx={{
                        margin: "0px 0px 0px 0px",
                        fontSize: "16px",
                        color: "red",
                      }}
                    >
                      Inactive{" "}
                    </Typography>
                  )
                }
              />
            </Box>
            <Typography
              variant="subtitle2"
              fontWeight="500"
              sx={{ margin: "0px 0px 10px 0px", lineHeight: "0.5" }}
            >
              {defaultValues.firstName} {defaultValues.lastName}
            </Typography>
          </Box>
        ) : null}
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            {[
              {
                title: "Title",
                value: "title",
                size: 4,
                options: ["Mr.", "Mrs.", "Ms."],
              },
              { title: "First Name", value: "firstName", size: 4 },
              { title: "Last Name", value: "lastName", size: 4 },
              { title: "Phone Number", value: "mobileNumber", size: 4 },
              {
                title: "Alternate Number",
                value: "altMobileNumber",
                size: 4,
              },
              { title: "Email", value: "email", size: 4 },
              { title: "Username", value: "username", size: 6 },
              { title: "Passowrd", value: "password", size: 6 },
              { title: "Address", value: "address", size: 12 },
              { title: "State", value: "state", size: 4 },
              { title: "City", value: "city", size: 4 },
              { title: "Postal Code", value: "postalCode", size: 4 },
              {
                title: "Driving License Number",
                value: "drivingLicenseNumber",
                size: 3,
              },
              { title: "PAN Card Number", value: "pancardNumber", size: 3 },
              { title: "Aadhar Number", value: "aadharNumber", size: 3 },
              {
                title: "Vehicle Insurance Number",
                value: "vehicleInsurance",
                size: 3,
              },
              {
                title: "Vehicle Registration Number",
                value: "vehicleRegister",
                size: 3,
              },
              {
                title: "Vehicle Type",
                value: "vehicleType",
                size: 3,
                options: ["Two Wheeler", "Three Wheeler", "Four Wheeler"],
              },
              // {
              //   title: "Station ID",
              //   value: "stationCode",
              //   size: 3,
              //   options: stations,
              // },
              // { title: "Delivery Hub", value: "deliveryHub", size: 3 },
            ].map((item, i) => {
              return (
                <React.Fragment key={i}>
                  {item.value === "stationCode" ? (
                    <Grid item xs={item.size}>
                      <Typography
                        mt={1}
                        mb={1}
                        variant="subtitle2"
                        fontWeight="bold"
                      >
                        {item.title}
                      </Typography>
                      {/* <Select
                       fullWidth
                       input={<BootstrapInput />}
                       name={item.value}
                       onChange={formik.handleChange}
                       value={formik.values[item.value]}
                     >
                       {item.options.map((opt) => (
                         <MenuItem value={opt}>{opt.stationName}</MenuItem>
                       ))}
                     </Select> */}
                      <Autocomplete
                        // disablePortal
                        disableClearable
                        options={item.options}
                        getOptionLabel={(option) => option.stationName}
                        focused
                        name={item.value}
                        onChange={(event, value) => {
                          formik.setFieldValue(item.value, value);
                        }}
                        // onChange={formik.handleChange}
                        value={
                          formik.values[item.value]
                            ? formik.values[item.value]
                            : null
                        }
                        // fullWidth
                        size="small"
                        renderInput={(params) => (
                          <FormAutoComplete
                            {...params}
                            focused
                            placeholder={item.title}
                            variant="outlined"
                            type="text"
                            error={
                              formik.touched[item.value] &&
                              Boolean(formik.errors[item.value])
                            }
                            helperText={
                              formik.touched[item.value] &&
                              formik.errors[item.value]
                            }
                          />
                        )}
                      />
                    </Grid>
                  ) : item.options ? (
                    <Grid item xs={item.size}>
                      <Typography
                        mt={1}
                        mb={1}
                        variant="subtitle2"
                        fontWeight="bold"
                      >
                        {item.title}
                      </Typography>
                      <FormControl fullWidth>
                        <Autocomplete
                          // disablePortal
                          disableClearable
                          options={item.options}
                          // getOptionLabel={(option) => option.stationName}
                          focused
                          name={item.value}
                          onChange={(event, value) => {
                            formik.setFieldValue(item.value, value);
                          }}
                          // onChange={formik.handleChange}
                          value={
                            formik.values[item.value]
                              ? formik.values[item.value]
                              : null
                          }
                          // fullWidth
                          size="small"
                          renderInput={(params) => (
                            <FormAutoComplete
                              {...params}
                              focused
                              placeholder={item.title}
                              variant="outlined"
                              type="text"
                              error={
                                formik.touched[item.value] &&
                                Boolean(formik.errors[item.value])
                              }
                              helperText={
                                formik.touched[item.value] &&
                                formik.errors[item.value]
                              }
                              disabled={status === 0 ? true : false}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={item.size}>
                      <Typography
                        mt={1}
                        mb={1}
                        variant="subtitle2"
                        fontWeight="bold"
                      >
                        {item.title}
                      </Typography>

                      <FormTextField
                        size="small"
                        fullWidth
                        placeholder={item.title}
                        variant="outlined"
                        type="text"
                        name={item.value}
                        onChange={formik.handleChange}
                        onBlur={() =>
                          item.value === "username"
                            ? checkUsername(formik.values.username)
                            : null
                        }
                        // onBlur={handleBlur}
                        value={formik.values[item.value]}
                        error={
                          formik.touched[item.value] &&
                          Boolean(formik.errors[item.value])
                        }
                        disabled={status === 0 ? true : false}
                        helperText={
                          formik.touched[item.value] &&
                          formik.errors[item.value]
                        }
                        //   value={values.firstName}
                        //   onChange={(e) => handleChange(e, "firstName")}
                      />
                      {item.value==="username"&&<p style={{color:"#DE5D72", fontSize:"12px"}}>{usernameCheck===false?"This Username Already Exist":null}</p>}
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
            <Grid item xs={12}>
              <Divider mt={5} />
            </Grid>

            <Grid item xs={4}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Upload Driving License
              </Typography>
              <Stack direction="row">
                <UploadTextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="Upload"
                  type="text"
                  value={
                    id
                      ? formik.values.drivingLicenseImage
                        ? "Uploaded"
                        : ""
                      : drivingLicense
                      ? drivingLicense.name
                      : null
                  }
                  // onChange={(e) => setSearch(e.currentTarget.value)}
                />
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    color: "#fff",
                    boxShadow: "none",
                  }}
                  onChange={(e) => setDrivingLicense(e.target.files[0])}
                >
                  Upload
                  <input type="file" hidden />
                </Button>
              </Stack>
              {errorDoc ? (
                <Typography variant="subtitle2" sx={{ color: "#DA5636" }}>
                  Please upload atleast one of the document
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={4}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Upload PAN Card
              </Typography>
              <Stack direction="row">
                <UploadTextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="Upload"
                  type="text"
                  value={
                    id
                      ? formik.values.pancardImage
                        ? "Uploaded"
                        : ""
                      : panCard
                      ? panCard.name
                      : null
                  }
                  // onChange={(e) => setSearch(e.currentTarget.value)}
                />
                <Button
                  variant="contained"
                  component="label"
                  onChange={(e) => setPanCard(e.target.files[0])}
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    color: "#fff",
                    boxShadow: "none",
                  }}
                >
                  Upload
                  <input type="file" hidden />
                </Button>
              </Stack>
              {errorDoc ? (
                <Typography variant="subtitle2" sx={{ color: "#DA5636" }}>
                  Please upload atleast one of the document
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={4}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Upload Selfie
              </Typography>
              <Stack direction="row">
                <UploadTextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="Upload"
                  type="text"
                  value={
                    id
                      ? formik.values.selfie
                        ? "Uploaded"
                        : ""
                      : selfie
                      ? selfie.name
                      : null
                  }
                  // onChange={(e) => setSearch(e.currentTarget.value)}
                />
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    color: "#fff",
                    boxShadow: "none",
                  }}
                  onChange={(e) => setSelfie(e.target.files[0])}
                >
                  Upload
                  <input type="file" hidden />
                </Button>
              </Stack>
              {errorSelfie ? (
                <Typography variant="subtitle2" sx={{ color: "#DA5636" }}>
                  Please upload selfie
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={4}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Upload Aadhar Card
              </Typography>
              <Stack direction="row">
                <UploadTextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="Upload"
                  type="text"
                  value={
                    id
                      ? formik.values.aadharImage
                        ? "Uploaded"
                        : ""
                      : aadharCard
                      ? aadharCard.name
                      : null
                  }
                  // value={search}
                  // onChange={(e) => setSearch(e.currentTarget.value)}
                />
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    color: "#fff",
                    boxShadow: "none",
                  }}
                  onChange={(e) => setAadharCard(e.target.files[0])}
                >
                  Upload
                  <input type="file" hidden />
                </Button>
              </Stack>
              {errorDoc ? (
                <Typography variant="subtitle2" sx={{ color: "#DA5636" }}>
                  Please upload atleast one of the document
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={4}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Upload BGC Documents{" "}
              </Typography>
              <Stack direction="row">
                <UploadTextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="Upload"
                  type="text"
                  value={
                    id
                      ? formik.values.bgcDocuments
                        ? "Uploaded"
                        : ""
                      : bgcDocuments
                      ? bgcDocuments.name
                      : null
                  }
                  // value={search}
                  // onChange={(e) => setSearch(e.currentTarget.value)}
                />
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    color: "#fff",
                    boxShadow: "none",
                  }}
                  onChange={(e) => setBgcDocuments(e.target.files[0])}
                >
                  Upload
                  <input type="file" hidden />
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Upload Vehicle Insurance
              </Typography>
              <Stack direction="row">
                <UploadTextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="Upload"
                  type="text"
                  value={
                    id
                      ? formik.values.vehicleInsurance
                        ? "Uploaded"
                        : ""
                      : vehicleInsurance
                      ? vehicleInsurance.name
                      : null
                  }
                  // value={search}
                  // onChange={(e) => setSearch(e.currentTarget.value)}
                />
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    color: "#fff",
                    boxShadow: "none",
                  }}
                  onChange={(e) => setVehicleInsurance(e.target.files[0])}
                >
                  Upload
                  <input type="file" hidden />
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ColorButton
              variant="contained"
              type="submit"
              sx={{ cursor: cursor }}
              disabled={cursor === "wait"}
            >
              Submit
            </ColorButton>
          </Box>
        </form>
      </Card>
    </>
  );
};

export default AddDeliveryAssociate;
