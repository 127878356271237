import { Card, Grid, Typography } from "@mui/material";
import React from "react";
import Cashback from "./Cashback";

const ApplyingCashback = () => {
  const [selectedButton, setSelectedButton] = React.useState("Cashback");

  return (
    <>
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        Offers And Cashback
      </Typography>
      <Card
        sx={{
          margin: "8px 15px 15px 15px",
          py: 3,
          px: 3,
          borderRadius: "8px",
          minheight: "90vh",
        }}
      >
        <Grid container spacing={1}>
          <Grid item sx={2} mb={3}>
            <div
              className={
                selectedButton === "Cashback" ? "selected" : "notSelected"
              }
              onClick={() => {
                setSelectedButton("Cashback");
              }}
            >
              Cashback
            </div>
          </Grid>
          {/* <Grid item sx={2} mb={3}><div className={selectedButton==="History"?"selected":"notSelected"} onClick={()=>{setSelectedButton("History")}}>Order  History</div></Grid> */}
          {/* <Grid item sx={2} mb={3}><div className={selectedButton==="Pending"?"selected":"notSelected"} onClick={()=>{setSelectedButton("Pending")}}>Pending Orders</div></Grid> */}
        </Grid>
        {selectedButton==="Cashback"&&<Cashback/>}
        {/* {selectedButton==="Requests"&&<Requested/>} */}
        {/* {selectedButton==="History"&&<OrderHistoryStationVendor/>} */}
        {/* {selectedButton==="Pending"&&<CostingSentToVendor/>} */}
      </Card>
    </>
  );
};

export default ApplyingCashback;
