import * as React from "react";
import {
  Typography,
  Card,
  Paper,
  IconButton,
  InputAdornment,
  Grid,
  Button,
  Modal,
  Stack,
} from "@mui/material";
import { Search, FilterList, Edit } from "@mui/icons-material";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
// import TableCell from '@mui/material/TableCell';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
// import TableRow from '@mui/material/TableRow';
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import { visuallyHidden } from "@mui/utils";
import XLSX from "xlsx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledTableBagCell, StyledTableBagRow } from "../styles/table";
import { getAllPackets } from "../services/packets";
import Appbar from './../components/Appbar';
import QuickTracking from './../components/QuickTracking';
import { SearchTextField, UploadTextField } from "../styles/textField";
import { ColorButton } from "../styles/button";
import { pickupPacketsDeliveryHub, pickupPacketsStation } from "../services/pickup";
import moment from "moment";
import { viewActiveOrders, viewOrderHistory } from "../services/vendors";
import ActiveOrdersMobile from './ActiveOrderMobile';




function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "vendorBookingId",
    numeric: false,
    disablePadding: true,
    label: "Vendor Delivery ID",
  },
  {
    id: "customerName",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "customerPhone",
    numeric: true,
    disablePadding: false,
    label: "Number",
  },
  {
    id: "vehicleType",
    numeric: true,
    disablePadding: false,
    label: "Vehicle Type",
  },
  {
    id: "deliveryDate",
    numeric: true,
    disablePadding: false,
    label: "Delivery Date ",
  },
  {
    id: "vendorOrderStatus",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },

  
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableBagRow>
        {headCells.map((headCell) => (
          <StyledTableBagCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableBagCell>
        ))}
      </StyledTableBagRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        // pl: { sm: 2 },
        // pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ActiveOrders() {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("orderDate");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [rowData,setRowData]=React.useState([]);

  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowSizeChange = () => { setWidth(window.innerWidth) };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  React.useEffect(()=>{

    const tokens = localStorage.getItem("token");
    

   
    //     if (token.stationCode) {
    //     getAllPacketStation();
    //   } else {
    //     getAllPacketDeliveryHub();
    //   }
    const getPacketList=async()=>{
        const {data}= await viewActiveOrders();
        console.log('data: ', data);
        const sortedData= data.sort((a,b)=>a.createdAt-b.createdAt)
        setRowData(sortedData)  
    }
    getPacketList();

  },[])


  const rows = rowData.filter(
    (item) =>
    item.vendorBookingId.toString().toLowerCase().includes(search.toString().toLowerCase()) ||
  item.customerName
    .toString()
    .toLowerCase()
    .includes(search.toLowerCase()) 
    ||
  item.vehicleType.toString().toLowerCase().includes(search) ||
  item.customerPhone.toString().toLowerCase().includes(search) 

  );



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const downloadExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(rowData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "branches");
    //Buffer
    let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "branches.xlsx");
  };

  
  const [open, setOpen] = React.useState(false);

  return (
    <>
    <Appbar/>
    {/* <div style={{ marginTop: "75px" }}></div> */}
       {/* <QuickTracking /> */}
    
   {width>786?   <Card
        sx={{
          margin: "8px 15px 15px 15px",
          py: 3,
          px: 3,
          borderRadius: "8px",
          minheight: "90vh",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={9} mb={2}>
            <SearchTextField
              size="small"
              fullWidth
              variant="outlined"
              placeholder="Search..."
              type="text"
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: "0px" }}>
                    <IconButton
                      sx={{
                        backgroundColor: "#F7F7FC",
                        borderRadius: "8px 0px 0px 8px",
                      }}
                      edge="start"
                    >
                      <Search sx={{ backgroundColor: "#F7F7FC" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
         
          <Grid item xs={0.5}>
            {/* <i type="button" style={{color:"#168e32"}} class="fa fa-file-excel"></i> */}
            {/* <BackupTable
              onClick={() => downloadExcel()}
              sx={{ height: "38px" }}
            /> */}
            <FontAwesomeIcon
              icon="fa-solid fa-file-excel"
              onClick={() => downloadExcel()}
              type="button"
              style={{
                height: "26px",
                marginTop: "6px",
                cursor: "pointer",
                color: "#168e32",
              }}
            />
          </Grid>
          <Grid item xs={1.8}>
           <Link to="/rover/vendor/vendorDeliveryRequest" className="link-style">
           <ColorButton variant="contained" sx={{marginTop:0}}  >Delivery Request</ColorButton>
           </Link>   
              <Modal
        open={open}
        onClose={()=>setOpen(false)}
      >
          <Box sx={{
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  borderRadius:"8px",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}}>
    <Link to="addpacketsmanually">
     <ColorButton variant="contained"  sx={{width:"48%", marginTop:"0", marginRight:2.2, padding:"10px 35px 10px 35px"}} >Enter Manually</ColorButton>
    </Link>
     <ColorButton variant="contained"  sx={{width:"48%", backgroundColor:"#646E78", color:"#fff",marginTop:"0",padding:"10px 35px 10px 35px"}}>Scan Packet</ColorButton>
     <Stack direction="row" mt={2}>

<UploadTextField
  size="small"
  fullWidth
  variant="outlined"
  placeholder="Shipment ID  "
  type="text"
  // sx={{padding:"10px 35px 10px 35px"}}
  
  // value={drivingLicense?drivingLicense[0].name:""}
  // onChange={(e) => setSearch(e.currentTarget.value)}
 
/>
  <Button
variant="contained"
component="label"
sx={{borderRadius:"0px 8px 8px 0px",
color:"#fff",
boxShadow:"none",
padding:"0px 35px 0px 35px"
}}
// onChange={(e)=>setDrivingLicense(e.target.files)}
>
Submit
</Button>
</Stack>

</Box>
      </Modal>
          </Grid>
          {/* <Grid item xs={0.5}>
              
          <ColorButton variant="contained" sx={{marginTop:0,width:"100%"}}>Back</ColorButton>
          </Grid> */}
        </Grid>

        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{
              width: "100%",
              mb: 2,
              boxShadow:
                "0px 2px 0px -5px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 4px 0px rgb(0 0 0 / 4%)",
            }}
          >
            <TableContainer>
              <Table
                sx={{ minWidth: 1100 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      console.log("isItemSelected: ", isItemSelected);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableBagRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          // role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          // selected={isItemSelected}
                        >
                          <StyledTableBagCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align="left"
                            padding="normal"
                          ><Link to={`/vendor/vendorOrderDetails/${row?.vendorBookingId}`} >
                         {row?.vendorBookingId}
                        </Link>
                          </StyledTableBagCell>
                          <StyledTableBagCell
                            align="left"
                            padding="normal"
                          >{row?.customerName}
                          </StyledTableBagCell>
                          <StyledTableBagCell
                            align="left"
                            padding="normal"
                          >
                         {row?.customerPhone}

                          
                          </StyledTableBagCell>
                          <StyledTableBagCell
                            align="left"
                            padding="normal"
                          >{row.vehicleType}
                          </StyledTableBagCell>
                          <StyledTableBagCell
                            align="left"
                            padding="normal"
                          > {moment(row?.deliveryDate).format("DD/MMM/YYYY")}
                          </StyledTableBagCell>
                          <StyledTableBagCell
                            align="left"
                            padding="normal"
                          >
                        {row?.vendorOrderStatus}
                          </StyledTableBagCell>
                         
                        </StyledTableBagRow>
                      );
                    })}{" "}
                  {emptyRows > 0 && (
                    <StyledTableBagRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <StyledTableBagCell colSpan={6} />
                    </StyledTableBagRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              sx={{ backgroundColor: "#F7F7FC" }}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            
          </Paper>
        </Box>
      </Card>: <ActiveOrdersMobile/>}
      
    </>
  );
}
