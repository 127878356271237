import React, { useState } from "react";
import { Grid } from "@mui/material";
import LeftBar from "./LeftBar";
import { Outlet, Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import AddDeliveryAssociate from "./DeliveryAssociate/AddDA";
import AddStation from "./Station/AddStation";
import DeliveryAssociateList from "./DeliveryAssociate/DeliveryAssociateList";
import Appbar from "./Appbar";
import ViewDA from "./DeliveryAssociate/ViewDA";
import StationList from "./Station/StationList";
import Dashboard from "./Dashboard";
import ViewStation from "./Station/ViewStation";
import AddDeliveryHub from "./Station/AddDeliveryHub";
import BottomBar from "./BottomBar";
import ReceiveShipmentList from "./ReceiveShipment/ReceiveShipmentList";
import AddVendor from "./Vendor/AddVendor";
import VendorList from "./Vendor/VendorList";
import ViewVendor from "./Vendor/ViewVendor";
import DeliveryHubList from "./Station/DeliveryHubList";
import ViewDeliveryHub from "./Station/ViewDeliveryHub";
import { ToastContainer } from "react-toastify";
import AddTeam from "./AddMember/AddTeam";
import ViewTeamMember from "./AddMember/viewTeamMember";
import ViewMemberList from "./AddMember/ViewMemberList";
import { useEffect } from "react";
import { getMemberPermission } from "../services/memberAndPermissions";
import TeamList from "./AddTeam/TeamList";
import CreateTeam from "./AddTeam/CreateTeam";
import AllRunsheets from './../DeliveryOperations/AllRunsheets';
import CreateRunsheets from './../DeliveryOperations/CreateRunsheets';
import TodayRunsheets from './../DeliveryOperations/TodayRunsheet';
import DARunsheet from './../DeliveryOperations/DARunsheet';
import SearchPackets from "./SearchPackets";
import PacketInfo from './PacketInfo';
import DAReconciliation from "./Cash/DAReconcillation";
import CashDepositList from "./Cash/CashDepositList";
import ParticularDACash from "./Cash/ParticularDACash";
import AddPickupPackets from './../PickUpOperations/AddPickupPacket';
import CreateRunsheetsPickup from './../PickUpOperations/CreateRunsheetsPickup';
import TodayRunsheetsPickup from "../PickUpOperations/TodayRunsheetPickup";
import AllPacketPickupList from "../PickUpOperations/AllPickupPacketList";
import SearchPacketsForPickups from "../PickUpOperations/SearchForPickup";
import DARunsheetPickup from './../PickUpOperations/DARunsheet';
import PacketList from './ReceiveShipment/PacketList';
import AddPacketsManually from "./ReceiveShipment/AddPacketsManually";
import RequestAndActive from "./StationD2D/RequestAndActive";
import OrderDetails from "../VendorUI/OrderDetails";
import OrderHistoryStationVendor from "./StationD2D/OrderHistoryStationVendor";
import CreateRunsheetVendor from "./StationD2D/CreateRunsheetsVendor";
import QuickTracking from "./QuickTracking";
import CashReconciliationVendor from "./StationD2D/CashReconciliationVendor";
import ParticularDAVendorCash from "./StationD2D/ParticularDAVendorCash";
import RunsheetClosure from "./StationD2D/RunsheetClosure";
import VendorPacketFormStation from "./StationD2D/VendorPacketFormStation";
import CostingSentToVendor from "./StationD2D/CostingSentToVendor";
import ApplyingCashback from "./StationD2D/ApplyingCashback";

const LandingPageMembers = () => {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const tokens = localStorage.getItem("token");
    const token = JSON.parse(tokens).userId;
    console.log("token: ", token);
    async function memberPermission() {
      const { data } = await getMemberPermission(token);
      if (data) {
        setPermissions(data);
      }
    }
    memberPermission();
  }, []);


  return (
    <>
      <Appbar />

      <ToastContainer />
      <Grid container spacing={2} sx={{ marginTop: "45px" }}>
        <Grid item xs={3} xl={2}>
          <LeftBar />
        </Grid>
        <Grid item xs={9}  xl={10}>
      <QuickTracking/>
          <Routes>
            <Route
              path="/addDeliveryAssociate"
              element={<AddDeliveryAssociate />}
            ></Route>
            <Route
              path="DAList/editDA/:id"
              element={<AddDeliveryAssociate />}
            ></Route>
            <Route
              path="vendorList/editVendor/:id"
              element={<AddVendor />}
            ></Route>
            <Route path="addStation" element={<AddStation />}></Route>
            <Route path="addDeliveryHub" element={<AddDeliveryHub />}></Route>
            <Route
              path="receiveShipment"
              element={<ReceiveShipmentList />}
            ></Route>
            
            <Route exact path="receiveShipment/packetlist" element={<PacketList />} />
            <Route exact path="receiveShipment/packetlist/addpacketsmanually" element={<AddPacketsManually />} />
            <Route
              path="stationList/editStation/:id"
              element={<AddStation />}
            ></Route>
            <Route path="DAList/viewDA/:id" element={<ViewDA />}></Route>
            <Route exact path="/applyingCashback" element={<ApplyingCashback />} />
            <Route path="addVendor" element={<AddVendor />}></Route>
            <Route path="daReconciliation" element={<DAReconciliation />}></Route>
            <Route path="particularDACash/:id/:ids" element={<ParticularDACash />}></Route>
            <Route path="cashDepositList" element={<CashDepositList />}></Route>
            <Route path="allRunsheets" element={<TodayRunsheets />}></Route>
            <Route path="allpickupPackets" element={<AllPacketPickupList />}></Route>
            <Route path="searchPackage" element={<SearchPackets />}></Route>
            <Route path="searchPackagePickup" element={<SearchPacketsForPickups />}></Route>
            <Route path="createRunsheet" element={<CreateRunsheets />}></Route>
            <Route path="createRunsheetPickup" element={<CreateRunsheetsPickup />}></Route>
            <Route path="todayRunsheet/DARunsheet/:id" element={<DARunsheet />}></Route>
            <Route path="todayRunsheetPickup/DARunsheet/:id" element={<DARunsheetPickup />}></Route>
            <Route path="/particularRunsheet" element={<DARunsheet />}></Route>
            <Route path="todayRunsheet" element={<TodayRunsheets />}></Route>
            <Route path="runsheetClosure" element={<RunsheetClosure />}></Route>
            <Route path="todayRunsheetPickup" element={<TodayRunsheetsPickup />}></Route>
            <Route path="searchPackets" element={<SearchPackets />}></Route>
            <Route path="packetInformation" element={<PacketInfo />}></Route>
            <Route path="requestandactive" element={<RequestAndActive />}></Route>
            <Route path="addTeam" element={<AddTeam />}></Route>
            <Route path="TeamList" element={<TeamList />}></Route>
            <Route path="TeamList/viewTeamDetails/:id" element={<CreateTeam />}></Route>
            <Route path="createTeam" element={<CreateTeam />}></Route>
            <Route
              path="/viewMemberList/viewTeamMember/:id"
              element={<ViewTeamMember />}
            ></Route>
            <Route
              path="/vendorList/viewVendor/:id"
              element={<ViewVendor />}
            ></Route>
            <Route path="viewMemberList" element={<ViewMemberList />}></Route>
            <Route path="vendorList" element={<VendorList />}></Route>
            <Route path="deliveryHubList" element={<DeliveryHubList />}></Route>
            <Route path="orderHistoryVendorStation" element={<OrderHistoryStationVendor />}></Route>
            <Route path="vendorDAReconciliation" element={<CashReconciliationVendor />}></Route>
            <Route path="particularDAVendorCash/:id/:ids" element={<ParticularDAVendorCash />}></Route>
            <Route path="createRunsheetVendor" element={<CreateRunsheetVendor />}></Route>
            <Route path="costingSentOrders" element={<CostingSentToVendor />}></Route>
            <Route path="allpickupPackets/addPickupPackets" element={<AddPickupPackets />}></Route>
            <Route path="vendorOrderDetails/:id" element={<OrderDetails />}></Route>
            <Route
              path="deliveryHubList/viewDeliveryHub/:id"
              element={<ViewDeliveryHub />}
            ></Route>
            <Route
              path="deliveryHubList/editDeliveryHub/:id"
              element={<AddDeliveryHub />}
            ></Route>
            <Route
              path="stationList/viewStation/:name"
              element={<ViewStation />}
            ></Route>
            <Route path="DAList" element={<DeliveryAssociateList />}></Route>
            <Route path="vendorPacketFormStation" element={<VendorPacketFormStation />}></Route>
            <Route path="stationList" element={<StationList />}></Route>
            <Route path="/" element={<Dashboard />}></Route>
          </Routes>
        </Grid>
      </Grid>
    </>
  );
};

export default LandingPageMembers;
