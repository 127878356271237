import http from "./httpService";
import config from "../config.json";


const tokens = localStorage.getItem("token");
const token=JSON.parse(tokens)?.jwttoken;
console.log('token: ', token);
  
const head = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      Authorization: `Bearer ${token}`,
    },
  };

export function addStationServices(data){
    return http.post(config.apiUrl+"/roverapi/v1/station", data,head);
}

export function viewStationServices(data){
    return http.get (config.apiUrl+"/roverapi/v1/station/"+data,head);
}
export function viewAllStationServices(){
    return http.get (config.apiUrl+"/roverapi/v1/station",head);
}
export function updateStationServices(data,id){
    return http.put (config.apiUrl+"/roverapi/v1/station/"+id,data,head);
}