import * as React from "react";
import {
  Typography,
  Card,
  Paper,
  IconButton,
  InputBase,
  InputAdornment,
  Grid,
  Popover,
  Menu,
  MenuItem,
  Badge,
  styled,
  Avatar,
  Autocomplete,
} from "@mui/material";
import {
  FormAutoComplete,
  FormTextField,
  SearchTextField,
} from "../../styles/textField";
import { Search, FilterList, Edit } from "@mui/icons-material";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
// import TableCell from '@mui/material/TableCell';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
// import TableRow from '@mui/material/TableRow';
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { StyledTableCell, StyledTableRow } from "../../styles/table";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BackupTable } from "@mui/icons-material";
import XLSX from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faCoffee } from "@fortawesome/fontawesome-free-solid";
import {
  viewAllDAService,
  viewDAServicesDeliveryHub,
  viewDAServicesStation,
} from "../../services/deliveryAssociate";
import { getMemberPermission } from "../../services/memberAndPermissions";
import QuickCashTracking from "./../../DA_UI/CashTransaction/QuickCashTracking";
import QuickTracking from "../QuickTracking";
import { MobileDatePicker, MobileDateTimePicker } from "@mui/x-date-pickers";
import { ColorButton } from "../../styles/button";
import { getAllRecordsWithDate } from "../../services/cashReconcile";
import { useState } from "react";
import {
  getAllD2DCashReconcileParticularDate,
  getAllD2DCashReconcileToday,
  getAllD2DPendingCashReconcile,
} from "./../../services/vendors";
import moment from "moment";

function createData(name, calories, fat, carbs, protein, vehicle, node) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    vehicle,
    node,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "2px solid currentColor",
      content: '" "',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "customerName",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "managerName",
    numeric: true,
    disablePadding: false,
    label: "Manager Name",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "expectedCash",
    numeric: true,
    disablePadding: false,
    label: "Expected Cash ",
  },
  {
    id: "cashCollected",
    numeric: true,
    disablePadding: false,
    label: "Cash Collected",
  },
  {
    id: "cashDeposited",
    numeric: true,
    disablePadding: false,
    label: "Cash Deposited",
  },
  {
    id: "variance",
    numeric: true,
    disablePadding: false,
    label: "Variance",
  },

  {
    id: "remark",
    // numeric: true,
    disablePadding: false,
    label: "Remark",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        // pl: { sm: 2 },
        // pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function CashReconciliationVendor() {
  const [order, setOrder] = React.useState("asc");
  const [value, setValue] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pending, setPending] = React.useState(true);

  const [daList, setDaList] = useState([]);
  const [da, setDa] = useState();

  const [rowData, setRowData] = React.useState([]);
  console.log("rowData: ", rowData);

  const navigate = useNavigate();

  const [permissions, setPermissions] = React.useState([]);
  const [role, setRole] = React.useState([]);

  React.useEffect(() => {
    const tokens = localStorage.getItem("token");
    const token = JSON.parse(tokens);
    console.log("token: ", token);
    setRole(token.role);
    async function memberPermission() {
      const { data } = await getMemberPermission(token.userId);
      if (data) {
        setPermissions(data);
      }
    }
    memberPermission();
  }, []);

  // const today=new Date();
  // console.log('today: ', today);
  //   let day = today.getDate();
  //   let month = today.getMonth()+1;
  //   let year = today.getFullYear();
  //   let date=year+"-"+month+"-"+day
  //   console.log('date: ', date);
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;

  React.useEffect(() => {
    const getBagList = async () => {
      if (!pending) {
        const { data } = await getAllD2DCashReconcileToday();
        console.log("data: ", data);
        setDa();
        setValue("");
        // const sortedData= data.sort((a,b)=>b.id-a.id)
        setRowData(data);
      } else {
        const { data } = await getAllD2DPendingCashReconcile();
        console.log("data: ", data);
        setDa();
        setValue("");

        // const sortedData= data.sort((a,b)=>a.assignDate-b.assignDate)
        setRowData(data);
      }
    };
    getBagList();
  }, [pending]);

  React.useEffect(() => {
    console.log("Date", value);
    if(!value) return
    var today = new Date(value);
    console.log("today: ", today);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    const getBagList = async () => {
      const { data } = await getAllD2DCashReconcileParticularDate(today);
      console.log("data: ", data);

      setRowData(data);
    };
    getBagList();
  }, [value]);

  React.useEffect(() => {
    const tokens = localStorage.getItem("token");
    const token = JSON.parse(tokens);
    console.log("token: ", token);

    // getPacketList();
    const getAllDAStation = async () => {
      const { data } = await viewDAServicesStation(token.stationCode);
      if (data) {
        setDaList(data);
      }
    };
    const getAllDADeliveryHub = async () => {
      const { data } = await viewDAServicesDeliveryHub(token.deliveryHubCode);
      if (data) {
        setDaList(data);
      }
    };
    if (token.stationCode) {
      getAllDAStation();
    } else {
      getAllDADeliveryHub();
    }
  }, []);

  const rows = rowData?.filter(
    (item) => item
    // item?.firstName.toLowerCase().includes(search.toLowerCase()) ||
    // item?.id.toLowerCase().includes(search.toLowerCase()) ||
    // item?.mobileNumber.toLowerCase().includes(search.toLowerCase()) ||
    // item?.email.toLowerCase().includes(search.toLowerCase()) ||
    // item?.stationCode.toLowerCase().includes(search.toLowerCase()) ||
    // item?.stationHub.toLowerCase().includes(search)
  );

  // calories,
  // fat,
  // carbs,
  // protein,
  // vehicle,

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const downloadExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(rowData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "branches");
    //Buffer
    let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "branches.xlsx");
  };

  const editPer = permissions?.editDA || role === "Admin";

  return (
    <>
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        Delivery Associate Reconciliation
      </Typography>
      <Card
        sx={{
          margin: "8px 15px 15px 15px",
          py: 3,
          px: 3,
          borderRadius: "8px",
          minheight: "90vh",
        }}
      >
        <Grid container spacing={1} mb={1}>
          <Grid item xs={3}>
            <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
              Date
            </Typography>
            <MobileDatePicker
              inputFormat="dd/MM/yyyy"
              // minDate={new Date()}
              focused
              renderInput={(params) => (
                <SearchTextField
                  {...params}
                  size="small"
                  fullWidth
                  placeholder="Select Date"
                  variant="outlined"
                />
              )}
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              //  {id? disabled={!edit}:null}
              //   name={item.name}
              fullWidth
              //   value={formik.values[item.name]}
              //   onChange={(value) =>
              //     formik.setFieldValue("deliveryDate", value, true)
              //   }
              // onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
              Select DA
            </Typography>
            <Autocomplete
              disablePortal
              // disableClearable
              key={pending}
              options={daList}
              getOptionLabel={(option) => option.username}
              focused
              // name="stationCode"

              onChange={(event, value) => {
                setDa(value);
              }}
              // onChange={formik.handleChange}
              value={da}
              //   value={
              //     formik.values.stationCode
              //       ? formik.values.stationCode
              //       : null
              //   }
              // fullWidth
              size="small"
              renderInput={(params) => (
                <FormAutoComplete
                  {...params}
                  // value={da}
                  focused
                  placeholder="Select DA"
                  variant="outlined"
                  type="text"
                />
              )}
            />
          </Grid>
          <Grid item xs={2} mt={-1}>
            <ColorButton
              variant="contained"
              onClick={() => {
                // var today = new Date();
                // var dd = String(today.getDate()).padStart(2, '0');
                // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                // var yyyy = today.getFullYear();

                // today = mm + '/' + dd + '/' + yyyy;

                // let day = value.getDate();
                let date = "undefined";
                if (value) {
                  let day = String(value?.getDate()).padStart(2, "0");

                  let month = String(value.getMonth() + 1).padStart(2, "0");
                  let year = value.getFullYear();
                  date = year + "-" + month + "-" + day;
                }
                console.log("date: ", date);
                navigate(
                  `/rover/user/particularDAVendorCash/${date}/${da?.username}`
                );
              }}
            >
              Get Data
            </ColorButton>
          </Grid>
          <Grid item xs={1} mt={5}>
            <div
              className={pending === false ? "selected" : "notSelected"}
              onClick={() => {
                setPending(false);
              }}
            >
              Today
            </div>
          </Grid>
          <Grid item xs={1} mt={5}>
            <div
              className={pending === true ? "selected" : "notSelected"}
              onClick={() => {
                setPending(true);
              }}
            >
              Pending
            </div>
          </Grid>
          {/* <Grid item xs={4} mt={-1}>
       <ColorButton variant="contained">Get Another Data</ColorButton>
       
       </Grid> */}
        </Grid>

        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{
              width: "100%",
              mb: 2,
              boxShadow:
                "0px 2px 0px -5px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 4px 0px rgb(0 0 0 / 4%)",
            }}
          >
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      console.log("isItemSelected: ", isItemSelected);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          // role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          // selected={isItemSelected}
                        >
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align="left"
                            padding="normal"
                            sx={{ width: "13%" }}
                          >
                            {row.customerName}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "10%" }}
                          >
                            {row.managerName}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "10%" }}
                          >
                            {moment(row.assignDate).format("DD/MMM/YYYY")}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "10%" }}
                          > {parseInt(row.expectedCashFromDa?row.expectedCashFromDa:0)+parseInt(row.expectedCashFromVendor?row.expectedCashFromVendor:0)}{" "}
                         
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "13%" }}
                          >
                            {row.cashCollected}{" "}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "13%" }}
                          >
                             {parseInt(row.cashDepositedByDa?row.cashDepositedByDa:0)+ parseInt(row?.cashDepositedByVendor?row?.cashDepositedByVendor:0)}{" "}
                          
                            </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "12%" }}
                          >
                            {parseInt(row.cashDepositedByDa?row.cashDepositedByDa:0)+ parseInt(row?.cashDepositedByVendor?row?.cashDepositedByVendor:0)
                              ? parseInt(row.daVariance?row.daVariance:0)+parseInt(row.vendorVariance?row.vendorVariance:0)
                              : "No Cash Deposited at Station"}{" "}
                          </StyledTableCell>

                          <StyledTableCell sx={{ width: "11%" }}>
                            {row.remark}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}{" "}
                  {emptyRows > 0 && (
                    <StyledTableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <StyledTableCell colSpan={6} />
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              sx={{ backgroundColor: "#F7F7FC" }}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Card>
    </>
  );
}
