import http from "./httpService";
import config from "../config.json";


const tokens = localStorage.getItem("token");
const token=JSON.parse(tokens)?.jwttoken;
  
const head = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      Authorization: `Bearer ${token}`,
    },
  };

  export function addDeliveryHubServices(data){
    return http.post(config.apiUrl+"/roverapi/v1/deliveryhub", data,head);
}

export function viewDeliveryHubServices(data){
    return http.get (config.apiUrl+"/roverapi/v1/deliveryhub/"+data,head);
}
export function viewAllDeliveryHubServices(){
    return http.get (config.apiUrl+"/roverapi/v1/deliveryhub",head);
}
export function viewDeliveryHubIdServices(){
    return http.get (config.apiUrl+"/roverapi/v1/alldeliveryhubcodename",head);
}
export function updateDeliveryHubServices(data,id){
    return http.put (config.apiUrl+"/roverapi/v1/deliveryhub/"+id,data,head);
}