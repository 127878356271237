import { ArrowDropDown, NotificationsNone } from '@mui/icons-material';
import { AppBar, Box, styled, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import avatar from "../images/avatar.png";

const Logo = styled("Box")(({ theme }) => ({
    backgroundColor: "#FF6A1A",
  // display: "flex",
  // gap: "20px",
  // alignItems: "center"
  borderRadius:"8px",
  height:"40px",
  width:"40px",
  marginRight:"0.5rem"
}));



const AppBarDA = () => {

    const navigate=useNavigate();
    return ( <>
  <Box  >

<AppBar position="fixed" sx={{  boxShadow:"0px 2px 1px -1px rgb(219 215 244 / 20%),  0px 1px 3px 0px rgb(0 0 0 / 12%)", position:"fixed"}} color="appbarColor" >


<div className="container">
  <div className="left">
    <Logo> </Logo>

<Typography variant="h6" fontWeight="bold">
      Rover
    </Typography>
 
  </div>

  <div className="right">
    {(window.localStorage.getItem('token'))&&
    (
      <>
    <NotificationsNone className="icons" />
    <img src={avatar} alt="avatar" />
    <div className="profile">
      <ArrowDropDown className="icons" />
      <div className="options">
        <span onClick={()=>{
          navigate("/rover/user/viewMemberList")
        }}>View Team</span>
        <span onClick={()=>{
          window.localStorage.removeItem('token');
          navigate("/")
          }}>Logout</span>
      </div>
    </div>
      </>

    )
    }

  </div>
</div>

{/* <ThemeToggle/> */}
</AppBar>
</Box>
    </> );
}
 
export default AppBarDA;