import http from "./httpService";
import config from "../config.json";


const tokens = localStorage.getItem("token");
const token=JSON.parse(tokens)?.jwttoken;
const userId= JSON.parse(tokens)?.userId
  
const head = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      Authorization: `Bearer ${token}`,
    },
  };


 
  export function getCashbackForParticularVendorList(){
    return http.get(config.apiUrl+"/roverapi/v1/getCashbackForParticularVendorList/"+userId ,head);
}
  export function deleteCashbackAndOfferRecord(id){
    return http.get(config.apiUrl+"/roverapi/v1/deleteCashbackAndOfferRecord/"+id ,head);
}
export function viewVendorDetails(){
 
  return http.get(config.apiUrl+"/roverapi/v1/vendor/"+userId,head);
}
  export function vendorCashbackDetails(){
    return http.get(config.apiUrl+"/roverapi/v1/vendorCashbackDetails/"+userId ,head);
}
  export function vendorCashbackDetails2(data){
    return http.get(config.apiUrl+"/roverapi/v1/vendorCashbackDetails/"+data ,head);
}

export function setOverallOffersAndCashback(data){
    return http.post (config.apiUrl+"/roverapi/v1/setOverallOffersAndCashback", data,head);
}
export function cashbackForParticularVendor(data){
    return http.post (config.apiUrl+"/roverapi/v1/cashbackForParticularVendor", data,head);
}
export function updateIndivisualCashbackRecord(data){
    return http.post (config.apiUrl+"/roverapi/v1/updateIndivisualCashbackRecord", data,head);
}