
import { Card, Divider, IconButton, InputAdornment } from "@mui/material";
import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material";
import { CssTextField } from "../styles/textField";
import { Box } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ColorButton } from "../styles/button";
import { Formik } from "formik";
import Appbar from './Appbar';
import { loginServices } from "../services/login";

const Form = styled(Box)({
  padding: "40px 80px",
});

const LoginDA = () => {
  
  const [show, setShow] = React.useState(false);

  const handleClickShowPassword = () => {
      console.log("Here1");
    setShow(!show)
  };

  const handleMouseDownPassword = (event) => {
    console.log("Here2");
    event.preventDefault();
  };
  
  async function submitLogin(values){
    // console.log(values);
   try{
    const {data}= await loginServices(values);
    console.log('data: ', data.token);
    localStorage.setItem("token",data.token)
    window.location.href="/rover/user";
    
   }catch(ex){
    if(ex.response && ex.response.status===401){
      
    }
   }
    // console.log('data: ', data);
    
  }

    return ( <>
  <Appbar/>
  <Card sx={{ width: "50%", py: 3, borderRadius: "8px" }}>
        <Typography variant="h6" fontWeight="bold" align="center" mb={2}>
          Sign In
        </Typography>
        <Divider
          sx={{
            color: "#FF6A1A",
            backgroundColor: "#FF6A1A",
            borderColor: "#FF6A1A",
            borderWidth: "2px",
          }}
        />

        <Formik
        validateOnsubmitForm
          initialValues={{ username: "", password: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.username) {
              errors.username = "Required";
            } 
            // else if (
            //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
            // )
            //  {
            //   errors.username = "Invalid username address";
            // }
            if (!values.password) {
              errors.password = "Required";
            }
            console.log(errors);
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values);
            // isValidating(true);
            setTimeout(() => {
              setSubmitting(false);
              submitLogin(values);
            }, 400);

          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isValidating,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Username
              </Typography>
              <CssTextField
                size="small"
                fullWidth
                placeholder="Enter Your Username Here"
                variant="outlined"
                type="username"
                name="username"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.username}
                //   value={values.username}
                //   onChange={(e) => handleChange(e, "username")}
              />
              {errors.username ? (
                <Typography variant="caption" sx={{ color: "#FF0000" }}>
                  {errors.username}
                </Typography>
              ) : null}

              <Typography mt={2} mb={1} variant="subtitle2" fontWeight="bold">
                Password
              </Typography>
              <CssTextField
                size="small"
                fullWidth
                placeholder="Enter Your Password Here"
                variant="outlined"
                type={show ? "text" : "password"}
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                //   value={values.password}
                //   onChange={(e) => handleChange(e, "password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {!show ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password ? (
                <Typography variant="caption" sx={{ color: "#FF0000" }}>
                  {errors.password}
                </Typography>
              ) : null}

              <div>
                <Typography
                  variant="caption"
                  sx={{ display: "flex", justifyContent: "right", mt: 1 }}
                  color="secondary"
                  fontWeight="500"
                >
                  Forgot Password?
                </Typography>
              </div>
              <ColorButton variant="contained" onClick={handleSubmit} sx={{width:"100%"}}>Sign In</ColorButton>
            </Form>
          )}
        </Formik>
      </Card>
    </> );
}
 
export default LoginDA;