import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom"
import ThemeContextProvider from './context/mode';
// import 'font-awesome/css/font-awesome.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <>
    <div className="scrollhost">

    <ThemeContextProvider>
      



    <BrowserRouter>
  <Routes>
    <Route   path="/*" element={<App/>}/>
  </Routes>

    </BrowserRouter>
  

    </ThemeContextProvider>
    </div>
  </>
);

