import Appbar from "./components/Appbar";
// import { useEffect } from "react";
import Login from "./components/Login";
import "./styles/layout.scss";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import AddDeliveryAssociate from "./components/DeliveryAssociate/AddDA";
import { ThemeContext } from "./context/mode";
import { useContext, useEffect, useState } from "react";
import { ThemeProvider, CssBaseline, Grid } from "@mui/material";
import { theme, darkTheme } from "./theme";
import LeftBar from "./components/LeftBar";
import AddStation from "./components/Station/AddStation";
import DeliveryAssociateList from "./components/DeliveryAssociate/DeliveryAssociateList";
import Dashboard from "./components/Dashboard";
import BagList from "./components/ReceiveShipment/BagList";
import "react-toastify/dist/ReactToastify.css";
import BagDetails from "./components/ReceiveShipment/BagDetails";
import LoginDA from "./components/LoginDA";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import LandingPageMembers from "./components/LandingPageMembers";
import LandingPageDA from "./components/LandingPageDa";
import ParticularRunsheet from "./DeliveryOperations/ParticularRunsheet";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PacketList from "./components/ReceiveShipment/PacketList";
import AddPacketsManually from "./components/ReceiveShipment/AddPacketsManually";
import AddBagManually from "./components/ReceiveShipment/AddBagManually";
import { LocalizationProvider } from "@mui/x-date-pickers";
import PacketInfo from './components/PacketInfo';
import ParticularRunsheetPickup from "./PickUpOperations/ParticularRunsheetPickup";
import AddPickupPackets from "./PickUpOperations/AddPickupPacket";
import PacketInfoPickup from "./PickUpOperations/PickupPacketInfo";
import { ToastContainer } from "react-toastify";
import VendorDeliveryRequestForm from "./VendorUI/VendorDeliveryRequestForm";
import OrderHistory from "./VendorUI/OrderHistory";
import OrderDetails from "./VendorUI/OrderDetails";
import LandingPageVendors from "./components/LandingPageVendors";
import ApplyingCashback from "./components/StationD2D/ApplyingCashback";
import Homepage from './Homepage/Homepage';
import RoverRoutes from "./RoverRoutes";
import PrivacyPolicy from "./Homepage/PrivacyPolicy";

const ROLES = ["Admin", "DA", "Member"];

function App() {
  const context = useContext(ThemeContext);
  !localStorage.getItem("mode")
    ? localStorage.getItem("mode")
    : localStorage.setItem("mode", "light-mode");
  const { isLightTheme } = context;
  // console.log("isLightTheme: ", isLightTheme);
  const [mode, setMode] = useState();
  useEffect(() => {
    setMode(localStorage.getItem("mode"));
  }, [context]);

  console.log("mode", mode);

  function PageRoute() {
    if (!localStorage.getItem("token")) {
      <Route exact path="/" element={<Login />} />;
    } else {
    }
  }

  // window.addEventListener("beforeunload", () => localStorage.removeItem('token'));
  // window.onbeforeunload = function() {
  //   localStorage.removeItem("token");
  //   return '';
  // };

  return (
    <>
      <ThemeProvider theme={isLightTheme ? theme : darkTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <ToastContainer />

          <Routes>
            <Route path="/" element={<Layout />}>
              <Route exact path="/" element={<Homepage />} />
              <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route exact path="/rover/*" element={<RoverRoutes />} />
              
              
            </Route>
          </Routes>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
