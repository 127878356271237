import { Card, Stack, Typography } from "@mui/material";
import React from "react";
import { ColorButton } from "../styles/button";
import { FormTextField } from "../styles/textField";
import QuickTracking from "./QuickTracking";

const SearchPackets = () => {
  return (
    <>
      <Card
        sx={{
          margin: "8px 15px 15px 15px",
          py: 3,
          px: 5,
          borderRadius: "8px",
          // minHeight:"87vh"
        }}
      >
            <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        Search
      </Typography>
            <Typography
        variant="caption"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        Search for more information about packages, containers or associates
      </Typography>
      <Stack direction="row"  mt={2}>
      <FormTextField
                  
                  size="large"
                  fullWidth
                  placeholder="Search for Packet, DA or Team Member"
                  variant="outlined"
                  type="text"
                //   value={item.designationName}
                //   onChange={(e) => {
                //     const data = [...designation];
                //     data[i].designationName = e.target.value;
                //     setDesignation(data);
                //   }}
            
                />
              <ColorButton variant="contained" sx={{marginTop:0,paddingLeft:"35px", paddingRight:"35px",borderRadius:"0px 8px 8px 0px", backgroundColor:"#FF6A1A", color:"#fff", width:"250px"}}> Search</ColorButton>
            </Stack>

      </Card>
    </>
  );
};

export default SearchPackets;
