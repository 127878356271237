import http from "./httpService";
import config from "../config.json";


const tokens = localStorage.getItem("token");
const token=JSON.parse(tokens)?.jwttoken;
console.log('token: ', token);
  
const head = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      Authorization: `Bearer ${token}`,
    },
  };

  
export function addTeamAndDesignation(data){
    return http.post(config.apiUrl+"/roverapi/v1/memberteam", data,head);
}
export function checkTeamAndDesignation(data){
    return http.get(config.apiUrl+"/roverapi/v1/memberteamnamecheck/"+ data,head);
}
export function getAllTeamNames(){
    return http.get(config.apiUrl+"/roverapi/v1/memberteam",head);
}
export function getAllDesignationNames(){
    return http.get(config.apiUrl+"/roverapi/v1/memberdesignation",head);
}
export function getAllTeamNamesWithId(){
    return http.get(config.apiUrl+"/roverapi/v1/allmemberteamnameid",head);
}
export function getTeamDetailsById(data){
    return http.get(config.apiUrl+"/roverapi/v1/memberteam/"+data,head);
}
