import { Box, Button, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppBarDA from "./AppBarDA";
import QuickTrackingDA from "./QuickTrackingDA";
import { Grid } from "@mui/material";
import { getDeliveryBoyPackets } from "../services/packets";
import { Link } from 'react-router-dom';

const TodayDeliveries = () => {

  const [today,setToday]=useState([])

  useEffect(()=>{
    const getTodayDelivery=async()=>{
      const {data}= await getDeliveryBoyPackets();
      if(data){
        console.log('data: ', data);
        setToday(data)
      }
    }
    getTodayDelivery()
  },[])

  return (
    <>
      {/* <AppBarDA/> */}
      <QuickTrackingDA />
      <div style={{ padding: "10px" }}>
        <Card sx={{ padding: "10px" }}>
          <Typography variant="heading6" fontWeight={600} mb={1}>
            Today's Deliveries
          </Typography>
          {today.map((item, i) => (
            <React.Fragment key={i}>
              <Link to={item.packageStatus!=="pending"?"#":`rover/packageInformation/${item.id}`} className="link-style" style={{textDecoration:"none #important"}}>
              <Box
                sx={{
                  marginBottom: "8px",
                  padding: "5px",
                  backgroundColor: "#F4F8FF",
                  position: "relative"
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={1.5}>
                    <div
                      style={{  
                        backgroundColor: "#44AD1F",
                        borderRadius: "12px",
                        height: "25px",
                        width: "25px",
                        textAlign:"center",
                        color:"#fff"
                      }}
                      className="centerVertically"
                    >
                      {i + 1}
                    </div>
                  </Grid>
                  <Grid item xs={6.5}>
                    <div style={{lineHeight:1, color:"#11142D"}}>
                      <Typography variant="body2" fontWeight={600} sx={{m:0, p:0}} p={0} m={0}>{item.customerName}</Typography>
                      <Typography variant="caption" p={0} m={0}  sx={{m:0, p:0}}fontWeight={400}>{item.address}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={3.5} sx={{color:"#11142D"}}>
                      <Typography variant="caption" fontSize={10} fontWeight={600}>{item.paymentMode}</Typography>
                      <div>
                        <div style={{color:"white", fontSize:10, padding:1,backgroundColor:item.packageStatus==="delivered"?"#44AD1F":"#B10000", textAlign:"center",borderRadius:"12px"}}>{"  "+item.packageStatus.toUpperCase()+"  "}</div>
                        </div>

                  </Grid>
                </Grid>
              </Box>
              </Link>
              
            </React.Fragment>
          ))}
        </Card>
      </div>
    </>
  );
};

export default TodayDeliveries;
