import http from "./httpService";
import config from "../config.json";


const tokens = localStorage.getItem("token");
const token=JSON.parse(tokens)?.jwttoken;
  
const head = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      Authorization: `Bearer ${token}`,
    },
  };

export function viewAllVendorServices(){
    return http.get(config.apiUrl+"/roverapi/v1/vendor",head);
}
export function viewVendorServices(data){
    console.log('data: ', data);
    return http.get(config.apiUrl+"/roverapi/v1/vendor/"+data,head);
}

export function addVendorService(data){
    console.log('token: ', token);
  return http.post(config.apiUrl+"/roverapi/v1/vendor", data,{
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
      "access-control-allow-origin": "*",
      Authorization: `Bearer ${token}`,
    }, 
  });
}