import { createTheme } from "@mui/material";


export const theme=createTheme({
    palette:{
          background: {
           
            default: "#F4F8FF"
          },
          text:{
            primary: "#3D3F52"
          },
          root: {
            "&$selected": {
              backgroundColor: "#FF6A1A",
              "&:hover": {
                backgroundColor: "orange",
              },
            },
          },
         primary:{
             main:"#FF6A1A",
             light:"#FCC9A8"
         },
         secondary:{
             main:"#92929D"
            },
            danger:{
                main:"#FF0000"
            },
            
         otherColor:{        //custom color
                main:"#999"
         },
         appbarColor:{
             main:"#ffff"
         },
         textColor:"#272835"
         
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
        color:"#11142D"
      },
     
})


export const darkTheme = createTheme({
  palette:{
    mode:"dark",
    
    background: {
      paper:"#1E1E1E",
      default: "#131E36"
    },
    // text:{
    //   primary: "#ff0000"
    // },
   primary:{
       main:"#FF6A1A",
      //  light:"#000"
   },
   secondary:{
       main:"#92929D"
      },
      danger:{
          main:"#FF0000"
      },
      
   otherColor:{        //custom color
          main:"#999"
   },
   appbarColor:{
       main:"#ffff"
   },
  //  textColor:"#ff0000"
},
typography: {
  fontFamily: 'Inter, sans-serif',
},

   
  });