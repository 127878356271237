import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Stack,
  Typography,
  Divider,
  Box,
  Avatar,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Edit } from "@mui/icons-material";
import { viewStationServices } from "../../services/station";
import { getMemberPermission } from "../../services/memberAndPermissions";

const ViewStation = () => {
  let { name: id } = useParams();
  console.log("userId: ", id);
  const [stationData, setStationData] = useState("");

  const [permissions,setPermissions]=useState([]);
  const [role,setRole]=React.useState([])

  useEffect(()=>{
    
    const tokens = localStorage.getItem("token");
    const token=JSON.parse(tokens);
    setRole(token.role)
    console.log('token: ', token);
    async function memberPermission(){
      const {data} = await getMemberPermission(token.userId)
      if(data){
        setPermissions(data);
      }
    }
    memberPermission();
  },[])

  useEffect(() => {
    const getStation = async () => {
      const { data } = await viewStationServices(id);
      console.log("result: ", data);
      setStationData(data);
    };
    getStation();


  }, []);

  return (
    <div style={{ margin: "8px 15px 15px 15px" }}>
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        Station
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Card
            sx={{
              margin: "10px 0px 0px 0px",
              py: 2,
              px: 2,
              borderRadius: "8px",
              // height:"80vh"
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F7F7FC",
                borderRadius: "8px 8px 0px 0px",
                boxShadow: "none",
              }}
              px="14px"
              py="10px"
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="600"
                  sx={{ margin: "0px 0px 0px 0px", fontSize: "20px" }}
                >
                  {stationData.stationCode}
                </Typography>
               {permissions?.editStation&& <Link
                  className="link-style"
                  to={`/rover/user/stationList/editStation/${stationData.id}`}
                >
                  <Edit />
                </Link>}
              </Box>
              <Typography
                variant="subtitle2"
                fontWeight="500"
                sx={{ margin: "0px 0px 10px 0px", lineHeight: "0.5" }}
              >
                {stationData.stationName}
              </Typography>
            </Box>
            <Box
              sx={{
                // borderRight:"2px solid #F7F7FC", borderLeft:"2px solid #F7F7FC",
                borderRadius: "0px 0px 8px 8px",
                boxShadow:
                  " 0px 1px 1px 0px rgb(0 0 0 / 9%), 0px 0px 1px 0px rgb(0 0 0 / 10%)",
              }}
            >
              <Grid container spacing={0}>
                {[
                  {
                    title: "Station Number",
                    value: stationData.phoneNumber,
                    action: false,
                  },
                  {
                    title: "Station Manager",
                    value: stationData.stationManager,
                    action: false,
                  },
                  {
                    title: "Station Manager Number",
                    value: stationData.managerNumber,
                    action: false,
                  },
                  {
                    title: "Email Id",
                    value: stationData.managerEmail,
                    action: false,
                  },
                  {
                    title: "Address",
                    value: stationData.address,
                    action: false,
                  },
                  {
                    title: "Locality",
                    value: stationData.locality,
                    action: false,
                  },
                  { title: "City", value: stationData.city, action: false },
                  { title: "State", value: stationData.state, action: false },
                  {
                    title: "Pincode",
                    value: stationData.pincode,
                    action: false,
                  },
                  {
                    title: "Latitude",
                    value: stationData.latitude,
                    action: false,
                  },
                  {
                    title: "Longitude",
                    value: stationData.longitude,
                    action: false,
                  },
                  {
                    title: "Delivery Hub",
                    value:
                      "DDN-B19789, DDN-B1984,DDN-B19789, DDN-B1984,DDN-B19789, DDN-B1984,DDN-B19789, DDN-B1984,DDN-B19789, DDN-B1984",
                    action: false,
                  },
                ].map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle2"
                          fontWeight="500"
                          sx={{ margin: "15px 0px 15px 15px" }}
                        >
                          {item.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          fontWeight="500"
                          sx={{ margin: "15px 0px 15px 0px" }}
                        >
                          {item.value}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {item.action && (
                          <>
                            <FontAwesomeIcon
                              icon="fa-solid fa-image"
                              style={{
                                color: "#FF6A1A",
                                height: "20px",
                                margin: "17px 25px 0px 20px",
                              }}
                            />
                            <FontAwesomeIcon
                              icon="fa-solid fa-download"
                              style={{ color: "#FF6A1A", height: "16px" }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {item.title === "Sub Station" ? null : (
                          <Divider light />
                        )}
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <Card
              sx={{
                margin: "10px 0px 0px 0px",
                py: 2,
                px: 2,
                borderRadius: "8px",
                height: "50vh",
              }}
            >
              <Typography
                variant="subtitle1"
                fontWeight="600"
                sx={{ margin: "0px 0px 5px 0px" }}
              >
                Performance
              </Typography>
              <Divider />
            </Card>
            <Card
              sx={{
                margin: "18px 0px 0px 0px",
                py: 3,
                px: 5,
                borderRadius: "8px",
                height: "27.5vh",
              }}
            ></Card>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewStation;
