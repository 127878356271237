import {
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";


import { toast } from "react-toastify";
import { viewDAServicesDeliveryHub, viewDAServicesStation } from "../../services/deliveryAssociate";
import { getMemberPermission } from "../../services/memberAndPermissions";
import { getAllRunsheet, getDeliveryHubUnassignedPackets, getTodayRunsheet } from "../../services/packets";
import { AllAcceptedOrders, AssignVendorPacketsToDA } from "../../services/vendors";
import { FormAutoComplete, UploadTextField } from "../../styles/textField";

import { ColorButton } from "../../styles/button";
import List from "./List";
import AllRunsheetList from "./AllRunsheetList";

const CreateRunsheetVendor = () => {
  const [packetList, setPacketList] = useState([]);
  const [dAList, setDAList] = useState([]);
  console.log("packetList: ", packetList);

  React.useEffect(() => {
    const tokens = localStorage.getItem("token");
    const token = JSON.parse(tokens);
    console.log("token: ", token);
    
    async function getAcceptedOrders(){
      const {data}= await AllAcceptedOrders();
      if(data){
        const val = {...items};
        val.available = data;
        setItems(val);
      }
    }
    getAcceptedOrders()
  }, []);

  useEffect(() => {
    const tokens = localStorage.getItem("token");
    const token = JSON.parse(tokens);
    console.log("token: ", token);

    const getAllDAStation = async () => {
      const { data } = await viewDAServicesStation(token.stationCode);
      if(data){

        setDAList(data);
        
      }
    };
    const getAllDADeliveryHub = async () => {
      const { data } = await viewDAServicesDeliveryHub(token.deliveryHubCode);
      if(data){

        setDAList(data);
      } 
    };
      if (token.stationCode) {
      getAllDAStation();
    } else {
      getAllDADeliveryHub();
    }
  }, []);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [searchFilter, setSearchFilter] = useState("");
  const [board, setBoard] = useState([]);
  const [da, setDa] = useState();
  const success = () => {
    toast.success("Runsheet Created Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const [items, setItems] = useState({ available: [], assigned: [] });
  console.log("items: ", items);

  const removeFromList = (list, index) => {
    const result = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
  };

  const addToList = (list, index, element) => {
    const result = Array.from(list);
    result.splice(index, 0, element);
    return result;
  };

  const rows = items.available&&items.available?.filter(
    (item) =>
     item?.vendorBookingId.toLowerCase().includes(searchFilter.toLowerCase()) ||
      item?.customerName
        .toString()
        .toLowerCase()
        .includes(searchFilter.toLowerCase()) ||
     
      item?.vendorCode.toString().toLowerCase().includes(searchFilter.toLowerCase())||
      item?.deliveryAddress.toString().toLowerCase().includes(searchFilter.toLowerCase
        ())||
      item?.orderDetails.toString().toLowerCase().includes(searchFilter.toLowerCase())||
      item?.customerPhone.toString().toLowerCase().includes(searchFilter.toLowerCase())
  );

  const onDragEnd = (result) => {
    if (!result.destination) {
      console.log(result);
      return;
    }
    const listCopy = { ...items };
    const sourceList = listCopy[result.source.droppableId];
    const [removedElement, newSourceList] = removeFromList(
      sourceList,
      result.source.index
    );
    listCopy[result.source.droppableId] = newSourceList;

    const destinationList = listCopy[result.destination.droppableId];
    listCopy[result.destination.droppableId] = addToList(
      destinationList,
      result.destination.index,
      removedElement
    );
    setItems(listCopy);
  };
  const handleSubmitOfRunSheet = async () => {
    console.log("Here", items.assigned, da.username);

    const val = items.assigned.map((item) => item.id);
    console.log("val: ", val);
    const datas = { id: val, assignPerson: da.username, createdBy: localStorage.getItem("username") };
    console.log("data: ", datas);
try{

  const { data } = await AssignVendorPacketsToDA(datas);
  if (data === "Vendor Order Assigned Sucessfully") {
    console.log("data: ", data);
    const val = {...items};
    console.log("val: ", val);
    val.assigned = [];
    setItems(val);
    // setDa();
    success();
  }else{
    error()
  }
} catch(ex){
error();
}
  };

  const searching=()=>{
    console.log();
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
    
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ margin: "15px 0px 0px 18px" }}
        >
          Create Runsheets{" "}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card
              sx={{
                margin: "8px 15px 15px 15px",
                py: 3,
                px: 3,
                borderRadius: "8px",
                minheight: "90vh",
              }}
            >
              
              <Box>
                <div style={{ margin: "15px 0px 0px 0px" }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Stack direction="row">
                        <UploadTextField
                          size="small"
                          fullWidth
                          variant="outlined"
                          placeholder="Search"
                          type="text"
                          value={searchFilter}
                          onChange={(e) =>
                            setSearchFilter(e.currentTarget.value)
                          }
                          sx={{ padding: 0 }}
                        />
                        <Button
                          variant="contained"
                          component="label"
                          onClick={()=>{
                            searching()
                          }}
                          sx={{
                            borderRadius: "0px 8px 8px 0px",
                            color: "#fff",
                            boxShadow: "none",
                          }}
                        >
                          Search
                        </Button>
                      </Stack>
                    </Grid>
                
                  </Grid>

                  <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

                  <List title="" onDragEnd={onDragEnd} name="available">
                    {rows.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <AllRunsheetList item={item} index={index} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </List>
                </div>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card
              sx={{
                margin: "8px 15px 15px 15px",
                py: 3,
                px: 3,
                borderRadius: "8px",
                // minheight: "50vh",
              }}
            >
              <Autocomplete
                // disablePortal
                disableClearable
                options={dAList}
                getOptionLabel={(option) => option.username}
                focused
                // name="stationCode"
                onChange={(event, value) => {
                  setDa(value);
                }}
                // onChange={formik.handleChange}
                value={da}
                // fullWidth
                size="small"
                renderInput={(params) => (
                  <FormAutoComplete
                    {...params}
                    focused
                    placeholder="Select DA"
                    variant="outlined"
                    type="text"
                  />
                )}
              />
              <Divider sx={{ marginTop: 2, marginBottom: 2 }}/>
              <List title="" onDragEnd={onDragEnd} name="assigned">
                <div className="Board">
                  {items.assigned.map((item, index) => {
                    return (
                      <Draggable
                        draggableId={item.id}
                        index={index}
                        key={item.id}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <AllRunsheetList
                              item={item}
                              id={item.id}
                              index={index}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              </List>
              <ColorButton
                onClick={() => handleSubmitOfRunSheet()}
                variant="contained"
                type="submit"
                disabled={da ? false : true}
              >
                Submit
              </ColorButton>
            </Card>
          </Grid>
        </Grid>
      </DragDropContext>
    </>
  );
};

export default CreateRunsheetVendor;

// function AllRunsheetList(i, item) {
//   return <React.Fragment key={i}>
//     <div
//       style={{
//         marginBottom: "15px",
//         padding: "10px",
//         backgroundColor: "#F7F7FC",
//       }}
//     >
//       <Grid container spacing={2}>
//         <Grid item xs={1.5} sx={{ marginTop: 1 }}>
//           <div
//             style={{
//               backgroundColor: "#C4C4C4",

//               borderRadius: "12px",
//               height: "25px",
//               width: "25px",
//               textAlign: "center",
//             }}
//           >
//             {i + 1}
//           </div>
//         </Grid>
//         <Grid item xs={6.5}>
//           <div>
//             <Typography variant="body2" fontWeight={600}>{item.name}</Typography>
//             <Typography variant="body2" fontWeight={400}>{item.deliveryLocation}</Typography>
//           </div>
//         </Grid>
//         <Grid item xs={3.5}>
//           <Typography variant="caption" fontSize={10} fontWeight={600}>{item.paymentMode}</Typography>
//           <div>
//             <div
//             >
//               <Typography variant="caption" fontWeight={600}>AMZGH678087</Typography>

//             </div>
//           </div>

//         </Grid>
//       </Grid>
//     </div>
//   </React.Fragment>;
// }
