import {
  Card,
  Typography,
  Grid,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { useFormik } from "formik";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ColorButton } from "../../styles/button";
import { FormAutoComplete, FormTextField, SearchTextField } from "../../styles/textField";
// import http from "./httpService";
import * as yup from "yup";
import {
  addStationServices,
  viewStationServices,
  updateStationServices,
  viewAllStationServices,
} from "../../services/station";
import { useNavigate, useParams } from "react-router-dom";
import QuickTracking from "../QuickTracking";
import { addPacketsManually, getPacketsById } from "./../../services/packets";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { viewDeliveryHubServices } from "../../services/deliveryHub";
import { viewAllDeliveryHubServices } from "./../../services/deliveryHub";
import { Edit, InfoRounded } from "@mui/icons-material";
import { viewAllVendorServices } from "../../services/vendor";
import { toast } from "react-toastify";
import VendorList from "./../Vendor/VendorList";
import moment from "moment";
import { over } from "stompjs";
import SockJS from "sockjs-client";
import config from "../../config.json"

const AddPacketsManually = () => {
  let { id } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  // useEffect(() => {
  //   const getValues = async () => {
  //     if (!id) return;
  //     const result = await getPacketsById(id);
  //     if (result) {
  //       console.log("data: ", result.data);
  //       setDefaultValues(result.data);
  //     }
  //   };
  //   getValues();
  // }, []);

  const initialValue = {
    customerName: "",
    customerPhone: "",
    orderId: "",
    trackingNumber: "",
    paymentMode: "",
    productCost: "",
    carrier: "",
    deliveryDate: "",
    product: "",
    quantity: "",
    height: "",
    length: "",
    width: "",
    weight: "",
    address: "",
    state: "",
    city: "",
    postalCode: "",
    dispatchAddress: "",
    returnAddress: "",
    latitude: "",
    longitude: "",
    stationCode: "",
    deliveryHub: "",
    vendor: "",
  };
  let navigate = useNavigate();
  const [stations, setStations] = useState([]);
  const [deliveryHubs, setDeliveryHubs] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [edit, setEdit] = useState(false);
  const success = () => {
    toast.success("Data Submitted Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  let Sock = new SockJS(config.webSocket);
  var stompClient = over(Sock);
    

  useEffect(() => {
    let stationValues;
    // const getStations = async () => {
    //   const { data } = await viewAllStationServices();
    //   stationValues = data;
    //   console.log("data: ", data);
    //   if (data) {
    //     setStations(data);
    //     // if(!id) return;
    //   }
    // };
    // getStations();

    // const getDeliveryHub = async () => {
    //   const { data } = await viewAllDeliveryHubServices();
    //   if (data) {
    //     setDeliveryHubs(data)
    //   }
    // };
    let vendorsList;
    const getValues = async () => {
      if (!id) return;
      const result = await getPacketsById(id);
      if (result) {
        let info=result.data;
        const val = vendorsList.find((item) => item.vendorCode === result.data.vendor);
        console.log('val: ', val);
        info.vendor=val;
        info.deliveryDate= result.data.assignDeliveryDate?result.data.assignDeliveryDate:result.data.deliveryDate;
        console.log('info: ', info);
        setDefaultValues(info);
      }
    };
    const getVendors = async () => {
      const { data } = await viewAllVendorServices();
      if (data) {
        vendorsList = data;
        setVendors(data);

        getValues();
      }
    };
    // getDeliveryHub();
    getVendors();
  }, []);
  const tokens = localStorage.getItem("token");
  const token = JSON.parse(tokens);
  console.log("token: ", token);


  async function submitPacketInfo(formValues) {
    console.log("token: ", token);
    console.log("formValues: ", formValues);
    console.log("Here I am ");
    const val = formValues;
    val.createdBy = window.localStorage.getItem("username");
    val.deliveryHub = token.deliveryHubCode ? token.deliveryHubCode : "";
    val.stationCode = token.stationCode ? token.stationCode : "";
    val.id = "";
    val.deliveryPoint = "";
    val.roverId = "";
    val.orderDate = new Date();
    val.vendor = formValues.vendor.vendorCode;
    console.log("val: ", val);

    try {
      const { data } = await addPacketsManually(val);
      console.log("data: ", data);
      if (data) {
        success();
        
        
        
        navigate(`/rover/user/receiveShipment/packetlist`);
        stompClient.send("/app/packetsCount", {}, JSON.stringify());
      }
    } catch (ex) {
      console.log("ex: ", ex);
      if (ex) {
        error();
      }
    }
    // try {
    // const { data } = await addPacketsManually(val);
    // if (data) {
    // console.log("data: ", data);
    // success();
    // navigate(`/packetlist`);
    // }
    // } catch (exception) {
    // error();
    // }
  }

  const validationSchema = yup.object({
    customerName: yup.string().required(" Name is required"),
    customerPhone: yup.number().required("Number is required"),
    orderId: yup.string().required("Id is required"),
    trackingNumber: yup.number().required("Tracking Number is required"),
    paymentMode: yup.string().required("Payment Mode is Required"),
    productCost: yup.number().required("Cost is required"),
    carrier: yup.string().required("Carrier is required"),
    deliveryDate: yup.string().required("Date is required"),
    height: yup.number().required("Height is required"),
    length: yup.number().required("Length is required"),
    width: yup.number().required("Width is required"),
    weight: yup.number().required("Weight is required"),
    address: yup.string().required("Address is required"),
    state: yup.string().required("State is required"),
    city: yup.string().required("City is required"),
    postalCode: yup.number().required("Code is required"),
    dispatchAddress: yup.string().required("Address is required"),
    returnAddress: yup.string().required("Address is required"),
    latitude: yup.number().required("Latitude is required"),
    longitude: yup.number().required("Longitude is required"),
    product: yup.string().required("Product Name is required"),
    quantity: yup.number().required("Quantity is required"),
    vendor: yup.object().required("Vendor is required"),
  });

  const updateValues = async (formData) => {
    const val = formData;
    val.id = id;
    val.createdBy = window.localStorage.getItem("username");
    val.deliveryHub = token.deliveryHubCode ? token.deliveryHubCode : "";
    val.stationCode = token.stationCode ? token.stationCode : "";
    val.orderDate = new Date();
    val.vendor = formData.vendor.vendorCode;

    console.log("Here Updating");
    try {
      const { data } = await addPacketsManually(val);
      console.log("data: ", data);
      if (data) {
        success();
        navigate(`/rover/packetlist`);
      }
    } catch (ex) {
      console.log("ex: ", ex);
      if (ex) {
        error();
      }
    }
  };

  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: id ? defaultValues : initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (id) {
        console.log("values: Id available", values);
        updateValues(values);
      } else {
        console.log("values: Id nahi hai", values);
        submitPacketInfo(values);
      }
    },
  });
  console.log(formik.errors);
  console.log(formik.values, defaultValues);

  return (
    <React.Fragment>
      {id ? (
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ margin: "15px 0px 0px 18px" }}
        >
          Edit Packet Information
        </Typography>
      ) : (
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ margin: "15px 0px 0px 18px" }}
        >
          Add Packet
        </Typography>
      )}

      <Card
        sx={{ margin: "8px 15px 15px 15px", py: 3, px: 5, borderRadius: "8px" }}
      >
        {id ? (
          <Box
            sx={{
              backgroundColor: "#F7F7FC",
              borderRadius: "8px 8px 0px 0px",
              boxShadow: "none",
            }}
            px="14px"
            py="10px"
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="subtitle1"
                fontWeight="600"
                sx={{ margin: "0px 0px 0px 0px", fontSize: "20px" }}
              >
                {defaultValues.customerName}
              </Typography>
              <Edit
                sx={{ color: "gray" }}
                onClick={() => {
                  setEdit(!edit);
                }}
              />
            </Box>
            <Typography
              variant="subtitle2"
              fontWeight="500"
              sx={{ margin: "0px 0px 10px 0px", lineHeight: "0.5" }}
            ></Typography>
          </Box>
        ) : null}
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            {[
              { title: "Customer Name", name: "customerName", size: 3 },
              { title: "Customer Phone", name: "customerPhone", size: 3 },
              { title: "Order Id", name: "orderId", size: 3 },
              { title: "Tracking Number", name: "trackingNumber", size: 3 },
              { title: "Payment Mode", name: "paymentMode", size: 3 },
              { title: "Product Cost", name: "productCost", size: 3 },
              { title: "Carrier/Seller/Party", name: "carrier", size: 3 },
              { title: "Delivery Date", name: "deliveryDate", size: 3 },
              { title: "Product Name", name: "product", size: 3 },
              { title: "Quantity", name: "quantity", size: 3 },
              { title: "Box Height(cms)", name: "height", size: 3 },
              { title: "Box Length(cms)", name: "length", size: 3 },
              { title: "Box Width(cms)", name: "width", size: 3 },
              { title: "Box Weight(kgs)", name: "weight", size: 3 },
              { title: "Address", name: "address", size: 6 },
              { title: "State", name: "state", size: 4 },
              { title: "City", name: "city", size: 4 },
              { title: "Postal Code", name: "postalCode", size: 4 },
              { title: "Dispatch Address", name: "dispatchAddress", size: 12 },
              { title: "Return Address", name: "returnAddress", size: 12 },
              { title: "Latitude", name: "latitude", size: 6 },
              { title: "Longitude", name: "longitude", size: 6 },
            ].map((item) => {
              return (
                <Grid item xs={item.size}>
                  <Typography
                    mt={1}
                    mb={1}
                    variant="subtitle2"
                    fontWeight="bold"
                  >
                    {item.title}
                  </Typography>

                  {item.name === "deliveryDate" ? (
                    <MobileDatePicker
                      minDate={new Date()}
                      

                      inputFormat="dd/MM/yyyy" 
                      renderInput={(params) => (
                        <SearchTextField
                          {...params}
                          size="small"
                          fullWidth
                          placeholder={item.title}
                          variant="outlined"
                          
                        />
                      )}
                      //  {id? disabled={!edit}:null}
                      name={item.name}
                      fullWidth
                      
                      value={formik.values[item.name]}
                      onChange={(value) =>
                        formik.setFieldValue("deliveryDate", value, true)
                      }
                      // onChange={formik.handleChange}
                    />
                  ) : item.name === "paymentMode" ? (
                    <Autocomplete
                      // disablePortal
                      disableClearable
                      options={["Online", "Cash"]}
                      focused
                      name="paymentMode"
                      onChange={(event, value) => {
                        formik.setFieldValue("paymentMode", value);
                      }}
                      // onChange={formik.handleChange}
                      value={
                        formik.values.paymentMode
                          ? formik.values.paymentMode
                          : null
                      }
                      // fullWidth
                      size="small"
                      renderInput={(params) => (
                        <FormAutoComplete
                          {...params}
                          focused
                          placeholder="Payment Mode"
                          variant="outlined"
                          type="text"
                          error={
                            formik.touched.paymentMode &&
                            Boolean(formik.errors.paymentMode)
                          }
                          helperText={
                            formik.touched.paymentMode &&
                            formik.errors.paymentMode
                          }
                        />
                      )}
                    />
                  ) : (
                    <FormTextField
                      size="small"
                      fullWidth
                      id={item.name}
                      placeholder={item.title}
                      variant="outlined"
                      type="text"
                      value={formik.values[item.name]}
                      name={item.name}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[item.name] &&
                        Boolean(formik.errors[item.name])
                      }
                      helperText={
                        formik.touched[item.name] && formik.errors[item.name]
                      }
                      // disabled={!edit}
                    />
                  )}
                </Grid>
              );
            })}
            {/* <Grid item xs={3}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Station Code
              </Typography>
             
              <Autocomplete
                // disablePortal
                disableClearable
                options={stations}
                getOptionLabel={(option) => option.stationName}
                focused
                name="stationCode"
                onChange={(event, value) => {
                  formik.setFieldValue("stationCode", value);
                }}
                // onChange={formik.handleChange}
                value={
                  formik.values.stationCode ? formik.values.stationCode : null
                }
                // fullWidth
                size="small"
                renderInput={(params) => (
                  <FormAutoComplete
                    {...params}
                    focused
                    placeholder="Delivery Hub Code"
                    variant="outlined"
                    type="text"
                    error={
                      formik.touched.stationCode &&
                      Boolean(formik.errors.stationCode)
                    }
                    helperText={
                      formik.touched.stationCode && formik.errors.stationCode
                    }
                  />
                )}
              />
            </Grid> */}
            {/* <Grid item xs={3}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Delivery Hub
              </Typography>
             
              <Autocomplete
                // disablePortal
                disableClearable
                options={deliveryHubs}
                getOptionLabel={(option) => option.deliveryHubName}
                focused
                name="deliveryHubCode"
                onChange={(event, value) => {
                  formik.setFieldValue("deliveryHub", value);
                }}
                // onChange={formik.handleChange}
                value={
                  formik.values.deliveryHub ? formik.values.deliveryHub : null
                }
                // fullWidth
                size="small"
                renderInput={(params) => (
                  <FormAutoComplete
                    {...params}
                    focused
                    placeholder="Delivery Hub Code"
                    variant="outlined"
                    type="text"
                    error={
                      formik.touched.deliveryHub &&
                      Boolean(formik.errors.deliveryHub)
                    }
                    helperText={
                      formik.touched.deliveryHub && formik.errors.deliveryHub
                    }
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={3}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Vendor
              </Typography>

              <Autocomplete
                // disablePortal
                disableClearable
                options={vendors}
                getOptionLabel={(option) => option.vendorCode}
                focused
                name="deliveryHubCode"
                onChange={(event, value) => {
                  formik.setFieldValue("vendor", value);
                }}
                // onChange={formik.handleChange}
                value={formik.values.vendor ? formik.values.vendor : null}
                // fullWidth
                size="small"
                renderInput={(params) => (
                  <FormAutoComplete
                    {...params}
                    focused
                    placeholder="Vendor"
                    variant="outlined"
                    type="text"
                    error={
                      formik.touched.vendor && Boolean(formik.errors.vendor)
                    }
                    helperText={formik.touched.vendor && formik.errors.vendor}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
{id&&edit &&<ColorButton variant="contained" type="submit">
              Submit
            </ColorButton>}
            {!id&&<ColorButton variant="contained" type="submit">
              Submit
            </ColorButton>}
          </Box>
        </form>
      </Card>
      {/* )} */}
      {/* </Formik> */}
    </React.Fragment>
  );
};

export default AddPacketsManually;
