import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Stack,
  Typography,
  Divider,
  Box,
  Avatar,
  Modal,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Edit } from "@mui/icons-material";
import { viewDAServices } from "./../../services/deliveryAssociate";
import config from "../../config.json";
import { getMemberPermission } from "../../services/memberAndPermissions";

const token = localStorage.getItem("token");

const ViewDA = () => {
  let { id } = useParams();
  console.log("userId: ", id);

  const [DAData, setDAData] = useState("");
  const [DADL, setDADL] = useState();
  const [DAPAN, setDAPAN] = useState();
  const [DAAadhar, setDAAadhar] = useState();
  const [DASelfie, setDASelfie] = useState();
  const [open, setOpen] = useState({"Driving License":false, "Aadhar Card":false, "PAN Card":false});
  const handleOpen = (val) =>{
    const data={...open};
    data[val]=true;
    setOpen(data);
  }
    const handleClose = (val) => {
      const data={...open};
      data[val]=false;
      setOpen(data);
    }

  useEffect(() => {
    const getDA = async () => {
      const { data } = await viewDAServices(id);
      console.log("result: ", data);
      setDAData(data);
      if (data) {
         getImg(data.aadharImage,setDAAadhar);
         getImg(data.drivingLicenseImage,setDADL);
         getImg(data.pancardImage,setDAPAN);
         getImg(data.selfie,setDASelfie);
        
      }
    };
    getDA();
  }, []);

  const [permissions,setPermissions]=React.useState([])
  const [role,setRole]=React.useState([])

  React.useEffect(()=>{
    
    const tokens = localStorage.getItem("token");
    const token=JSON.parse(tokens);
    console.log('token: ', token);
    setRole(token.role)
    
    async function memberPermission(){
      const {data} = await getMemberPermission(token.userId)
      if(data){
        setPermissions(data);
      }
    }
    memberPermission();
  },[])


  async function getImg(api,setData) {
    const tokens = localStorage.getItem("token");
    const token=JSON.parse(tokens).jwttoken;
    console.log('token: ', token);
    const response = await fetch(
      config.apiUrl + "/roverapi/v1/getimage" + api,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      }
      );
      console.log('response: ', response);
    const imageBlob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(imageBlob);
    reader.onloadend = () => {
      const base64data = reader.result;
      console.log("base64data: ", base64data);
      setData(base64data);
    
    };
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    
    boxShadow: 24,
    p: 2  ,
  };

  const editPer=permissions?.editDA||role==="Admin";

  return (
    <div style={{ margin: "8px 15px 15px 15px" }}>
      
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        Delivery Associate
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Card
            sx={{
              margin: "10px 0px 0px 0px",
              py: 2,
              px: 2,
              borderRadius: "8px",
              minHeight: "80vh",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F7F7FC",
                borderRadius: "8px 8px 0px 0px",
                boxShadow: "none",
              }}
              px="14px"
              py="10px"
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={DASelfie}
                    sx={{
                      width: 56,
                      height: 56,
                      border: "2px solid #FF6A1A",
                      marginRight: "10px",
                    }}
                  />
                  <div>
                    <Typography
                      variant="subtitle1"
                      fontWeight="600"
                      sx={{ margin: "0px 0px 0px 0px", fontSize: "20px" }}
                    >
                      {DAData
                        ? DAData.title +
                          " " +
                          DAData.firstName +
                          " " +
                          DAData.lastName
                        : ""}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight="500"
                      sx={{ margin: "0px 0px 10px 0px", lineHeight: "0.5" }}
                    >
                      {DAData ? DAData.id : ""}
                    </Typography>
                  </div>
                </div>
                {editPer&&<Link
                  className="link-style"
                  to={`/rover/user/DAList/editDA/${DAData.id}`}
                >
                  <Edit sx={{ marginTop: 1.5 }} />
                </Link>}
              </Box>
            </Box>
            <Box
              sx={{
                // borderRight:"2px solid #F7F7FC", borderLeft:"2px solid #F7F7FC",
                borderRadius: "0px 0px 8px 8px",
                boxShadow:
                  " 0px 1px 1px 0px rgb(0 0 0 / 9%), 0px 0px 1px 0px rgb(0 0 0 / 10%)",
              }}
            >
              <Grid container spacing={0}>
                {[
                  { title: "Phone", value: DAData.mobileNumber, action: false },
                  {
                    title: "Email",
                    value: DAData.email,
                    action: false,
                  },
                  {
                    title: "Address",
                    value: DAData.address,
                    action: false,
                  },
                  {
                    title: "City",
                    value: DAData.city,
                    action: false,
                  },
                  {
                    title: "State",
                    value: DAData.state,
                    action: false,
                  },
                  {
                    title: "Driving License",
                    value: DAData.drivingLicenseNumber,
                    action: true,
                    image:DADL,
                    name:DAData.username+"DrivingLicense.png"
                  },
                  {
                    title: "PAN Card",
                    value: DAData.pancardNumber,
                    action: true,
                    image:DAPAN,
                    name:DAData.username+"PANCard.png"
                  },
                  {
                    title: "Aadhar Card",
                    value: DAData.aadharNumber,
                    action: true,
                    image:DAAadhar,
                    name:DAData.username+"AadharCard.png"
                  },
                  {
                    title: "Station Id",
                    value: DAData.stationCode,
                    action: false,
                  },
                  {
                    title: "Delivery Hub",
                    value: DAData.deliveryHub,
                    action: false,
                  },
                  {
                    title: "Vehicle Type",
                    value: DAData.vehicleType,
                    action: false,
                  },
                  {
                    title: "Vehicle Registration Number",
                    value: DAData.vehicleRegister,
                    action: false,
                  },
                  {
                    title: "Vehicle Registration Number",
                    value: DAData.vehicleRegister,
                    action: false,
                  },
                ].map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle2"
                          fontWeight="500"
                          sx={{ margin: "15px 0px 15px 15px" }}
                        >
                          {item.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          fontWeight="500"
                          sx={{ margin: "15px 0px 15px 0px" }}
                        >
                          {item.value}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {item.action && (
                          <>
                            <FontAwesomeIcon
                              icon="fa-solid fa-image"
                              style={{
                                color: "#FF6A1A",
                                height: "20px",
                                margin: "17px 25px 0px 20px",
                              }}
                              onClick={()=>handleOpen(item.title)}
                            />
                            <Modal
  open={open[item.title]}
  onClose={()=>handleClose(item.title)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      {item.title}
    </Typography>
   <img src={item.image} style={{width:470}} alt={item.name}/>
  </Box>
</Modal>
                            <a href={item.image} download={item.name}>

                            <FontAwesomeIcon
                              icon="fa-solid fa-download"
                              style={{ color: "#FF6A1A", height: "16px" }}
                              />
                              </a>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {item.title === "Sub Station" ? null : (
                          <Divider light />
                        )}
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <Card
              sx={{
                margin: "10px 0px 0px 0px",
                py: 2,
                px: 2,
                borderRadius: "8px",
                height: "50vh",
              }}
            >
              <Typography
                variant="subtitle1"
                fontWeight="600"
                sx={{ margin: "0px 0px 5px 0px" }}
              >
                Performance
              </Typography>
              <Divider />
            </Card>
            <Card
              sx={{
                margin: "18px 0px 0px 0px",
                py: 3,
                px: 5,
                borderRadius: "8px",
                height: "27.5vh",
              }}
            ></Card>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewDA;
