import React from "react";
import {
  Card,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  Dashboard,
  DirectionsBoatFilled,
  LocalShipping,
  Backpack,
  Dock,
  SportsMotorsports,
  CurrencyRupee,
  Equalizer,
  AddBusiness,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

import RoverLogo from "../images/Rover Logo.svg";

const FireNav = styled(List)(({ theme }) => ({
  "& .Mui-selected": {
    backgroundColor: "#FF6A1A !important",
    borderRadius: "8px",
    color: "#fff",
  },
}));
const CollapseNav = styled(List)(({ theme }) => ({
  "& .Mui-selected": {
    backgroundColor: "#F5F5F5 !important",
    borderRadius: "8px",
    color: "#000",
  },
}));

const VendorUIMobileLeftBarMenu = ({toggle, anchor}) => {
  const [selected, setSelected] = useState("orderHistory");
  console.log('selected: ', selected);
  
  React.useEffect(()=>{
localStorage.getItem("menu")&& setSelected(localStorage.getItem("menu"))
    
  },[])


  return (
    <>
      <img style={{
        height: "5%",
        width:"80%",
        }}src={RoverLogo} alt="avatar" />
      <FireNav
        component="nav"
        aria-label="main mailbox folders"
        sx={{ padding: 1 }}
      >
        {[
          {
            name: "Order History",
            value: "orderHistory",
            path: "/vendor/orderHistory",
            icon: (
              <Dock
                color={selected === "orderHistory" ? "appbarColor" : "primary"}
              />
            ),
            show: true,
            collapsable: "",
            // (
            //   <Collapse
            //     in={selected === "deliveries"}
            //     timeout="auto"
            //     unmountOnExit
            //   >
            //     {[
            //       {
            //         name: "Today's Deliveries",
            //         value: "todayDeliveries",
            //         path: "todayDeliveries",
            //         icon: <Dock />,
            //         show: true,
            //       },
            //       {
            //         name: "Packet Delivered",
            //         value: "packetsDelivered",
            //         path: "packetsDelivered",
            //         icon: <Dock />,
            //         show: true,
            //       },
            //       {
            //         name: "Packets Undelivered",
            //         value: "packetsUndelivered",
            //         path: "undelivered",
            //         icon: <Dock />,
            //         show: true,
            //       },
            //       {
            //         name: "To Be Delivered",
            //         value: "tobeDelivered",
            //         path: "toBeDelivered",
            //         icon: <Dock />,
            //         show: true,
            //       },
            //     ].map((val) => (
            //       <CollapseNav component="div" disablePadding>
            //         <Link
            //           to={val.path}
            //           className="link-style"
            //           style={{ color: "black" }}
            //         >
            //           <ListItemButton
            //             selected={submenu === val.value}
            //             onClick={() => setSubmenu(val.value)}
            //             sx={{ pl: 4 }}
            //           >
            //             <ListItemIcon>{val?.icon}</ListItemIcon>
            //             <ListItemText primary={val?.name} />
            //           </ListItemButton>
            //         </Link>
            //       </CollapseNav>
            //     ))}
            //   </Collapse>
            // ),
          },
          {
            name: "Active Orders",
            value: "activeOrders",
            path: "/vendor/activeOrders",
            icon: (
              <SportsMotorsports
                color={selected === "activeOrders" ? "appbarColor" : "primary"}
              />
            ),
            show: true,
            collapsable: null,
          },
          {
            name: "Confirmation Requests",
            value: "requestToVendor",
            path: "/vendor/requestToVendor",
            icon: (
              <SportsMotorsports
                color={selected === "requestToVendor" ? "appbarColor" : "primary"}
              />
            ),
            show: true,
            collapsable: null,
          },
          {
            name: "Delivery Requests",
            value: "vendorDeliveryRequest",
            path: "/vendor/vendorDeliveryRequest",
            icon: (
              <SportsMotorsports
                color={selected === "vendorDeliveryRequest" ? "appbarColor" : "primary"}
              />
            ),
            show: true,
            collapsable: null
          },
          
        ].map((item, i) => {
          return (
            <React.Fragment key={i}>
              {item?.show && (
                <Link
                  to={item.path}
                  className="link-style"
                  style={{ color: "black" }}
                >
                  <ListItemButton
                    selected={selected=== item.value}
                    onClick={() => {
                      console.log('item.value: ', item.value);
                      setSelected(item.value)
                      localStorage.setItem("menu",item.value)
                      toggle(anchor, true)
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <Typography variant="subtitle1" fontWeight={600}>
                      {item.name}
                    </Typography>
                  </ListItemButton>
                </Link>
              )}
              {item.collapsable ? item.collapsable : null}
            </React.Fragment>
          );
        })}
      </FireNav>
    </>
  );
};

export default VendorUIMobileLeftBarMenu;
