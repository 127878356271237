import React from "react";
import { Grid } from "@mui/material";
import LeftBar from "./LeftBar";
import { Outlet, Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import AddDeliveryAssociate from "./DeliveryAssociate/AddDA";
import AddStation from "./Station/AddStation";
import DeliveryAssociateList from "./DeliveryAssociate/DeliveryAssociateList";
import Appbar from "./Appbar";
import ViewDA from "./DeliveryAssociate/ViewDA";
import StationList from "./Station/StationList";
import Dashboard from "./Dashboard";
import ViewStation from "./Station/ViewStation";
import AddDeliveryHub from "./Station/AddDeliveryHub";
import BottomBar from "./BottomBar";
import ReceiveShipmentList from './ReceiveShipment/ReceiveShipmentList';
import AddVendor from './Vendor/AddVendor';
import VendorList from './Vendor/VendorList';
import ViewVendor from './Vendor/ViewVendor';
import DeliveryHubList from './Station/DeliveryHubList';
import ViewDeliveryHub from './Station/ViewDeliveryHub';
import { ToastContainer } from 'react-toastify';
import AddTeam from "./AddMember/AddTeam";
import ViewTeamMember from "./AddMember/viewTeamMember";
import ViewMemberList from "./AddMember/ViewMemberList";
import TodayDeliveries from '../DA_UI/TodayDeliveries';
import ToBeDelivered from './../DA_UI/ToBeDelivered';
import PacketsUndelivered from './../DA_UI/PacketsUndelivered';
import PacketsDelivered from './../DA_UI/PacketsDelivered';
import PackageInformation from "../DA_UI/PackageInformation";
import TotalCashProjected from './../DA_UI/CashTransaction/TotalCashProjected';
import TotalCashCollected from "../DA_UI/CashTransaction/TotalCashCollected";
import TotalCashPending from './../DA_UI/CashTransaction/TotalCashPending';
import TodayPickups from './../DA_UI/Pickups/TodayPickups';
import PickupPackageInformation from "../DA_UI/Pickups/PickupPackageInfo";
import TodayVendorRequests from './../DA_UI/D2D/TodayVendorRequests';
import D2DPackageInformation from './../DA_UI/D2D/D2DPackageInformation';


const LandingPageDA = () => {
  return (
    <>
      <Appbar />

  <Routes>

    <Route path="/*" element={<TodayDeliveries />}></Route>
    <Route path="/todayDeliveries" element={<TodayDeliveries />}></Route>
    <Route path="/todayPickups" element={<TodayPickups />}></Route>
    <Route path="/d2dorders" element={<TodayVendorRequests />}></Route>
    <Route path="/totalCashProjected" element={<TotalCashProjected />}></Route>
    <Route path="/packageInformation/:id" element={<PackageInformation />}></Route>
    <Route path="/packageInformationPickup/:id" element={<PickupPackageInformation />}></Route>
    <Route path="/packageInformationD2D/:id" element={<D2DPackageInformation />}></Route>
    <Route path="/totalCashCollected" element={<TotalCashCollected />}></Route>
    <Route path="/totalCashPending" element={<TotalCashPending />}></Route>
    <Route path="/toBeDelivered" element={<ToBeDelivered />}></Route>
    <Route path="/undelivered" element={<PacketsUndelivered />}></Route>
    <Route path="/packetsDelivered" element={<PacketsDelivered />}></Route>
  </Routes>
    
      
     
    </>
  );
};

export default LandingPageDA;