import { styled, TableCell, tableCellClasses, TableRow } from '@mui/material';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F7F7FC",
      color: "#3D3F52",
      margin:"20px",
      fontSize: 14,
      fontWeight:600,
      height:"20px"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight:400,
      height:"20px",
      borderBottom:"0.5px solid rgba(224, 224, 224, .7)"

      //!600 for heading and 400 for body
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
 
  const StyledTableBagCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F7F7FC",
      color: "#3D3F52",
      margin:"20px",
      fontSize: 13,
      fontWeight:600,
      height:"20px"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
      fontWeight:400,
      height:"20px",
      borderBottom:"0.5px solid rgba(224, 224, 224, .7)"

      //!600 for heading and 400 for body
    },
  }));
  
  const StyledTableBagRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


  export {StyledTableCell,StyledTableRow,StyledTableBagCell,StyledTableBagRow};