//! Add Delivery Hub and Staion DropDown in the form

import React from "react";
import { Box, Typography, Grid, Stack, Button } from "@mui/material";
import { ColorButton } from "../../styles/button";
import {
  FormAutoComplete,
  FormTextField,
  UploadTextField,
} from "../../styles/textField";
import { Card } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addVendorService, viewVendorServices } from "../../services/vendor";
import { Autocomplete } from "@mui/material";
import { viewAllStationServices } from "../../services/station";
import { useEffect } from "react";
import { toast } from "react-toastify";

const AddVendor = () => {
  const initialValue = {
    id: "",
    businessName: "",
    displayName: "",
    phoneNumber: "",
    altNumber: "",
    email: "",
    vendorCode: "",
    category: "",
    address: "",
    locality: "",
    city: "",
    gstNumber: "",
    pincode: "",
    aadharNumber: "",
    accountNumber: "",
    beneficiaryName: "",
    bankName: "",
    ifscCode: "",
    branch: "",
    remark: "",
    pancardNumber: "",
    latitude: "",
    longitude: "",
    stationCode: "",
    password: "",
    oneToFiveCharges: "",
    fiveToTenCharges: "",
    tenToFifteenCharges: "",
    fifteenToTwentyCharges: "",
  };

  let { id } = useParams();
  const [stations, setStations] = useState([]);

  const [defaultValues, setDefaultValues] = useState({});

  useEffect(() => {
    let stationValues;
    const getStations = async () => {
      const { data } = await viewAllStationServices();
      stationValues = data;
      console.log("data: ", data);
      if (data) {
        setStations(data);
        getValues();
        // if(!id) return;
        // getDeliveryHub();
      }
    };
    getStations();
    const getValues = async () => {
      if (!id) return;
      const { data } = await viewVendorServices(id);
      if (data) {
        const dataValues = data;
        console.log("dataValues: ", dataValues);
        console.log("stationValues: ", stationValues);
        const station = stationValues.filter(
          (item) => item.stationCode === dataValues.stationCode
        );
        console.log("station: ", station);
        dataValues.stationCode = stationValues[0];
        console.log("dataValues: ", dataValues);

        setDefaultValues(dataValues);
      }
    };
  }, []);

  const validationSchema = yup.object({
    businessName: yup.string().required("Business Name is required"),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    displayName: yup.string().required("Display Name is required"),
    vendorCode: yup.string().required("Vendor Code is required"),
    phoneNumber: yup
      .string()
      .matches(/[6-9]{1}[0-9 ]{3}[0-9 ]{3}[0-9]{3}/, {
        message: "Invalid Phone Number",
        excludeEmptyString: false,
      })
      .required("Phone Number is required"),
    address: yup.string().required("Address is required"),
    locality: yup.string().required("Locality is required"),
    city: yup.string().required("City is required"),
    category: yup.string().required("Category is required"),
    pincode: yup.number().required("Pincode is required"),
    // gstNumber: yup.number().required("GST Number is required"),
    // aadharNumber: yup.number().required("Aadhar Number is required"),
    // accountNumber: yup.number().required("Account Number is required"),
    // beneficiaryName: yup.string().required("Beneficiary Name is required"),
    // bankName: yup.string().required("Bank Name is required"),
    // ifscCode: yup.string().required("IFSC Code is required"),
    // branch: yup.string().required("Branch is required"),
    password: yup.string().required("Password is required"),
    // remarks: yup.string().required("Remarks is required"),

    // password: yup
    //   .string('Enter your password')
    //   .min(8, 'Password should be of minimum 8 characters length')
    //   .required('Password is required'),
  });

  //!Field for Lat Long Aadhar upload createdby in post and put request and upload photo as well.
  const [panCard, setPanCard] = useState();
  const [aadharCard, setAadharCard] = useState();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const distanceOptions = ["1-5 Kms", "5-10 Kms", "10-15 Kms"];

  const success = () => {
    toast.success("Data Submitted Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const postVendorInformation = async (formValues) => {
    var form_data = new FormData();
    const val = formValues;
    val.stationCode = formValues.stationCode.stationCode;
    for (var key in val) {
      form_data.append(key, val[key]);
    }

    form_data.append("pancardImage", panCard);
    form_data.append("aadharImage", aadharCard);
    form_data.append("state", "Test State");
    form_data.append("createdBy", localStorage.getItem("username"));

    try {
      console.log("Submitting The Values");
      const { data } = await addVendorService(form_data);
      console.log("data: ", data);
      if (data) {
        success();
        navigate(`/rover/user/vendorList/viewVendor/${data.id}`);
      }
    } catch (ex) {
      console.log("ex: ", ex);
      setDisabled(false);
      if (ex) {
        error();
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: id ? defaultValues : initialValue,
    validationSchema: validationSchema,
    isSubmitting: true,
    onSubmit: (values) => {
      postVendorInformation(values);
      setDisabled(true);
    },
  });

  console.log("Valuesss", formik.values);
  return (
    <>
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        Vendor Information
      </Typography>

      <Card
        sx={{ margin: "8px 15px 15px 15px", py: 3, px: 5, borderRadius: "8px" }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            {[
              { title: "Business Name", name: "businessName", size: 4 },
              { title: "Display Name", name: "displayName", size: 4 },
              { title: "Phone Number", name: "phoneNumber", size: 4 },
              { title: "Alternate Number", name: "altNumber", size: 4 },
              { title: "Email", name: "email", size: 4 },
              { title: "Password", name: "password", size: 4 },
              { title: "Vendor Code", name: "vendorCode", size: 4 },
              { title: "Category", name: "category", size: 4 },
              { title: "GST Number", name: "gstNumber", size: 4 },
              { title: "Aadhar Card Number", name: "aadharNumber", size: 4 },
              { title: "Address", name: "address", size: 6 },
              { title: "Locality", name: "locality", size: 3 },
              { title: "City", name: "city", size: 3 },
              { title: "Pincode", name: "pincode", size: 3 },
              { title: "Latitude", name: "latitude", size: 3 },
              { title: "Longitude", name: "longitude", size: 3 },
              { title: "Bank Account Number", name: "accountNumber", size: 3 },
              { title: "Beneficiary Name", name: "beneficiaryName", size: 3 },
              { title: "Pan Card Number", name: "pancardNumber", size: 3 },
              { title: "Bank Name", name: "bankName", size: 3 },
              { title: "IFSC Code", name: "ifscCode", size: 3 },
              { title: "Branch", name: "branch", size: 3 },
              { title: "Remarks", name: "remark", size: 3 },
            ].map((item) => {
              return (
                <Grid item xs={item.size}>
                  <Typography
                    mt={1}
                    mb={1}
                    variant="subtitle2"
                    fontWeight="bold"
                  >
                    {item.title}
                  </Typography>
                  <FormTextField
                    size="small"
                    fullWidth
                    id={item.name}
                    placeholder={item.title}
                    variant="outlined"
                    type="text"
                    value={formik.values[item.name]}
                    name={item.name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[item.name] &&
                      Boolean(formik.errors[item.name])
                    }
                    helperText={
                      formik.touched[item.name] && formik.errors[item.name]
                    }
                    //   value={values.firstName}
                    //   onChange={(e) => handleChange(e, "firstName")}
                  />

                  {/* {errors.firstName ? (
                      <Typography variant="caption" sx={{ color: "#FF0000" }}>
                        {errors.firstName}
                      </Typography>
                    ) : null} */}
                </Grid>
              );
            })}

            <Grid item xs={3}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Station Code
              </Typography>
              {/* <Select
                        fullWidth
                        input={<BootstrapInput />}
                        name={item.value}
                        onChange={formik.handleChange}
                        value={formik.values[item.value]}
                      >
                        {item.options.map((opt) => (
                          <MenuItem value={opt}>{opt.stationName}</MenuItem>
                        ))}
                      </Select> */}
              <Autocomplete
                // disablePortal
                disableClearable
                options={stations}
                getOptionLabel={(option) => option.stationName}
                focused
                name="stationCode"
                onChange={(event, value) => {
                  formik.setFieldValue("stationCode", value);
                }}
                // onChange={formik.handleChange}
                value={
                  formik.values.stationCode ? formik.values.stationCode : null
                }
                // fullWidth
                size="small"
                renderInput={(params) => (
                  <FormAutoComplete
                    {...params}
                    focused
                    placeholder="Station Code"
                    variant="outlined"
                    type="text"
                    error={
                      formik.touched.stationCode &&
                      Boolean(formik.errors.stationCode)
                    }
                    helperText={
                      formik.touched.stationCode && formik.errors.stationCode
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={3}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Upload PAN Card
              </Typography>
              <Stack direction="row">
                <UploadTextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="Upload"
                  type="text"
                  value={panCard ? panCard.name : ""}
                  // onChange={(e) => setSearch(e.currentTarget.value)}
                />
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    color: "#fff",
                    boxShadow: "none",
                  }}
                  onChange={(e) => setPanCard(e.target.files[0])}
                >
                  Upload
                  <input type="file" hidden />
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Upload Aadhar Card
              </Typography>
              <Stack direction="row">
                <UploadTextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="Upload"
                  type="text"
                  value={aadharCard ? aadharCard.name : ""}
                  // onChange={(e) => setSearch(e.currentTarget.value)}
                />
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    color: "#fff",
                    boxShadow: "none",
                  }}
                  onChange={(e) => setAadharCard(e.target.files[0])}
                >
                  Upload
                  <input type="file" hidden />
                </Button>
              </Stack>
            </Grid>
{/* 
            {[
              { dropDown: "0-5 Kms", textField: "oneToFiveCharges" },
              { dropDown: "6-10 Kms", textField: "fiveToTenCharges" },
              { dropDown: "11-15 Kms", textField: "tenToFifteenCharges" },
              { dropDown: "16-20 Kms", textField: "fifteenToTwentyCharges" },
            ].map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={6}>
                  <Typography
                    mt={1}
                    mb={1}
                    variant="subtitle2"
                    fontWeight="bold"
                  >
                    Distance{" "}
                  </Typography>
                  <FormTextField
                    size="small"
                    fullWidth
                    placeholder="Distance"
                    variant="outlined"
                    type="text"
                    value={item.dropDown}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    mt={1}
                    mb={1}
                    variant="subtitle2"
                    fontWeight="bold"
                  >
                    Charges (in Rupees)
                  </Typography>
                  <FormTextField
                    size="small"
                    fullWidth
                    id={item.name}
                    placeholder="Input Charges in Rupees"
                    variant="outlined"
                    type="number"
                    value={formik.values[item.textField]}
                    name={item.textField}
                    onChange={formik.handleChange}

                    //   value={values.firstName}
                    //   onChange={(e) => handleChange(e, "firstName")}
                  />
                </Grid>
              </React.Fragment>
            ))} */}
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ColorButton variant="contained" type="submit" disabled={disabled}>
              Submit
            </ColorButton>
          </Box>
        </form>
      </Card>
      {/* )} */}
      {/* </Formik> */}
    </>
  );
};

export default AddVendor;
