import { Box, Button, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import QuickTrackingDA from './QuickTrackingDA';

const ToBeDelivered = () => {
   
    return ( <>
     <div style={{ padding: "10px" }}>
        <Card sx={{ padding: "10px" }}>
          <Typography variant="heading6" fontWeight={600} mb={1}>
            To Be Delivered
          </Typography>
          {[
            {
              name: "Deepak",
              status: "To Be Delivered",
              trackingId: "MYN12345",
              paymentMode: "Cash on Delivery",
              deliveryLocation: "GMS Road, Wadia Institute",
            },
            {
              name: "Deepak",
              status: "To Be Delivered",
              trackingId: "MYN12345",
              paymentMode: "Cash on Delivery",
              deliveryLocation: "GMS Road, Wadia Institute",
            },
            {
              name: "Deepak",
              status: "To Be Delivered",
              trackingId: "MYN12345",
              paymentMode: "Prepaid",
              deliveryLocation: "GMS Road, Wadia Institute",
            },
            {
              name: "Deepak",
              status: "To Be Delivered",
              trackingId: "MYN12345",
              paymentMode: "Cash on Delivery",
              deliveryLocation: "GMS Road, Wadia Institute, Near Khopche Restaurant,#1 Towno",
            },
            {
              name: "Deepak",
              status: "To Be Delivered",
              trackingId: "MYN12345",
              paymentMode: "Cash on Delivery",
              deliveryLocation: "GMS Road, Wadia Institute",
            },
            {
              name: "Deepak",
              status: "To Be Delivered",
              trackingId: "MYN12345",
              paymentMode: "Cash on Delivery",
              deliveryLocation: "GMS Road, Wadia Institute",
            },
            {
              name: "Deepak",
              status: "To Be Delivered",
              trackingId: "MYN12345",
              paymentMode: "Cash on Delivery",
              deliveryLocation: "GMS Road, Wadia Institute",
            },
          ].map((item, i) => (
            <React.Fragment key={i}>
              <Box
                sx={{
                  marginBottom: "8px",
                  padding: "5px",
                  backgroundColor: "#F4F8FF",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={1.5}>
                    <div
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "12px",
                        height: "25px",
                        width: "25px",
                        textAlign:"center",
                        border:"2px soild #FF6667"
                      }}
                    >
                      {i + 1}
                    </div>
                  </Grid>
                  <Grid item xs={6.5}>
                    <div>
                      <Typography variant="body2" fontWeight={600}>{item.name}</Typography>
                      <Typography variant="body2" fontWeight={400}>{item.deliveryLocation}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={3.5}>
                      <Typography variant="caption" fontSize={10} fontWeight={600}>{item.paymentMode}</Typography>
                      <div>
                        <div  style={{color:"white", fontSize:10, padding:1,backgroundColor:"#FF6A1A", borderRadius:"10px", textAlign:"center"}}>
                            <span>
                                {   item.status}
                                </span>
                                </div>
                        </div>

                  </Grid>
                </Grid>
              </Box>
            </React.Fragment>
          ))}
        </Card>
      </div>
    </> );
}
 
export default ToBeDelivered;