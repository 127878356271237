import React, { useEffect } from "react";
import {
  Card,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  Dashboard,
  DirectionsBoatFilled,
  LocalShipping,
  Backpack,
  Dock,
  SportsMotorsports,
  CurrencyRupee,
  Equalizer,
  AddBusiness
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { getMemberPermission } from "../services/memberAndPermissions";
// import {  SubmenuNav } from "../styles/LeftBarVendor";


const FireNav = styled(List)(({ theme }) => ({
  

  "& .Mui-selected": {
    backgroundColor: "#FF6A1A !important",
    borderRadius: "8px",
    color: "#fff",
  },

}));
const CollapseNav = styled(List)(({ theme }) => ({
  

  "& .Mui-selected": {
    backgroundColor: "#F5F5F5 !important",
    borderRadius: "8px",
    color: "#000",
  },

}));


const LeftBarVendor = () => {

  
  const [selected, setSelected] = useState("order");
  const [submenu, setSubmenu] = useState("");
  const [role, setRole] = useState("");
  const [permissions,setPermissions]=useState([])

  useEffect(()=>{

    if(localStorage.getItem("menu")){
      setSelected(localStorage.getItem("menu"))
    }else{
      localStorage.setItem("menu","order")

    }
    
    const tokens = localStorage.getItem("token");
    const token=JSON.parse(tokens);
    console.log('token: ', token);
    setRole(token.role)
    async function memberPermission(){
      const {data} = await getMemberPermission(token.userId)
      if(data){
        console.log('data: ', data);
        setPermissions(data);
      }
    }
    // memberPermission();
  },[])
  



  

  return (
    <>
      <Card
        sx={{
          minHeight: "87vh",
          padding: "2rem 1.2rem",
          ml: 2,
          my: 2,
          borderRadius: "8px",
        
        }}
        className="vendorLeftBar"
      >
        <FireNav component="nav" aria-label="main mailbox folders">
          {[
            {
              name: "Order History",
              value: "order",
              path:"orderHistory",
              icon: (
                <Dashboard
                  color={selected === "order" ? "appbarColor" : "primary"}
                />
              ),
              show:true
            },
            {
              name: "Active Orders",
              value: "active",
              path:"activeOrders",
              icon: (
                <DirectionsBoatFilled
                  color={selected === "active" ? "appbarColor" : "primary"}
                />
              ),
              show:true,
              collapsable: "",
            },
            {
              name: "Confirmation Requests",
              value: "requestToVendor",
              path:"requestToVendor",
              icon: (
                <DirectionsBoatFilled
                  color={selected === "requestToVendor" ? "appbarColor" : "primary"}
                />
              ),
              show:true,
              collapsable: "",
            },
            {
              name: "Delivery Requests",
              value: "deliveryRequests",
              path:"vendorDeliveryRequest",
              icon: (
                <LocalShipping
                  color={selected === "deliveryRequests" ? "appbarColor" : "primary"}
                />
              ),
              show:true,
              collapsable: ""
            },
            // {
            //   name: "Pick-Up Operations",
            //   value: "pickup",
            //   path:"allpickupPackets",
            //   icon: (
            //     <Backpack
            //       color={selected === "pickup" ? "appbarColor" : "primary"}
            //     />
            //   ),
            //   collapsable: (
              
            //     <Collapse
            //     in={selected === "pickup"}
            //     timeout="auto"
            //     unmountOnExit
            //   >
            //     {[
            //       {
            //         name: "Create Runsheet",
            //         value: "createRunsheet",
            //         path:"createRunsheetPickup",
            //         icon: <LocalShipping />,
            //         show:permissions?.deliveryOperations||role==="Admin"
            //       },
            //       {
            //         name: "View Runsheet",
            //         value: "viewRunsheet",
            //         path:"todayRunsheetPickup",
            //         icon: <LocalShipping />,
            //         show:permissions?.deliveryOperations||role==="Admin"
            //       },
            //       {
            //         name: "Search Packet",
            //         value: "searchPackagePickup",
            //         path:"searchPackagePickup",
            //         icon: <LocalShipping />,
            //         show:permissions?.deliveryOperations||role==="Admin"
            //       },
            //     ].map((val) => (
            //       <CollapseNav component="div" disablePadding>
            //        {val.show&& <Link to={val.path} className="link-style" style={{color:"black"}}>
            //         <ListItemButton
            //           selected={submenu === val.value}
            //           onClick={() => setSubmenu(val.value)}
            //           sx={{ pl: 4 }}
            //         >
            //           <ListItemIcon>{val?.icon}</ListItemIcon>
            //           <ListItemText primary={val?.name}  />
            //         </ListItemButton>
            //         </Link>}
            //       </CollapseNav>
            //     ))}
            //   </Collapse>
            //   ),
            //   show:permissions.pickUpOperations||role==="Admin",
            // },
      
           
          ].map((item, i) => {
            return (
              <React.Fragment key={i}>
              {item?.show&&  <Link to={item.path} className="link-style" style={{color:"black"}}>
                <ListItemButton
                  selected={selected === item.value}
                  onClick={() => {
                    setSelected(item.value);
                    localStorage.setItem("menu", item.value)
                  }}
                 
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {item.name}
                  </Typography>
                </ListItemButton>
                </Link>}
                {item.collapsable ? item.collapsable : null}
              </React.Fragment>
            );
          })}
        </FireNav>
      </Card>
    </>
  );
};

export default LeftBarVendor;

