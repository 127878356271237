//! Location Code will be added in the form

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import {
  Autocomplete,
  Card,
  Divider,
  FormControl,
  StepConnector,
  stepConnectorClasses,
  styled,
  Tab,
  Tabs,
} from "@mui/material";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
import { FormAutoComplete, FormTextField } from "../../styles/textField";
import { useState } from "react";
import * as yup from "yup";
import { IOSSwitch } from "../../styles/Slider";
import { FormControlLabel } from "@mui/material";
import { ColorButton } from "../../styles/button";
import { useEffect } from "react";
import { viewAllStationServices } from "../../services/station";
import {
  DisplaySettings,
  CurrencyRupee,
  Dashboard,
  Dock,
  Leaderboard,
  PersonPin,
  Check,
  AddModerator,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import DeliveryAssociateList from "./../DeliveryAssociate/DeliveryAssociateList";
import {
  addMember,
  addPermissions,
  checkExistingUsername,
} from "../../services/memberAndPermissions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddIcon  from '@mui/icons-material/Add';
import  DeleteIcon from '@mui/icons-material/Delete';
import { viewDeliveryHubIdServices } from "../../services/deliveryHub";
import { getAllTeamNamesWithId } from "../../services/teamCreation";
import { getTeamDetailsById } from './../../services/teamCreation';
import { viewAllVendorServices } from './../../services/vendor';

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const steps = ["Add Member", "Add Permission", "Add To Team"];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <GroupAddIcon />,
    2: <AddModerator />,
    3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default function AddTeam() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [memberId, setMemberId] = useState();
  const [stations, setStations] = useState([]);
  const [stationSelected, setStationSelected] = useState({});
  const [addingStationPermissions, setAddingStationPermissions] = useState([]);
  console.log('addingStationPermissions: ', addingStationPermissions);
  const [deliveryHub, setDeliveryHub] = useState([]);
  const [deliveryHubSelected, setDeliveryHubSelected] = useState({});
  console.log('deliveryHubSelected: ', deliveryHubSelected);
  const [addingDeliveryHubPermissions, setAddingDeliveryHubPermissions] = useState([]);
  console.log('addingDeliveryHubPermissions: ', addingDeliveryHubPermissions);
  const [vendorSelected, setVendorSelected] = useState({});
  const [addingVendorPermissions, setVendorAddingPermissions] = useState([]);
  console.log('addingVendorPermissions: ', addingVendorPermissions);
  const [teamList, setTeamList] = useState([]);
  const [designationList, setDesignationList] = useState(["Please Select A Team First"]);
  const [teamSelected, setTeamSelected] = useState([]);
  const [designationSelected, setDesignationSelected] = useState({});
  const [vendorList, setVendorList] = useState([]);

  const isStepOptional = (step) => {
    return step === -1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (activeStep === 0) {
      setNext(false);
    }
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  let navigate = useNavigate();

  const handleSubmitting = () => {
    console.log("Handle Submit", formik.values);

    postMemberData(formik.values);
    console.log("memberId: ", memberId);
    if (memberId) {
      console.log("memberId: ", memberId);
    }
  };

  const handleBack = () => {
    setNext(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  
  const validationSchema = yup.object({
    username: yup.string().required(" UserName is required"),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    stationCode: yup.object().required("Station Code is required"),
    phone: yup.number().required("Phone is required"),
    gender: yup.string().required("City is required"),

    // password: yup
    //   .string('Enter your password')
    //   .min(8, 'Password should be of minimum 8 characters length')
    //   .required('Password is required'),
  });

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [status, setStatus] = useState(false);
  const [next, setNext] = useState(true);
  const [test, setTest] = useState(false);

  const [operations, setOperations] = useState({
    receivedShipments: false,
    deliveryOperations: false,
    pickUpOperations: false,

    deliveryHub: {
      all: false,
      add: false,
      edit: false,
      view: false,
      others: false,
    },
  });
  const [cash, setCash] = useState({
    viewFinanceData: false,
  });
  const [dashboard, setDashboard] = useState({
    viewDashboard: false,
  });
  // const [station, setStation] = useState({
  //   stations: {
  //     all: false,
  //     add: false,
  //     edit: false,
  //     view: false,
  //     others: false,
  //   },
  // });
  // const [vendor, setVendor] = useState({
  //   vendors: {
  //     all: false,
  //     add: false,
  //     edit: false,
  //     view: false,
  //     others: false,
  //   },
  // });
  const [performanceMetrics, setPerformanceMetrics] = useState({
    viewPerformance: false,
  });
  const [employeeManagement, setEmployeeManagement] = useState({
    employee: {
      all: false,
      add: false,
      edit: false,
      view: false,
      others: false,
    },
    deliveryAssociate: {
      all: false,
      add: false,
      edit: false,
      view: false,
      others: false,
    },
  });

  const initialValue = {
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    stationCode: "",
    phone: "",
    altPhone: "",
    email: "",
    gender: "",
  };

  const addingPermissions = async (values, id) => {
    const { data } = await addPermissions(values);
    console.log("data: ", data);
    if (data) {
      navigate(`/rover/user/viewMemberList/viewTeamMember/${id}`);
    }
  };

  const success = () => {
    toast.success("Data Submitted Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

 const errorS = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };



  const handleSubmitPermissions = (memId) => {
    console.log("memId: ", memId);
    let permissions = {};
    const stationPermissions=addingStationPermissions.map(item=>{
      return(
        {stationCode:item.stationCode,id:item.id, add:item.add, edit:item.edit, view:item.view}
        )
      });
    const deliveryPermissions=addingDeliveryHubPermissions.map(item=>{
      return(
        {deliveryHubCode:item.deliveryHubCode,id:item.id, add:item.add, edit:item.edit, view:item.view}
        )
      });
      const vendorPermissions=addingVendorPermissions.map(item=>{
        return(
          {vendorId:item.vendorId, id:item.id, add:item.add, edit:item.edit, view:item.view}
          )
        })
        console.log('vendorPermissions: ', vendorPermissions);
      console.log('stationPermissions: ', stationPermissions);
      console.log('deliveryPermissions: ', deliveryPermissions);
    
    permissions.memberId = memId;
    permissions.id = "";
    permissions.receivedShipments = operations.receivedShipments;
    permissions.deliveryOperations = operations.deliveryOperations;
    permissions.pickUpOperations = operations.pickUpOperations;
    permissions.roleDeliveryHubRequest=addingDeliveryHubPermissions;
    permissions.viewFinanceData = cash.viewFinanceData;
    permissions.viewDashboard = dashboard.viewDashboard;
    permissions.viewPerformance = performanceMetrics.viewPerformance;
    permissions.roleStationRequest=stationPermissions;
    permissions.allEmp = employeeManagement.employee.all;
    permissions.addEmp = employeeManagement.employee.add;
    permissions.editEmp = employeeManagement.employee.edit;
    permissions.viewEmp = employeeManagement.employee.view;
    permissions.othersEmp = employeeManagement.employee.others;
    permissions.allDA = employeeManagement.deliveryAssociate.all;
    permissions.addDA = employeeManagement.deliveryAssociate.add;
    permissions.editDA = employeeManagement.deliveryAssociate.edit;
    permissions.viewDA = employeeManagement.deliveryAssociate.view;
    permissions.othersDA = employeeManagement.deliveryAssociate.others;
    permissions.roleVendorRequest=vendorPermissions;
    permissions.teamId=teamSelected.id;
    permissions.designationId=designationSelected.id;
    permissions.station = "";
    permissions.employee = "";
    permissions.deliveryAssociate = "";
    permissions.vendor = "";
    permissions.createdBy = "test";

    console.log(">>>>>>>>>>>>>", permissions);

    addingPermissions(permissions, memId);
  };


  const postMemberData = async (dataValues) => {
    dataValues.createdBy = "createdBy";
    const value = dataValues;
    // value.stationCode = dataValues.stationCode.stationCode;

    
try {
  const { data } = await addMember(value);
  console.log("data: ", data);
  if (data) {
    success();
    console.log("data: ", data);
    console.log(data);
    handleSubmitPermissions(data);
  }
} catch (ex) {
  console.log("ex: ", ex);
  if (ex) {
    error();
  }
}

    // const { data } = await addMember(value);
    // if (data) {
    //   console.log("data: ", data);
    //   console.log(data);
    //   handleSubmitPermissions(data);
    // }
  };
  const error = () => {
    toast.error("Username already exist, please try a new one   ", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  // console.log('values: ', formik.values);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { data } = await checkExistingUsername(formik.values.username);
      console.log('data>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>: ', data);
      if (data) {
        setNext(true);
      } else {
        error();
      }
    },
  });
  const errors = () => {
    toast.error("Already Added", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

 
  
  console.log(designationSelected);
  console.log('teamSelected: ', teamSelected);
  useEffect(() => {
    let stationValues;
    const getStations = async () => {
      const { data } = await viewAllStationServices();
      stationValues = data;
      console.log("data: ", data);
      if (data) {
        data.unshift({ stationName: "All Stations" ,stationCode:"All"});
        setStations(data);
      }
    };
    const getDeliveryHub = async () => {
      const { data } = await viewDeliveryHubIdServices();
      if (data) {
        
        data.unshift({ deliveryHubName: "All Delivery Hubs",deliveryHubCode:"All" });
        setDeliveryHub(data);
      }
    };
    const getAllTeamMemberWithId = async () => {
      const { data } = await getAllTeamNamesWithId();
      if (data) {
        console.log('data of Team List: ', data);
        
        setTeamList(data);
      }
    };
    const getAllVendors = async () => {
      const { data } = await viewAllVendorServices();
      if (data) {
        console.log('data of Vendor List: ', data);
        data.unshift({displayName:"All Vendors",vendorCode:"All"})
        
        setVendorList(data);
      }
    };


    getStations();
    getDeliveryHub();
    getAllTeamMemberWithId();
    getAllVendors();
  }, []);

 
  
  const [value, setValue] = React.useState(0);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(()=>{

    const getDesignations=async()=>{
      const {data}= await getTeamDetailsById(teamSelected.id);
      console.log('data: ', data);
      if(data){
        setDesignationList(data.designationResponse);
      }
    }
    getDesignations();

  },[teamSelected])
  
 
  return (
    <>
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        Add Team
      </Typography>
      <Card
        sx={{
          margin: "8px 15px 15px 15px",
          py: 3,
          px: 5,
          borderRadius: "8px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} sx={{ marginBottom: 2 }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel
                    StepIconComponent={ColorlibStepIcon}
                    {...labelProps}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box
                sx={{
                  backgroundColor: "#F7F7FC",
                  borderRadius: "8px 8px 8px 8px",
                  boxShadow: "none",
                }}
                px="14px"
                py="10px"
              >
                {activeStep === 0 ? (
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={1}>
                      {[
                        { title: "Username", name: "username", size: 4 },
                        { title: "Password", name: "password", size: 4 },
                        { title: "First Name", name: "firstName", size: 4 },
                        { title: "Last Name", name: "lastName", size: 4 },
                        { title: "Phone", name: "phone", size: 4 },
                        { title: "Alternate Phone", name: "altPhone", size: 4 },
                        { title: "Email", name: "email", size: 4 },
                        {
                          title: "Gender",
                          name: "gender",
                          size: 4,
                          options: ["Male", "Female"],
                        },
                      ].map((item) => {
                        return (
                          <>
                            {item.options ? (
                              item.name === "stationCode" ? (
                                <Grid item xs={item.size}>
                                  <Typography
                                    mt={1}
                                    mb={1}
                                    variant="subtitle2"
                                    fontWeight="bold"
                                  >
                                    {item.title}
                                  </Typography>
                                 
                                  <Autocomplete
                                    // disablePortal
                                    disableClearable
                                    options={item.options}
                                    getOptionLabel={(option) =>
                                      option.stationName
                                    }
                                    focused
                                    name={item.name}
                                    onChange={(event, value) => {
                                      formik.setFieldValue(item.name, value);
                                    }}
                                    // onChange={formik.handleChange}
                                    value={
                                      formik.values[item.name]
                                        ? formik.values[item.name]
                                        : null
                                    }
                                    // fullWidth
                                    size="small"
                                    renderInput={(params) => (
                                      <FormAutoComplete
                                        {...params}
                                        focused
                                        placeholder={item.title}
                                        variant="outlined"
                                        type="text"
                                        error={
                                          formik.touched[item.name] &&
                                          Boolean(formik.errors[item.name])
                                        }
                                        helperText={
                                          formik.touched[item.name] &&
                                          formik.errors[item.name]
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                              ) : (
                                <Grid item xs={item.size}>
                                  <Typography
                                    mt={1}
                                    mb={1}
                                    variant="subtitle2"
                                    fontWeight="bold"
                                  >
                                    {item.title}
                                  </Typography>
                                  <FormControl fullWidth>
                                    <Autocomplete
                                      // disablePortal
                                      disableClearable
                                      options={item.options}
                                      // getOptionLabel={(option) => option.stationName}
                                      focused
                                      name={item.name}
                                      onChange={(event, value) => {
                                        formik.setFieldValue(item.name, value);
                                      }}
                                      // onChange={formik.handleChange}
                                      value={
                                        formik.values[item.name]
                                          ? formik.values[item.name]
                                          : null
                                      }
                                      // fullWidth
                                      size="small"
                                      renderInput={(params) => (
                                        <FormAutoComplete
                                          {...params}
                                          focused
                                          placeholder={item.title}
                                          variant="outlined"
                                          type="text"
                                          error={
                                            formik.touched[item.name] &&
                                            Boolean(formik.errors[item.name])
                                          }
                                          helperText={
                                            formik.touched[item.name] &&
                                            formik.errors[item.name]
                                          }
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </Grid>
                              )
                            ) : (
                              <Grid item xs={item.size}>
                                <Typography
                                  mt={1}
                                  mb={1}
                                  variant="subtitle2"
                                  fontWeight="bold"
                                >
                                  {item.title}
                                </Typography>
                                <FormTextField
                                  size="small"
                                  fullWidth
                                  id={item.name}
                                  placeholder={item.title}
                                  variant="outlined"
                                  type="text"
                                  value={formik.values[item.name]}
                                  name={item.name}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched[item.name] &&
                                    Boolean(formik.errors[item.name])
                                  }
                                  helperText={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name]
                                  }
                                />

                                
                              </Grid>
                            )}
                          </>
                        );
                      })}
                    </Grid>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={status}
                            onChange={() => {
                              setStatus(!status);
                            }}
                          />
                        }
                        label={
                          status ? (
                            <Typography
                              variant="subtitle1"
                              fontWeight="600"
                              sx={{
                                margin: "0px 0px 0px 0px",
                                fontSize: "16px",
                                color: "green",
                              }}
                            >
                              Active
                            </Typography>
                          ) : (
                            <Typography
                              variant="subtitle1"
                              fontWeight="600"
                              sx={{
                                margin: "0px 0px 0px 0px",
                                fontSize: "16px",
                                color: "red",
                              }}
                            >
                              Inactive{" "}
                            </Typography>
                          )
                        }
                      />
                      <Box sx={{ flex: "1 1 auto" }} />
                      <ColorButton
                        sx={{ marginTop: 0 }}
                        type="submit"
                        variant="contained"
                      >
                        Confirm
                      </ColorButton>
                    </Box>
                  </form>
                ) : activeStep === 1 ? (
                  <>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="icon label tabs example"
                    >
                      <Tab icon={<DisplaySettings />} label="OPERATIONS" />
                      <Tab icon={<CurrencyRupee />} label="CASH" />
                      <Tab icon={<Dashboard />} label="DASHBOARD" />
                      <Tab icon={<Dock />} label="STATION" />
                      <Tab icon={<Dock />} label="DELIVERY HUB" />
                      <Tab icon={<Leaderboard />} label="PERFORMANCE" />
                      <Tab icon={<Leaderboard />} label="VENDORS" />
                      <Tab icon={<PersonPin />} label="EMPLOYEE" />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <Grid container spacing={2}>
                        {[
                          {
                            title: "Received Shipment",
                            value: "receivedShipments",
                            extra: false,
                          },
                          {
                            title: "Delivery Operations",
                            value: "deliveryOperations",
                            extra: false,
                          },
                          {
                            title: "Pick Up Operations",
                            value: "pickUpOperations",
                            extra: false,
                          },
                          // {
                          //   title: "Delivery Hub",
                          //   value: "deliveryHub",
                          //   extra: true,
                          // },
                        ].map((item, i) => (
                          <React.Fragment key={i}>
                            <Grid item xs={4}>
                              {item.title}
                            </Grid>
                            {
                              !item.extra ? (
                                <Grid item xs={6}>
                                  <FormControlLabel
                                    key={i}
                                    control={
                                      <IOSSwitch
                                        sx={{ m: 1 }}
                                        checked={operations[item.value]}
                                        name={item.value}
                                        label={null}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        onChange={() => {
                                          const ops = { ...operations };

                                          ops[item.value] = !ops[item.value];
                                          setOperations(ops);
                                        }}
                                      />
                                    }
                                  />
                                </Grid>
                              ) : (
                                <>
                                  {[
                                    {
                                      name: "All",
                                      value: "all",
                                      onChange: () => {
                                        const operat = { ...operations };
                                        operat[item.value].all =
                                          !operat[item.value].all;

                                        setOperations(operat);
                                      },
                                    },
                                    {
                                      name: "Add",
                                      value: "add",
                                      onChange: () => {
                                        const operat = { ...operations };
                                        operat[item.value].add =
                                          !operat[item.value].add;
                                        setOperations(operat);
                                      },
                                    },
                                    {
                                      name: "Edit",
                                      value: "edit",
                                      onChange: () => {
                                        const operat = { ...operations };
                                        operat[item.value].edit =
                                          !operat[item.value].edit;
                                        setOperations(operat);
                                      },
                                    },
                                    {
                                      name: "View",
                                      value: "view",
                                      onChange: () => {
                                        const operat = { ...operations };
                                        operat[item.value].view =
                                          !operat[item.value].view;
                                        setOperations(operat);
                                      },
                                    },
                                    {
                                      name: "Other",
                                      value: "others",
                                      onChange: () => {
                                        const operat = { ...operations };
                                        operat[item.value].others =
                                          !operat[item.value].others;
                                        setOperations(operat);
                                      },
                                    },
                                  ].map((val) => (
                                    <Grid item xs={1.5}>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={
                                              operations[item.value][val.value]
                                            }
                                            onChange={val.onChange}
                                          />
                                        }
                                        label={
                                          <Typography
                                            variant="subtitle1"
                                            fontWeight="400"
                                            sx={{
                                              margin: "0px 0px 0px 0px",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {val.name}
                                          </Typography>
                                        }
                                      />
                                    </Grid>
                                  ))}
                                </>
                              )
                              //   <>
                              // <Grid item xs={1.5}>Hello</Grid>
                              // <Grid item xs={1.5}>Hello</Grid>
                              // <Grid item xs={1.5}>Hello</Grid>
                              // <Grid item xs={1.5}>Hello</Grid>
                              //   </>
                            }
                          </React.Fragment>
                        ))}
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Grid container spacing={2}>
                        {[
                          {
                            title: "View Finance Data",
                            value: "viewFinanceData",
                            extra: false,
                          },
                        ].map((item, i) => (
                          <React.Fragment key={i}>
                            <Grid item xs={4}>
                              {item.title}
                            </Grid>
                            {!item.extra ? (
                              <Grid item xs={6}>
                                <FormControlLabel
                                  key={i}
                                  control={
                                    <IOSSwitch
                                      sx={{ m: 1 }}
                                      checked={cash[item.value]}
                                      name={item.value}
                                      label={null}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                      onChange={() => {
                                        const ops = { ...cash };

                                        ops[item.value] = !ops[item.value];
                                        setCash(ops);
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            ) : (
                              <>
                                {[
                                  {
                                    name: "All",
                                    value: "all",
                                    onChange: () => {
                                      const operat = { ...cash };
                                      operat[item.value].all =
                                        !operat[item.value].all;
                                      operat[item.value].add =
                                        operat[item.value].all;
                                      operat[item.value].edit =
                                        operat[item.value].all;
                                      operat[item.value].view =
                                        operat[item.value].all;
                                      setCash(operat);
                                    },
                                  },
                                  {
                                    name: "Add",
                                    value: "add",
                                    onChange: () => {
                                      const operat = { ...cash };
                                      operat[item.value].add =
                                        !operat[item.value].add;
                                      setCash(operat);
                                    },
                                  },
                                  {
                                    name: "Edit",
                                    value: "edit",
                                    onChange: () => {
                                      const operat = { ...cash };
                                      operat[item.value].edit =
                                        !operat[item.value].edit;
                                      setCash(operat);
                                    },
                                  },
                                  {
                                    name: "View",
                                    value: "view",
                                    onChange: () => {
                                      const operat = { ...cash };
                                      operat[item.value].view =
                                        !operat[item.value].view;
                                      setCash(operat);
                                    },
                                  },
                                  {
                                    name: "Other",
                                    value: "others",
                                    onChange: () => {
                                      const operat = { ...cash };
                                      operat[item.value].others =
                                        !operat[item.value].others;
                                      setCash(operat);
                                    },
                                  },
                                ].map((val) => (
                                  <Grid item xs={1.5}>
                                    <FormControlLabel
                                      control={
                                        <IOSSwitch
                                          sx={{ m: 1 }}
                                          checked={cash[item.value][val.value]}
                                          onChange={val.onChange}
                                        />
                                      }
                                      label={
                                        <Typography
                                          variant="subtitle1"
                                          fontWeight="400"
                                          sx={{
                                            margin: "0px 0px 0px 0px",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {val.name}
                                        </Typography>
                                      }
                                    />
                                  </Grid>
                                ))}
                              </>
                            )}
                          </React.Fragment>
                        ))}
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Grid container spacing={2}>
                        {[
                          {
                            title: "View Dashboard",
                            value: "viewDashboard",
                            extra: false,
                          },
                        ].map((item, i) => (
                          <React.Fragment key={i}>
                            <Grid item xs={4}>
                              {item.title}
                            </Grid>
                            {
                              !item.extra ? (
                                <Grid item xs={6}>
                                  <FormControlLabel
                                    key={i}
                                    control={
                                      <IOSSwitch
                                        sx={{ m: 1 }}
                                        checked={dashboard[item.value]}
                                        name={item.value}
                                        label={null}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        onChange={() => {
                                          const ops = { ...dashboard };

                                          ops[item.value] = !ops[item.value];
                                          setDashboard(ops);
                                        }}
                                      />
                                    }
                                  />
                                </Grid>
                              ) : (
                                <>
                                  {[
                                    {
                                      name: "All",
                                      value: "all",
                                      onChange: () => {
                                        const operat = { ...dashboard };
                                        operat[item.value].all =
                                          !operat[item.value].all;

                                        setDashboard(operat);
                                      },
                                    },
                                    {
                                      name: "Add",
                                      value: "add",
                                      onChange: () => {
                                        const operat = { ...dashboard };
                                        operat[item.value].add =
                                          !operat[item.value].add;
                                        setDashboard(operat);
                                      },
                                    },
                                    {
                                      name: "Edit",
                                      value: "edit",
                                      onChange: () => {
                                        const operat = { ...dashboard };
                                        operat[item.value].edit =
                                          !operat[item.value].edit;
                                        setDashboard(operat);
                                      },
                                    },
                                    {
                                      name: "View",
                                      value: "view",
                                      onChange: () => {
                                        const operat = { ...dashboard };
                                        operat[item.value].view =
                                          !operat[item.value].view;
                                        setDashboard(operat);
                                      },
                                    },
                                    {
                                      name: "Other",
                                      value: "others",
                                      onChange: () => {
                                        const operat = { ...dashboard };
                                        operat[item.value].others =
                                          !operat[item.value].others;
                                        setDashboard(operat);
                                      },
                                    },
                                  ].map((val) => (
                                    <Grid item xs={1.5}>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={
                                              dashboard[item.value][val.value]
                                            }
                                            onChange={val.onChange}
                                          />
                                        }
                                        label={
                                          <Typography
                                            variant="subtitle1"
                                            fontWeight="400"
                                            sx={{
                                              margin: "0px 0px 0px 0px",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {val.name}
                                          </Typography>
                                        }
                                      />
                                    </Grid>
                                  ))}
                                </>
                              )
                              //   <>
                              // <Grid item xs={1.5}>Hello</Grid>
                              // <Grid item xs={1.5}>Hello</Grid>
                              // <Grid item xs={1.5}>Hello</Grid>
                              // <Grid item xs={1.5}>Hello</Grid>
                              //   </>
                            }
                          </React.Fragment>
                        ))}
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                     <>
                     <div style={{display:"flex"}}>
                      <>
                   
                      <FormControl sx={{width:"40vh"}}>
                        <Autocomplete
                          disableClearable
                          options={stations}
                          getOptionLabel={(option) => option.stationName}
                          focused
                          onChange={(event, newValue) => {
                            setStationSelected(newValue);
                          }}
                          size="small"
                          renderInput={(params) => (
                            <FormAutoComplete
                              {...params}
                              focused
                              placeholder="Select Station"
                              variant="outlined"
                              type="text"
                            />
                          )}
                        />
                      </FormControl>
                      </>
                     
                     <ColorButton variant ="contained" sx={{marginTop:"0", marginLeft:1, borderRadius:"15px",padding:0}}
                     onClick={()=>{
                      const data=[...addingStationPermissions];
                      if(data.find(item=>item.name===stationSelected.stationName)||!stationSelected.stationName){
                        errors();
                        return;
                      }
                      data.push(
                        {name:stationSelected.stationName, add:false,edit:false,view:false, stationCode:stationSelected.stationCode,id:""}
                        )
                        setAddingStationPermissions(data)
                        console.log('addingStationPermissions: ', addingStationPermissions);
                    }}
                     ><AddIcon /></ColorButton>

                     </div>
                     <Grid container  sx={{marginTop:1.5}} spacing={2}>

                     {addingStationPermissions&&addingStationPermissions.map((item,i)=>
                     <React.Fragment key={i}>
                     <Grid item xs={4} >{item.name}</Grid>
                     <>
                                  {[
                                    
                                    {
                                      name: "Add",
                                      value: "add",
                                      onChange: () => {
                                        const operat = [ ...addingStationPermissions ];
                                        operat[i].add =
                                          !operat[i].add;
                                        setAddingStationPermissions(operat);
                                      },
                                    },
                                    {
                                      name: "Edit",
                                      value: "edit",
                                      onChange: () => {
                                        const operat =  [...addingStationPermissions] ;
                                        operat[i].edit =
                                          !operat[i].edit;
                                        setAddingStationPermissions(operat);
                                      },
                                    },
                                    {
                                      name: "View",
                                      value: "view",
                                      onChange: () => {
                                        const operat =  [...addingStationPermissions] ;
                                        operat[i].view=
                                          !operat[i].view;
                                        setAddingStationPermissions(operat);
                                      },
                                    },
                                    
                                  ].map((val) => 
                                    {
                                      return (<Grid item xs={1.5}>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={
                                              addingStationPermissions[i][val.value]
                                            }
                                            onChange={val.onChange}
                                          />
                                        }
                                        label={
                                          <Typography
                                            variant="subtitle1"
                                            fontWeight="400"
                                            sx={{
                                              margin: "0px 0px 0px 0px",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {val.name}
                                          </Typography>
                                        }
                                      />
                                    </Grid>)}
                                  )}
                                  <Grid item xs={1.5}><DeleteIcon sx={{marginTop:1}} onClick={()=>{
                                    const data=[...addingStationPermissions];
                                    const valu=data.filter(val=>val.name!==item.name);
                                    setAddingStationPermissions(valu)
                                  }}/></Grid>
                                </>
                     </React.Fragment>
                     )}
                     </Grid>
                     </>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                    <>
                     <div style={{display:"flex"}}>
                      <>
                   
                      <FormControl sx={{width:"40vh"}}>
                        <Autocomplete
                          // disablePortal
                          disableClearable
                          options={deliveryHub}
                          getOptionLabel={(option) => option.deliveryHubName}
                          focused
                          onChange={(event, newValue) => {
                            setDeliveryHubSelected(newValue);
                          }}
                          size="small"
                          renderInput={(params) => (
                            <FormAutoComplete
                              {...params}
                              focused
                              placeholder="Select Delivery Hub"
                              variant="outlined"
                              type="text"
                             />
                          )}
                        />
                      </FormControl>
                      </>
                     
                     <ColorButton variant ="contained" sx={{marginTop:"0", marginLeft:1, borderRadius:"15px",padding:0}}
                     onClick={()=>{
                      const data=[...addingDeliveryHubPermissions];
                      if(data.find(item=>item.name===deliveryHubSelected.deliveryHubName)){
                        return;
                      }
                      data.push(
                        {name:deliveryHubSelected.deliveryHubName, add:false,edit:false,view:false, deliveryHubCode:deliveryHubSelected.deliveryHubCode,id:""}
                        )
                        setAddingDeliveryHubPermissions(data)
                        console.log('addingStationPermissions: ', addingDeliveryHubPermissions);
                    }}
                     ><AddIcon /></ColorButton>

                     </div>
                     <Grid container sx={{marginTop:1.5}} spacing={2}>

                     {addingDeliveryHubPermissions&&addingDeliveryHubPermissions.map((item,i)=>
                     <React.Fragment>
                     <Grid item xs={4} sx={{marginTop:1}}>{item.name}</Grid>
                     <>
                                  {[
                                    
                                    {
                                      name: "Add",
                                      value: "add",
                                      onChange: () => {
                                        const operat = [ ...addingDeliveryHubPermissions ];
                                        operat[i].add =
                                          !operat[i].add;
                                          setAddingDeliveryHubPermissions(operat);
                                      },
                                    },
                                    {
                                      name: "Edit",
                                      value: "edit",
                                      onChange: () => {
                                        const operat = [...addingDeliveryHubPermissions ];
                                        operat[i].edit =
                                          !operat[i].edit;
                                          setAddingDeliveryHubPermissions(operat);
                                      },
                                    },
                                    {
                                      name: "View",
                                      value: "view",
                                      onChange: () => {
                                        const operat = [...addingDeliveryHubPermissions];
                                        operat[i].view =
                                          !operat[i].view;
                                          setAddingDeliveryHubPermissions(operat);
                                      },
                                    },
                                    
                                  ].map((val) => (
                                    <Grid item xs={1.5}>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={
                                              addingDeliveryHubPermissions[i][val.value]
                                            }
                                            onChange={val.onChange}
                                          />
                                        }
                                        label={
                                          <Typography
                                            variant="subtitle1"
                                            fontWeight="400"
                                            sx={{
                                              margin: "0px 0px 0px 0px",
                                              fontSize: "16px",
                                              
                                            }}
                                          >
                                            {val.name}
                                          </Typography>
                                        }
                                      />
                                    </Grid>
                                  ))}
                                  <Grid item xs={1.5}><DeleteIcon sx={{marginTop:1}} onClick={()=>{
                                    const data=[...addingDeliveryHubPermissions];
                                    const valu=data.filter(val=>val.name!==item.name);
                                    setAddingDeliveryHubPermissions(valu)
                                  }}/></Grid>
                                </>
                     </React.Fragment>
                     )}
                     </Grid>
                     </>
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                      <Grid container spacing={2}>
                        {[
                          {
                            title: "View Performance",
                            value: "viewPerformance",
                            extra: false,
                          },
                        ].map((item, i) => (
                          <React.Fragment key={i}>
                            <Grid item xs={4}>
                              {item.title}
                            </Grid>
                            {
                              !item.extra ? (
                                <Grid item xs={6}>
                                  <FormControlLabel
                                    key={i}
                                    control={
                                      <IOSSwitch
                                        sx={{ m: 1 }}
                                        checked={performanceMetrics[item.value]}
                                        name={item.value}
                                        label={null}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        onChange={() => {
                                          const ops = { ...performanceMetrics };

                                          ops[item.value] = !ops[item.value];
                                          setPerformanceMetrics(ops);
                                        }}
                                      />
                                    }
                                  />
                                </Grid>
                              ) : (
                                <>
                                  {[
                                    {
                                      name: "All",
                                      value: "all",
                                      onChange: () => {
                                        const operat = {
                                          ...performanceMetrics,
                                        };
                                        operat[item.value].all =
                                          !operat[item.value].all;

                                        setPerformanceMetrics(operat);
                                      },
                                    },
                                    {
                                      name: "Add",
                                      value: "add",
                                      onChange: () => {
                                        const operat = {
                                          ...performanceMetrics,
                                        };
                                        operat[item.value].add =
                                          !operat[item.value].add;
                                        setPerformanceMetrics(operat);
                                      },
                                    },
                                    {
                                      name: "Edit",
                                      value: "edit",
                                      onChange: () => {
                                        const operat = {
                                          ...performanceMetrics,
                                        };
                                        operat[item.value].edit =
                                          !operat[item.value].edit;
                                        setPerformanceMetrics(operat);
                                      },
                                    },
                                    {
                                      name: "View",
                                      value: "view",
                                      onChange: () => {
                                        const operat = {
                                          ...performanceMetrics,
                                        };
                                        operat[item.value].view =
                                          !operat[item.value].view;
                                        setPerformanceMetrics(operat);
                                      },
                                    },
                                    {
                                      name: "Other",
                                      value: "others",
                                      onChange: () => {
                                        const operat = {
                                          ...performanceMetrics,
                                        };
                                        operat[item.value].others =
                                          !operat[item.value].others;
                                        setPerformanceMetrics(operat);
                                      },
                                    },
                                  ].map((val) => (
                                    <Grid item xs={1.5}>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={
                                              performanceMetrics[item.value][
                                                val.value
                                              ]
                                            }
                                            onChange={val.onChange}
                                          />
                                        }
                                        label={
                                          <Typography
                                            variant="subtitle1"
                                            fontWeight="400"
                                            sx={{
                                              margin: "0px 0px 0px 0px",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {val.name}
                                          </Typography>
                                        }
                                      />
                                    </Grid>
                                  ))}
                                </>
                              )
                              //   <>
                              // <Grid item xs={1.5}>Hello</Grid>
                              // <Grid item xs={1.5}>Hello</Grid>
                              // <Grid item xs={1.5}>Hello</Grid>
                              // <Grid item xs={1.5}>Hello</Grid>
                              //   </>
                            }
                          </React.Fragment>
                        ))}
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                    <>
                     <div style={{display:"flex"}}>
                      <>
                   
                      <FormControl sx={{width:"40vh"}}>
                        <Autocomplete
                          // disablePortal
                          disableClearable
                          options={vendorList}
                          getOptionLabel={(option) => option.displayName}
                          focused
                          onChange={(event, newValue) => {
                            setVendorSelected(newValue);
                          }}
                          size="small"
                          renderInput={(params) => (
                            <FormAutoComplete
                              {...params}
                              focused
                              placeholder="Select Vendor"
                              variant="outlined"
                              type="text"
                              />
                          )}
                        />
                      </FormControl>
                      </>
                     
                     <ColorButton variant ="contained" sx={{marginTop:"0", marginLeft:1, borderRadius:"15px",padding:0}}
                     onClick={()=>{
                      const data=[...addingVendorPermissions];
                      if(data.find(item=>item.name===vendorSelected.name)||!vendorSelected){
                        errors();
                        return;
                      }
                      data.push(
                        {name:vendorSelected.displayName, add:false,edit:false,view:false, vendorId:vendorSelected.vendorCode, id:""}
                        )
                        setVendorAddingPermissions(data)
                        console.log('addingStationPermissions: ', addingStationPermissions);
                    }}
                     ><AddIcon /></ColorButton>

                     </div>
                     <Grid container  sx={{marginTop:1.5}} spacing={2}>

                     {addingVendorPermissions&&addingVendorPermissions.map((item,i)=>
                     <React.Fragment sx={{marginTop:"2em"}}>
                     <Grid item xs={4}>{item.name}</Grid>
                     <>
                                  {[
                                    
                                    {
                                      name: "Add",
                                      value: "add",
                                      onChange: () => {
                                        const operat = [...addingVendorPermissions];
                                        operat[i].add =
                                          !operat[i].add;
                                          setVendorAddingPermissions(operat);
                                      },
                                    },
                                    {
                                      name: "Edit",
                                      value: "edit",
                                      onChange: () => {
                                        const operat =  [...addingVendorPermissions ];
                                        operat[i].edit =
                                          !operat[i].edit;
                                          setVendorAddingPermissions(operat);
                                      },
                                    },
                                    {
                                      name: "View",
                                      value: "view",
                                      onChange: () => {
                                        const operat =  [...addingVendorPermissions];
                                        operat[i].view =
                                          !operat[i].view;
                                          setVendorAddingPermissions(operat);
                                      },
                                    },
                                    
                                  ].map((val) => (
                                    <Grid item xs={1.5}>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={
                                              addingVendorPermissions[i][val.value]
                                            }
                                            onChange={val.onChange}
                                          />
                                        }
                                        label={
                                          <Typography
                                            variant="subtitle1"
                                            fontWeight="400"
                                            sx={{
                                              margin: "0px 0px 0px 0px",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {val.name}
                                          </Typography>
                                        }
                                      />
                                    </Grid>
                                  ))}
                                  <Grid item xs={1.5}><DeleteIcon sx={{marginTop:1}} onClick={()=>{
                                    const data=[...addingVendorPermissions];
                                    const valu=data.filter(val=>val.name!==item.name);
                                    setVendorAddingPermissions(valu)
                                  }}/></Grid>
                                </>
                     </React.Fragment>
                     )}
                     </Grid>
                     </>
                    </TabPanel>
                    <TabPanel value={value} index={7}>
                      <Grid container spacing={2}>
                        {[
                          { title: "Employee", value: "employee", extra: true },
                          {
                            title: "Delivery Associate",
                            value: "deliveryAssociate",
                            extra: true,
                          },
                        ].map((item, i) => (
                          <React.Fragment key={i}>
                            <Grid item xs={4}>
                              {item.title}
                            </Grid>
                            {
                              !item.extra ? (
                                <Grid item xs={6}>
                                  <FormControlLabel
                                    key={i}
                                    control={
                                      <IOSSwitch
                                        sx={{ m: 1 }}
                                        checked={employeeManagement[item.value]}
                                        name={item.value}
                                        label={null}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        onChange={() => {
                                          const ops = { ...employeeManagement };

                                          ops[item.value] = !ops[item.value];
                                          setEmployeeManagement(ops);
                                        }}
                                      />
                                    }
                                  />
                                </Grid>
                              ) : (
                                <>
                                  {[
                                    {
                                      name: "All",
                                      value: "all",
                                      onChange: () => {
                                        const operat = {
                                          ...employeeManagement,
                                        };
                                        operat[item.value].all =
                                          !operat[item.value].all;

                                        setEmployeeManagement(operat);
                                      },
                                    },
                                    {
                                      name: "Add",
                                      value: "add",
                                      onChange: () => {
                                        const operat = {
                                          ...employeeManagement,
                                        };
                                        operat[item.value].add =
                                          !operat[item.value].add;
                                        setEmployeeManagement(operat);
                                      },
                                    },
                                    {
                                      name: "Edit",
                                      value: "edit",
                                      onChange: () => {
                                        const operat = {
                                          ...employeeManagement,
                                        };
                                        operat[item.value].edit =
                                          !operat[item.value].edit;
                                        setEmployeeManagement(operat);
                                      },
                                    },
                                    {
                                      name: "View",
                                      value: "view",
                                      onChange: () => {
                                        const operat = {
                                          ...employeeManagement,
                                        };
                                        operat[item.value].view =
                                          !operat[item.value].view;
                                        setEmployeeManagement(operat);
                                      },
                                    },
                                    {
                                      name: "Other",
                                      value: "others",
                                      onChange: () => {
                                        const operat = {
                                          ...employeeManagement,
                                        };
                                        operat[item.value].others =
                                          !operat[item.value].others;
                                        setEmployeeManagement(operat);
                                      },
                                    },
                                  ].map((val) => (
                                    <Grid item xs={1.5}>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={
                                              employeeManagement[item.value][
                                                val.value
                                              ]
                                            }
                                            onChange={val.onChange}
                                          />
                                        }
                                        label={
                                          <Typography
                                            variant="subtitle1"
                                            fontWeight="400"
                                            sx={{
                                              margin: "0px 0px 0px 0px",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {val.name}
                                          </Typography>
                                        }
                                      />
                                    </Grid>
                                  ))}
                                </>
                              )
                              //   <>
                              // <Grid item xs={1.5}>Hello</Grid>
                              // <Grid item xs={1.5}>Hello</Grid>
                              // <Grid item xs={1.5}>Hello</Grid>
                              // <Grid item xs={1.5}>Hello</Grid>
                              //   </>
                            }
                          </React.Fragment>
                        ))}
                      </Grid>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "end",
                        }}
                      >
                        <ColorButton
                          sx={{ marginTop: 2 }}
                          variant="contained"
                          onClick={() => setNext(true)}
                        >
                          Confirm
                        </ColorButton>
                      </Box>
                    </TabPanel>
                  </>
                ) : (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          mt={1}
                          mb={1}
                          variant="subtitle2"
                          fontWeight="bold"
                        >
                          Team Name
                        </Typography>
                        <Autocomplete
                          // disablePortal
                          disableClearable
                          options={teamList}
                          getOptionLabel={(option) =>
                            option.name
                          }
                          focused
                          onChange={(event, newValue) => {
                            setTeamSelected(newValue);
                          }}
                          
                          size="small"
                          renderInput={(params) => (
                            <FormAutoComplete
                              {...params}
                              focused
                              placeholder="Team Name"
                              variant="outlined"
                              type="text"
                              
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                      <Typography
                          mt={1}
                          mb={1}
                          variant="subtitle2"
                          fontWeight="bold"
                        >
                          Designation Name
                        </Typography>
                        <Autocomplete
                          // disablePortal
                          disableClearable
                          options={designationList}
                          getOptionLabel={(option) =>
                            option.designationName
                          }
                          focused
                          onChange={(event, newValue) => {
                            setDesignationSelected(newValue);
                          }}
                          
                          size="small"
                          renderInput={(params) => (
                            <FormAutoComplete
                              {...params}
                              focused
                              placeholder="Designation Name"
                              variant="outlined"
                              type="text"
                              
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}

                <Button
                  onClick={
                    activeStep === steps.length - 1
                      ? handleSubmitting
                      : handleNext
                  }
                  disabled={!next}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Card>
    </>
  );
}
