import http from "./httpService";
import config from "../config.json";


const tokens = localStorage.getItem("token");
const token=JSON.parse(tokens)?.jwttoken;
  
const head = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      Authorization: `Bearer ${token}`,
    },
  };


  export function packetsOfDAOnDate(date, user){
    return http.get(config.apiUrl+"/roverapi/v1/packetmanuallyofdaondate/"+date+"/"+user ,head);
}
 
  export function pickupPacketsStation(data ){
    return http.get(config.apiUrl+"/roverapi/v1/getlastmonthstationpickup/"+data ,head);
}
  export function pickupPacketsStationUnassigned(data ){
    return http.get(config.apiUrl+"/roverapi/v1/stationpickuprunsheetAll/"+data ,head);
}
  export function pickupPacketsDeliveryHubUnassigned(data ){
    return http.get(config.apiUrl+"/roverapi/v1/deliveryhubpickuprunsheetAll/"+data ,head);
}
 
  export function pickupPacketsDeliveryHub(data){
    return http.get(config.apiUrl+"/roverapi/v1/getlastmonthdeliveryhubickup/"+data ,head);
}
 
  export function pickupPacketsRunsheetStation(data, date){
    return http.get(config.apiUrl+"/roverapi/v1/gettodaypickupcountstation/"+data+"/"+date ,head);
}
 
  export function pickupPacketsRunsheetDeliveryHub(data, date){
    return http.get(config.apiUrl+"/roverapi/v1/gettodaypickupcountdeliveryhub/"+data+"/"+date ,head);
}
 
  export function getDAPickupRunsheet(data){
    return http.get(config.apiUrl+"/roverapi/v1/getpickupcountofdeliveryassociate/"+data ,head);
}
 
  export function getDAPickupToday(data){
    return http.get(config.apiUrl+"/roverapi/v1/dapickuprunsheettoday/"+data ,head);
}
 
  export function getPickupPacketInfo(data){
    return http.get(config.apiUrl+"/roverapi/v1/pickupsdetailsbyid/"+data ,head);
}
 
  export function getPickupPacketInfoRunsheetID(data){
    return http.get(config.apiUrl+"/roverapi/v1/pickupsrunsheetdetailbyid/"+data ,head);
}
 
 
  export function getPickupRunsheetInfo(data){
    return http.get(config.apiUrl+"/roverapi/v1/pickupsofrunsheet/"+data ,head);
}
  export function getPickupParticularData(station,date){
    return http.get(config.apiUrl+"/roverapi/v1/gettodaypickupcountstation/"+station+"/"+date ,head);
}
  export function getPickupParticularDataDH(station,date){
    return http.get(config.apiUrl+"/roverapi/v1/gettodaypickupcountdeliveryhub/"+station+"/"+date ,head);
}
 
  export function getPickupDAImage(data){
    return http.get(config.apiUrl+"/roverapi/v1/dauploadedpickupimageurl/"+data ,head);
}
 

export function addPickupPacket(data){
    return http.post (config.apiUrl+"/roverapi/v1/createorupdatepickupmanually", data,head);
}
export function multipleIdsFetch(data){
    return http.post (config.apiUrl+"/roverapi/v1/getmultipleids", data,head);
}
export function AssignPickupToDA(data){
    return http.post (config.apiUrl+"/roverapi/v1/pickuprunsheetassign", data,head);
}
export function DAPickupDone(data){
    return http.post (config.apiUrl+"/roverapi/v1/dapickupreceivedfromcustomer", data,head);
}
export function DAPickupCancel(data){
    return http.post (config.apiUrl+"/roverapi/v1/dapickupcancelreason", data,head);
}
export function setPacketToPickup(data){
    return http.post (config.apiUrl+"/roverapi/v1/setpickuptrue", data,head);
}
export function updatePacketAtStation(data){
    return http.post (config.apiUrl+"/roverapi/v1/managerconformationpickupstatus", data,head);
}
export function unableToPickup(data){
    return http.post (config.apiUrl+"/roverapi/v1/dapickupcallstatus", data,head);
}