import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Compressor from "compressorjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DAPickupCancel,
  getPickupPacketInfo,
  multipleIdsFetch,
  unableToPickup,
} from "../../services/pickup";
import { ColorButton } from "../../styles/button";
import { UploadTextField } from "../../styles/textField";
import { DAPickupDone } from "./../../services/pickup";

const PickupPackageInformation = () => {
  let { id } = useParams();

  const [packet, setPacket] = useState();
  const [rejectDelivery, setRejectDelivery] = React.useState(false);
  const [notDelivered, setNotDelivered] = React.useState(false);
  const [confirmDelivered, setConfirmDelivered] = React.useState(false);
  const [rejectReason, setRejectReason] = React.useState("");
  const [notDeliverReason, setNotDeliverReason] = React.useState("");
  const [submitting, setSubmitting] = useState(false);
  
  const [imagePacket, setImagePacket] = useState();

  const compressFile=(e)=>{
    const file = e.target.files[0];

    if (!file) {
      return;
    }
  
    new Compressor(file, {
      quality: 0.4,
  
      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        console.log(">>>>>>>",result);
        setImagePacket(result)
      },
      error(err) {
        console.log(err.message);
      }
  })}

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const success = () => {
    toast.success("Data Submitted Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  console.log("packet: ", packet);

  useEffect(() => {
    console.log("id: ", id);
    const getPacketInfo = async () => {
      console.log("dharrrrrrr");
      const { data } = await getPickupPacketInfo(id);
      if (data) {
        setPacket(data);
      }
    };
    getPacketInfo();
  }, []);
  const navigate = useNavigate();

  const markPicked = async () => {
    console.log(packet.id);
    setSubmitting(true);
    var form_data = new FormData();
    let formValues = {};
    formValues.id= packet.id;
    formValues.createdBy= window.localStorage.getItem("username");
    for (var key in formValues) {
      form_data.append(key, formValues[key]);
    }
    form_data.append("pickupimageblob", imagePacket);
   

    try{

      const { data } = await DAPickupDone(form_data);
      console.log("skehgfkhsdbfkjsb", data);
      if (data) {
        console.log("Ho gayaaa");
        success()
        setSubmitting(false)
        navigate("/rover/DA/todayPickups");
      }
    } catch(ex){
      error()
      setSubmitting(false)
    }
  };

  console.log(rejectReason);
  const markCancelled = async () => {
    console.log(packet.id);
    try{

      const { data } = await DAPickupCancel({
        id: packet.id,
        cancelReason: rejectReason,
      });
      if (data) {
        success()
        navigate("/rover/DA/todayPickups");
      }
    }catch(ex){
      error()
    }
  };
  const markUndelivered = async () => {
    console.log(packet.id);
    try{

      const { data } = await unableToPickup({
        id: packet.id,
        callStatus: notDeliverReason,
      });
      if (data) {
        success()
        navigate("/rover/DA/todayPickups");
      }
    }catch(ex){
      error();
    }
  };
  return (
    <>
      <Card sx={{ margin: "65px 10px 0px 10px" }}>
        <div
          style={{ backgroundColor: "#FF6A1A", color: "#FFF", padding: "10px" }}
        >
          <Typography variant="body2" fontWeight={600}>
            {packet?.customerName}
          </Typography>
          <Typography variant="caption" fontWeight={400}>
            {packet?.address},{packet?.city}, {packet?.state}
          </Typography>
        </div>

        <div style={{ padding: "15px" }}>
          <div
            style={{
              backgroundColor: "#F4F8FF",
              padding: "5px",
              borderRadius: "12px",
            }}
          >
            <Typography variant="body2" fontWeight={600}>
              {packet?.roverId}
            </Typography>
          </div>
          <Typography variant="body1" mt={1} fontWeight={600}>
            {packet?.paymentMode}
          </Typography>
          <Typography variant="caption" fontWeight={600}>
            Order Value:{" "}
            <span style={{ color: "#FF6A1A" }}>{packet?.productCost}</span>{" "}
          </Typography>
          <Grid container spacing={2} mt={5}>
            <Grid item xs={6}>
              <div
                style={{
                  backgroundColor: "#F4F8FF",
                  textAlign: "center",
                  padding: "15px",
                  borderRadius: "12px",
                  color: "#3D3F52",
                }}
              >
                <Typography variant="body2" fontWeight={600}>
                  <a href={`tel:${packet?.customerPhone}`}>
                    {/* <span style={{ color: "#3D3F52", textDecoration: "none" }}> */}
                      Call
                    {/* </span> */}
                  </a>{" "}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  backgroundColor: "#F4F8FF",
                  textAlign: "center",
                  padding: "15px",
                  borderRadius: "12px",
                }}
              >
                  <a
                  href={`google.navigation:q=${packet?.latitude}+${packet?.longitude}`}
                >
                <Typography variant="body2" fontWeight={600}>
                  Track
                </Typography>

                </a>
              </div>
            </Grid>
            <Grid item xs={12}>
              {/* <div style={{backgroundColor:"#F4F8FF", textAlign:"center", padding:"15px", borderRadius:"12px"}}>
                            <Typography variant="body2" fontWeight={600}>Collect Payment</Typography>
                            
                            </div> */}
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: "#F4F8FF",
                  textAlign: "center",
                  padding: "15px",
                  borderRadius: "12px",
                }}
                onClick={() => {
                    setRejectDelivery(true)
                    // markCancelled()
                }
                }
              >
                <Typography variant="body2" fontWeight={600}>
                  Rejected Pickup
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: "#F4F8FF",
                  textAlign: "center",
                  padding: "15px",
                  borderRadius: "12px",
                }}
                onClick={()=>setNotDelivered(true)}
              >
                <Typography variant="body2" fontWeight={600}>
                  Unable To Pickup
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row">
                <UploadTextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="Upload Packet Image"
                  type="text"
                  value={imagePacket?.name}

                  // onChange={(e) => setSearch(e.currentTarget.value)}
                />
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    color: "#fff",
                    boxShadow: "none",
                  }}
                  onChange={(e) => {
                    console.log("dksgfkjah", e.target.files[0]);
                    compressFile(e);
                  }}
                >
                  Upload
                  <input
                    type="file"
                    name="picture"
                    accept="image/*"
                    capture="user"
                    hidden
                  />
                </Button>
              </Stack>
            </Grid>
           { <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: "#F4F8FF",
                  textAlign: "center",
                  padding: "15px",
                  borderRadius: "12px",
                }}
                onClick={() => setConfirmDelivered(true)}
              >
                <Typography variant="body2" fontWeight={600}>
                  Picked Up
                </Typography>
              </div>
            </Grid>}
          </Grid>
        </div>
      </Card>

      <Modal
        open={rejectDelivery}
        onClose={() => setRejectDelivery("")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="body">Rejection Reason</Typography>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth sx={{ mt: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-simple-select-label">
                Rejection Reason
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rejectReason}
                //   size="small"
                label="Rejection Reason"
                onChange={(event) => {
                  setRejectReason(event.target.value);
                }}
              >
                <MenuItem value="Different Product">Different Product</MenuItem>
                <MenuItem value="Used Product">Used Product</MenuItem>
                <MenuItem value="Damaged By Customer">
                  Damaged By Customer
                </MenuItem>
                <MenuItem value="Missing Label">Missing Label</MenuItem>
                <MenuItem value="Missing Packaging">Missing Packaging</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <ColorButton
            variant="contained"
            onClick={() => {
              setRejectDelivery(false);
              markCancelled()
            }}
          >
            Submit
          </ColorButton>
        </Box>
      </Modal>
      <Modal
        open={notDelivered}
        onClose={() => setNotDelivered(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="body">Unable To Pickup Reason</Typography>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth sx={{ mt: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-simple-select-label">
                Reason
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={notDeliverReason}
                //   size="small"
                label="Rejection Reason"
                onChange={(event) => {
                  setNotDeliverReason(event.target.value);
                }}
              >
              <MenuItem value="Call Not Picked">Call Not Picked</MenuItem>
              <MenuItem value="Customer Not Available">Customer Not Available</MenuItem>
              <MenuItem value="Customer Unable To Pay">Customer Unable To Pay</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <ColorButton
            variant="contained"
            onClick={() => {
              setNotDelivered(false);
              markUndelivered()
            }}
          >
            Submit
          </ColorButton>
        </Box>
      </Modal>
      <Modal
        open={confirmDelivered}
        onClose={() => setConfirmDelivered(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="body">Confirm If Picked Up</Typography>
          <Box sx={{ minWidth: 120 }}>
          
          </Box>

          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
      }}>

          <ColorButton variant="outlined" sx={{color:"#000", mr:2}}
          onClick={() => {

            setConfirmDelivered(false)
            
        }}
          >
            Cancel
          </ColorButton>
          <ColorButton variant="contained" 
          onClick={() => {

            setConfirmDelivered(false)
            markPicked()
        }}
          >
            Confirm
          </ColorButton>
      </div>
        </Box>
      </Modal>

      <Modal
        open={submitting}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="body">Submitting...</Typography>

                 </Box>
      </Modal>
    </>
  );
};

export default PickupPackageInformation;
