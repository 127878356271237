import * as React from "react";
import {
  Typography,
  Card,
  Paper,
  IconButton,
  InputAdornment,
  Grid,
  Avatar,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { Search, FilterList, Edit } from "@mui/icons-material";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
// import TableCell from '@mui/material/TableCell';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
// import TableRow from '@mui/material/TableRow';
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BackupTable } from "@mui/icons-material";
import XLSX from "xlsx";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faCoffee } from "@fortawesome/fontawesome-free-solid";
import { StyledTableCell, StyledTableRow } from "../styles/table";
import { getMemberPermission } from "../services/memberAndPermissions";
import { viewAllStationServices } from "./../services/station";
import { SearchTextField } from "../styles/textField";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { ColorButton } from "../styles/button";
import QuickTracking from "../components/QuickTracking";
import { getDeliveryBoyPackets, getRunsheetDetails, updateDevOpsInStation } from "../services/packets";
import moment from "moment";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "stationName",
    numeric: false,
    disablePadding: true,
    label: "No.",
  },
  {
    id: "stationName",
    numeric: false,
    disablePadding: true,
    label: "Vendor",
  },
  {
    id: "stationCode",
    numeric: true,
    disablePadding: false,
    label: "Customer Details",
  },
  {
    id: "stationManager",
    numeric: true,
    disablePadding: false,
    label: "Rover Id",
  },

  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Address",
  },
  // {
  //   id: "Status",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Product",
  // },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Items",
  },
  {
    id: "Status by DA",
    numeric: true,
    disablePadding: false,
    label: "COD Amount",
  },
  {
    id: "Status by Manager",
    numeric: true,
    disablePadding: false,
    label: "Status by DA",
  },

  {
    id: "",
    // numeric: true,
    disablePadding: false,
    label: "Status by Manager",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const EnhancedTableToolbar = (props) => {  
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        // pl: { sm: 2 },
        // pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ParticularRunsheet() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [rowData, setRowData] = React.useState([]);
  const [permissions, setPermissions] = React.useState({});
  const [role, setRole] = React.useState("");
  const [statuses, setStatuses] = React.useState([]);
  const [editable, setEditable] = React.useState(false);

  let {id}= useParams();
  React.useEffect(() => {
    const tokens = localStorage.getItem("token");
    const token = JSON.parse(tokens);
    console.log("token: ", token);
    let permission;
    setRole(token.role);
    async function memberPermission() {
      const { data } = await getMemberPermission(token.userId);
      if (data) {
        setPermissions(data);
        permission = data.roleStationResponse;
        getDARunsheet();
      }
    }

    const getDARunsheet= async()=>{
      const {data} = await getRunsheetDetails(id);
      console.log('data: ', data);
      if(data){
        const vals=data.map(item=>{
          return {id:item.id, status:item.managerPacketStatus}
        })
        console.log('vals: ', vals);
        setStatuses(vals)
        setRowData(data)
        

      }
    }

 
    memberPermission();
  }, []);

  console.log("rows: ", rowData);
  const rows =
    rowData &&
    rowData?.filter(
      (item) => item
        // item?.stationName.toLowerCase().includes(search.toLowerCase()) ||
        // item?.stationCode
        //   .toString()
        //   .toLowerCase()
        //   .includes(search.toLowerCase()) ||
        // item?.city.toString().toLowerCase().includes(search)
    );

  // calories,
  // stationManager,
  // managerNumber,
  // managerEmail,
  // city,

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  console.log(permissions?.editStation);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const submitHandover= async()=>{
    console.log("hhe",statuses);
    const datas={
      "createdBy":window.localStorage.getItem("username"),
      "runsheetId":rowData[0].runsheetId,
      "data":statuses
  
  }

  console.log("Data hai", datas);

  const {data}= await updateDevOpsInStation(datas)
  if(data){

    console.log('dataapi: ', data);
    setEditable(false)
  }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const downloadExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(rowData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "branches");
    //Buffer
    let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "branches.xlsx");
  };

  const editPer = role === "Admin" || permissions?.editStation;
  console.log("role: ", role);
  console.log("editPer: ", editPer);

  return (
    <>
      <Grid container spacing={1}>
<Grid item xs={11}>
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        Runsheet List
      </Typography>

</Grid>
<Grid item xs={1}>

      <ModeEditIcon sx={{mt:2}} onClick={()=> setEditable(!editable)}/> 
</Grid>
      </Grid>

      <Card
        sx={{
          margin: "8px 15px 15px 15px",
          py: 3,
          px: 3,
          borderRadius: "8px",
          minheight: "90vh",
        }}
      >
        <Box
          sx={{
            marginBottom: 2,
          }}
          px="14px"
          py="10px"
        >
          <Grid container spacing={4}>
            {[
              { title: "Runsheet ID", subValue: id },
              { title: "DA Name", subValue: rowData?.[0]?.assignPerson },
              { title: "Date Created", subValue: moment(rowData?.[0]?.assignDate).format("dddd, Do MMMM YYYY")} ,
              { title: "Number of Packets", subValue: rowData.length },
              // { title: "Status", subValue: "Complete" },
            ].map((item) => {
              return (
                <Grid item xs={2.2}>
                  <Typography
                    variant="caption"
                    fontWeight="600"
                    sx={{ margin: "15px 0px 0px 18px", linespacing:0 }}
                  >{item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight="600"
                    sx={{ margin: "15px 0px 0px 18px" }}
                  >
{item.subValue}                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />

        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{
              width: "100%",
              mb: 2,
              boxShadow:
                "0px 2px 0px -5px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 4px 0px rgb(0 0 0 / 4%)",
            }}
          >
            <TableContainer>
              <Table
                sx={{ minWidth: 1100 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      console.log("isItemSelected: ", isItemSelected);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          // role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          // selected={isItemSelected}
                        >
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align="left"
                            padding="normal"
                            sx={{ width: "15%", cursor: "pointer" }}
                          >
                            
                              {index+1}
                         
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align="left"
                            padding="normal"
                            sx={{ width: "15%", cursor: "pointer" }}
                          >
                            
                              {row.vendor}
                         
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                            {row.customerName}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "16%" }}
                          >
                            <Link to={`/rover/particularRunsheetrunsheetId/${id}/${row.roverId}`}>
                            {row.roverId}
                            </Link>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                           {row.customerPhone}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                         {row.address}, {row.city}, {row.state},({row.postalCode})
                          </StyledTableCell>
                          
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                         1
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                          {row.productCost}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                          {row.packageStatus.toUpperCase()}
                          </StyledTableCell>

                          <StyledTableCell sx={{ width: "11%" }}>
                           
                          <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth  sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-simple-select-label">Select</InputLabel>
        <Select
          id="demo-simple-select"
          disabled={!editable}
          value={statuses?.[index]?.status}
          label="Select"
          placeholder="Select"

          size="small"
          onChange={(event)=>{
            const data=[...statuses];
            data[index].status=event.target.value;
            console.log('data: ', data);

          }}
        >
          <MenuItem value={"pending"}>RTS</MenuItem>
          <MenuItem value={"cancelled"}>Cancelled</MenuItem>
          <MenuItem value={"delivered"}>Delivered</MenuItem>
        </Select>
      </FormControl>
    </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}{" "}
                  {emptyRows > 0 && (
                    <StyledTableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <StyledTableCell colSpan={6} />
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              sx={{ backgroundColor: "#F7F7FC" }}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
        {editable&&<ColorButton variant="contained" onClick={()=>{
          submitHandover()
        }}>Submit</ColorButton>}
      </Card>
    </>
  );
}
