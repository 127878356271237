import React from "react";
import HeaderImage from "./images/HeaderImage.jpg";
import VitranLogo from "./images/VitranLogo.png";
import V1 from "./images/V1.jpg";
import V2 from "./images/V2.jpg";
import V3 from "./images/v3.png";
import map from "./images/map3.png";
import V4 from "./images/v4.jpg";
import V5 from "./images/v5.png";
import tech from "./images/Tech.svg";
import log from "./images/Logistics.svg";
import launch from "./images/Launch.svg";
import customer from "./images/Customer.svg";
import { Grid } from "@mui/material";
import "./styles/layoutHomepage.scss";
import { Link } from "react-router-dom";
import { sendEnquiry } from "../services/login";
import { toast } from "react-toastify";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Amazon from "../video/AmazonVideo.mp4";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';

const Homepage = () => {
  const [form, setForm] = React.useState({
    name: "",
    companyName: "",
    phoneNumber: "",
    emailId: "",
    message: "",
  });

  const [open, setOpen]=React.useState(false)
  console.log('open: ', open);

  const success = () => {
    toast.success("Data Submitted Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const errorM = () => {
    toast.error("Please Fill All The Feilds", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const [faq, setFaq] = React.useState(0);
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("hashchange", (e) => {
      setTimeout(function () {
        if (window.location.hash) {
          window.history.replaceState(
            "",
            document.title,
            window.location.href.split("#")[0]
          );
        }
      }, 1);
    });
  }, []);

  const submitForm = async () => {
    if (!form.name || !form.phoneNumber) {
      errorM();
      return;
    }
    try {
      setDisabled(true);
      const { data } = await sendEnquiry(form);
      if (data) {
        setForm({
          name: "",
          companyName: "",
          phoneNumber: "",
          emailId: "",
          message: "",
        });
        success();
        setDisabled(false);
      }
    } catch (e) {
      setDisabled(false);
      error();
    }
  };

  return (
    <>
      <div className="headerblock" id="home">
        <div className="container-head">
          <img
            src={HeaderImage}
            alt="HeaderImage"
            className="backgroundImage"
          />
          <div className="topMenu">
            <div className="topMenuContainer">
              <div className="leftMenu">
                <div className="menuItems">
                  <a className="anchorTags" href="#home">
                    Home
                  </a>
                </div>
                <div className="menuItems">
                  <a className="anchorTags" href="#services">
                    Services
                  </a>
                </div>
                <div className="menuItems">
                  <a className="anchorTags" href="#saksham">
                    Saksham
                  </a>
                </div>
                <div className="menuItems">
                  <a className="anchorTags" href="#areas">
                    Our Areas
                  </a>
                </div>
              </div>
              <div className="middleMenu">
                <img src={VitranLogo} alt="Logo" />
              </div>
              <div className="rightMenu">
                <div className="menuItems">
                  <a className="anchorTags" href="#talkToExpert">
                    Talk To Expert
                  </a>
                </div>
                <div className="menuItems">
                  <a className="anchorTags" href="#whyUs">
                    Why Us
                  </a>
                </div>
                <div className="menuItems">
                  <a className="anchorTags" href="#contact">
                    Contact Us
                  </a>
                </div>
                <button>Download APK</button>
              </div>
            </div>
          </div>
            <div className="mobileTopMenu">
              <img src={VitranLogo} alt="Logo" />
            <div className="nav-icon">
             {open? <CloseIcon onClick={()=>{
                  setOpen(!open)
              }}/>:<MenuIcon onClick={()=>{
                  setOpen(!open)
              }} />}
            </div>
            </div>
          {open&&<div className="sideMenu">
                <ul>
                  <li>  <a onClick={()=>{setOpen(false)}} className="anchorTags" href="#home">
                    Home
                  </a>
                  </li>

                  <li> <a onClick={()=>{setOpen(false)}} className="anchorTags" href="#services">
                    Services
                  </a></li>
                  <li> <a onClick={()=>{setOpen(false)}} className="anchorTags" href="#saksham">
                    Saksham
                  </a></li>
                  <li> <a onClick={()=>{setOpen(false)}} className="anchorTags" href="#areas">
                    Our Areas
                  </a></li>
                  <li> <a onClick={()=>{setOpen(false)}} className="anchorTags" href="#talkToExpert">
                    Talk To Expert
                  </a></li>
                  <li>  <a onClick={()=>{setOpen(false)}} className="anchorTags" href="#whyUs">
                    Why Us
                  </a></li>
                  <li> <a onClick={()=>{setOpen(false)}} className="anchorTags" href="#contact">
                    Contact Us
                  </a></li>
                  <li> <button>Download APK</button></li>
                </ul>
            </div>
         }
          <h1 className="textover">
            WE <span className="colorChangeSpan">D</span>
            ELIVER
            <span className="colorChangeSpan">.</span>
            <br />
            WE <span className="colorChangeSpan">D</span>
            ISTRIBUTE
            <span className="colorChangeSpan">.</span>
          </h1>
          <p className="paragraphs fontSizePara">
            We empower e-commerce, logistics and hyperlocal companies by setting
            up quick-scale delivery models that address variations in demand and
            enable digital transformation.&nbsp;&nbsp;
          </p>

          <div className="button">
            <Link to="rover/login">
              <button className="buttonOne">Login To Rover</button>
            </Link>
            <a href="#talkToExpert">
              <button className="buttonTwo">Let's Talk</button>
            </a>
          </div>
        </div>
      </div>
      <div></div>

      <div className="logisticNetwork" id="services">
        <div>
          <h2
            className="heading-text"
            style={{ color: "#fff", textAlign: "center" }}
          >
            A <span className="colorChangeSpan">Flexible</span> Plug And Play
            Logistics Network{" "}
          </h2>
        </div>
        <br />
        <br />
        <div className="gridCard-1">
          <Grid container spacing={4}>
            {[
              {
                title: "Flexible Delivery models",
                subtitle:
                  "First mile, middle mile, reverse pick up and last mile delivery services.",
              },
              {
                title: "Quick deployment",
                subtitle: "Prompt set-up for new business and scale",
              },
              {
                title: "Fast scale up",
                subtitle: "Address peaks and demand fluctuations",
              },
              {
                title: "Warehousing solutions",
                subtitle: "Elastic storage and distribution facilities",
              },
              {
                title: "Hyperlocal support",
                subtitle:
                  "On demand and scheduled delivery partners for hyperlocal services",
              },
              {
                title: "Customer Efficiency",
                subtitle: "Empower customers to achieve business goals",
              },
            ].map((item, index) => (
              <Grid item xs={12} lg={4} key={index}>
                <div className="cardLogisticsNetwork">
                  <h3
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span className="colorChangeSpan">{item?.title}</span>
                  </h3>
                  <br />
                  <h5
                    style={{ textAlign: "center", color: "#fff" }}
                    className="fontSizePara"
                  >
                    {item?.subtitle}
                  </h5>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <div className="saksham" id="saksham">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <img
                  className="imagesOfStation"
                  src={V1}
                  alt="Station Photos"
                />
              </Grid>
              <Grid item xs={6}>
                <img
                  className="imagesOfStation"
                  src={V2}
                  alt="Station Photos"
                />
              </Grid>
              <Grid item xs={6}>
                <img
                  className="imagesOfStation"
                  src={V4}
                  alt="Station Photos"
                />
              </Grid>
              <Grid item xs={6}>
                <img
                  className="imagesOfStation"
                  src={V5}
                  alt="Station Photos"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className="saksham-text">
              <h4 className="heading-text-2">
                {" "}
                <span className="colorChangeSpan">SAKSHAM</span>
              </h4>
              <br />
              <h5 className="paragraph">
                Saksham, our last mile delivery service provides highly
                effective and cost-efficient services in North India. With our
                own warehouses, well-trained fieldforce and network of delivery
                vans, our Saksham teams are easily integrated in client
                operations using world-class processes and cutting-edge
                technology.
              </h5>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="map-section" id="areas">
        <Grid container spacing={3}>
          <Grid item sm={12} lg={6}>
            <h4 className="heading-text" style={{ color: "#fff" }}>
              WE HAVE DEEP PRESENCE IN{" "}
              <span className="colorChangeSpan"> NORTH INDIA</span>
            </h4>
            <br />
            {[
              { number: "6", text: "States" },
              { number: "60+", text: "Towns & Cities" },
              { number: "30000", text: "Daily Shipments" },
            ].map((item, index) => (
              <div className="numbers" key={index}>
                <h4 className="heading-text">
                  <span className="colorChangeSpan">{item.number}</span>
                </h4>
                <h3 style={{ color: "#fff" }}>{item.text}</h3>
              </div>
            ))}
          </Grid>
          <Grid item sm={12} lg={6}>
            <img src={map} alt="map" />
          </Grid>
        </Grid>
      </div>
      <div className="whyChooseUs" id="whyUs">
        <h4
          className="heading-text-2"
          style={{ color: "#fff", textAlign: "center" }}
        >
          WHY CHOOSE <span className="colorChangeSpan">US</span>
        </h4>
        <Grid container spacing={3}>
          {[
            {
              icon: customer,
              text: "Deliver Exceptional Experience & Customer Satisfaction",
            },
            { icon: log, text: "Drive Logistics Effeciency" },
            { icon: tech, text: "Cutting Edge Technology" },
            {
              icon: launch,
              text: "Launch And Scale Innovative Delivery Channels",
            },
          ].map((item, index) => (
            <Grid item key={index} xs={12} lg={3}>
              <div className="iconsAndText">
                <img src={item.icon} alt={item.text} />

                <h3 style={{ color: "#fff" }} className="fontSizePara">
                  {item.text}
                </h3>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>

      <div className="videoSection">
        <h4 className="heading-text" style={{ color: "#fefeff" }}>
          Delivering <span className="colorChangeSpan">Excellence</span>
        </h4>
        <h3
          style={{ color: "#fefeff", marginTop: "15px" }}
          className="fontSizePara"
        >
          Watch the determination of our team as they navigate treacherous
          trails, bridging the gap between remote communities and essential
          supplies in remote areas.
        </h3>
        <Grid container spacing={2} sx={{ marginTop: "5%" }}>
          <Grid item xs={12} lg={6}>
            <h4 className="quotes">
              “It has been a fulfilling experience. With Amazon’s support, we
              have set up a highly efficient operational team. This has allowed
              us to serve our customers well in all the territories in which we
              currently operate. I want to continue growing with Amazon as we
              expand to other geographical locations over the next 3 years.”
            </h4>
            <p
              className="colorChangeSpan"
              style={{
                marginTop: "10px",
                padding: "0% 15% 0 10% ",
                fontWeight: "800",
              }}
            >
              -Rahul Kesar | CEO Vitran{" "}
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <video controls>
              <source src={Amazon} type="video/mp4" />
            </video>
            <p
              className="colorChangeSpan"
              style={{
                marginTop: "10px",
                padding: "0% 15% 0 10% ",
                fontWeight: "400",
              }}
            >
              Video Credits:{" "}
              <a
                href="https://www.aboutamazon.in/news/operations/delivering-amazon-packages-in-himalayas?utm_source=paid&utm_medium=ani&utm_term=datw"
                target="_blank"
              >
                <i>
                  <strong>Amazon News India</strong>
                </i>
              </a>{" "}
            </p>
          </Grid>
        </Grid>
      </div>

      <div className="faq">
        <h4
          className="heading-text"
          style={{
            color: "#fefeff",
          }}
        >
          FREQUENTLY ASKED <span className="colorChangeSpan">QUESTIONS</span>
        </h4>
        <br />
        <br />

        {[
          {
            question: "What areas do Vitran delivery services cover?",
            answer:
              "We are currently operational in over 60+ cities and towns across Uttarakhand, Uttar Pradesh, Punjab, Haryana, Himachal Pradesh, and NCR.",
          },
          {
            question: "Does Vitran have its own delivery workforce and fleet?",
            answer:
              "Yes, we have our own dedicated workforce and fleet. Our team is trained and deployed based on client requirements, allowing us to swiftly launch new operations and scale efficiently during peak demand.",
          },
          {
            question: "What types of deliveries does Vitran specialize in?",
            answer:
              "We specialize in both last mile delivery for e-commerce businesses and hyperlocal delivery services for vendors, ensuring swift and efficient distribution of goods.",
          },
          {
            question: " Can Vitran handle bulk deliveries for businesses?",
            answer:
              " Absolutely. We have the capacity and expertise to handle bulk deliveries for businesses of all sizes. Our tailored solutions cater to the unique needs of each client and our technology easily integrates with existing ERP systems.",
          },
          {
            question: "What sets Vitran apart from other delivery services?",
            answer:
              "At Vitran, we pride ourselves on our commitment to reliability, speed, and customer satisfaction. Our dedicated workforce, technology, and personalized solutions and ability to service far-flung and hard to reach areas such as Uttarkashi distinguish us as a preferred choice for last mile and hyperlocal deliveries.",
          },
          {
            question:
              "How does Vitran ensure sustainability in its operations?",
            answer:
              "We are committed to sustainability. Our fleet is equipped with eco-friendly vehicles, and we are continually implementing environmentally conscious practices and electric vehicles to minimize our carbon footprint in the last mile delivery process.",
          },
        ].map((item, index) => (
          <div
            className={
              faq === index ? "questionAnswer-Selected" : "questionAnswer"
            }
            key={index}
          >
            <div
              className="iconsFlex"
              onClick={() => {
                setFaq(index);
              }}
            >
              <h3 className="h3Tag">{item?.question}</h3>
              <ExpandCircleDownIcon sx={{ color: "#F46D25" }} />
            </div>

            {faq === index && (
              <h5 className="h5Tag fontSizePara">{item?.answer}</h5>
            )}
          </div>
        ))}
      </div>

      <div className="talkToExpert" id="talkToExpert">
        <h4
          className="heading-text"
          style={{ color: "#fefeff", textAlign: "left" }}
        >
          TALK TO OUR LOGISTICS <span className="colorChangeSpan">EXPERT</span>
        </h4>
        <br />
        <h3 style={{ color: "#fefeff" }} className="fontSizePara">
          Schedule a call with us to join India’s leading ecommerce companies
          and become a Vitran partner.
        </h3>
        <form id="contactForm">
          <Grid container spacing={4}>
            {[
              { title: "Name", type: "text", value: "name" },
              { title: "Company Name", type: "text", value: "companyName" },
              { title: "Phone Number", type: "number", value: "phoneNumber" },
              { title: "Email Id", type: "email", value: "emailId" },
            ].map((item, index) => (
              <Grid item sm={12} lg={6}>
                <input
                  type={item?.type}
                  required
                  value={form[item.value]}
                  placeholder={item.title}
                  onChange={(e) => {
                    const val = { ...form };
                    val[item?.value] = e.currentTarget.value;
                    setForm(val);
                  }}
                />
              </Grid>
            ))}

            <Grid item sm={12} lg={12}>
              <textarea
                id="message"
                name="message"
                placeholder="Message"
                rows="4"
                required
                value={form.message}
                onChange={(e) => {
                  const val = { ...form };
                  val.message = e.currentTarget.value;
                  setForm(val);
                }}
              ></textarea>
            </Grid>
          </Grid>
          <div className="submitButton">
            <button
              type="submit"
              className="button"
              disabled={disabled}
              onClick={() => {
                submitForm();
              }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      <div className="footerHomePage" id="contact">
        <img src={V3} alt="footerImage"></img>
        <h4 className="rightText">
          {" "}
          Second Floor, 1, GMS Road
          <br />
          Nehru Enclave, Satya Vihar, Ballupur
          <br />
          Dehradun, Uttarakhand 248001
        </h4>

        <h4 className="contact">
          Contact Us:
          <br />
          <br />
          <span className="colorChangeSpan">
            <a href="mailto:HELLO@VITRAN.IN"> HELLO@VITRAN.IN</a>
          </span>
          <br />
          <span className="colorChangeSpan">
            <a href="mailto:HELLO@SAKSHAMHUB.COM">HELLO@SAKSHAMHUB.COM</a>
          </span>
        </h4>
        <h4 className="bottom-text">
        <h5 style={{textAlign:"center"}}> 
<Link to="/privacyPolicy">
          Privacy Policy
</Link>
        </h5>
          <br/>
          <br/>
          Copyright ©{new Date().getFullYear()} | Vitran Technologies Pvt Ltd
        </h4>
      </div>
    </>
  );
};

export default Homepage;
