import { Autocomplete, Box, Divider, Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableSortLabel, Toolbar, Typography, alpha } from "@mui/material";
import React, { useEffect } from "react";
import { FormAutoComplete, FormTextField } from "../../styles/textField";
import { ColorButton } from "../../styles/button";
import { StyledTableCell, StyledTableRow } from "../../styles/table";
import { visuallyHidden } from '@mui/utils';
import PropTypes from "prop-types";
import { viewAllVendorServices } from "../../services/vendor";
import { useState } from "react";
import { cashbackForParticularVendor, deleteCashbackAndOfferRecord, getCashbackForParticularVendorList, setOverallOffersAndCashback } from "../../services/cashbacksVendors";
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from "../../Loader";
import { toast } from 'react-toastify';


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  const headCells = [
    {
      id: "vendor",
      numeric: false,
      disablePadding: true,
      label: "Vendor Name",
    },
    {
      id: "cashbackPercent",
      numeric: true,
      disablePadding: false,
      label: "Cashback Percentage",
    },
    {
      id: "",
      // numeric: true,
      disablePadding: false,
      label: "Action",
    },
  ];
  
  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <StyledTableRow>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align="left"
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </StyledTableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          // pl: { sm: 2 },
          // pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      ></Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

const Cashback = () => {

    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [waiting, setWaiting] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowData,setRowData]=React.useState([]);
    const [vendorList, setVendorList]=useState([])
    const [selectedVendor, setSelectedVendor]=useState([])
    const [cashbackAllValue,setCashbackAllValue]= useState(0)
    const [cashbackIndivisualValue,setCashbackIndivisualValue]= useState(0)
    
    const tokens = localStorage.getItem("token");
    const userId= JSON.parse(tokens)?.userId

    const success = () => {
      toast.success("Cashback Percentage applied sucessfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    };
  
    const error = () => {
      toast.error("Some Error Occoured, please try again later", {
        position: toast.POSITION.TOP_RIGHT,
      });
    };

    const getIndivisualData= async()=>{

      const {data}= await getCashbackForParticularVendorList();
      if(data){
        console.log('data:>>>>>>>>>>>>>> ', data);
        setRowData(data)
        setWaiting(false)
         

      }
    }
    
    useEffect(()=>{

      const getAllVendors =async()=>{
        const {data}= await viewAllVendorServices()
        if(data){
          const vals= data?.map(items=> items?.vendorCode)
          setVendorList(vals)
          
        }
      }

      

      getIndivisualData()
      getAllVendors()
    },[])

    const setCashbackForAll= async()=>{
      setWaiting(true)
      const vals = {userId:userId, cashbackPercent:cashbackAllValue}
      try{
        const {data}= await setOverallOffersAndCashback(vals)
        if(data==="Success"){
          success()
          setWaiting(false)
        }
      }
      catch(err){
        error()
        setWaiting(false)
      }
    }
    const setCashbackForIndivisual= async()=>{
      setWaiting(true)
      const vals = {userId:userId, cashbackPercent:cashbackIndivisualValue, vendorCode:selectedVendor,createdBy:localStorage.getItem("username")}
      try{
        const {data}= await cashbackForParticularVendor(vals)
        if(data==="Success"){
          success()
          getIndivisualData()
          setCashbackIndivisualValue(0)
          setSelectedVendor([])

        }else{
          error()
          setWaiting(false)
        }
      }
      catch(err){
        error()
        setWaiting(false)
      }
    }

    const rows = rowData.filter(
        (item) => item 
       
          // // item.displayName.toLowerCase().includes(search.toLowerCase()) 
          // // ||
          // item.phoneNumber
          //   .toString()
          //   .toLowerCase()
          //   .includes(search.toString().toLowerCase()) ||
          // item.email
          //   .toString()
          //   .toLowerCase()
          //   .includes(search.toLowerCase()) ||
          // // item.businessName
          // //   .toString()
          // //   .toLowerCase()
          // //   .includes(search.toLowerCase()) ||
          // item.accountNumber.toString().toLowerCase().includes(search) ||
          // item.gstNumber.toString().toLowerCase().includes(search)
      );

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
      };
    
      const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelecteds = rows.map((n) => n.name);
          setSelected(newSelecteds);
          return;
        }
        setSelected([]);
      };
    
      const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1)
          );
        }
    
        setSelected(newSelected);
      };
    
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    
      const handleChangeDense = (event) => {
        setDense(event.target.checked);
      };
    
      const isSelected = (name) => selected.indexOf(name) !== -1;
    
      // Avoid a layout jump when reaching the last page with empty rows.
      const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;



        const deleteVendor= async(id)=>{

          setWaiting(true)
          try{
            const {data}= await deleteCashbackAndOfferRecord(id)
            if(data){
              getIndivisualData()
              success()
            }
          }
          catch(er){
            setWaiting(false)
            error()
          }
        }

        if(waiting) return <Loader/>
        
        
  return (
    <>
      <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
        Cashback For All(%)
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormTextField
            size="small"
            fullWidth
            placeholder="Enter the Percentage of Cashback"
            variant="outlined"
            type="number"
            onChange={(e)=>setCashbackAllValue(e.target.value)}
            value={cashbackAllValue}
            // error={
            //   formik.touched[item.value] &&
            //   Boolean(formik.errors[item.value])
            // }
            // helperText={
            //   formik.touched[item.value] &&
            //   formik.errors[item.value]
            // }
          />
        </Grid>
        <Grid item xs={6}>
          <ColorButton variant="contained" sx={{ mt: 0 }} onClick={()=>{setCashbackForAll()}}>
            Submit
          </ColorButton>
        </Grid>
      </Grid>
      <br />
      <Divider />

      <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
        Cashback For Particular Vendors
      </Typography>
      <Autocomplete
        multiple
        options={vendorList}
        // getOptionLabel={(option) => option.stationName}
        value={selectedVendor}
        focused
        // name={item.value}
        onChange={(event, value) => {
          setSelectedVendor(value);
        }}
        // value={
        //   formik.values[item.value]
        //     ? formik.values[item.value]
        //     : null
        // }
        // fullWidth
        size="small"
        renderInput={(params) => (
          <FormAutoComplete
            {...params}
            focused
            placeholder="Select The Vendor"
            variant="outlined"
            type="text"
            // error={
            //   formik.touched[item.value] &&
            //   Boolean(formik.errors[item.value])
            // }
            // helperText={
            //   formik.touched[item.value] &&
            //   formik.errors[item.value]
            // }
          />
        )}
      />

      
<br/>
      <Grid container spacing={2}>
      <Grid item xs={1.5}>
      <Typography mt={2} mb={1} variant="subtitle2" fontWeight="bold">
        Disount Value(%)
      </Typography>
      </Grid>
        <Grid item xs={6}>
          <FormTextField
            size="small"
            fullWidth
            placeholder="Enter the Percentage of Cashback"
            variant="outlined"
            type="number"
            onChange={(e)=>setCashbackIndivisualValue(e.target.value)}
            value={cashbackIndivisualValue}
            // error={
            //   formik.touched[item.value] &&
            //   Boolean(formik.errors[item.value])
            // }
            // helperText={
            //   formik.touched[item.value] &&
            //   formik.errors[item.value]
            // }
          />
        </Grid>
        <Grid item xs={3}>
          <ColorButton variant="contained" sx={{ mt: 0 }} onClick={()=>{setCashbackForIndivisual()}}>
            Submit
          </ColorButton>
        </Grid>
      </Grid>

      <br/>
      <Box sx={{ width: "100%" }}>
          <Paper    
            sx={{
              width: "100%",
              mb: 2,
              boxShadow:
                "0px 2px 0px -5px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 4px 0px rgb(0 0 0 / 4%)",
            }}
          >
            <TableContainer>
              <Table
                sx={{ minWidth: 1100 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      console.log("isItemSelected: ", isItemSelected);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          // role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          // selected={isItemSelected}
                        >
                         
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                            {row?.vendor}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                            {row?.cashbackPercent}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                            <DeleteIcon onClick={()=>{deleteVendor(row?.id)}}/>
                          </StyledTableCell >

                        </StyledTableRow>
                      );
                    })}{" "}
                  {emptyRows > 0 && (
                    <StyledTableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <StyledTableCell colSpan={6} />
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              sx={{ backgroundColor: "#F7F7FC" }}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
    </>
  );
};

export default Cashback;
