import { Box, Card, Checkbox, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import QuickTracking from "../components/QuickTracking";
import { multipleIdsFetch, setPacketToPickup } from "../services/pickup";
import { ColorButton } from "../styles/button";
import { FormTextField } from "../styles/textField";
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from "react-toastify";

const SearchPacketsForPickups = () => {

    const [id, setId]=useState()
    const [packetInfo, setPacketInfo]=useState([]);

    console.log('packetInfo: ', packetInfo);

    const getPacketInfos= async()=>{
        const {data}= await multipleIdsFetch(id);
        console.log('data: ', data);
        if(data.length>0){
            const value=[...packetInfo];
            value.push(data);
            setPacketInfo(value);
             
            setId("")
        }else {
          error();
        }

    }
    const success = () => {
      toast.success("Data Submitted Sucessfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    };
  
    const error = () => {
      toast.error("Some Error Occoured, please try again later", {
        position: toast.POSITION.TOP_RIGHT,
      });
    };

    const setPickups=async()=>{
      const pickups= packetInfo.map(item=>{
        console.log(item);
        return item[0].id});
      const pickupsId= {"id":pickups}
      console.log('pickupsId: ', pickupsId);
      try{

        const {data}= await setPacketToPickup(pickupsId);
        console.log('data: ', data);
        if(data){
  
          setPacketInfo([])
          success();
        }else{
          error()
        }
      }
      catch(ex){
        error();
      }

    }

    console.log(id);
    return (
    <>
      <Card
        sx={{
          margin: "8px 15px 15px 15px",
          py: 3,
          px: 5,
          borderRadius: "8px",
          // minHeight:"87vh"
        }}
      >
            <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        Search
      </Typography>
            <Typography
        variant="caption"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        Search Packets
      </Typography>
      <Stack direction="row"  mt={2}>
      <FormTextField
                  
                  size="large"
                  fullWidth
                  placeholder="Search for Ids"
                  variant="outlined"
                  type="text"
                  value={id }
                  onChange={(e) => {
                    setId(e.currentTarget.value);
                  }}
            
                />
              <ColorButton variant="contained" sx={{marginTop:0,paddingLeft:"35px", paddingRight:"35px",borderRadius:"0px 8px 8px 0px", backgroundColor:"#FF6A1A", color:"#fff", width:"250px"}} onClick={()=>id?getPacketInfos():null}> Search</ColorButton>
            </Stack>
            {/* <Card
        sx={{
          margin: "8px 15px 15px 15px",
          py: 3,
          px: 5,
          borderRadius: "8px",
        }}
      > */}
        <Box
          sx={{
            // backgroundColor: "#F7F7FC",
            borderRadius: "8px 8px 0px 0px",
            boxShadow: "none",
          }}
          px="14px"
          py="10px"
          fontWeight="600"
        >Search Packets
        </Box>
        <div
          style={{
            backgroundColor: "#F7F7FC",
            borderRadius: "8px 8px 0px 0px",
            boxShadow: "none",
            padding: "14px 10px",
            fontWeight: "600",
          }}
        >
          <Grid container spacing={1}>
            
            <Grid item xs={2}>
              Rover Id
            </Grid>
            <Grid item xs={1}>
              Carrier
            </Grid>
            <Grid item xs={1.5}>
              Payment Type
            </Grid>
            <Grid item xs={2}>
              Customer No.
            </Grid>
            <Grid item xs={2.5}>
              Destinations
            </Grid>
            <Grid item xs={2}>
              Origin
            </Grid>
            <Grid item xs={0.5}>
              
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            boxShadow: "none",
            padding: "14px 10px",
            fontWeight: "400",
          }}
        >
          <Grid container spacing={1}>


         {packetInfo?.map((item, i)=>
         {
            console.log("ITemmm",item);
            return(<React.Fragment key={i}>
         
         <Grid item xs={2}>
         {item[0]?.roverId}
       </Grid>
       <Grid item xs={1}>
         {item[0]?.vendor}
       </Grid>
       <Grid item xs={1.5}>
       {item[0]?.paymentMode}
       </Grid>
       <Grid item xs={2}>
      {item[0]?.customerPhone}
       </Grid>
       <Grid item xs={2.5}>
         
      {item[0]?.address}
       </Grid>
       <Grid item xs={2}>
         
      {item[0]?.returnAddress}
       </Grid>
       <Grid item xs={0.5}>
         <DeleteIcon onClick={()=>{
          const data=[...packetInfo];
          data.splice(i,1);
          setPacketInfo(data)
         }}/>
       </Grid>
       </React.Fragment>)}

         )   }
         
          </Grid>
        </div>
      
         <ColorButton variant="contained" onClick={()=>{
setPickups()
         }}> Submit</ColorButton>
      </Card>
    </>
  );
};

export default SearchPacketsForPickups;
