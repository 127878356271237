//!Team name:{teamName:"", description:""};

//!(+)

//!Designations:{designationName:"", description:"", teamId:""};
//!Designations:{designationName:"", description:"", teamId:""};

import React from "react";
import { Grid, Typography } from "@mui/material";
import { Card } from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/material";
import { FormTextField } from "../../styles/textField";
import { ColorButton } from "../../styles/button";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  checkTeamAndDesignation,
  getTeamDetailsById,
} from "../../services/teamCreation";
import { Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import { addTeamAndDesignation } from "./../../services/teamCreation";
import { useNavigate } from "react-router-dom";

const CreateTeam = () => {
  const [team, setTeam] = useState({
    teamName: "",
    teamDescription: "",
    id: "",
  });
  const [designation, setDesignation] = useState([
    { designationName: "", designationDescription: "", key: "0", id: "" },
  ]);

  const [editable, setEditable] = useState(true);

  let { id } = useParams();
  console.log("userId: ", id);

  function handleDesignationDelete(des) {
    const data = [...designation];
    const result = data.filter(
      (val) => val.designationName !== des.designationName
    );
    setDesignation(result);
  }
  const valueNotAvailable = () => {
    toast.error("Please make sure the Team/Description Name is filled ", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const nameNotAvailable = () => {
    toast.error("Team Name already Exists, please try a new one", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    if (id) {
      async function getDetails() {
        const { data } = await getTeamDetailsById(id);
        console.log("data: ", data);
        const team = {
          teamName: data.teamName,
          description: data.description,
          id: data.id,
        };
        setTeam(team);
        const designations = data.designationResponse.map((item) => ({
          designationName: item.designationName,
          description: item.description,
          id: item.id,
        }));
        console.log("designations: ", designations);
        setDesignation(designations);
      }

      getDetails();
    }
  }, []);

  const navigate = useNavigate();

  async function postTeamDetails() {
    if (!team.teamName || !designation[0].designationName) {
      valueNotAvailable();
      return;
    }
    if(!id){
    const result = await checkTeamAndDesignation(team.teamName);
    console.log("result: ", result);

    if (result.data === false) {
      nameNotAvailable();
      return;
    }}

    const teamValues = { ...team };
    teamValues.designationRequest = [...designation];
    teamValues.createdBy = "Deepak";
    console.log("teamValues: ", teamValues);

    const { data } = await addTeamAndDesignation(teamValues);
    console.log("data: ", data);
    if (data) {
      setEditable(false);
      window.location.href="/rover/user/TeamList/viewTeamDetails/" + data;
    }
  }
  async function updateTeamDetails() {}

  console.log("designation: ", designation);
  return (
    <>
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        {id ? "View Team" : "Create Team"}
      </Typography>
      <Card
        sx={{
          margin: "8px 15px 15px 15px",
          py: 3,
          px: 5,
          borderRadius: "8px",
          // minHeight:"87vh"
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F7F7FC",
            borderRadius: "8px 8px 0px 0px",
            boxShadow: "none",
          }}
          px="14px"
          py="10px"
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="subtitle1"
              fontWeight="600"
              sx={{ margin: "0px 0px 0px 0px", fontSize: "20px" }}
            >
              Team
            </Typography>

          { id&&<Edit
              onClick={() => {
                setEditable(false);
              }}
            ></Edit>}
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
              Team Name
            </Typography>

            <FormTextField
              disabled={id ? editable : false}
              size="small"
              fullWidth
              placeholder="Team Name"
              variant="outlined"
              type="text"
              onChange={(e) => {
                const data = { ...team };
                data.teamName = e.target.value;
                setTeam(data);
              }}
              value={team.teamName}
              // name={item.value}
              // onChange={formik.handleChange}
              // onBlur={handleBlur}
              // value={formik.values[item.value]}
              // error={
              //   formik.touched[item.value] &&
              //   Boolean(formik.errors[item.value])
              // }
              // disabled={status === 0 ? true : false}
              // helperText={
              //   formik.touched[item.value] &&
              //   formik.errors[item.value]
              // }
              //   value={values.firstName}
              //   onChange={(e) => handleChange(e, "firstName")}
            />
          </Grid>
          <Grid item xs={8}>
            {" "}
            <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
              Team Description
            </Typography>
            <FormTextField
              disabled={id ? editable : false}
              size="small"
              fullWidth
              placeholder="Team Description"
              variant="outlined"
              type="text"
              onChange={(e) => {
                const data = { ...team };
                data.teamDescription = e.target.value;
                setTeam(data);
              }}
              value={team.teamDescription}
              // name={item.value}
              // onChange={formik.handleChange}
              // onBlur={handleBlur}
              // value={formik.values[item.value]}
              // error={
              //   formik.touched[item.value] &&
              //   Boolean(formik.errors[item.value])
              // }
              // disabled={status === 0 ? true : false}
              // helperText={
              //   formik.touched[item.value] &&
              //   formik.errors[item.value]
              // }
              //   value={values.firstName}
              //   onChange={(e) => handleChange(e, "firstName")}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            backgroundColor: "#F7F7FC",
            borderRadius: "8px 8px 0px 0px",
            boxShadow: "none",
          }}
          mt="10px"
          px="14px"
          py="10px"
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="subtitle1"
              fontWeight="600"
              sx={{ margin: "0px 0px 0px 0px", fontSize: "20px" }}
            >
              Designations
            </Typography>

            {(id && !editable && (
              <ColorButton
                variant="contained"
                sx={{
                  borderRadius: "15px",
                  padding: "5px",
                  minWidth: 0,
                  marginTop: 0,
                }}
                onClick={() => {
                  const data = [...designation];
                  data.push({
                    designationName: "",
                    designationDescription: "",
                    key: designation.length + 1,
                    id: "",
                  });
                  console.log("data: ", data);
                  setDesignation(data);
                }}
              >
                <AddIcon />
              </ColorButton>
            )) ||
              (!id && (
                <ColorButton
                  variant="contained"
                  sx={{
                    borderRadius: "15px",
                    padding: "5px",
                    minWidth: 0,
                    marginTop: 0,
                  }}
                  onClick={() => {
                    const data = [...designation];
                    data.push({
                      designationName: "",
                      designationDescription: "",
                      key: designation.length + 1,
                      id: "",
                    });
                    console.log("data: ", data);
                    setDesignation(data);
                  }}
                >
                  <AddIcon />
                </ColorButton>
              ))}
          </Box>
        </Box>
        <Grid container spacing={2} mt={1}>
          {designation.map((item, i) => (
            <React.Fragment key={i}>
              <Grid item xs={0.5} mt={1}>
                {i + 1} .
              </Grid>
              <Grid item xs={4}>
                <FormTextField
                  disabled={id ? editable : false}
                  size="small"
                  fullWidth
                  placeholder="Designation Name"
                  variant="outlined"
                  type="text"
                  value={item.designationName}
                  onChange={(e) => {
                    const data = [...designation];
                    data[i].designationName = e.target.value;
                    setDesignation(data);
                  }}
            
                />
              </Grid>
              <Grid item xs={7}>
                <FormTextField
                  disabled={id ? editable : false}
                  size="small"
                  fullWidth
                  placeholder="Designation Description"
                  variant="outlined"
                  type="text"
                  value={item.designationDescription}
                  onChange={(e) => {
                    const data = [...designation];
                    data[i].designationDescription = e.target.value;
                    setDesignation(data);
                  }}
                  // onBlur={handleBlur}
                  // value={formik.values[item.value]}
                  // error={
                  //   formik.touched[item.value] &&
                  //   Boolean(formik.errors[item.value])
                  // }
                  // disabled={status === 0 ? true : false}
                  // helperText={
                  //   formik.touched[item.value] &&
                  //   formik.errors[item.value]
                  // }
                  //   value={values.firstName}
                  //   onChange={(e) => handleChange(e, "firstName")}
                />
              </Grid>
              <Grid item xs={0.5} mt={1}>
                {designation.length > 1 && (
                  <span onClick={() => handleDesignationDelete(item)}>
                    {(id && !editable && <DeleteIcon />) ||
                      (!id && <DeleteIcon />)}
                  </span>
                )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {(id && !editable && (
            <ColorButton variant="contained"   onClick={() => {
              postTeamDetails();
           }}>Submit</ColorButton>
          )) ||
            (!id && (
              <ColorButton
                variant="contained"
                onClick={() => {
                   postTeamDetails();
                }}
              >
                Submit
              </ColorButton>
            ))}
        </div>
      </Card>
    </>
  );
};

export default CreateTeam;
