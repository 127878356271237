import { Card, Typography, Grid } from "@mui/material";
import {  useFormik } from "formik";

import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ColorButton } from "../../styles/button";
import { FormTextField } from "../../styles/textField";
// import http from "./httpService";
import * as yup from "yup";
import {
  addStationServices,
  viewStationServices,
  updateStationServices,
} from "../../services/station";
import {  useNavigate, useParams } from "react-router-dom";
import QuickTracking from "../QuickTracking";

const AddBagManually = () => {
  let { id } = useParams();
  const [defaultValues, setDefaultValues]=useState( {});
  useEffect(() => {
    const getValues = async () => {
      if(!id)return;
      const result=await viewStationServices(id);
      if(result){
        console.log('data: ', result.data);
        setDefaultValues(result.data);
      }
    };
    getValues();
  }, []);

  const initialValue = {
    bagId: "",
    trackingId: "",
    orderId: "",
    loadId: "",
    departureDate: "",
    departureTime: "",
    origin: "",
    eWayBill: "",
    noOfPackets: "",
    
  };
  let navigate = useNavigate();

  async function submitStation(formValues) {
    console.log("Here I am ");
    try {
      const { data } = await addStationServices(formValues);
      if (data) {
        // console.log('data: ', data);
        navigate(`/rover/user/stationList/viewStation/${data.id}`);
        // <Navigate to={`stationList/viewStation/${data.id}`}  />
      }
    } catch (exception) {}

    // console.log("result: ", result);
  }

  const validationSchema = yup.object({

    // stationName: yup.string().required("Station Name is required"),
    // stationCode: yup.string().required("Station Code is required"),
    // address: yup.string().required("Address is required"),
    // locality: yup.string().required("Locality is required"),
    // city: yup.string().required("City is required"),
    // state: yup.string().required("State is required"),
    // pincode: yup.number().required("Pincode is required"),
    // latitude: yup.number().required("Latitude is required"),
    // longitude: yup.number().required("Longitude is required"),

    // password: yup
    //   .string('Enter your password')
    //   .min(8, 'Password should be of minimum 8 characters length')
    //   .required('Password is required'),
  });

  const updateValues=async (formData)=>{

    const {data}=await updateStationServices(formData,id)
    if (data) {
      console.log('data: ', data);
      navigate(`/rover/user/stationList/viewStation/${data.id}`);
      // <Navigate to={`stationList/viewStation/${data.id}`}  />
    }

  }

  const formik = useFormik({
    enableReinitialize:true,
    initialValues:id? defaultValues:initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
    //   if(id){
    //     console.log("values: Id available", values);
    //     updateValues(values);
    //   }else{
    //     console.log("values: Id nahi hai", values);
    //     submitStation(values);
    //  } // alert(JSON.stringify(values, null, 2));
    },
  });
  console.log(formik.values,defaultValues);

  return (
    <>
    <QuickTracking/>
      {id ? (
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ margin: "15px 0px 0px 18px" }}
        >
          Edit Bag Information
        </Typography>
      ) : (
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ margin: "15px 0px 0px 18px" }}
        >
          Add Bag
        </Typography>
      )}


      <Card
        sx={{ margin: "8px 15px 15px 15px", py: 3, px: 5, borderRadius: "8px" }}
      >
        {id ? (
          <Box
            sx={{
              backgroundColor: "#F7F7FC",
              borderRadius: "8px 8px 0px 0px",
              boxShadow: "none",
            }}
            px="14px"
            py="10px"
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="subtitle1"
                fontWeight="600"
                sx={{ margin: "0px 0px 0px 0px", fontSize: "20px" }}
              >
             {defaultValues.stationCode}
                           </Typography>
            </Box>
            <Typography
              variant="subtitle2"
              fontWeight="500"
              sx={{ margin: "0px 0px 10px 0px", lineHeight: "0.5" }}
            >
              {defaultValues.stationName}
            </Typography>
          </Box>
        ) : null}
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            {[
              { title: "Bag Id", name: "bagId", size: 3 },
              { title: "Tracking Id", name: "trackingId", size: 3 },
              { title: "Order Id", name: "orderId", size: 3 },
              { title: "Load Id", name: "loadId", size: 3 },
              { title: "Departure Date", name: "departureDate", size: 3 },
              { title: "Departure Time", name: "departureTime", size: 3 },
              { title: "Origin", name: "origin", size: 3 },
              { title: "EWaybill No", name: "eWayBill", size: 3 },
              { title: "No. Of Packets", name: "noOfPackets", size: 3 },
             
            ].map((item) => {
              return (
                <Grid item xs={item.size}>
                  <Typography
                    mt={1}
                    mb={1}
                    variant="subtitle2"
                    fontWeight="bold"
                  >
                    {item.title}
                  </Typography>
                  <FormTextField
                    size="small"
                    fullWidth
                    id={item.name}
                    placeholder={item.title}
                    variant="outlined"
                    type="text"
                    value={formik.values[item.name]}
                    name={item.name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[item.name] &&
                      Boolean(formik.errors[item.name])
                    }
                    helperText={
                      formik.touched[item.name] && formik.errors[item.name]
                    }
                    //   value={values.firstName}
                    //   onChange={(e) => handleChange(e, "firstName")}
                  />

                  {/* {errors.firstName ? (
                    <Typography variant="caption" sx={{ color: "#FF0000" }}>
                      {errors.firstName}
                    </Typography>
                  ) : null} */}
                </Grid>
              );
            })}
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ColorButton variant="contained" type="submit">
              Submit
            </ColorButton>
          </Box>
        </form>
      </Card>
      {/* )} */}
      {/* </Formik> */}
    </>
  );
};

export default AddBagManually;
