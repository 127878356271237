import * as React from "react";
import {
  Typography,
  Card,
  Paper,
  IconButton,
  InputAdornment,
  Grid,
} from "@mui/material";
import { SearchTextField } from "../../styles/textField";
import { Search, FilterList, Edit } from "@mui/icons-material";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
// import TableCell from '@mui/material/TableCell';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
// import TableRow from '@mui/material/TableRow';
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { StyledTableCell, StyledTableRow } from "../../styles/table";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BackupTable } from "@mui/icons-material";
import XLSX from "xlsx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faCoffee } from "@fortawesome/fontawesome-free-solid";
import { viewAllStationServices } from "./../../services/station";
import { getMemberPermission } from "../../services/memberAndPermissions";
import {
  AcceptOrCancelOrder,
  viewActiveOrdersStation,
  viewOrdersHistoryVendorStation,
  viewRequestedOrdersStation,
} from "../../services/vendors";
import Delete from "@mui/icons-material/Delete";
import OrderHistory from "./../../VendorUI/OrderHistory";
import moment from "moment";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "vendorBookingId",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "vendorCode",
    numeric: true,
    disablePadding: false,
    label: "Vendor Code",
  },
  {
    id: "customerName",
    numeric: true,
    disablePadding: false,
    label: "Customer Name",
  },
  {
    id: "customerPhone",
    numeric: true,
    disablePadding: false,
    label: "Customer Number",
  },
  {
    id: "assignPerson",
    numeric: true,
    disablePadding: false,
    label: "DA Name",
  },
  {
    id: "vendorOrderStatus",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "deliveryDate",
    numeric: true,
    disablePadding: false,
    label: "Delivery Date",
  },
  {
    id: "vehicleType",
    numeric: true,
    disablePadding: false,
    label: "Vehicle Type",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        // pl: { sm: 2 },
        // pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function OrderHistoryStationVendor() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [rowData, setRowData] = React.useState([]);
  const [permissions, setPermissions] = React.useState({});
  const [role, setRole] = React.useState("");
  const username = localStorage.getItem("username");

  React.useEffect(() => {
    const tokens = localStorage.getItem("token");
    const token = JSON.parse(tokens);
    console.log("token: ", token);
    let permission;
    setRole(token.role);

    const getActiveOrders = async () => {
      const { data } = await viewOrdersHistoryVendorStation();
      console.log("permission: ", permission);
      console.log("data: ", data);
      if (data) {
        const sortedData = data.sort((a, b) => {
          console.log(
            'moment(a?.createdAt).format("DD/MMM/YYYY")-moment(b?.createdAt).format("DD/MMM/YYYY"): ',
            moment().subtract(a?.createdAt - b?.createdAt)
          );
          return (
            moment(a?.createdAt).format("DD/MMM/YYYY") -
            moment(b?.createdAt).format("DD/MMM/YYYY")
          );
        });
        setRowData(data);
        console.log("sortedData: ", sortedData);
      }
    };
    getActiveOrders();
  }, []);

  console.log("rows: ", rowData);
  const rows =
    rowData &&
    rowData?.filter(
      (item) =>
        item?.vendorBookingId
          ?.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item?.customerName
          ?.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item?.assignPerson
          ?.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item?.vendorCode
          ?.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item?.customerPhone
          ?.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item?.vendorOrderStatus
          ?.toString()
          .toLowerCase()
          .includes(search.toLowerCase())
    );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  console.log(permissions?.editStation);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const downloadExcel = () => {
    console.log(">>>>>>", rowData);
    const vals = rowData.map((item) => {
      return {
        vendorCode: item?.vendorCode,
        vendorBookingId: item?.vendorBookingId,
        customerName: item?.customerName,
        customerPhone: item?.customerPhone,
        vehicleType: item?.vehicleType,
        orderDetails: item?.orderDetails,
        pickupStore: item?.pickupStore,
        deliveryAddress: item?.deliveryAddress,
        deliveryDate: item?.deliveryDate,
        assignPerson: item?.assignPerson,
        vendorOrderStatus: item?.vendorOrderStatus,
        packetDeliveredDate: item?.packetDeliveredDate,
        createdBy: item?.createdBy,
        createdAt: item?.createdAt,
        packetDeliveredBy: item?.packetDeliveredBy,
        pickupSlot: item?.pickupSlot,
        pickedTimestamp: item?.pickedTimestamp,
        deliveryDistance: item?.deliveryDistance,
        noOfLabours: item?.noOfLabours,
        totalLabourCharge: item?.totalLabourCharge,
        deliveryCharges: item?.deliveryCharges,
        totalDeliveryCharges: item?.totalDeliveryCharges,
        takeDelChargeFromCustomer: item?.takeDelChargeFromCustomer,
        codAmount: item?.codAmount,
        additionalCharges: item?.additionalCharges,
        totalPayableAmount: item?.totalPayableAmount,
        amountCollectedOnDelivery: item?.amountCollectedOnDelivery,
        cashReconciled: item?.cashReconciled,
        variance: parseInt(item?.amountCollectedOnDelivery?item?.amountCollectedOnDelivery:0)-parseInt(item?.cashReconciled?item?.cashReconciled:0),
        cashReconcileTime: item?.cashReconcileTime,
        cashReconsileRemark: item?.cashReconsileRemark,
        remarks: item?.remark,
      };
    });
    console.log("vals: ", vals);
    const workSheet = XLSX.utils.json_to_sheet(vals);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "branches");
    //Buffer
    let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "OrderHistory.xlsx");
  };

  const editPer = role === "Admin" || permissions?.editStation;
  console.log("role: ", role);
  console.log("editPer: ", editPer);

  return (
    <>
      {/* <Typography
    variant="h6"
    fontWeight="600"
    sx={{ margin: "15px 0px 0px 18px" }}
  >
    Vendor Packets Order History
  </Typography>
  <Card
    sx={{
      margin: "8px 15px 15px 15px",
      py: 3,
      px: 3,
      borderRadius: "8px",
      minheight: "90vh",
    }}
  > */}

      <Grid container spacing={1}>
        <Grid item xs={11} mb={2}>
          <SearchTextField
            size="small"
            fullWidth
            variant="outlined"
            placeholder="Search..."
            type="text"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: "0px" }}>
                  <IconButton
                    sx={{
                      backgroundColor: "#F7F7FC",
                      borderRadius: "8px 0px 0px 8px",
                    }}
                    edge="start"
                  >
                    <Search sx={{ backgroundColor: "#F7F7FC" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={0.5}>
          {/* <FontAwesomeIcon icon="fa-solid fa-bars-filter" /> */}
          <FontAwesomeIcon
            icon="fa-solid fa-filter"
            style={{ height: "25px", marginTop: "6px" }}
          />
        </Grid>
        <Grid item xs={0.5}>
          {/* <i type="button" style={{color:"#168e32"}} class="fa fa-file-excel"></i> */}
          {/* <BackupTable
              onClick={() => downloadExcel()}
              sx={{ height: "38px" }}
            /> */}
          <FontAwesomeIcon
            icon="fa-solid fa-file-excel"
            onClick={() => downloadExcel()}
            type="button"
            style={{
              height: "26px",
              marginTop: "6px",
              cursor: "pointer",
              color: "#168e32",
            }}
          />
        </Grid>
      </Grid>

      <Box sx={{ width: "100%" }}>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            boxShadow:
              "0px 2px 0px -5px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 4px 0px rgb(0 0 0 / 4%)",
          }}
        >
          <TableContainer>
            <Table
              sx={{ minWidth: 1100 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    console.log("isItemSelected: ", isItemSelected);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        // role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        // selected={isItemSelected}
                      >
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="left"
                          padding="normal"
                          sx={{ width: "15%" }}
                        >
                          <Link
                            to={`/rover/user/vendorOrderDetails/${row?.vendorBookingId}`}
                          >
                            {row.vendorBookingId}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          padding="normal"
                          sx={{ width: "14%" }}
                        >
                          {row.vendorCode}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          padding="normal"
                          sx={{ width: "14%" }}
                        >
                          {row.customerName}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          padding="normal"
                          sx={{ width: "14%" }}
                        >
                          {row.customerPhone}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          padding="normal"
                          sx={{ width: "16%" }}
                        >
                          {row.assignPerson}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          padding="normal"
                          sx={{ width: "14%" }}
                        >
                          {row.vendorOrderStatus}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          padding="normal"
                          sx={{ width: "14%" }}
                        >
                          {moment(row?.deliveryDate).format("DD/MMM/YYYY")}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          padding="normal"
                          sx={{ width: "14%" }}
                        >
                          {row.vehicleType}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}{" "}
                {emptyRows > 0 && (
                  <StyledTableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <StyledTableCell colSpan={6} />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            sx={{ backgroundColor: "#F7F7FC" }}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {/* </Card> */}
    </>
  );
}
