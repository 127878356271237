import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 4,
  };
export default function Loader() {
  
  return (
    <div>
      <Modal
        open={true}
      >
        <Box sx={style}>
        <span class="loader"></span>
        </Box>
      </Modal>
    </div>
  );
}