import { Grid, Typography } from '@mui/material';
import React from 'react';



export default function AllRunsheetList({ item, index }) {
  const date = new Date();
  console.log("Item",item, date);
  
  console.log("difference",new Date(item?.deliveryDate).getDate()-date.getDate());

    return <React.Fragment key={item.id}>
      <div
      
        style={{
          marginBottom: "15px",
          padding: "10px",  
          backgroundColor: "#F7F7FC",
        }}
      >
        {/* "#C4C4C4" */}
        <Grid container spacing={2}>
          <Grid item xs={1.5} sx={{ marginTop: 1 }}>
            <div
              style={{
                backgroundColor:new Date(item?.deliveryDate).getDate()-date.getDate()>0?"#44AD1F":"#B10000" ,
                color:new Date(item?.deliveryDate).getDate()-date.getDate()>0?"#000":"#FFF",
                borderRadius: "12px",
                height: "25px",
                width: "25px",
                textAlign: "center",
              }}
            >
              {new Date(item?.deliveryDate).getDate()-date.getDate()}
            </div>
          </Grid>
          <Grid item xs={6.5}>
            <div>
              <Typography variant="body2" fontWeight={600}>{item?.customerName}</Typography>
              <Typography variant="body2" fontWeight={400}>{item?.address}</Typography>
            </div>
          </Grid>
          <Grid item xs={3.5}>
            <Typography variant="caption" fontSize={10} fontWeight={600}>{item?.paymentMode}</Typography>
            <div>
              <div
              >
                <Typography variant="caption" fontWeight={600}>{item.roverId}</Typography>
  
              </div>
            </div>
  
          </Grid>
        </Grid>
      </div>
    </React.Fragment>;
  }