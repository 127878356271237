import * as React from "react";
import {
  Typography,
  Card,
  Paper,
  IconButton,
  InputAdornment,
  Grid,
  Modal,
  Divider,
  Autocomplete,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  FormAutoComplete,
  FormTextField,
  SearchTextField,
} from "../../styles/textField";
import { Search, FilterList, Edit } from "@mui/icons-material";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
// import TableCell from '@mui/material/TableCell';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
// import TableRow from '@mui/material/TableRow';
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { StyledTableCell, StyledTableRow } from "../../styles/table";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BackupTable } from "@mui/icons-material";
import XLSX from "xlsx";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faCoffee } from "@fortawesome/fontawesome-free-solid";
import { viewAllStationServices } from "./../../services/station";
import { getMemberPermission } from "../../services/memberAndPermissions";
import {
  AcceptOrCancelOrder,
  DeliveryCharges,
  RejectOrder,
  SendPaymentDetails,
  VendorDeliveryCharges,
  costingSentOrders,
  viewRequestedOrdersStation,
} from "../../services/vendors";
import { toast } from "react-toastify";
import moment from "moment";
import { ColorButton } from "../../styles/button";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "vendorCode",
    numeric: false,
    disablePadding: true,
    label: "Vendor Name",
  },
  {
    id: "pickupStore",
    numeric: true,
    disablePadding: false,
    label: "Vehicle Type",
  },
  {
    id: "vehicleType",
    numeric: true,
    disablePadding: false,
    label: "Pickup Destination ",
  },
  {
    id: "deliveryAddress",
    numeric: true,
    disablePadding: false,
    label: "Drop Destination",
  },
  {
    id: "pickupDate",
    numeric: true,
    disablePadding: false,
    label: "Pickup Date",
  },
  {
    id: "pickupSlot",
    numeric: true,
    disablePadding: false,
    label: "Pickup Slot",
  },
  {
    id: "deliveryDate",
    numeric: true,
    disablePadding: false,
    label: "Delivery Date",
  },
  {
    id: "deliverySlot",
    numeric: true,
    disablePadding: false,
    label: "Delivery Slot",
  },

];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        // pl: { sm: 2 },
        // pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function CostingSentToVendor() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [rowData, setRowData] = React.useState([]);
  const [permissions, setPermissions] = React.useState({});
  const [role, setRole] = React.useState("");
  const [packetInfo, setPacketInfo] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const getActiveOrders = async () => {
    const { data } = await costingSentOrders();
    // console.log("permission: ", permission);
    console.log("data: ", data);
    if (data) {
      const sortedData = data.sort((a, b) => b.id - a.id);
      setRowData(sortedData);
    }
  };

  React.useEffect(() => {
    const tokens = localStorage.getItem("token");
    const token = JSON.parse(tokens);
    console.log("token: ", token);
    let permission;
    setRole(token.role);

    getActiveOrders();
  }, []);
  console.log("rows: ", rowData);
  const rows =
    rowData &&
    rowData?.filter(
      (item) =>
        item?.vendorCode.toLowerCase().includes(search.toLowerCase()) ||
        item?.vehicleType
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item?.pickupStore
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item?.deliveryAddress
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase())
    );

  // calories,
  // stationManager,
  // managerNumber,
  // managerEmail,
  // city,

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  console.log(permissions?.editStation);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const downloadExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(rowData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "branches");
    //Buffer
    let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "branches.xlsx");
  };

  const editPer = role === "Admin" || permissions?.editStation;
  console.log("role: ", role);
  console.log("editPer: ", editPer);

  async function acceptOrder(id) {
    const getActiveOrders = async () => {
      const { data } = await viewRequestedOrdersStation();

      console.log("data: ", data);
      if (data) {
        const sortedData = data.sort((a, b) => b.id - a.id);
        setRowData(sortedData);
      }
    };
    const values = {
      vendorBookingId: id,
      vendorOrderStatus: "Pickup Confirmed",
      createdBy: localStorage.getItem("username"),
    };

    try {
      const { data } = await AcceptOrCancelOrder(values);
      if (data) {
        getActiveOrders();
      }
    } catch (error) {
      error();
    }
  }
  async function rejectOrder(id) {
    const getActiveOrders = async () => {
      const { data } = await viewRequestedOrdersStation();

      console.log("data: ", data);
      if (data) {
        const sortedData = data.sort((a, b) => b.id - a.id);
        setRowData(sortedData);
      }
    };
    const values = {
      vendorBookingId: id,
      createdBy: localStorage.getItem("username"),
    };

    try {
      const { data } = await RejectOrder(values);
      if (data) {
        getActiveOrders();
      }
    } catch (error) {
      error();
    }
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const val = [
    { title: "Customer Name", value: packetInfo?.customerName },
    { title: "Phone Number", value: packetInfo?.customerPhone },
    { title: "Vehicle Type", value: packetInfo?.vehicleType },
    { title: "Pickup Location", value: packetInfo?.pickupStore },
    {
      title: "Pickup Date",
      value: moment(packetInfo?.pickupDate).format("dddd, Do MMMM YYYY"),
    },
    { title: "Pickup Slot", value: packetInfo?.pickupSlot },
    { title: "Delivery Location", value: packetInfo?.deliveryAddress },
    {
      title: "Delivery Date",
      value: moment(packetInfo?.deliveryDate).format("dddd, Do MMMM YYYY"),
    },
    { title: "Delivery Slot", value: packetInfo?.deliverySlot },
    {
      title: "Collect Delivery Charge",
      value: packetInfo?.takeDelChargeFromCustomer,
    },
    {
      title: "COD Amount",
      value: packetInfo?.codAmount,
    },
    { title: "Order Details", value: packetInfo?.orderDetails },
    { title: "Remarks", value: packetInfo?.remarks },
  ];

  const location= useLocation()
  console.log('location: ', location);
  

  return (
    <>
     {location.pathname==="/user/costingSentOrders" &&
     <>
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ margin: "15px 0px 0px 18px" }}
        >
          Pending Orders
        </Typography>
   
        </>}

        <Card
        sx={{
          margin: "8px 15px 15px 15px",
          py: location.pathname==="/user/costingSentOrders"?3:0,
          px: location.pathname==="/user/costingSentOrders"?3:0,
          borderRadius: "8px",
          minheight: "90vh",
          visibility:location.pathname==="/user/costingSentOrders"?"visible":"hidden",
        }}
      >
      <Grid container spacing={1} sx={{visibility:"visible"}}>
        <Grid item xs={11} mb={2}>
          <SearchTextField
            size="small"
            fullWidth
            variant="outlined"
            placeholder="Search..."
            type="text"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: "0px" }}>
                  <IconButton
                    sx={{
                      backgroundColor: "#F7F7FC",
                      borderRadius: "8px 0px 0px 8px",
                    }}
                    edge="start"
                  >
                    <Search sx={{ backgroundColor: "#F7F7FC" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={0.5}>
          {/* <FontAwesomeIcon icon="fa-solid fa-bars-filter" /> */}
          <FontAwesomeIcon
            icon="fa-solid fa-filter"
            style={{ height: "25px", marginTop: "6px" }}
          />
        </Grid>
        <Grid item xs={0.5}>
          {/* <i type="button" style={{color:"#168e32"}} class="fa fa-file-excel"></i> */}
          {/* <BackupTable
              onClick={() => downloadExcel()}
              sx={{ height: "38px" }}
            /> */}
          <FontAwesomeIcon
            icon="fa-solid fa-file-excel"
            onClick={() => downloadExcel()}
            type="button"
            style={{
              height: "26px",
              marginTop: "6px",
              cursor: "pointer",
              color: "#168e32",
            }}
          />
        </Grid>
      </Grid>

      <Box sx={{ width: "100%",visibility:"visible"}}>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            boxShadow:
              "0px 2px 0px -5px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 4px 0px rgb(0 0 0 / 4%)",
          }}
        >
          <TableContainer>
            <Table
              sx={{ minWidth: 1100 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    console.log("isItemSelected: ", isItemSelected);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        // role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        // selected={isItemSelected}
                      >
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="left"
                          padding="normal"
                          sx={{ width: "15%" }}
                        >
                          {row.vendorCode}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          padding="normal"
                          sx={{ width: "14%" }}
                        >
                          {row.vehicleType}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          padding="normal"
                          sx={{ width: "16%" }}
                        >
                          {row.pickupStore}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          padding="normal"
                          sx={{ width: "14%" }}
                        >
                          {row.deliveryAddress}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          padding="normal"
                          sx={{ width: "14%" }}
                        >
                          {moment(row?.pickupDate).format("dddd, Do MMMM YYYY")}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          padding="normal"
                          sx={{ width: "14%" }}
                        >
                          {row.pickupSlot}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          padding="normal"
                          sx={{ width: "14%" }}
                        >
                          {moment(row?.deliveryDate).format(
                            "dddd, Do MMMM YYYY"
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          padding="normal"
                          sx={{ width: "14%" }}
                        >
                          {row.deliverySlot}
                        </StyledTableCell>

                      </StyledTableRow>
                    );
                  })}{" "}
                {emptyRows > 0 && (
                  <StyledTableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <StyledTableCell colSpan={6} />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            sx={{ backgroundColor: "#F7F7FC" }}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
 </Card>
    </>
  );
}
