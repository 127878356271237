import { AppBar, Badge, Box, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ArrowDropDown } from "@mui/icons-material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { over } from "stompjs";
import SockJS from "sockjs-client";
import avatar from "../images/avatar.png";
import RoverLogoNoti from "../images/RoverLogoNoti.svg";
import RoverLogo from "../images/Rover Logo.svg";
import { Link, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import DAUILeftBarMenu from "../DA_UI/DAUI_LeftBarMenu";
import { getMemberPermission } from "../services/memberAndPermissions";
import RoverIcon from "../images/RoverIcon.png";
import VendorUIMobileLeftBarMenu from "../VendorUI/VendorUIMobileLeftBar";
import config from "../config.json";
import alarm from "../sound/alarm.mp3";

var packetsRequest = 0;
const RequestPending = () => {
  if (packetsRequest === 0) {
    return;
  } else {
    const options = {
      body: "There are pending packets to be accepted or rejected",
      icon: RoverIcon,
      image: RoverLogoNoti,
      requireInteraction: true,
    };
    const n = new Notification("Request Pending", options);

    console.log(n.body);
  }
};
const intervalID = setInterval(
  localStorage.getItem("token") &&
    JSON.parse(localStorage.getItem("token"))?.role !== "vendor" &&
    JSON.parse(localStorage.getItem("token"))?.role !== "delivery associate" &&
    RequestPending,
  300000
);

const Appbar = () => {
  const navigate = useNavigate();
  const [hamburger, setHamburger] = React.useState({ left: false });
  const [role, setRole] = React.useState("");
  const [permissions, setPermissions] = React.useState({});

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const tokens = localStorage.getItem("token");
    if (!tokens) return;

    const token = JSON.parse(tokens);
    console.log("token: ", token.role);
    setRole(token.role);

    async function memberPermission() {
      if (token.role === "delivery associate") return;

      const { data } = await getMemberPermission(token.userId);
      if (data) {
        setPermissions(data);
      }
    }
    memberPermission();
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setHamburger({ left: open });
  };

  const station = localStorage.getItem("username");
  const [privateChats, setPrivateChats] = useState(new Map());
  const [packets, setPackets] = useState(0);
  const [packetsCheck, setPacketsCheck] = useState(0);
  const [tab, setTab] = useState(station);
  var stompClient = null;

  useEffect(() => {
    connect();
  }, []);

  const connect = () => {
    let Sock = new SockJS(config.webSocket);
    stompClient = over(Sock);
    stompClient.connect(
      {},
      onConnected
      // , onError
    );
  };  

  const onPrivateMessage = (payload) => {
    // let beat = new Audio(alarm);
    var payloadData = JSON.parse(payload.body);
    // if (payloadData === 0) {
    //   beat.pause();
    //   beat.loop = false
    // }
    if (payloadData >= 0) {
      console.log("payloadData: ", payloadData, "   ", packets);

      // if (payloadData > 0) {
      //   JSON.parse(localStorage.getItem("token"))?.role === "member" &&
      //     beat.play();
      // }

      if (
        payloadData !== packets &&
        JSON.parse(localStorage.getItem("token"))?.role !== "vendor"
      ) {
        setPackets(payloadData);
      }
      setPackets(payloadData);
    }
    if (privateChats.get(payloadData.senderName)) {
      privateChats.get(payloadData.senderName).push(payloadData);
      setPrivateChats(new Map(privateChats));
    } else {
      let list = [];
      list.push(payloadData);
      privateChats.set(payloadData.senderName, list);
      setPrivateChats(new Map(privateChats));
    }
  };

  const [userData, setUserData] = useState({
    username: "",
    receivername: "",
    connected: false,
    message: "",
    total: "",
  });

  const userJoin = () => {
    var chatMessage = {
      senderName: userData.username,
      status: "JOIN",
    };
    stompClient.send("/app/message", {}, JSON.stringify(chatMessage));
  };

  const sendPrivateValue = () => {
    if (stompClient) {
      var chatMessage = {
        senderName: "DATA",
        receiverName: tab,
        message: "DATA",
        status: "MESSAGE",
      };

      stompClient.send(
        "/app/requestOrderCount",
        {},
        JSON.stringify(chatMessage),
        packets
      );
    }
  };

  setInterval(sendPrivateValue, 10000);
  // console.log('userData: ', userData);
  function onConnected() {
    setUserData({ ...userData, connected: true });
    // stompClient.subscribe('/count/public', onMessageReceived);
    stompClient.subscribe(
      "/user/" + station + "/requestOrderCount",
      onPrivateMessage
    );

    userJoin();
  }

  JSON.parse(localStorage.getItem("token"))?.role !== "vendor" &&
    JSON.parse(localStorage.getItem("token"))?.role !== "delivery associate" &&
    Notification.requestPermission().then((response) => {
      response === "granted"
        ? console.log("Notifications are Sucessfully Granted")
        : Notification.requestPermissions();
    });

  useEffect(() => {
    if (packets > packetsCheck) {
      localStorage.getItem("token") &&
        JSON.parse(localStorage.getItem("token"))?.role !== "vendor" &&
        JSON.parse(localStorage.getItem("token"))?.role !==
          "delivery associate" &&
        spawnNotification();
      setPacketsCheck(packets);
    }
    setPacketsCheck(packets);
    packetsRequest = packets;
  }, [packets]);

  function spawnNotification() {
    const options = {
      body: "A New Packet is created by Vendor",
      icon: RoverIcon,
      image: RoverLogoNoti,
      requireInteraction: true,
    };
    const n = new Notification("New Packet By Vendor", options);

    console.log(n.body);
  }

  console.log(packets);

  return (
    <>
      <Box>
        <AppBar
          position="fixed"
          sx={{
            boxShadow:
              "0px 2px 1px -1px rgb(219 215 244 / 20%),  0px 1px 3px 0px rgb(0 0 0 / 12%)",
            position: "fixed",
          }}
          color="appbarColor"
        >
          <div
            className={
              role === "delivery associate" || "vendor"
                ? "containerDA"
                : "container"
            }
          >
            {role === "delivery associate" && (
              <div className="left">
                {["left"].map((anchor) =>
                  role === "delivery associate" ? (
                    <React.Fragment key={anchor}>
                      <Button
                        onClick={toggleDrawer(anchor, true)}
                        sx={{ ml: 0 }}
                      >
                        <MenuIcon />
                      </Button>

                      <Drawer
                        anchor={anchor}
                        open={hamburger[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                      >
                        <DAUILeftBarMenu
                          toggle={toggleDrawer}
                          anchor={anchor}
                        />
                      </Drawer>
                    </React.Fragment>
                  ) : null
                )}
                {(role === "member" ||
                  role === "admin" ||
                  role === "vendor") && <img src={RoverLogo} alt="avatar" />}
              </div>
            )}
            {width < 786 && (
              <div className="left">
                {["left"].map((anchor) =>
                  role === "vendor" ? (
                    <React.Fragment key={anchor}>
                      <Button
                        onClick={toggleDrawer(anchor, true)}
                        sx={{ ml: 0 }}
                      >
                        <MenuIcon />
                      </Button>

                      <Drawer
                        anchor={anchor}
                        open={hamburger[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                      >
                        <VendorUIMobileLeftBarMenu
                          toggle={toggleDrawer}
                          anchor={anchor}
                        />
                      </Drawer>
                    </React.Fragment>
                  ) : null
                )}
              </div>
            )}
            {(role === "member" || role === "admin" || role === "vendor") && (
              <img src={RoverLogo} alt="avatar" class="imgSize vendorLeftBar" />
            )}

            <div className="right">
              {window.localStorage.getItem("token") && (
                <>
                <Typography variant="caption" mr="5">{localStorage?.getItem("username").toUpperCase()}</Typography>
                  <Badge badgeContent={packets} color="primary">
                    <NotificationsIcon color="action" />
                  </Badge>

                  <Tooltip title={localStorage.getItem("username")}>
                  <Link to={role==="vendor"?"/rover/vendor/myInfo":"#"}>
                  <img src={avatar} alt="avatar" style={{ marginLeft: 25 }} />
                  
                  </Link>
                  </Tooltip>
                  <div className="profile">
                    <ArrowDropDown className="icons" />
                    <div className="options">
                      {permissions.viewEmp && (
                        <span
                          onClick={() => {
                            navigate("/rover/user/viewMemberList");
                          }}
                        >
                          Member
                        </span>
                      )}

                      {permissions.viewEmp && (
                        <span
                          onClick={() => {
                            navigate("/rover/user/TeamList");
                          }}
                        >
                          Team
                        </span>
                      )}

                      <span
                        onClick={() => {
                          window.localStorage.removeItem("token");
                          navigate("/");
                        }}
                      >
                        Logout
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* <ThemeToggle/> */}
        </AppBar>
      </Box>
    </>
  );
};

export default Appbar;

// const list = (anchor) => (
//   <Box
//     sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
//     role="presentation"
//     onClick={toggleDrawer(anchor, false)}
//     onKeyDown={toggleDrawer(anchor, false)}
//   >
//     <List>
//       {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
//         <ListItem key={text} disablePadding>
//           <ListItemButton>
//             <ListItemIcon>
//               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//             </ListItemIcon>
//             <ListItemText primary={text} />
//           </ListItemButton>
//         </ListItem>
//       ))}
//     </List>
//     <Divider />
//     <List>
//       {["All mail", "Trash", "Spam"].map((text, index) => (
//         <ListItem key={text} disablePadding>
//           <ListItemButton>
//             <ListItemIcon>
//               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//             </ListItemIcon>
//             <ListItemText primary={text} />
//           </ListItemButton>
//         </ListItem>
//       ))}
//     </List>
//   </Box>
// );
