import { Button, styled } from "@mui/material";
import { theme } from '../theme';

const ColorButton = styled(Button)({
    color: "white",
    backgroundColor: theme.palette.primary,
    boxShadow:"0px 2px 1px -1px rgb(219 215 244 / 20%),  0px 1px 3px 0px rgb(0 0 0 / 12%)",
    marginTop:"3rem",
    padding:"7px 15px",
    borderRadius:8,
    '&:hover': {
      backgroundColor:  theme.palette.primary,
      boxShadow:"0px 2px 1px -1px rgb(219 215 244 / 20%),  0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
  });

  export {ColorButton}