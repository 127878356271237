import {
  Card,
  Typography,
  Grid,
  InputAdornment,
  Autocomplete,
  Stack,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
//   import QuickTracking from "../QuickTracking";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Edit, FormatItalic } from "@mui/icons-material";
import { faDiagramSuccessor } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import {
  DeliveryCharges,
  VendorDeliveryCharges,
  createOrUpdateVendorPacket,
  createVendorOrderByStation,
} from "../../services/vendors";
import {
  FormAutoComplete,
  FormTextField,
  SearchTextField,
  UploadTextField,
} from "../../styles/textField";
import { ColorButton } from "../../styles/button";
import { addPickupPacket, getPickupPacketInfo } from "../../services/pickup";
import { viewAllVendorServices } from "./../../services/vendor";
import {
  cashbackForParticularVendor,
  vendorCashbackDetails2,
} from "../../services/cashbacksVendors";

const VendorPacketFormStation = () => {
  let { id } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  // useEffect(() => {
  //   const getValues = async () => {
  //     if (!id) return;
  //     const result = await getPickupPacketInfo(id);
  //     if (result) {
  //       console.log("data: ", result.data);
  //       setDefaultValues(result.data);
  //     }
  //   };
  //   getValues();
  // }, []);

  const initialValue = {
    customerName: "",
    customerPhone: "",
    vehicleType: "",
    orderDetails: "",
    pickupStore: "",
    deliveryAddress: "",
    deliveryDate: "",
    deliverySlot: "",
    alternatePhone: "",
    pickupDate: "",
    pickupSlot: "",
    deliveryDistance: "",
    deliveryCharges: "",
    takeDelChargeFromCustomer: "",
    additionalCharges: 0,
    vendorName: "",
    codAmount: 0,
    totalPayableAmount: 0,
    remarks: "",
    reasonForPayableAmountChange: "",
  };
  let navigate = useNavigate();
  const [stations, setStations] = useState([]);
  const [deliveryHubs, setDeliveryHubs] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [edit, setEdit] = useState(false);
  const [distance, setDistance] = useState([]);
  const [charges, setCharges] = useState("");
  const [deliveryDistances, setDeliveryDistances] = useState([]);
  const [totalRoverPoints, setTotalRoverPoints] = useState(0);
  const [roverPointsClaimed, setRoverPointsClaimed] = useState(0);
  console.log("totalRoverPoints: ", totalRoverPoints);

  const distanceCharge = [
    { name: "oneToFiveCharges", value: "1-5 KMs" },
    { name: "fiveToTenCharges", value: "5-10 Kms" },
    { name: "tenToFifteenCharges", value: "10-15Kms" },
    { name: "fifteenToTwentyCharges", value: "15-20Kms" },
  ];

  useEffect(() => {
    let vendorsList;
    const getValues = async () => {
      if (!id) return;
      const result = await getPickupPacketInfo(id);
      if (result) {
        console.log("result: ", result);
        let info = result.data;
        console.log("result.data: ", result.data);
        const val = vendorsList.find(
          (item) => item.vendorCode === result.data.vendor
        );
        console.log("val: ", val);
        info.vendor = val;
        const event = new Date(result.data.deliveryDate);
        info.deliveryDate = event.toISOString();
        console.log("info: ", info);
        console.log("data: ", result.data);
        setDefaultValues(info);
      }
    };

    const getVendors = async () => {
      const { data } = await viewAllVendorServices();
      if (data) {
        vendorsList = data;
        setVendors(data);
        getValues();
      }
    };
    // getDeliveryHub();
    //!   getVendors()

    const getDistanceCharges = async () => {
      const { data } = await VendorDeliveryCharges();
      console.log("data: ", data);
      setCharges(data);
      const val = distanceCharge.filter((item) =>
        data[item.name] ? item : null
      );
      setDistance(val);
    };

    getDistanceCharges();
  }, []);
  const tokens = localStorage.getItem("token");
  const token = JSON.parse(tokens);
  console.log("token: ", token);

  const success = () => {
    toast.success("Data Submitted Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  async function submitPacketInfo(formValues) {
    const val = formValues;
    val.createdBy = localStorage.getItem("username");
    val.id = "";
    val.deliveryDistance = formValues.deliveryDistance.distance;
    val.vendorCode = formValues.vendorName.vendorCode;
    val.vendorName = formValues.vendorName.vendorCode;
    val.roverPointsUsed = roverPointsClaimed;
    console.log(
      "parseInt(formValues.vendorName.totalLabourCharge)+parseInt(formValues.vendorName.deliveryCharges): ",
      parseInt(formValues.totalLabourCharge) +
        parseInt(formValues.deliveryCharges)
    );
    val.totalDeliveryCharges =
      parseInt(formValues.totalLabourCharge) +
      parseInt(formValues.deliveryCharges);

    console.log("val: ", val);
    try {
      const { data } = await createVendorOrderByStation(val);
      if (data) {
        console.log("data: ", data);
        success();
        navigate(`/rover/user/costingSentOrders`);
      }
    } catch (exception) {
      error();
    }
  }

  const validationSchema = yup.object({
    customerName: yup.string().required(" Name is required"),
    customerPhone: yup.number().required("Number is required"),
    vehicleType: yup.string().required("Vehicle Type is required"),
    orderDetails: yup.string().required("Order Details is required"),
    pickupStore: yup.string().required("Pickup Store is Required"),
    deliveryAddress: yup.string().required("Address is required"),
    deliveryDate: yup.string().required("Delivery Date is required"),
    pickupDate: yup.string().required("Delivery Date is required"),
    deliverySlot: yup.string().required("Time is required"),
    pickupSlot: yup.string().required("Time is required"),
    deliveryDistance: yup.object().required("Distance is required"),
    vendorName: yup.object().required("Distance is required"),
    deliveryCharges: yup.string().required("Charges Are required"),
    takeDelChargeFromCustomer: yup.string().required("This Field is required"),
    codAmount: yup.number().required("COD Amount is required"),
    noOfLabours: yup.number().required("COD Amount is required"),
    totalLabourCharge: yup.number().required("COD Amount is required"),
    totalPayableAmount: yup.string().required("Amount is required"),
  });

  const updateValues = async (formData) => {
    const val = formData;
    val.id = id;
    val.createdBy = localStorage.getItem("username");
    val.vendor = formData.vendor.vendorCode;
    val.updatedAt = new Date();
    val.updatedBy = localStorage.getItem("username");
    try {
      const { data } = await addPickupPacket(val);
      if (data) {
        console.log("data: ", data);
        success();
        navigate(`/rover/user/requestandactive`);
        // <Navigate to={`stationList/viewStation/${data.id}`}  />
      }
    } catch (ex) {
      error();
    }
  };
  console.log(defaultValues);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: id ? defaultValues : initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (id) {
        console.log("values: Id available", values);
        updateValues(values);
      } else {
        console.log("values: Id nahi hai", values);
        submitPacketInfo(values);
      }
    },
  });

  useEffect(() => {
    if (formik.values.takeDelChargeFromCustomer === "Yes") {
      formik.values.totalPayableAmount =
        parseInt(formik.values.deliveryCharges) +
        parseInt(formik.values.totalLabourCharge) +
        parseInt(formik.values.codAmount) +
        parseInt(formik.values.additionalCharges);
    }
    if (formik.values.takeDelChargeFromCustomer === "No") {
      formik.values.totalPayableAmount = parseInt(formik.values.codAmount);
    }
  }, [
    formik.values.deliveryCharges,
    formik.values.takeDelChargeFromCustomer,
    formik.values.totalLabourCharge,
    formik.values.codAmount,
    formik.values.additionalCharges,
  ]);

  useEffect(() => {
    const getAllVendors = async () => {
      const { data } = await viewAllVendorServices();
      console.log("data: ", data);
      if (data) {
        setVendors(data);
      }
    };
    getAllVendors();
  }, []);

  useEffect(() => {
    const getAllVendors = async () => {
      const { data } = await DeliveryCharges(formik.values.category);
      console.log("data: ", data);
      if (data) {
        setDistance(data);
      }
    };
    getAllVendors();
  }, [formik.values.category]);

  const getVendorCashback = async (name) => {
    const { data } = await vendorCashbackDetails2(name);
    if (data) {
      setTotalRoverPoints(data?.totalRoverPoints);
    }
  };

  useEffect(() => {
    console.log("formik.values.vendorName", formik.values.vendorName.username);

    getVendorCashback(formik.values.vendorName.username);
  }, [formik.values.vendorName]);

  useEffect(() => {
    console.log("ODhat hai");
    setRoverPointsClaimed(0);
  }, [formik.values.deliveryDistance]);

  return (
    <>
      {/* <QuickTracking /> */}
      {id ? (
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ margin: "15px 0px 0px 18px" }}
        >
          Edit Packet Information
        </Typography>
      ) : (
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ margin: "15px 0px 0px 18px" }}
        >
          Add Packet
        </Typography>
      )}

      <Card
        sx={{ margin: "8px 15px 15px 15px", py: 3, px: 5, borderRadius: "8px" }}
      >
        {id ? (
          <Box
            sx={{
              backgroundColor: "#F7F7FC",
              borderRadius: "8px 8px 0px 0px",
              boxShadow: "none",
            }}
            px="14px"
            py="10px"
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="subtitle1"
                fontWeight="600"
                sx={{ margin: "0px 0px 0px 0px", fontSize: "20px" }}
              >
                {defaultValues.customerName}
              </Typography>
              <Edit
                sx={{ color: "gray" }}
                onClick={() => {
                  setEdit(!edit);
                }}
              />
            </Box>
            <Typography
              variant="subtitle2"
              fontWeight="500"
              sx={{ margin: "0px 0px 10px 0px", lineHeight: "0.5" }}
            ></Typography>
          </Box>
        ) : null}
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            {[
              { title: "Customer Name", name: "customerName", size: 4 },
              { title: "Phone Number", name: "customerPhone", size: 4 },
              { title: "Alternate Number", name: "alternatePhone", size: 4 },
              { title: "Vehicle Type", name: "vehicleType", size: 6 },
              {
                title: "Pick-Up Store Locations",
                name: "pickupStore",
                size: 6,
              },
              { title: "Delivery Address", name: "deliveryAddress", size: 8 },
              { title: "Select Vendor", name: "vendorName", size: 4 },
              { title: "Select Category", name: "category", size: 4 },
              { title: "Delivery Distance", name: "deliveryDistance", size: 4 },
              { title: "Delivery Charges", name: "deliveryCharges", size: 4 },
              { title: "No. Of Labours", name: "noOfLabours", size: 4 },
              {
                title: "Total Labour Charge",
                name: "totalLabourCharge",
                size: 4,
              },
              {
                title: "Collect Delivery Charges From Customer",
                name: "takeDelChargeFromCustomer",
                size: 4,
              },
              { title: "COD Amount", name: "codAmount", size: 4 },
              {
                title: "Additional Charges",
                name: "additionalCharges",
                size: 4,
              },
              {
                title: "Amount To Be Collected",
                name: "totalPayableAmount",
                size: 4,
              },
              {
                title: "Reason For Payable Amount Change",
                name: "reasonForPayableAmountChange",
                size: 12,
              },

              { title: "Pickup Date", name: "pickupDate", size: 6 },
              { title: "Pickup Time Slot", name: "pickupSlot", size: 6 },
              { title: "Date To Be Delivered", name: "deliveryDate", size: 6 },
              { title: "Delivery Time Slot", name: "deliverySlot", size: 6 },
              { title: "Order Details", name: "orderDetails", size: 12 },
              { title: "Remark", name: "remarks", size: 6 },
            ].map((item) => {
              return (
                <Grid item xs={12} lg={item.size}>
                  <Typography
                    mt={1}
                    mb={1}
                    variant="subtitle2"
                    fontWeight="bold"
                  >
                    {item.title}
                  </Typography>

                  {item.name === "deliveryDate" ||
                  item.name === "pickupDate" ? (
                    <MobileDatePicker
                      focused
                      inputFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      renderInput={(params) => (
                        <SearchTextField
                          {...params}
                          size="small"
                          fullWidth
                          placeholder={item.title}
                        />
                      )}
                      //  {id? disabled={!edit}:null}
                      name={item.name}
                      fullWidth
                      value={formik.values[item.name]}
                      onChange={(value) =>
                        formik.setFieldValue(item.name, value, true)
                      }
                      // onChange={formik.handleChange}
                    />
                  ) : item.name === "deliveryDistance" ? (
                    <Autocomplete
                      // disablePortal
                      disableClearable
                      options={distance}
                      getOptionLabel={(option) => option?.distance}
                      focused
                      name="deliveryDistance"
                      onChange={(event, value) => {
                        formik.setFieldValue("deliveryDistance", value);
                        formik.setFieldValue("deliveryCharges", value?.charges);
                      }}
                      // onChange={formik.handleChange}
                      value={
                        formik.values.deliveryDistance
                          ? formik.values.deliveryDistance
                          : null
                      }
                      // fullWidth
                      size="small"
                      renderInput={(params) => (
                        <FormAutoComplete
                          {...params}
                          focused
                          placeholder="Select Distance"
                          variant="outlined"
                          type="text"
                          error={
                            formik.touched.deliveryDistance &&
                            Boolean(formik.errors.deliveryDistance)
                          }
                          helperText={
                            formik.touched.deliveryDistance &&
                            formik.errors.deliveryDistance
                          }
                        />
                      )}
                    />
                  ) : item.name === "noOfLabours" ? (
                    <FormTextField
                      size="small"
                      fullWidth
                      type="number"
                      id={item.name}
                      placeholder={item.title}
                      variant="outlined"
                      value={formik.values.noOfLabours}
                      name={item.name}
                      onChange={(event, value) => {
                        formik.setFieldValue("noOfLabours", event.target.value);
                        formik.setFieldValue(
                          "totalLabourCharge",
                          parseInt(event.target.value) *
                            parseInt(
                              formik.values.deliveryDistance?.labourCharges
                            )
                        );
                      }}
                      error={
                        formik.touched[item.name] &&
                        Boolean(formik.errors[item.name])
                      }
                      helperText={
                        formik.touched[item.name] && formik.errors[item.name]
                      }
                      // disabled={!edit}
                    />
                  ) : item.name === "vendorName" ? (
                    <Autocomplete
                      // disablePortal
                      disableClearable
                      options={vendors}
                      getOptionLabel={(option) => option?.vendorCode}
                      focused
                      name="vendorName"
                      onChange={(event, value) => {
                        formik.setFieldValue("vendorName", value);
                        // formik.setFieldValue(
                        //   "vendorName",
                        //   charges[value.name]
                        // );
                      }}
                      // onChange={formik.handleChange}
                      value={
                        formik.values.vendorName
                          ? formik.values.vendorName
                          : null
                      }
                      // fullWidth
                      size="small"
                      renderInput={(params) => (
                        <FormAutoComplete
                          {...params}
                          focused
                          placeholder="Select Vendor"
                          variant="outlined"
                          type="text"
                          error={
                            formik.touched.vendorName &&
                            Boolean(formik.errors.vendorName)
                          }
                          helperText={
                            formik.touched.vendorName &&
                            formik.errors.vendorName
                          }
                        />
                      )}
                    />
                  ) : item.name === "category" ? (
                    <Autocomplete
                      // disablePortal
                      disableClearable
                      options={[
                        "Electronics Goods",
                        "Furniture",
                        "Sanitary Shops",
                        "Marbels",
                        "Household Items",
                      ]}
                      // getOptionLabel={(option) => option?.vendorCode}
                      focused
                      name="category"
                      onChange={(event, value) => {
                        formik.setFieldValue("category", value);
                        formik.setFieldValue("deliveryDistance", "");
                        formik.setFieldValue("deliveryCharges", "");
                        formik.setFieldValue("noOfLabours", "");
                        formik.setFieldValue("totalLabourCharge", "");
                      }}
                      value={
                        formik.values.category ? formik.values.category : null
                      }
                      size="small"
                      renderInput={(params) => (
                        <FormAutoComplete
                          {...params}
                          focused
                          placeholder="Select Category"
                          variant="outlined"
                          type="text"
                          error={
                            formik.touched.category &&
                            Boolean(formik.errors.category)
                          }
                          helperText={
                            formik.touched.category && formik.errors.category
                          }
                        />
                      )}
                    />
                  ) : item.name === "vehicleType" ? (
                    <Autocomplete
                      // disablePortal
                      disableClearable
                      options={["Two Wheeler", "Three Wheeler", "Four Wheeler"]}
                      focused
                      name="vehicleType"
                      onChange={(event, value) => {
                        formik.setFieldValue("vehicleType", value);
                      }}
                      // onChange={formik.handleChange}
                      value={
                        formik.values.vehicleType
                          ? formik.values.vehicleType
                          : null
                      }
                      // fullWidth
                      size="small"
                      renderInput={(params) => (
                        <FormAutoComplete
                          {...params}
                          focused
                          placeholder="Vehicle Type"
                          variant="outlined"
                          type="text"
                          error={
                            formik.touched.vehicleType &&
                            Boolean(formik.errors.vehicleType)
                          }
                          helperText={
                            formik.touched.vehicleType &&
                            formik.errors.vehicleType
                          }
                        />
                      )}
                    />
                  ) : item.name === "deliverySlot" ||
                    item.name === "pickupSlot" ? (
                    <Autocomplete
                      disableClearable
                      options={["8-12 PM", "12-4 PM", "4-8 PM"]}
                      focused
                      name={item.name}
                      onChange={(event, value) => {
                        formik.setFieldValue(item.name, value);
                      }}
                      value={
                        formik.values[item.name]
                          ? formik.values[item.name]
                          : null
                      }
                      size="small"
                      renderInput={(params) => (
                        <FormAutoComplete
                          {...params}
                          focused
                          placeholder={item.title}
                          variant="outlined"
                          type="text"
                          error={
                            formik.touched[item.name] &&
                            Boolean(formik.errors[item.name])
                          }
                          helperText={
                            formik.touched[item.name] &&
                            formik.errors[item.name]
                          }
                        />
                      )}
                    />
                  ) : item.name === "takeDelChargeFromCustomer" ? (
                    <Autocomplete
                      // disablePortal
                      disableClearable
                      options={["Yes", "No"]}
                      focused
                      name="takeDelChargeFromCustomer"
                      onChange={(event, value) => {
                        formik.setFieldValue(
                          "takeDelChargeFromCustomer",
                          value
                        );
                      }}
                      // onChange={formik.handleChange}
                      value={
                        formik.values.takeDelChargeFromCustomer
                          ? formik.values.takeDelChargeFromCustomer
                          : null
                      }
                      // fullWidth
                      size="small"
                      renderInput={(params) => (
                        <FormAutoComplete
                          {...params}
                          focused
                          placeholder="Collect Delivery Charges From Customer"
                          variant="outlined"
                          type="text"
                          error={
                            formik.touched.takeDelChargeFromCustomer &&
                            Boolean(formik.errors.takeDelChargeFromCustomer)
                          }
                          helperText={
                            formik.touched.takeDelChargeFromCustomer &&
                            formik.errors.takeDelChargeFromCustomer
                          }
                        />
                      )}
                    />
                  ) : (
                    <FormTextField
                      size="small"
                      fullWidth
                      id={item.name}
                      placeholder={item.title}
                      disabled={item.name === "totalPayableAmount"}
                      variant="outlined"
                      type="text"
                      value={formik.values[item.name]}
                      name={item.name}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[item.name] &&
                        Boolean(formik.errors[item.name])
                      }
                      helperText={
                        formik.touched[item.name] && formik.errors[item.name]
                      }
                      // disabled={!edit}
                    />
                  )}
                </Grid>
              );
            })}

            <Grid item xs={6}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Apply Cashback{" "}
                <span style={{ color: "#FF6A1A" }}> ({totalRoverPoints}) </span>
              </Typography>
              <Stack direction="row">
                <UploadTextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="Upload"
                  type="text"
                  value={roverPointsClaimed}
                  // onChange={(e) => setSearch(e.currentTarget.value)}
                />
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    color: "#fff",
                    boxShadow: "none",
                  }}
                  onClick={() => {
                    parseInt(formik.values.deliveryCharges) <
                    parseInt(totalRoverPoints)
                      ? setRoverPointsClaimed(formik.values.deliveryCharges)
                      : setRoverPointsClaimed(totalRoverPoints);

                    const val =
                      parseInt(formik.values.deliveryCharges) <
                      parseInt(totalRoverPoints)
                        ? formik.values.deliveryCharges
                        : totalRoverPoints;

                        formik.values.totalPayableAmount =
                        parseInt(formik.values.totalPayableAmount) - parseInt(val);
                    
                  }}
                >
                  Apply
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ColorButton variant="contained" type="submit">
              Submit
            </ColorButton>
          </Box>
        </form>
      </Card>
      {/* )} */}
      {/* </Formik> */}
    </>
  );
};

export default VendorPacketFormStation;
