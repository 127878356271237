import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AddDeliveryAssociate from "./components/DeliveryAssociate/AddDA";
import AddStation from "./components/Station/AddStation";
import DeliveryAssociateList from "./components/DeliveryAssociate/DeliveryAssociateList";
import Dashboard from "./components/Dashboard";
import BagList from "./components/ReceiveShipment/BagList";
import BagDetails from "./components/ReceiveShipment/BagDetails";
import LoginDA from "./components/LoginDA";
import RequireAuth from "./components/RequireAuth";
import LandingPageMembers from "./components/LandingPageMembers";
import LandingPageDA from "./components/LandingPageDa";
import ParticularRunsheet from "./DeliveryOperations/ParticularRunsheet";
import PacketList from "./components/ReceiveShipment/PacketList";
import AddPacketsManually from "./components/ReceiveShipment/AddPacketsManually";
import AddBagManually from "./components/ReceiveShipment/AddBagManually";
import { LocalizationProvider } from "@mui/x-date-pickers";
import PacketInfo from './components/PacketInfo';
import ParticularRunsheetPickup from "./PickUpOperations/ParticularRunsheetPickup";
import AddPickupPackets from "./PickUpOperations/AddPickupPacket";
import PacketInfoPickup from "./PickUpOperations/PickupPacketInfo";
import VendorDeliveryRequestForm from "./VendorUI/VendorDeliveryRequestForm";
import OrderHistory from "./VendorUI/OrderHistory";
import OrderDetails from "./VendorUI/OrderDetails";
import LandingPageVendors from "./components/LandingPageVendors";
import ApplyingCashback from "./components/StationD2D/ApplyingCashback";
import Login from './components/Login';


const RoverRoutes = () => {
    return ( <>
    <Routes>
            
                <Route exact path="/login" element={<Login />} />
              <Route exact path="/LoginDA" element={<LoginDA />} />
              <Route exact path="/baglist" element={<BagList />} />
              <Route
                exact
                path="/baglist/addBagManually"
                element={<AddBagManually />}
              />
              <Route exact path="/packetlist" element={<PacketList />} />
             
              <Route
                exact
                path="/packetlist/addpacketsmanually"
                element={<AddPacketsManually />}
              />
              <Route
                exact
                path="/packetlist/addpacketsmanually/:id"
                element={<AddPacketsManually />}
              />
              <Route
                exact
                path="/packetlist/addpickuppacketsmanually/:id"
                element={<AddPickupPackets />}
              />
              <Route
                exact
                path="/baglist/bagDetails"
                element={<BagDetails />}
              />
              <Route
                path="/particularRunsheet/:id"
                element={<ParticularRunsheet />}
              />
              <Route
                path="/particularRunsheetPickup/:id"
                element={<ParticularRunsheetPickup />}
              />
              <Route
                path="/particularRunsheet/:id/:ids"
                element={<PacketInfo />}
              />
              <Route
                path="/particularRunsheetrunsheetId/:runsheetId/:ids"
                element={<PacketInfo />}
              />
              <Route
                path="/particularRunsheetPickup/:id/:ids"
                element={<PacketInfoPickup />}
              />
              <Route
                path="/particularRunsheetPickups/:pickupid/:ids"
                element={<PacketInfoPickup />}
              />
              //!Protected Routes
              {/* <Route element={<RequireAuth allowedRoles="delivery associate"/>}> */}
              <Route exact path="/DA/*" element={<LandingPageDA />} />
              {/* </Route> */}
              {/* <Route element={<RequireAuth allowedRoles="Admin"/>}> */}
              <Route exact path="/user/*" element={<LandingPageMembers />} />
              <Route exact path="/vendor/*" element={<LandingPageVendors />} />
              {/* </Route> */}
              {/* <Route path="/redirect" element={<Navigate to ="/dashboard"/>}/> */}
              {/* <Route
        path="*"
        element={<Navigate to="/" replace />}
      /> */}
      
    </Routes>
    </> );
}
 
export default RoverRoutes;