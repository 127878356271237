import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Typography,
  Card,
  Box,
  Grid,
  Divider,
  Modal,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getOrderHistoryTime, viewOrderDetails } from "../services/vendors";
import config from "../config.json";

const OrderDetails = () => {
  const { ids } = useParams();
  const { id } = useParams();
  const { runsheetId } = useParams();

  const [packetInfo, setPacketInfo] = useState();
  const [activeStep, setActiveStep] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [openPickupModal, setOpenPickupModal] = React.useState(false);
  const [daImage, setDAImage] = useState();
  const [pickUpImage, setPickupImage] = useState();
  const [rtvImage, setRTVImage] = useState();
  const [steps, setSteps] = useState([]);

  async function getImg(api, state) {
    if(!api) return
    const tokens = localStorage.getItem("token");
    const token = JSON.parse(tokens).jwttoken;
    console.log("token: ", token);
    const response = await fetch(
      config.apiUrl + "/roverapi/v1/getimage" + api,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const imageBlob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(imageBlob);
    reader.onloadend = () => {
      const base64data = reader.result;
      console.log("base64data: ", base64data);
      state(base64data);
    };
  }

  useEffect(() => {
    const getPacketDetailsById = async () => {
      const { data } = await viewOrderDetails(id);
      if (data) {
        console.log("data: ", data);
        setPacketInfo(data);
        getImg(data.daUploadedImage, setDAImage);
        getImg(data.pickupConfirmImage, setPickupImage);
        getImg(data.rtvConfirmImage, setRTVImage);

        const {data:value}= await getOrderHistoryTime(data.vendorBookingId)
        console.log('value: ', value);

        if(data){
          setSteps(value)
        }

        switch (data?.vendorOrderStatus) {
          case "Requested": {
            setActiveStep(0);
            break;
          }
          case "Pickup Confirmed": {
            setActiveStep(1);
            break;
          }
          case "Cancelled": {
            setActiveStep(1);
            break;
          }
          case "DA Assigned": {
            setActiveStep(2);
            break;
          }
          case "Picked": {
            setActiveStep(3);
            break;
          }
          case "Delivered": {
            setActiveStep(4);
            break;
          }
          default: {
            setActiveStep(0);
          }
        }
      }
    };

    getPacketDetailsById();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 2,
  };

  const val = [
    { title: "Rover D2D ID", value: packetInfo?.vendorBookingId },
    { title: "Customer Name", value: packetInfo?.customerName },
    { title: "Phone Number", value: packetInfo?.customerPhone },
    { title: "Vehicle Type", value: packetInfo?.vehicleType },
    { title: "Store Location", value: packetInfo?.pickupStore },
    { title: "Drop Address", value: packetInfo?.deliveryAddress },
    { title: "Delivery Distance", value: packetInfo?.deliveryDistance },
    { title: "Delivery Charges", value: packetInfo?.deliveryCharges },
    { title: "COD Amount", value: packetInfo?.codAmount },
    { title: "Total Delivery Charges", value: packetInfo?.totalDeliveryCharges },
    { title: "Total Amount To Collect", value: packetInfo?.totalPayableAmount },
    {
      title: "Payment Collected",
      value: packetInfo?.amountCollectedOnDelivery,
    },
    {
      title: "Date to be Delivered",
      value: moment(packetInfo?.deliveryDate).format("dddd, Do MMMM YYYY"),
    },
    { title: "Order Details", value: packetInfo?.orderDetails },
    { title: "Assigned DA", value: packetInfo?.assignPerson },
    { title: "Picked Up Image", value: "" },
    { title: "Station Manager Contact No.", value: packetInfo?.daPhoneNumber },
    {
      title: "Packet Delivered on",
      value: moment(packetInfo?.packetDeliveredDate).format(
        "dddd, Do MMMM YYYY, hh:mm A"
      ),
    },
    { title: "Status", value: packetInfo?.vendorOrderStatus },
    { title: "Cancelled Reason", value: packetInfo?.cancelReason },
  ];
console.log(">>>>>>",steps);
  const step = [
    {
      label: "Delivery Request Sent",
      value: packetInfo?.createdAt,
    },
    {
      label:
        packetInfo?.vendorOrderStatus === "Cancelled"
          ? "Order Cancelled"
          : "Delivery Request Accepted",
      value: packetInfo?.acceptorrejectTimestamp,
    },
    {
      label: "On The Way",
      value: packetInfo?.assignTimestamp,
    },
    {
      label: "Picked Up",
      value: packetInfo?.pickedTimestamp,
    },
    {
      label: "Delivered",
      value: packetInfo?.packetDeliveredDate,
    },
  ];
  return (
    <>
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        Packet Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <Card
            sx={{
              margin: "8px 15px 15px 15px",
              py: 3,
              px: 5,
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F7F7FC",
                borderRadius: "8px 8px 0px 0px",
                boxShadow: "none",
              }}
              px="14px"
              py="10px"
              fontWeight="600"
            >
              Shipment Information
            </Box>
            {val.map((item, i) =>
              item.title === "Status" ? (
                <div key={i} style={{ padding: 11 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" fontWeight="600">
                        {item.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2">{item.value}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <>
                        {(packetInfo?.vendorOrderStatus === "Delivered"||packetInfo?.vendorOrderStatus === "RTV") && (
                          <FontAwesomeIcon
                            icon="fa-solid fa-image"
                            style={{
                              color: "#FF6A1A",
                              height: "20px",
                              // margin: "17px 25px 0px 20px",
                              marginRight: "25px",
                            }}
                            onClick={() => setOpenModal(true)}
                          />
                        )}
                      </>
                    </Grid>
                  </Grid>
                  <Divider />
                </div>
              ) : item.title === "Picked Up Image" ? (
                <>
                  <div key={i} style={{ padding: 11 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body2" fontWeight="600">
                          {item.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FontAwesomeIcon
                          icon="fa-solid fa-image"
                          style={{
                            color: "#FF6A1A",
                            height: "20px",
                            // margin: "17px 25px 0px 20px",
                            marginRight: "25px",
                          }}
                          onClick={() => setOpenPickupModal(true)}
                        />
                      </Grid>
                    </Grid>
                  </div>

                  <Divider />
                </>
              ) : (
                <div key={i} style={{ padding: 11 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" fontWeight="600">
                        {item.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{item.value}</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </div>
              )
            )}
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card
            sx={{
              margin: "8px 15px 15px 15px",
              py: 3,
              px: 5,
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F7F7FC",
                borderRadius: "8px 8px 0px 0px",
                boxShadow: "none",
              }}
              px="14px"
              py="10px"
              fontWeight="600"
            >
              Order Information
            </Box>

            <Box sx={{ maxWidth: 400 }}>
              <Stepper activeStep={steps.length-1} orientation="vertical">
                {steps?.map((step, index) => (
                  <Step key={index}>
                    <StepLabel>{step.actionName}</StepLabel>

                    <Typography variant="caption">
                      {" "}
                      
                        {moment(step.actionDateTime).format("DD MM YYYY, hh:mm A")+ " by "  +step?.actionby?.toUpperCase()}
                    </Typography>
                  </Step>
                ))}
              </Stepper>
            </Box>

            <Modal
              open={openModal}
              onClose={() => setOpenModal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Packet Image
                </Typography>

                <img src={packetInfo?.vendorOrderStatus === "RTV"?rtvImage:daImage} style={{ width: 470 }} alt="ImageByDA" />
              </Box>
            </Modal>

            <Modal
              open={openPickupModal}
              onClose={() => setOpenPickupModal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Packet Image
                </Typography>

                <img src={pickUpImage} style={{ width: 470 }} alt="ImageByDA" />
              </Box>
            </Modal>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderDetails;
