import http from "./httpService";
import config from "../config.json";

const tokens = localStorage.getItem("token");
const token = JSON.parse(tokens)?.jwttoken;
const code = JSON.parse(tokens)?.vendorCode;
const stationCode = JSON.parse(tokens)?.stationCode;
const username = localStorage.getItem("username");

const head = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "access-control-allow-origin": "*",
    Authorization: `Bearer ${token}`,
  },
};

export function createOrUpdateVendorPacket(data) {
  return http.post(
    config.apiUrl + "/roverapi/v1/createvendororder",
    data,
    head
  );
}
export function createVendorOrderByStation(data) {
  return http.post(
    config.apiUrl + "/roverapi/v1/createVendorOrderByStation",
    data,
    head
  );
}

export function viewOrderHistory(date) {
  return http.get(
    config.apiUrl +
      "/roverapi/v1/vendororderhistoryofdate/" +
      code +
      "/" +
      date,
    head
  );
}
export function viewActiveOrders() {
  return http.get(
    config.apiUrl + "/roverapi/v1/vendoractiveorder/" + code,
    head
  );}

export function costingSentOrders() {
  return http.get(
    config.apiUrl + "/roverapi/v1/costingSentOrders/" + username,
    head
  );
}


export function viewOrderDetails(data) {
  return http.get(
    config.apiUrl + "/roverapi/v1/vendororderdetails/" + data,
    head
  );
}
export function viewActiveOrdersStation() {
  return http.get(
    config.apiUrl + "/roverapi/v1/activevendororders/" + username,
    head
  );
}
export function viewRequestedOrdersStation() {
  return http.get(
    config.apiUrl + "/roverapi/v1/requestedvendororders/" + username,
    head
  );
}
export function viewOrdersHistoryVendorStation() {
  return http.get(
    config.apiUrl + "/roverapi/v1/vendorordershistory/" + username,
    head
  );
}
export function AcceptOrCancelOrder(data) {
  return http.post(
    config.apiUrl + "/roverapi/v1/aceeptorrejectvendorrequest",
    data,
    head
  );
}
export function AllAcceptedOrders() {
  return http.get(
    config.apiUrl + "/roverapi/v1/acceptedvendororders/" + username,
    head
  );
}
export function AssignVendorPacketsToDA(data) {
  return http.post(
    config.apiUrl + "/roverapi/v1/assignvendorordertoda",
    data,
    head
  );
}
export function D2DOrderAssignedToDA(data) {
  return http.get(
    config.apiUrl + "/roverapi/v1/vendorordersassignedtoda/" + data,
    head
  );
}

export function D2DOrderDANotAbleToDeliver(data) {
  return http.post(
    config.apiUrl + "/roverapi/v1/unabletodeliverreason",
    data,
    head
  );
}

export function D2DOrderDADelivered(data) {
  return http.post(
    config.apiUrl + "/roverapi/v1/vendororderdelivered",
    data,
    head
  );
}
export function D2DOrderDAPickedUp(data) {
  return http.post(config.apiUrl + "/roverapi/v1/setstatuspickeup", data, head);
}

export function getAllD2DCashReconcileToday() {
  return http.get(
    config.apiUrl + "/roverapi/v1/dtodcashreconcilationsummary/" + username,
    head
  );
}
export function getAllD2DCashReconcileParticularDate(date) {
  return http.get(
    config.apiUrl + "/roverapi/v1/dtodcashreconcilationsummaryofdateonly/" + username+"/"+date,
    head
  );
}

export function getAllD2DPendingCashReconcile() {
  return http.get(
    config.apiUrl + "/roverapi/v1/dtodcashreconcilationsummaryoverall/" + username,
    head
  );
}
export function getAllD2DPendingCashReconcilewrtDate(date) {
  return http.get(
    config.apiUrl + "/roverapi/v1/dtodcashreconcilationsummaryofdateonly/" + username+"/"+date,
    head
  );
}
export function getAllD2DPendingCashReconcilewrtDA(da) {
  return http.get(
    config.apiUrl + "/roverapi/v1/dtodcashreconcilationsummaryofdaonly/" + username+"/"+da,
    head
  );
}
export function getAllD2DCashReconcileDADate(date, da) {
  return http.get(
    config.apiUrl +
      "/roverapi/v1/dtodcashreconcilationsummaryofdateandda/" +
      username +
      "/" +
      date +
      "/" +
      da,
    head
  );
}

export function TodayD2DRunsheet() {
  return http.get(
    config.apiUrl + "/roverapi/v1/defaultdtodrunsheetclosure",
    head
  );
}
export function DateD2DRunsheet(date) {
  return http.get(
    config.apiUrl + "/roverapi/v1/dtodrunsheetclosure/" + date,
    head
  );
}

export function VendorDeliveryCharges() {
  return http.get(config.apiUrl + "/roverapi/v1/vendorDeliveryCharges", head);
}

export function getOrderHistoryTime(data) {
  return http.get(
    config.apiUrl + "/roverapi/v1/vendororderindivisualhistory/" + data,
    head
  );
}
export function cashReconcileUpdateD2D(data) {
  return http.post(
    config.apiUrl + "/roverapi/v1/dtodcashreconcilation",
    data,
    head
  );
}

export function updateD2DClosure(data) {
  return http.post(
    config.apiUrl + "/roverapi/v1/dtodrunsheetclosure",
    data,
    head
  );
}

export function DeliveryCharges(data) {
  return http.get(config.apiUrl + "/roverapi/v1/deliveryCharges/" + data, head);
}
export function SendPaymentDetails(data) {
  return http.post(
    config.apiUrl + "/roverapi/v1/sendPaymentDetails",
    data,
    head
  );
}
export function CostingSentToVendor(data) {
  return http.get(
    config.apiUrl + "/roverapi/v1/costingReceivedOrders/"+code,

    head
  );
}
export function RejectOrder(data) {
  return http.post(
    config.apiUrl + "/roverapi/v1/rejectVendorOrderRequest",
    data,
    head
  );
}
export function VendorConfirmOrder(data) {
  return http.post(
    config.apiUrl + "/roverapi/v1/confirmVendorOrderRequest",
    data,
    head
  );
}
export function VendorRejectOrder(data) {
  return http.post(
    config.apiUrl + "/roverapi/v1/cancelVendorOrderRequest",
    data,
    head
  );
}
