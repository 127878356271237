import * as React from "react";
import {
  Typography,
  Card,
  Paper,
  IconButton,
  InputAdornment,
  Grid,
} from "@mui/material";
import { Search, FilterList, Edit } from "@mui/icons-material";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
// import TableCell from '@mui/material/TableCell';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
// import TableRow from '@mui/material/TableRow';
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BackupTable } from "@mui/icons-material";
import XLSX from "xlsx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faCoffee } from "@fortawesome/fontawesome-free-solid";
import { StyledTableCell, StyledTableRow } from "../styles/table";
import { getMemberPermission } from "../services/memberAndPermissions";
import { viewAllStationServices } from "../services/station";
import { FormTextField, SearchTextField } from "../styles/textField";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { ColorButton } from "../styles/button";
import QuickTracking from "../components/QuickTracking";
import { getDeliveryHubPacketDetails, getDeliveryHubRunsheetDetails, getStationPacketDetails, getStationRunsheetDetails, getTodayRunsheet } from "../services/packets";
import { getPickupParticularData, getPickupParticularDataDH, pickupPacketsRunsheetDeliveryHub, pickupPacketsRunsheetStation } from "../services/pickup";
import moment from "moment";
import { MobileDatePicker } from '@mui/x-date-pickers';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "stationName",
    numeric: false,
    disablePadding: true,
    label: "DA Name",
  },

  

  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Runsheet Id",
  },

  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Created On",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Total",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Picked",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Left",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Cancelled",
  },


  // {
  //   id: "",
  //   // numeric: true,
  //   disablePadding: false,
  //   label: "Action",
  // },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        // pl: { sm: 2 },
        // pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function TodayRunsheetsPickup() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [rowData, setRowData] = React.useState([]);
  const [permissions, setPermissions] = React.useState({});
  const [role, setRole] = React.useState("");
  const [value, setValue] = React.useState("");

  var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today =  yyyy+'-' +mm + '-' + dd ;

  React.useEffect(() => {
    const tokens = localStorage.getItem("token");
    const token = JSON.parse(tokens);
    console.log("token: ", token);
    let permission;
    setRole(token.role);
    async function memberPermission() {
      const { data } = await getMemberPermission(token.userId);

      const deliveryHubRusheet=async(value)=>{

        const {data}=await pickupPacketsRunsheetDeliveryHub(value,today);
        if(data){
          console.log("??????????????????????????????????????",data);
          setRowData(data);
        }
      }
      const stationRunsheet=async(value)=>{

        const {data}= await pickupPacketsRunsheetStation(value,today)
        if(data){
          console.log(data);
          setRowData(data);
        }
      }

      if (data) {
        setPermissions(data);
        console.log('data: ', data);
        permission = data.roleStationResponse;
        if(data.roleDeliveryHubResponse.length>0){
          console.log('data.roleDeliveryHubResponse.deliveryHubCode: ', data.roleDeliveryHubResponse[0].deliveryHubCode);
          deliveryHubRusheet(data.roleDeliveryHubResponse[0].deliveryHubCode);
        }else{
          stationRunsheet(data.roleStationResponse[0].stationCode);
          // const sortedData = data.sort((a, b) => b.id - a.id);
 
        }
      }
    }


    const getRunsheetList = async () => {
      const { data } = await getTodayRunsheet();
    };
    memberPermission();
  }, []);

  React.useEffect(()=>{
let today= new Date(value);
    var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();


today =  yyyy+'-' +mm + '-' + dd ;
console.log('today: ', today);

const tokens = localStorage.getItem("token");
    const token = JSON.parse(tokens);
    console.log("token: ", token);
    
    const getData= async()=>{
      const {data}=  token.stationCode?await getPickupParticularData(token.stationCode, today):await getPickupParticularDataDH(token.deliveryHubCode, today);
      if(data){
        console.log(data);
        setRowData(data);
     
      }
    }


    getData();
  },[value])

  console.log("rows: ", rowData);
  const rows =
    rowData &&
    rowData?.filter(
      (item) => item
      // item?.stationName.toLowerCase().includes(search.toLowerCase()) ||
      // item?.stationCode
      //   .toString()
      //   .toLowerCase()
      //   .includes(search.toLowerCase()) ||

      // item?.city.toString().toLowerCase().includes(search)
    );

  // calories,
  // stationManager,
  // managerNumber,
  // managerEmail,
  // city,

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  console.log(permissions?.editStation);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const downloadExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(rowData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "branches");
    //Buffer
    let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "branches.xlsx");
  };

  const editPer = role === "Admin" || permissions?.editStation;
  console.log("role: ", role);
  console.log("editPer: ", editPer);

  return (
    <>
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        Run Sheet List
      </Typography>
      <Card
        sx={{
          margin: "8px 15px 15px 15px",
          py: 3,
          px: 3,
          borderRadius: "8px",
          minheight: "90vh",
        }}
      >
        <Grid container spacing={1}>
        <Grid item xs={6}>
        <SearchTextField
          size="small"
          sx={{ marginBottom: 2 }}
          fullWidth
          variant="outlined"
          placeholder="Search..."
          type="text"
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ marginRight: "0px" }}>
                <IconButton
                  sx={{
                    backgroundColor: "#F7F7FC",
                    borderRadius: "8px 0px 0px 8px",
                  }}
                  edge="start"
                >
                  <Search sx={{ backgroundColor: "#F7F7FC" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        </Grid>
        <Grid item xs={6}>
        <MobileDatePicker
                      // minDate={new Date()}
                      
                      inputFormat="dd/MM/yyyy" 
                      focused
                      renderInput={(params) => (
                        <SearchTextField
                          {...params}
                          size="small"
                          fullWidth
                          placeholder="Select Date"
                          variant="outlined"
                        />
                      )}
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue);
                      }}
                      //  {id? disabled={!edit}:null}
                    //   name={item.name}
                      fullWidth
                    //   value={formik.values[item.name]}
                    //   onChange={(value) =>
                    //     formik.setFieldValue("deliveryDate", value, true)
                    //   }
                      // onChange={formik.handleChange}
                    />
        </Grid>
        </Grid>

        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{
              width: "100%",
              mb: 2,
              boxShadow:
                "0px 2px 0px -5px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 4px 0px rgb(0 0 0 / 4%)",
            }}
          >
            <TableContainer>
              <Table
                sx={{ minWidth: 1100 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      console.log("isItemSelected: ", isItemSelected);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          // role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          // selected={isItemSelected}
                        >
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align="left"
                            padding="normal"
                            sx={{ width: "15%", cursor: "pointer" }}
                          >
                            <Link to={`DARunsheet/${row.assignPerson}`}>{row.assignPerson}</Link>
                          </StyledTableCell>

                         

                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                            <Link to={`/rover/particularRunsheetPickup/${row.runsheetId}`} target="_blank">

                            {row.runsheetId}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                            {row.pendingcount>0?"Pending":"Completed"}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                            {moment(row?.assignDate).format("DD/MMM/YYYY")}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                            {row.totalCount}{" "}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                            {row.receivedcount}{" "}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                            {row.pendingcount}{" "}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            padding="normal"
                            sx={{ width: "14%" }}
                          >
                            {row.cancelcount }{" "}
                          </StyledTableCell>
                       

                        </StyledTableRow>
                      );
                    })}{" "}
                  {emptyRows > 0 && (
                    <StyledTableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <StyledTableCell colSpan={6} />
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              sx={{ backgroundColor: "#F7F7FC" }}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Card>
    </>
  );
}
