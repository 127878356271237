import * as React from "react";
import {
  Typography,
  Card,
  Grid,
} from "@mui/material";

import ActiveOrders from "./ActiveOrders";
import Requested from "./Requested";
import OrderHistoryStationVendor from "./OrderHistoryStationVendor";
import CostingSentToVendor from "./CostingSentToVendor";

export default function RequestAndActive() {
  const [selectedButton, setSelectedButton] = React.useState("Active");

  return (
    <>
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        D2D Orders
      </Typography>
      <Card
        sx={{
          margin: "8px 15px 15px 15px",
          py: 3,
          px: 3,
          borderRadius: "8px",
          minheight: "90vh",
        }}
      >
        <Grid container spacing={1}>
            <Grid item sx={2} mb={3}><div className={selectedButton==="Requests"?"selected":"notSelected"} onClick={()=>{setSelectedButton("Requests")}}>Delivery Requests</div></Grid>
            <Grid item sx={2} mb={3}><div className={selectedButton==="Active"?"selected":"notSelected"} onClick={()=>{setSelectedButton("Active")}}>Active Orders</div></Grid>
            <Grid item sx={2} mb={3}><div className={selectedButton==="History"?"selected":"notSelected"} onClick={()=>{setSelectedButton("History")}}>Order  History</div></Grid>
            <Grid item sx={2} mb={3}><div className={selectedButton==="Pending"?"selected":"notSelected"} onClick={()=>{setSelectedButton("Pending")}}>Pending Orders</div></Grid>
            </Grid>
            {selectedButton==="Active"&&<ActiveOrders/>}
            {selectedButton==="Requests"&&<Requested/>}
            {selectedButton==="History"&&<OrderHistoryStationVendor/>}
            {selectedButton==="Pending"&&<CostingSentToVendor/>}
      </Card>
    </>
  );
}
