import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  vendorCashbackDetails,
  viewVendorDetails,
} from "../services/cashbacksVendors";
import { viewVendorServices } from "../services/vendor";
import moment from "moment/moment";

const VendorInformation = () => {
  const [cashbackDetails, setCashbackDetails] = useState();
  const [vendorDetails, setVendorDetails] = useState();
  const [selectedButton, setSelectedButton] = React.useState("credited");

  const getCashbackDetails = async () => {
    const { data } = await vendorCashbackDetails();
    if (data) {
      console.log("data: ", data);
      setCashbackDetails(data);
    }
  };
  const getVendorDetails = async () => {
    const { data } = await viewVendorDetails();
    if (data) {
      console.log("data: ", data);
      setVendorDetails(data);
    }
  };

  useEffect(() => {
    getCashbackDetails();
    getVendorDetails();
  }, []);

  return (
    <>
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
        My Profile
      </Typography>

      <Grid container spacing={1} p={1}>
        <Grid item lg={5} xs={12}>
          {" "}
          <Card
            sx={{
              borderRadius: "8px",
              height: "50vh",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F7F7FC",
                borderRadius: "8px 8px 0px 0px",
                boxShadow: "none",
              }}
              px="14px"
              py="10px"
              fontWeight="600"
            >
              Vendor Name
            </Box>

            <div style={{ padding: "10px" }}>
              <Grid container spacing={1}>
                {[
                  { name: "Name", value: vendorDetails?.businessName },
                  { name: "Username", value: vendorDetails?.username },
                  { name: "Email Id", value: vendorDetails?.email },
                  { name: "Phone Number", value: vendorDetails?.phoneNumber },
                  { name: "Address", value: vendorDetails?.address },
                  { name: "City", value: vendorDetails?.city },
                  { name: "Pincode", value: vendorDetails?.pincode },
                  { name: "Vendor Code", value: vendorDetails?.vendorCode },
                  { name: "Category", value: vendorDetails?.category },
                  { name: "Cashback Percentage", value: cashbackDetails?.vendorCashbackPer },
                ]?.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">{item.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{item?.value}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </div>
          </Card>
        </Grid>
        <Grid item lg={7} xs={12}>
          {" "}
          <Card
            sx={{
              borderRadius: "8px",
              height: "50vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F7F7FC",
                borderRadius: "8px 8px 0px 0px",
                boxShadow: "none",
              }}
              px="14px"
              py="10px"
              fontWeight="600"
            >
              Rover Coins - <span>{cashbackDetails?.totalRoverPoints}</span>
            </Box>
            <div style={{ padding: "10px" }}>
              <Grid container spacing={1}>
                <Grid item sx={2} mb={3}>
                  <div
                    className={
                      selectedButton === "credited" ? "selected" : "notSelected"
                    }
                    onClick={() => {
                      setSelectedButton("credited");
                    }}
                  >
                    Credited
                  </div>
                </Grid>
                <Grid item sx={2} mb={3}>
                  <div
                    className={
                      selectedButton === "debited" ? "selected" : "notSelected"
                    }
                    onClick={() => {
                      setSelectedButton("debited");
                    }}
                  >
                    Debited
                  </div>
                </Grid>
              </Grid>
              <div
                style={{
                  padding: "5px",
                }}
              >
                <Grid container spacing={1}>
                  {(selectedButton === "credited"
                    ? cashbackDetails?.vendorCreditedCashbackHistory
                    : cashbackDetails?.vendorDebitedCashbackHistory
                  )?.map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={3}>
                        <Typography variant="subtitle2">
                          {item?.vendorBookingId}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle2">
                         Rs. {item?.orderTotal}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle2" sx={{color:selectedButton==="credited"?"green":"red"}} >
                          {selectedButton==="credited"?item?.roverPointsCredited:item?.roverPointsDebited} coins
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle2">
                          {moment(item?.createdAt).format("MMM Do YY")}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default VendorInformation;
