import React from "react";
import Appbar from "../Appbar";
import QuickTracking from "../QuickTracking";
import XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColorButton } from "../../styles/button";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import { StyledTableBagCell, StyledTableBagRow } from "../../styles/table";
import { Card, Divider, Grid, Paper, Typography } from "@mui/material";
import { viewAllStationServices } from "./../../services/station";
import { visuallyHidden } from "@mui/utils";
import { FormTextField } from "../../styles/textField";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "stationName",
    numeric: false,
    disablePadding: true,
    label: "Packet Id",
  },
  {
    id: "stationCode",
    numeric: true,
    disablePadding: false,
    label: "Station Id",
  },
  {
    id: "stationManager",
    numeric: true,
    disablePadding: false,
    label: "Date ",
  },
  {
    id: "managerNumber",
    numeric: true,
    disablePadding: false,
    label: "Origin",
  },
  {
    id: "managerEmail",
    numeric: true,
    disablePadding: false,
    label: "Destination",
  },
  {
    id: "city",
    numeric: true,
    disablePadding: false,
    label: "Transporter",
  },

  {
    id: "",
    // numeric: true,
    disablePadding: false,
    label: "Origin",
  },
  {
    id: "",
    // numeric: true,
    disablePadding: false,
    label: "Title",
  },
//   {
//     id: "",
//     // numeric: true,
//     disablePadding: false,
//     label: "Total Packets",
//   },
//   {
//     id: "",
//     // numeric: true,
//     disablePadding: false,
//     label: "Transporter",
//   },
//   {
//     id: "",
//     // numeric: true,
//     disablePadding: false,
//     label: "Origin",
//   },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableBagRow>
        {headCells.map((headCell) => (
          <StyledTableBagCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableBagCell>
        ))}
      </StyledTableBagRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        // pl: { sm: 2 },
        // pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const BagDetails = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [rowData, setRowData] = React.useState([]);

  React.useEffect(() => {
    const getBagList = async () => {
      const { data } = await viewAllStationServices();
      console.log("data: ", data);
      const sortedData = data.sort((a, b) => b.id - a.id);
      setRowData(sortedData);
    };
    getBagList();
  }, []);

  const rows = rowData.filter(
    (item) =>
      item.stationName.toLowerCase().includes(search.toLowerCase()) ||
      item.stationCode
        .toString()
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      item.managerNumber
        .toString()
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      item.stationManager
        .toString()
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      item.managerEmail.toString().toLowerCase().includes(search) ||
      item.city.toString().toLowerCase().includes(search)
  );

  // calories,
  // stationManager,
  // managerNumber,
  // managerEmail,
  // city,

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const downloadExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(rowData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "branches");
    //Buffer
    let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "branches.xlsx");
  };

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Appbar />
      <div style={{ marginTop: "75px" }}></div>
      
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Card
            sx={{
              margin: "8px 0px 0px 15px",
              py: 2,
              px: 2,
              borderRadius: "8px",
              height: "30vh",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Typography variant="subtitle2">Bag Id</Typography>
                <Typography variant="subtitle1" fontWeight="600">
                  BAGID-IUTSY-678
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle2">Date Of Arrival</Typography>
                <Typography variant="subtitle1" fontWeight="600">
                  12 July, 2022
                </Typography>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="subtitle2" mr={1}>
                  Status{" "}
                </Typography>
                <ColorButton
                  variant="contained"
                  sx={{ margin: 0, width: "100%", height: "50%", cursor:"none" }}
                >
                  In Process
                </ColorButton>
              </div>
            </Box>
            <Divider />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            sx={{
              margin: "8px 15px 5px 0px",
              py: 2,
              px: 2,
              borderRadius: "8px",
              height: "30vh",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" , marginBottom:"5px"}}>
              <Typography variant="h6" fontWeight="600">
                Scan To Identify
              </Typography>

              <FormTextField
                size="small"
                className="styleFieldSearch"
                
                variant="outlined"
                type="text"
                placeholder="Scan To Identify..."
              />
            </Box>
            <Divider/>
          </Card>
        </Grid>
      </Grid>
      <Card
        sx={{
          margin: "8px 15px 15px 15px",
          py: 3,
          px: 3,
          borderRadius: "8px",
          minheight: "90vh",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{
              width: "100%",
              mb: 2,
              boxShadow:
                "0px 2px 0px -5px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 4px 0px rgb(0 0 0 / 4%)",
            }}
          >
            <TableContainer>
              <Table
                sx={{ minWidth: 1100 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      console.log("isItemSelected: ", isItemSelected);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableBagRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          // role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          // selected={isItemSelected}
                        >
                          <StyledTableBagCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align="left"
                            padding="normal"
                          >
                            {row.stationName}
                          </StyledTableBagCell>
                          <StyledTableBagCell align="left" padding="normal">
                            {row.stationCode}
                          </StyledTableBagCell>
                          <StyledTableBagCell align="left" padding="normal">
                            {row.stationManager}
                          </StyledTableBagCell>
                          <StyledTableBagCell align="left" padding="normal">
                            {row.managerNumber}
                          </StyledTableBagCell>
                          <StyledTableBagCell align="left" padding="normal">
                            {row.managerEmail}
                          </StyledTableBagCell>
                          <StyledTableBagCell align="left" padding="normal">
                            {row.city}
                          </StyledTableBagCell>

                          <StyledTableBagCell>50</StyledTableBagCell>
                          <StyledTableBagCell>50</StyledTableBagCell>
                          {/* <StyledTableBagCell>50</StyledTableBagCell>
                          <StyledTableBagCell>
                            Broadway Transporter
                          </StyledTableBagCell>
                          <StyledTableBagCell>Completed </StyledTableBagCell> */}
                        </StyledTableBagRow>
                      );
                    })}{" "}
                  {emptyRows > 0 && (
                    <StyledTableBagRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <StyledTableBagCell colSpan={6} />
                    </StyledTableBagRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              sx={{ backgroundColor: "#F7F7FC" }}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Card>
    </>
  );
};

export default BagDetails;
