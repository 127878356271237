import { Card, Grid, Typography } from "@mui/material";
import React from "react";
import { Box } from "@mui/material";

const QuickCashTracking = () => {
  return (
    <>
      <Box sx={{ margin: "55px 0px 0px 0px", padding:"10px" }} >
        <Grid   container spacing={2}>
            {[
                {"val":4300000, "desc":"Total Cash Projected"},
                {"val":2600000, "desc":"Total Cash Collected"},
                {"val":120000, "desc":"Cash Pending"},
                {"val":50000, "desc":"Misc"}
        ].map((item,i)=>
        (
            <Grid item xs={3} key={i}>
            <Card
              sx={{
                margin: "0px 0px 0px 0px",
                py: 0.5,
                px: 0.5,
                borderRadius: "8px",
                minHeight:"14vh"
              }}
            >
                <Typography
                variant="h6"
                fontWeight="600"
                sx={{color:"#FF6A1A"}}
              >
                {item.val}
              </Typography>
              <Typography
                variant="caption"
                fontWeight="600"
                gutterBottom
              sx={{lineHeight: 0, margin:0, padding:0  }}
           >
             {item.desc}
              </Typography>
              
            </Card>
          </Grid>)
        
        )}
          
        </Grid>
      </Box>
    </>
  );
};

export default QuickCashTracking;
