import { Box, Button, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { Link } from 'react-router-dom';
import { viewActiveOrders } from './../services/vendors';
import  moment  from 'moment';
import { ColorButton } from "../styles/button";




const ActiveOrdersMobile = () => {

  const [today,setToday]=useState([])

  useEffect(()=>{
    const getActiveOrders=async()=>{
    const {data}= await viewActiveOrders();
      if(data){
        console.log('data: ', data);
        setToday(data)
      }
    }
    getActiveOrders()
  },[])

  return (
    <>
      {/* <AppBarDA/> */}
      <div style={{ padding: "10px" }}>
        <Card sx={{ padding: "10px" }}>
        
        <div class="containerDA">
          <Typography variant="heading6" fontWeight={600} mb={1}>
            Active Orders
          </Typography>
          <Link to="/rover/vendor/vendorDeliveryRequest" className="link-style">
<ColorButton variant="contained" sx={{margin:0}}>New Request</ColorButton></Link>
        </div>
          {today.map((item, i) => (
            <React.Fragment key={i}>
              <Link to={`/rover/vendor/vendorOrderDetails/${item?.vendorBookingId}`} >
              <Box
                sx={{
                  marginBottom: "8px",
                  marginTop:"15px",
                  borderRadius: "12px",
                  backgroundColor: "#F4F8FF",
                  paddingLeft:"10px"
                }}
              >
                <Grid container >
                  <Grid item xs={1.5} sx={{  padding:"5px",}}>
                    <div
                      style={{
                        backgroundColor: "#FF6A1A",
                        borderRadius: "12px",
                        height: "25px",
                        width: "25px",
                        textAlign:"center",
                      
                        color:"#fff "
                      }}
                    >
                      {i + 1}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{lineHeight:1, color:"#11142D", padding:"5px"}}>
                      <Typography variant="body2" fontWeight={600}>{item.customerName}</Typography>
                      <Typography variant="body2" fontWeight={400}>{item.deliveryAddress}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4.5} sx={{color:"#11142D"}}>
                    <div style={{backgroundColor:"#FF6A1A"  , borderRadius: " 0px 12px 12px 0px" ,padding:"5px"}}>

                        <Typography variant="caption" sx={{color:"white", fontSize:12, padding:1, textAlign:"center",borderRadius:"12px"}} fontWeight={600}>{"  "+item?.vendorOrderStatus?.toUpperCase()+"  "}</Typography>
                        <div>

                      <Typography variant="caption" sx={{color:"white"}} fontSize={10} >{moment(item?.createdAt).format("DD MM YYYY, hh:mm A") }</Typography>
                        </div>
                        </div>
                      <div>
                    </div>

                  </Grid>
                </Grid>
              </Box>
              </Link>
              
            </React.Fragment>
          ))}
        </Card>
      </div>
    </>
  );
};

export default ActiveOrdersMobile;
