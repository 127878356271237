import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Stack,
  Typography,
  Divider,
  Box,
  Avatar,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Edit } from "@mui/icons-material";
import { viewStationServices } from "../../services/station";
import { viewVendorServices } from "../../services/vendor";

const ViewVendor = () => {
  let {  id } = useParams();
  console.log("userId: ", id);
  const [vendorData, setVendorData] = useState("");
  useEffect(() => {
    const getVendor = async () => {
      const { data } = await viewVendorServices(id);
      console.log("result: ", data);
      setVendorData(data);
    };
    getVendor();
  }, []); 

  return (
    <div style={{ margin: "8px 15px 15px 15px" }}>
      <Typography
        variant="h6"
        fontWeight="600"
        sx={{ margin: "15px 0px 0px 18px" }}
      >
     Vendor </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Card
            sx={{
              margin: "10px 0px 0px 0px",
              py: 2,
              px: 2,
              borderRadius: "8px",
              // height:"80vh"
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F7F7FC",
                borderRadius: "8px 8px 0px 0px",
                boxShadow: "none",
              }}
              px="14px"
              py="10px"
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="600"
                  sx={{ margin: "0px 0px 0px 0px", fontSize: "20px" }}
                >
                  {vendorData.businessName}
                </Typography>
                {/* <Link
                  className="link-style"
                  to={`/user/stationList/editStation/${vendorData.id}`}
                >
                  <Edit />
                </Link> */}
              </Box>
              <Typography
                variant="subtitle2"
                fontWeight="500"
                sx={{ margin: "0px 0px 10px 0px", lineHeight: "0.5" }}
              >
                {vendorData.displayName}
              </Typography>
            </Box>
            <Box
              sx={{
                // borderRight:"2px solid #F7F7FC", borderLeft:"2px solid #F7F7FC",
                borderRadius: "0px 0px 8px 8px",
                boxShadow:
                  " 0px 1px 1px 0px rgb(0 0 0 / 9%), 0px 0px 1px 0px rgb(0 0 0 / 10%)",
              }}
            >
              <Grid container spacing={0}>
                {[
                  {
                    title: "Business Name",
                    value: vendorData.businessName,
                    action: false,
                  },
                  {
                    title: "Display Name",
                    value: vendorData.displayName,
                    action: false,
                  },
                  {
                    title: "Phone Number",
                    value: vendorData.phoneNumber,
                    action: false,
                  },
                  {
                    title: "Alternate Number",
                    value: vendorData.altNumber,
                    action: false,
                  },
                  {
                    title: "Email",
                    value: vendorData.email,
                    action: false,
                  },
                  {
                    title: "Vendor Code",
                    value: vendorData.vendorCode,
                    action: false,
                  },
                  { title: "Category", value: vendorData.category, action: false },
                  { title: "Address", value: vendorData.address, action: false },
                  {
                    title: "Pincode",
                    value: vendorData.pincode,
                    action: false,
                  },
                  {
                    title: "GST Number",
                    value: vendorData.gstNumber,
                    action: false,
                  },
                  {
                    title: "Aadhar Number",
                    value: vendorData.aadharNumber,
                    action: false,
                  },
                  {
                    title: "Beneficiary Name",
                    value:vendorData.beneficiaryName,
                    action: false,
                  },
                  {
                    title: "Bank Name",
                    value:vendorData.bankName,
                    action: false,
                  },
                  {
                    title: "IFSC Code",
                    value:vendorData.ifscCode,
                    action: false,
                  },
                  {
                    title: "Branch",
                    value:vendorData.branch,
                    action: false,
                  },
                  {
                    title: "Remark",
                    value:vendorData.remark,
                    action: false,
                  },
                  {
                    title: "PAN Card",
                    value:vendorData.panCard,
                    action: false,
                  },
                  {
                    title: "Latitude",
                    value:vendorData.latitude,
                    action: false,
                  },
                  {
                    title: "Longitude",
                    value:vendorData.longitude,
                    action: false,
                  },
                ].map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle2"
                          fontWeight="500"
                          sx={{ margin: "15px 0px 15px 15px" }}
                        >
                          {item.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          fontWeight="500"
                          sx={{ margin: "15px 0px 15px 0px" }}
                        >
                          {item.value}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {item.action && (
                          <>
                            <FontAwesomeIcon
                              icon="fa-solid fa-image"
                              style={{
                                color: "#FF6A1A",
                                height: "20px",
                                margin: "17px 25px 0px 20px",
                              }}
                            />
                            <FontAwesomeIcon
                              icon="fa-solid fa-download"
                              style={{ color: "#FF6A1A", height: "16px" }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {item.title === "Sub Station" ? null : (
                          <Divider light />
                        )}
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <Card
              sx={{
                margin: "10px 0px 0px 0px",
                py: 2,
                px: 2,
                borderRadius: "8px",
                height: "50vh",
              }}
            >
              <Typography
                variant="subtitle1"
                fontWeight="600"
                sx={{ margin: "0px 0px 5px 0px" }}
              >
                Performance
              </Typography>
              <Divider />
            </Card>
            <Card
              sx={{
                margin: "18px 0px 0px 0px",
                py: 3,
                px: 5,
                borderRadius: "8px",
                height: "27.5vh",
              }}
            ></Card>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewVendor     ;
