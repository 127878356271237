import http from "./httpService";
import config from "../config.json";


const tokens = localStorage.getItem("token");
const token=JSON.parse(tokens)?.jwttoken;
  
const head = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      Authorization: `Bearer ${token}`,
    },
  };


  export function packetsOfDAOnDate(date, user){
    return http.get(config.apiUrl+"/roverapi/v1/packetmanuallyforcashofdaondate/"+date+"/"+user ,head);
}
  export function getAllRecordsWithDate(date){
    return http.get(config.apiUrl+"/roverapi/v1/packetmanuallyforcashofdate/"+date ,head);
}

export function cashReconcileUpdate(data){
    return http.post (config.apiUrl+"/roverapi/v1/cashreconcilation/", data,head);
}