import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CurrencyRupee,
  Dashboard,
  DisplaySettings,
  Dock,
  Edit,
  Leaderboard,
  PersonPin,
} from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ColorButton } from "../../styles/button";
import PropTypes from "prop-types";
import { IOSSwitch } from "../../styles/Slider";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  addPermissions,
  getMemberDetails,
  getMemberPermission,
  updateMemberDetails,
  updateMemberPermission,
} from "../../services/memberAndPermissions";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormAutoComplete, FormTextField } from "../../styles/textField";
import { viewAllStationServices } from "../../services/station";
import { viewDeliveryHubIdServices } from "../../services/deliveryHub";
import {
  getAllDesignationNames,
  getAllTeamNamesWithId,
} from "../../services/teamCreation";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { getTeamDetailsById } from "./../../services/teamCreation";
import TeamList from "./../AddTeam/TeamList";
import VendorList from "./../Vendor/VendorList";
import { viewAllVendorServices } from "./../../services/vendor";

const ViewTeamMember = () => {
  const [value, setValue] = React.useState(0);
  const [dataValues, setDataValues] = useState({});
  const [permissionValues, setPermissionValues] = useState({});
  console.log('permissionValues: ', permissionValues);
  const [editPermissions, setEditPermissions] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [stations, setStations] = React.useState([]);
  const [stationSelected, setStationSelected] = useState({});
  const [addingStationPermissions, setAddingStationPermissions] = useState([]);
  console.log("addingStationPermissions: ", addingStationPermissions);
  const [deliveryHub, setDeliveryHub] = useState([]);
  const [deliveryHubSelected, setDeliveryHubSelected] = useState({});
  console.log("deliveryHubSelected: ", deliveryHubSelected);
  const [addingDeliveryHubPermissions, setAddingDeliveryHubPermissions] =
    useState([]);
  console.log("addingDeliveryHubPermissions: ", addingDeliveryHubPermissions);
  const [vendorSelected, setVendorSelected] = useState({});
  const [addingVendorPermissions, setVendorAddingPermissions] = useState([]);
  const [VendorList, setVendorList] = useState([]);
  console.log("addingVendorPermissions: ", addingVendorPermissions);
  const [teamList, setTeamList] = useState([]);
  const [designationList, setDesignationList] = useState([
    "Please Select A Team First",
  ]);
  const [teamSelected, setTeamSelected] = useState({});
  const [designationSelected, setDesignationSelected] = useState([]);

  const [status, setStatus] = useState(false);
  var DesigList;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  let { id } = useParams();

  useEffect(() => {
    let stationValues;
    const getStations = async () => {
      const { data } = await viewAllStationServices();
      console.log("data: ", data);
      if (data) {
        data.unshift({ stationName: "All Stations", stationCode: "All" });
        stationValues = data;

        setStations(data);
      }
    };
    // const getDeliveryHub = async () => {
    //   const { data } = await viewDeliveryHubIdServices();
    //   if (data) {

    //     data.unshift({deliveryHubName:"All Delivery Hubs"})
    //     setDeliveryHub(data);
    //   }
    // };

    getStations();
    // getDeliveryHub();
    // getAllTeamMemberWithId();
  }, []);

  const [role, setRole]= useState();
  const [personPermission, setPersonPermission]= useState();


  useEffect(()=>{
    const getPersonPermission=async()=>{
      const tokens = localStorage.getItem("token");
      const token = JSON.parse(tokens);
      console.log("token: ", token);
   
      // setRole(token.role);
      const { data } = await getMemberPermission(token.userId);
      if(data){
        setPersonPermission(data);
      }
    }
    getPersonPermission();
  },[])

  useEffect(() => {
    const getDesignations = async () => {
      const { data } = await getTeamDetailsById(teamSelected.id);
      console.log("data: ", data);
      if (data) {
        DesigList = data.designationResponse;
        setDesignationList(data.designationResponse);
      }
    };
    getDesignations();
  }, [teamSelected]);

  const updatePermissions = async () => {
    let permissions = {};
    const stationPermissions = addingStationPermissions.map((item) => {
      return {
        stationCode: item.stationCode,
        id: item.id,
        add: item.add,
        edit: item.edit,
        view: item.view,
      };
    });
    const deliveryPermissions = addingDeliveryHubPermissions.map((item) => {
      return {
        deliveryHubCode: item.deliveryHubCode,
        id: item.id,
        add: item.add,
        edit: item.edit,
        view: item.view,
      };
    });
    const vendorPermissions = addingVendorPermissions.map((item) => {
      console.log(">>>>>>>>>",item);
      return {
        id: item.id,
        vendorId: item.vendorCode?item.vendorCode:item.vendorId ,
        add: item.add,
        edit: item.edit,
        view: item.view,
      };
    });
    console.log("vendorPermissions: ", vendorPermissions);
    console.log("stationPermissions: ", stationPermissions);
    console.log("deliveryPermissions: ", deliveryPermissions);
    permissions.id = permissionValues.id;
    permissions.memberId = id;
    permissions.receivedShipments = operations.receivedShipments;
    permissions.deliveryOperations = operations.deliveryOperations;
    permissions.pickUpOperations = operations.pickUpOperations;
    permissions.viewFinanceData = cash.viewFinanceData;
    permissions.viewDashboard = dashboard.viewDashboard;
    permissions.viewPerformance = performanceMetrics.viewPerformance;
    permissions.allEmp = employeeManagement.employee.all;
    permissions.addEmp = employeeManagement.employee.add;
    permissions.editEmp = employeeManagement.employee.edit;
    permissions.viewEmp = employeeManagement.employee.view;
    permissions.othersEmp = employeeManagement.employee.others;
    permissions.allDA = employeeManagement.deliveryAssociate.all;
    permissions.addDA = employeeManagement.deliveryAssociate.add;
    permissions.editDA = employeeManagement.deliveryAssociate.edit;
    permissions.viewDA = employeeManagement.deliveryAssociate.view;
    permissions.othersDA = employeeManagement.deliveryAssociate.others;
    permissions.allVendor = vendor.vendors.all;
    permissions.addVendor = vendor.vendors.add;
    permissions.editVendor = vendor.vendors.edit;
    permissions.viewVendor = vendor.vendors.view;
    permissions.othersVendor = vendor.vendors.others;
    permissions.teamId = teamSelected.id;
    permissions.designationId = designationSelected.id;
    permissions.station = "";
    permissions.employee = "";
    permissions.deliveryAssociate = "";
    permissions.vendor = "";
    permissions.createdBy = "test";
    permissions.roleVendorRequest = vendorPermissions;
    permissions.roleDeliveryHubRequest = deliveryPermissions;
    permissions.roleStationRequest = stationPermissions;

    setPermissionValues(permissions);

    const { data } = await addPermissions(permissions);
    if (data) {
      setEditPermissions(false);
    }
  };
  const [operations, setOperations] = useState({
    receivedShipments: false,
    deliveryOperations: false,
    pickUpOperations: false,

    deliveryHub: {
      all: false,
      add: false,
      edit: false,
      view: false,
      others: false,
    },
  });
  const [cash, setCash] = useState({
    viewFinanceData: false,
  });
  const [dashboard, setDashboard] = useState({
    viewDashboard: false,
  });
  const [station, setStation] = useState({
    stations: {
      all: false,
      add: false,
      edit: false,
      view: false,
      others: false,
    },
  });
  const [vendor, setVendor] = useState({
    vendors: {
      all: false,
      add: false,
      edit: false,
      view: false,
      others: false,
    },
  });
  const [performanceMetrics, setPerformanceMetrics] = useState({
    viewPerformance: false,
  });
  const [employeeManagement, setEmployeeManagement] = useState({
    employee: {
      all: false,
      add: false,
      edit: false,
      view: false,
      others: false,
    },
    deliveryAssociate: {
      all: false,
      add: false,
      edit: false,
      view: false,
      others: false,
    },
  });
  
  console.log('employeeManagement: ', employeeManagement);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  useEffect(() => {
    var teamLists;

    const getDataPermissions = async () => {
      const { data } = await getMemberPermission(id);

      console.log("data: Permissions", data);
      if (data) {
        setPermissionValues(data);
        operations.receivedShipments = data.receivedShipments;
        operations.deliveryOperations = data.deliveryOperations;
        operations.pickUpOperations = data.pickUpOperations;
        operations.deliveryHub.all = data.allDH;
        operations.deliveryHub.add = data.addDH;
        operations.deliveryHub.edit = data.editDH;
        operations.deliveryHub.view = data.viewDH;
        operations.deliveryHub.others = data.othersDH;
        cash.viewFinanceData = data.viewFinanceData;
        dashboard.viewDashboard = data.viewDashboard;
        performanceMetrics.viewPerformance = data.viewPerformance;
        employeeManagement.employee.all = data.allEmp;
        employeeManagement.employee.add = data.addEmp;
        employeeManagement.employee.edit = data.editEmp;
        employeeManagement.employee.view = data.viewEmp;
        employeeManagement.employee.others = data.othersEmp;
        employeeManagement.deliveryAssociate.all = data.allDA;
        employeeManagement.deliveryAssociate.add = data.addDA;
        employeeManagement.deliveryAssociate.edit = data.editDA;
        employeeManagement.deliveryAssociate.view = data.viewDA;
        employeeManagement.deliveryAssociate.others = data.othersDA;

        const stationVal = data.roleStationResponse.map((item) => {
          console.log("item:SSSSSSSSSSSSSSSS ", item);
          const stationN = stationValues.find(
            (val) => item.stationCode === val.stationCode
          );
          console.log("stationN: ", stationN);
          console.log("stationValues: ", stationValues);
          const val = item;
          val.name = stationN?.stationName;
          return val;
        });
        const vendorVal = data.roleVendorResponse.map((item) => {
          console.log("itemMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM: ", item);
          console.log(
            "vendorListTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT: ",
            vendorLists
          );
          const vendorN = vendorLists.find((val) => item.vendorCode === val.vendorId);
          const val = item;
          val.name = vendorN?.displayName;
          return val;
        });
        console.log("vendorVal: ", vendorVal);
        const deliveryVal = data.roleDeliveryHubResponse.map((item) => {
          console.log("deliveryHub: ", deliveryHub);
          console.log("item: ", item);
          const deliveryN = deliveryHubs.find(
            (val) => val.deliveryHubCode === item.deliveryHubCode
          );
          console.log("deliveryN: ", deliveryN);
          const val = item;
          val.name = deliveryN.deliveryHubName;
          return val;
        });

        const teamSel = teamLists.find(
          (item) => data.memberTeamDesignationResponse.teamId === item.id
        );
        console.log("teamList: ", teamList);
        console.log(
          "data.memberTeamDesignationResponse.teamId: ",
          data.memberTeamDesignationResponse.teamId
        );
        console.log("teamSel: ", teamSel);

        const desSel = desList.find(
          (item) => data.memberTeamDesignationResponse.designationId === item.id
        );
        console.log(
          "data.memberTeamDesignationResponse.designationId: ",
          data.memberTeamDesignationResponse.designationId
        );
        console.log("desList: ", desList);
        console.log("desSel: ", desSel);

        console.log("deliveryVal: ", deliveryVal);

        setAddingStationPermissions(stationVal);
        setAddingDeliveryHubPermissions(data.roleDeliveryHubResponse);
        setVendorAddingPermissions(data.roleVendorResponse);
        setTeamSelected(teamSel);
        setDesignationSelected(desSel);
      }
    };
    var stationValues;
    var deliveryHubs;
    var vendorLists;
    const getDeliveryHub = async () => {
      const { data } = await viewDeliveryHubIdServices();
      if (data) {
        data.unshift({
          deliveryHubName: "All Delivery Hubs",
          deliveryHubCode: "All",
        });
        deliveryHubs = data;
        setDeliveryHub(data);
        getData();
      }
    };

    const getStations = async () => {
      const { data } = await viewAllStationServices();
      console.log("data: ", data);
      if (data) {
        data.unshift({ stationName: "All Stations", stationCode: "All" });
        stationValues = data;
        // setStations(data);
        getDeliveryHub();
      }
    };

    const getData = async () => {
      const { data } = await getMemberDetails(id);
      console.log("data: ", data);
      if (data) {
        const dataValues = data;
        console.log("stationValues: ", stationValues);
        const station = stationValues.filter(
          (item) => item.stationCode === dataValues.stationCode
        );
        console.log("station: ", station);
        dataValues.stationCode = station[0];
        console.log("dataValues: ", dataValues);

        setDataValues(dataValues);
        getDataPermissions();
      }
    };
    const getAllVendorList = async () => {
      const { data } = await viewAllVendorServices();
      if (data) {
        vendorLists = data;
        setVendorList(data);
      }
    };
    var desList;
    const getAllDesignationName = async () => {
      const { data } = await getAllDesignationNames();
      if (data) {
        desList = data;
      }
    };
    const getAllTeamMemberWithId = async () => {
      const { data } = await getAllTeamNamesWithId();
      if (data) {
        console.log("data of Team List: ", data);
        teamLists = data;
        setTeamList(data);
        getStations();
      }
    };
    getAllVendorList();
    getAllDesignationName();
    getAllTeamMemberWithId();
  }, []);

  console.log("Delivery HUbsssssss", deliveryHub);

  const cancelChangePermissions = () => {
    const data = { ...permissionValues };
    operations.receivedShipments = data.receivedShipments;
    operations.deliveryOperations = data.deliveryOperations;
    operations.pickUpOperations = data.pickUpOperations;
    operations.deliveryHub.all = data.allDH;
    operations.deliveryHub.add = data.addDH;
    operations.deliveryHub.edit = data.editDH;
    operations.deliveryHub.view = data.viewDH;
    operations.deliveryHub.others = data.othersDH;
    cash.viewFinanceData = data.viewFinanceData;
    dashboard.viewDashboard = data.viewDashboard;
    performanceMetrics.viewPerformance = data.viewPerformance;
    station.stations.all = data.allStation;
    station.stations.add = data.addStation;
    station.stations.edit = data.editStation;
    station.stations.view = data.viewStation;
    station.stations.others = data.othersStation;
    employeeManagement.employee.all = data.allEmp;
    employeeManagement.employee.add = data.addEmp;
    employeeManagement.employee.edit = data.editEmp;
    employeeManagement.employee.view = data.viewEmp;
    employeeManagement.employee.others = data.othersEmp;
    employeeManagement.deliveryAssociate.all = data.allDA;
    employeeManagement.deliveryAssociate.add = data.addDA;
    employeeManagement.deliveryAssociate.edit = data.editDA;
    employeeManagement.deliveryAssociate.view = data.viewDA;
    employeeManagement.deliveryAssociate.others = data.othersDA;
    vendor.vendors.all = data.allVendor;
    vendor.vendors.add = data.addVendor;
    vendor.vendors.edit = data.editVendor;
    vendor.vendors.view = data.viewVendor;
    vendor.vendors.others = data.othersVendor;
  };

  const validationSchema = yup.object({
    username: yup.string().required(" UserName is required"),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    phone: yup.number().required("Phone is required"),
    gender: yup.string().required("City is required"),

    // password: yup
    //   .string('Enter your password')
    //   .min(8, 'Password should be of minimum 8 characters length')
    //   .required('Password is required'),
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  

  const updateMemberDetailsFunction = async (dataValues) => {
    let formValues = dataValues;
    delete formValues.memberId;
    formValues.stationCode = dataValues?.stationCode?.stationCode?dataValues?.stationCode?.stationCode:"";
    console.log("formValues: ", formValues);

    const { data } = await updateMemberDetails(id, formValues);
    if (data) {
      setDataValues(data);
      window.location.reload();
      // handleClose();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: dataValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values: ", values);
      updateMemberDetailsFunction(values);
    },
  });

  console.log("teamSelected   zdfdfsdfsdffs", teamSelected);

  return (
    <>
      <div style={{ margin: "8px 15px 15px 15px" }}>
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ margin: "15px 0px 0px 18px" }}
        >
          Team Member Name
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Card
              sx={{
                margin: "10px 0px 0px 0px",
                py: 2,
                px: 2,
                borderRadius: "8px",
                minHeight: "80vh",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#F7F7FC",
                  borderRadius: "8px 8px 0px 0px",
                  boxShadow: "none",
                }}
                px="14px"
                py="10px"
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Typography
                        variant="subtitle1"
                        fontWeight="600"
                        sx={{ margin: "0px 0px 0px 0px", fontSize: "20px" }}
                      >
                        {dataValues.firstName + " " + dataValues.lastName}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontWeight="500"
                        sx={{ margin: "0px 0px 10px 0px", lineHeight: "0.5" }}
                      >
                        {dataValues.username}
                      </Typography>
                    </div>
                  </div>

                  {personPermission?.editEmp&&<Edit sx={{ marginTop: 1.5 }} onClick={handleClickOpen} />}
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Edit Member Details</DialogTitle>
                    <DialogContent>
                      <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={1}>
                          {[
                            { title: "Username", name: "username", size: 6 },
                            { title: "Password", name: "password", size: 6 },
                            { title: "First Name", name: "firstName", size: 6 },
                            { title: "Last Name", name: "lastName", size: 6 },
                            {
                              title: "Station Code",
                              name: "stationCode",
                              size: 6,
                              options: stations,
                            },
                            { title: "Phone", name: "phone", size: 6 },
                            {
                              title: "Alternate Phone",
                              name: "altPhone",
                              size: 6,
                            },
                            { title: "Email", name: "email", size: 6 },
                            {
                              title: "Gender",
                              name: "gender",
                              size: 6,
                              options: ["Male", "Female"],
                            },
                          ].map((item) => {
                            return (
                              <>
                                {item.options ? (null) : (
                                  <Grid item xs={item.size}>
                                    <Typography
                                      mt={1}
                                      mb={1}
                                      variant="subtitle2"
                                      fontWeight="bold"
                                    >
                                      {item.title}
                                    </Typography>
                                    <FormTextField
                                      size="small"
                                      fullWidth
                                      id={item.name}
                                      placeholder={item.title}
                                      variant="outlined"
                                      type="text"
                                      value={formik.values[item.name]}
                                      name={item.name}
                                      onChange={formik.handleChange}
                                      error={
                                        formik.touched[item.name] &&
                                        Boolean(formik.errors[item.name])
                                      }
                                      helperText={
                                        formik.touched[item.name] &&
                                        formik.errors[item.name]
                                      }
                                      //   value={values.firstName}
                                      //   onChange={(e) => handleChange(e, "firstName")}
                                    />

                                    {/* {errors.firstName ? (
                    <Typography variant="caption" sx={{ color: "#FF0000" }}>
                      {errors.firstName}
                    </Typography>
                  ) : null} */}
                                  </Grid>
                                )}
                              </>
                            );
                          })}
                          <Grid item xs={6}>
                            <Typography
                              mt={1}
                              mb={1}
                              variant="subtitle2"
                              fontWeight="bold"
                            >
                              Status
                            </Typography>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  sx={{ m: 1 }}
                                  checked={status}
                                  onChange={() => {
                                    setStatus(!status);
                                  }}
                                />
                              }
                              label={
                                status ? (
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="600"
                                    sx={{
                                      margin: "0px 0px 0px 0px",
                                      fontSize: "16px",
                                      color: "green",
                                    }}
                                  >
                                    Active
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="600"
                                    sx={{
                                      margin: "0px 0px 0px 0px",
                                      fontSize: "16px",
                                      color: "red",
                                    }}
                                  >
                                    Inactive{" "}
                                  </Typography>
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                        <Box
                          sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        >
                          <Box sx={{ flex: "1 1 auto" }} />

                          <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <ColorButton
                              type="submit"
                              sx={{ marginTop: 0 }}
                              variant="contained"
                            >
                              Update
                            </ColorButton>
                          </DialogActions>
                        </Box>
                      </form>
                    </DialogContent>
                  </Dialog>
                </Box>
              </Box>
              <Box
                sx={{
                  // borderRight:"2px solid #F7F7FC", borderLeft:"2px solid #F7F7FC",
                  borderRadius: "0px 0px 8px 8px",
                  boxShadow:
                    " 0px 1px 1px 0px rgb(0 0 0 / 9%), 0px 0px 1px 0px rgb(0 0 0 / 10%)",
                }}
              >
                <Grid container spacing={0}>
                  {[
                    {
                      title: "Username",
                      value: dataValues.username,
                      action: false,
                    },
                    {
                      title: "First Name",
                      value: dataValues.firstName,
                      action: false,
                    },
                    {
                      title: "Last Name",
                      value: dataValues.lastName,
                      action: false,
                    },
                    {
                      title: "Station Code",
                      value: dataValues.stationCode?.stationCode,
                      action: false,
                    },
                    {
                      title: "Phone Number",
                      value: dataValues.phone,
                      action: false,
                    },
                    {
                      title: "Alternate Number",
                      value: dataValues.altPhone,
                      action: false,
                    },
                    { title: "Email", value: dataValues.email, action: false },
                    {
                      title: "Gender",
                      value: dataValues.gender,
                      action: false,
                    },
                    //   { title: "Station Id", value: DAData.stationCode, action: false },
                    //   { title: "Delivery Hub", value: DAData.deliveryHub, action: false },
                    //   { title: "Vehicle Type", value: DAData.vehicleType, action: false },
                    //   { title: "Vehicle Registration Number", value: DAData.vehicleRegister, action: false },
                    //   { title: "Vehicle Registration Number", value: DAData.vehicleRegister, action: false },
                  ].map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Grid item xs={4}>
                          <Typography
                            variant="subtitle2"
                            fontWeight="500"
                            sx={{ margin: "15px 0px 15px 15px" }}
                          >
                            {item.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="subtitle2"
                            fontWeight="500"
                            sx={{ margin: "15px 0px 15px 0px" }}
                          >
                            {item.value}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          {item.action && (
                            <>
                              <FontAwesomeIcon
                                icon="fa-solid fa-image"
                                style={{
                                  color: "#FF6A1A",
                                  height: "20px",
                                  margin: "17px 25px 0px 20px",
                                }}
                              />
                              <FontAwesomeIcon
                                icon="fa-solid fa-download"
                                style={{ color: "#FF6A1A", height: "16px" }}
                              />
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          {item.title === "Sub Station" ? null : (
                            <Divider light />
                          )}
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Stack>
              <Card
                sx={{
                  margin: "10px 0px 0px 0px",
                  py: 2,
                  px: 2,
                  borderRadius: "8px",
                  height: "50vh",
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontWeight="600"
                  sx={{ margin: "0px 0px 5px 0px" }}
                >
                  Performance
                </Typography>
                <Divider />
              </Card>
              <Card
                sx={{
                  margin: "18px 0px 0px 0px",
                  py: 3,
                  px: 5,
                  borderRadius: "8px",
                  height: "27.5vh",
                }}
              ></Card>
            </Stack>
          </Grid>
        </Grid>
        <Card
          sx={{
            margin: "10px 0px 0px 0px",
            py: 2,
            px: 2,
            borderRadius: "8px",
            
            // minHeight: "50vh",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#F7F7FC",
              borderRadius: "8px 8px 0px 0px",
              boxShadow: "none",
            }}
            px="14px"
            py="10px"
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography
                    variant="subtitle1"
                    fontWeight="600"
                    sx={{ margin: "0px 0px 0px 0px", fontSize: "20px" }}
                  >
                    Team
                  </Typography>
                </div>
              </div>

             {personPermission?.editEmp&& <Edit
                sx={{ marginTop: 1.5 }}
                onClick={() => setEditPermissions(!editPermissions)}
              />}
            </Box>
          </Box>
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                  Team Name
                </Typography>
                <Autocomplete
                  // disablePortal
                  disableClearable
                  options={teamList}
                  getOptionLabel={(option) => option.name}
                  focused
                  onChange={(event, newValue) => {
                    setTeamSelected(newValue);
                  }}
                  value={teamSelected}
                  // inputValue={teamSelected}
                  disabled={!editPermissions}

                  size="small"
                  renderInput={(params) => (
                    <FormAutoComplete
                      {...params}
                      focused
                      placeholder="Team Name"
                      variant="outlined"
                      type="text"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                  Designation Name
                </Typography>
                <Autocomplete
                  // disablePortal
                  disableClearable
                  options={designationList}
                  getOptionLabel={(option) => option.designationName}
                  focused
                  onChange={(event, newValue) => {
                    setDesignationSelected(newValue);
                  }}
                  value={designationSelected}
                  disabled={!editPermissions}
                  size="small"
                  renderInput={(params) => (
                    <FormAutoComplete
                      {...params}
                      focused
                      placeholder="Designation Name"
                      variant="outlined"
                      type="text"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </>
       
          <Box
            sx={{
              backgroundColor: "#F7F7FC",
              borderRadius: "8px 8px 0px 0px",
              boxShadow: "none"
              , marginTop:"15px"
            }}
            px="14px"
            py="10px"
          >
            <Box sx={{ display: "flex", justifyContent: "space-between"}}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography
                    variant="subtitle1"
                    fontWeight="600"
                    sx={{ margin: "0px 0px 0px 0px", fontSize: "20px" }}
                  >
                    Permissions
                  </Typography>
                </div>
              </div>

             
            </Box>
          </Box>
          <>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="icon label tabs example"
            >
              <Tab icon={<DisplaySettings />} label="OPERATIONS" />
              <Tab icon={<CurrencyRupee />} label="CASH" />
              <Tab icon={<Dashboard />} label="DASHBOARD" />
              <Tab icon={<Dock />} label="STATION" />
              <Tab icon={<Dock />} label="DELIVERY HUB" />
              <Tab icon={<Leaderboard />} label="PERFORMANCE" />
              <Tab icon={<Leaderboard />} label="VENDORS" />
              <Tab icon={<PersonPin />} label="EMPLOYEE" />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Grid container spacing={2}>
                {[
                  {
                    title: "Received Shipment",
                    value: "receivedShipments",
                    extra: false,
                  },
                  {
                    title: "Delivery Operations",
                    value: "deliveryOperations",
                    extra: false,
                  },
                  {
                    title: "Pick Up Operations",
                    value: "pickUpOperations",
                    extra: false,
                  },
                ].map((item, i) => (
                  <React.Fragment key={i}>
                    <Grid item xs={4}>
                      {item.title}
                    </Grid>
                    {
                      !item.extra ? (
                        <Grid item xs={6}>
                          <FormControlLabel
                            key={i}
                            control={
                              <IOSSwitch
                                disabled={!editPermissions}
                                sx={{ m: 1 }}
                                checked={operations[item.value]}
                                name={item.value}
                                label={null}
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                                onChange={() => {
                                  const ops = { ...operations };

                                  ops[item.value] = !ops[item.value];
                                  setOperations(ops);
                                }}
                              />
                            }
                          />
                        </Grid>
                      ) : (
                        <>
                          {[
                            
                            {
                              name: "Add",
                              value: "add",
                              onChange: () => {
                                const operat = { ...operations };
                                operat[item.value].add =
                                  !operat[item.value].add;
                                setOperations(operat);
                              },
                            },
                            {
                              name: "Edit",
                              value: "edit",
                              onChange: () => {
                                const operat = { ...operations };
                                operat[item.value].edit =
                                  !operat[item.value].edit;
                                setOperations(operat);
                              },
                            },
                            {
                              name: "View",
                              value: "view",
                              onChange: () => {
                                const operat = { ...operations };
                                operat[item.value].view =
                                  !operat[item.value].view;
                                setOperations(operat);
                              },
                            },
                            
                          ].map((val) => (
                            <Grid item xs={1.5}>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    disabled={!editPermissions}
                                    sx={{ m: 1 }}
                                    checked={operations[item.value][val.value]}
                                    onChange={val.onChange}
                                  />
                                }
                                label={
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="400"
                                    sx={{
                                      margin: "0px 0px 0px 0px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {val.name}
                                  </Typography>
                                }
                              />
                            </Grid>
                          ))}
                        </>
                      )
                      //   <>
                      // <Grid item xs={1.5}>Hello</Grid>
                      // <Grid item xs={1.5}>Hello</Grid>
                      // <Grid item xs={1.5}>Hello</Grid>
                      // <Grid item xs={1.5}>Hello</Grid>
                      //   </>
                    }
                  </React.Fragment>
                ))}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container spacing={2}>
                {[
                  {
                    title: "View Finance Data",
                    value: "viewFinanceData",
                    extra: false,
                  },
                ].map((item, i) => (
                  <React.Fragment key={i}>
                    <Grid item xs={4}>
                      {item.title}
                    </Grid>
                    {!item.extra ? (
                      <Grid item xs={6}>
                        <FormControlLabel
                          key={i}
                          control={
                            <IOSSwitch
                              disabled={!editPermissions}
                              sx={{ m: 1 }}
                              checked={cash[item.value]}
                              name={item.value}
                              label={null}
                              inputProps={{
                                "aria-label": "controlled",
                              }}
                              onChange={() => {
                                const ops = { ...cash };

                                ops[item.value] = !ops[item.value];
                                setCash(ops);
                              }}
                            />
                          }
                        />
                      </Grid>
                    ) : (
                      <>
                        {[
                          {
                            name: "All",
                            value: "all",
                            onChange: () => {
                              const operat = { ...cash };
                              operat[item.value].all = !operat[item.value].all;
                              operat[item.value].add = operat[item.value].all;
                              operat[item.value].edit = operat[item.value].all;
                              operat[item.value].view = operat[item.value].all;
                              setCash(operat);
                            },
                          },
                          {
                            name: "Add",
                            value: "add",
                            onChange: () => {
                              const operat = { ...cash };
                              operat[item.value].add = !operat[item.value].add;
                              setCash(operat);
                            },
                          },
                          {
                            name: "Edit",
                            value: "edit",
                            onChange: () => {
                              const operat = { ...cash };
                              operat[item.value].edit =
                                !operat[item.value].edit;
                              setCash(operat);
                            },
                          },
                          {
                            name: "View",
                            value: "view",
                            onChange: () => {
                              const operat = { ...cash };
                              operat[item.value].view =
                                !operat[item.value].view;
                              setCash(operat);
                            },
                          },
                          {
                            name: "Other",
                            value: "others",
                            onChange: () => {
                              const operat = { ...cash };
                              operat[item.value].others =
                                !operat[item.value].others;
                              setCash(operat);
                            },
                          },
                        ].map((val) => (
                          <Grid item xs={1.5}>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  disabled={!editPermissions}
                                  sx={{ m: 1 }}
                                  checked={cash[item.value][val.value]}
                                  onChange={val.onChange}
                                />
                              }
                              label={
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="400"
                                  sx={{
                                    margin: "0px 0px 0px 0px",
                                    fontSize: "16px",
                                  }}
                                >
                                  {val.name}
                                </Typography>
                              }
                            />
                          </Grid>
                        ))}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid container spacing={2}>
                {[
                  {
                    title: "View Dashboard",
                    value: "viewDashboard",
                    extra: false,
                  },
                ].map((item, i) => (
                  <React.Fragment key={i}>
                    <Grid item xs={4}>
                      {item.title}
                    </Grid>
                    {
                      !item.extra ? (
                        <Grid item xs={6}>
                          <FormControlLabel
                            key={i}
                            control={
                              <IOSSwitch
                                disabled={!editPermissions}
                                sx={{ m: 1 }}
                                checked={dashboard[item.value]}
                                name={item.value}
                                label={null}
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                                onChange={() => {
                                  const ops = { ...dashboard };

                                  ops[item.value] = !ops[item.value];
                                  setDashboard(ops);
                                }}
                              />
                            }
                          />
                        </Grid>
                      ) : (
                        <>
                          {[
                            {
                              name: "All",
                              value: "all",
                              onChange: () => {
                                const operat = { ...dashboard };
                                operat[item.value].all =
                                  !operat[item.value].all;

                                setDashboard(operat);
                              },
                            },
                            {
                              name: "Add",
                              value: "add",
                              onChange: () => {
                                const operat = { ...dashboard };
                                operat[item.value].add =
                                  !operat[item.value].add;
                                setDashboard(operat);
                              },
                            },
                            {
                              name: "Edit",
                              value: "edit",
                              onChange: () => {
                                const operat = { ...dashboard };
                                operat[item.value].edit =
                                  !operat[item.value].edit;
                                setDashboard(operat);
                              },
                            },
                            {
                              name: "View",
                              value: "view",
                              onChange: () => {
                                const operat = { ...dashboard };
                                operat[item.value].view =
                                  !operat[item.value].view;
                                setDashboard(operat);
                              },
                            },
                            {
                              name: "Other",
                              value: "others",
                              onChange: () => {
                                const operat = { ...dashboard };
                                operat[item.value].others =
                                  !operat[item.value].others;
                                setDashboard(operat);
                              },
                            },
                          ].map((val) => (
                            <Grid item xs={1.5}>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    disabled={!editPermissions}
                                    sx={{ m: 1 }}
                                    checked={dashboard[item.value][val.value]}
                                    onChange={val.onChange}
                                  />
                                }
                                label={
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="400"
                                    sx={{
                                      margin: "0px 0px 0px 0px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {val.name}
                                  </Typography>
                                }
                              />
                            </Grid>
                          ))}
                        </>
                      )
                      //   <>
                      // <Grid item xs={1.5}>Hello</Grid>
                      // <Grid item xs={1.5}>Hello</Grid>
                      // <Grid item xs={1.5}>Hello</Grid>
                      // <Grid item xs={1.5}>Hello</Grid>
                      //   </>
                    }
                  </React.Fragment>
                ))}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <>
                <div style={{ display: "flex" }}>
                  <>
                    <FormControl sx={{ width: "40vh" }}>
                      <Autocomplete
                        disableClearable
                        options={stations}
                        getOptionLabel={(option) => option.stationName}
                        focused
                        onChange={(event, newValue) => {
                          setStationSelected(newValue);
                        }}
                        size="small"
                        renderInput={(params) => (
                          <FormAutoComplete
                            {...params}
                            focused
                            placeholder="Select Station"
                            variant="outlined"
                            type="text"
                          />
                        )}
                      />
                    </FormControl>
                  </>

                  <ColorButton
                    variant="contained"
                    sx={{
                      marginTop: "0",
                      marginLeft: 1,
                      borderRadius: "15px",
                      padding: 0,
                    }}
                    onClick={() => {
                      const data = [...addingStationPermissions];
                      if (
                        data.find(
                          (item) => item.name === stationSelected.stationName
                        ) ||
                        !stationSelected.stationName
                      ) {
                        // errors();
                        return;
                      }
                      data.push({
                        name: stationSelected.stationName,
                        add: false,
                        edit: false,
                        view: false,
                        stationCode: stationSelected.stationCode,
                        id: "",
                      });
                      setAddingStationPermissions(data);
                      console.log(
                        "addingStationPermissions: ",
                        addingStationPermissions
                      );
                    }}
                  >
                    <AddIcon />
                  </ColorButton>
                </div>
                <Grid container sx={{ marginTop: 1.5 }} spacing={2}>
                  {addingStationPermissions &&
                    addingStationPermissions.map((item, i) => (
                      <React.Fragment sx={{ marginTop: "2em" }}>
                        <Grid item xs={4}>
                          {item.stationCode}
                        </Grid>
                        <>
                          {[
                            {
                              name: "Add",
                              value: "add",
                              onChange: () => {
                                const operat = [...addingStationPermissions];
                                operat[i].add = !operat[i].add;
                                setAddingStationPermissions(operat);
                              },
                            },
                            {
                              name: "Edit",
                              value: "edit",
                              onChange: () => {
                                const operat = [...addingStationPermissions];
                                operat[i].edit = !operat[i].edit;
                                setAddingStationPermissions(operat);
                              },
                            },
                            {
                              name: "View",
                              value: "view",
                              onChange: () => {
                                const operat = [...addingStationPermissions];
                                operat[i].view = !operat[i].view;
                                setAddingStationPermissions(operat);
                              },
                            },
                          ].map((val) => (
                            <Grid item xs={1.5}>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    sx={{ m: 1 }}
                                    checked={
                                      addingStationPermissions[i][val.value]
                                    }
                                    onChange={val.onChange}
                                    disabled={!editPermissions}
                                  />
                                }
                                label={
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="400"
                                    sx={{
                                      margin: "0px 0px 0px 0px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {val.name}
                                  </Typography>
                                }
                              />
                            </Grid>
                          ))}
                          <Grid item xs={1.5}>
                            <DeleteIcon
                              sx={{ marginTop: 1 }}
                              onClick={() => {
                                const data = [...addingStationPermissions];
                                const valu = data.filter(
                                  (val) => val.name !== item.name
                                );
                                setAddingStationPermissions(valu);
                              }}
                            />
                          </Grid>
                        </>
                      </React.Fragment>
                    ))}
                </Grid>
              </>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <>
                <div style={{ display: "flex" }}>
                  <>
                    <FormControl sx={{ width: "40vh" }}>
                      <Autocomplete
                        // disablePortal
                        disableClearable
                        options={deliveryHub}
                        getOptionLabel={(option) => option.deliveryHubName}
                        focused
                        onChange={(event, newValue) => {
                          setDeliveryHubSelected(newValue);
                        }}
                        size="small"
                        renderInput={(params) => (
                          <FormAutoComplete
                            {...params}
                            focused
                            placeholder="Select Delivery Hub"
                            variant="outlined"
                            type="text"
                          />
                        )}
                      />
                    </FormControl>
                  </>

                  <ColorButton
                    variant="contained"
                    sx={{
                      marginTop: "0",
                      marginLeft: 1,
                      borderRadius: "15px",
                      padding: 0,
                    }}
                    onClick={() => {
                      const data = [...addingDeliveryHubPermissions];
                      if (
                        data.find(
                          (item) =>
                            item.name === deliveryHubSelected.deliveryHubName
                        )
                      ) {
                        return;
                      }
                      data.push({
                        name: deliveryHubSelected.deliveryHubName,
                        add: false,
                        edit: false,
                        view: false,
                        deliveryHubCode: deliveryHubSelected.deliveryHubCode,
                        id: "",
                      });
                      setAddingDeliveryHubPermissions(data);
                      console.log(
                        "addingStationPermissions: ",
                        addingDeliveryHubPermissions
                      );
                    }}
                  >
                    <AddIcon />
                  </ColorButton>
                </div>
                <Grid container sx={{ marginTop: 1.5 }} spacing={2}>
                  {addingDeliveryHubPermissions &&
                    addingDeliveryHubPermissions.map((item, i) => (
                      <React.Fragment>
                        <Grid item xs={4} sx={{ marginTop: 1 }}>
                          {item.name}
                        </Grid>
                        <>
                          {[
                            {
                              name: "Add",
                              value: "add",
                              onChange: () => {
                                const operat = [
                                  ...addingDeliveryHubPermissions,
                                ];
                                operat[i].add = !operat[i].add;
                                setAddingDeliveryHubPermissions(operat);
                              },
                            },
                            {
                              name: "Edit",
                              value: "edit",
                              onChange: () => {
                                const operat = [
                                  ...addingDeliveryHubPermissions,
                                ];
                                operat[i].edit = !operat[i].edit;
                                setAddingDeliveryHubPermissions(operat);
                              },
                            },
                            {
                              name: "View",
                              value: "view",
                              onChange: () => {
                                const operat = [
                                  ...addingDeliveryHubPermissions,
                                ];
                                operat[i].view = !operat[i].view;
                                setAddingDeliveryHubPermissions(operat);
                              },
                            },
                          ].map((val) => (
                            <Grid item xs={1.5}>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    sx={{ m: 1 }}
                                    checked={
                                      addingDeliveryHubPermissions[i][val.value]
                                    }
                                    onChange={val.onChange}
                                    disabled={!editPermissions}
                                  />
                                }
                                label={
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="400"
                                    sx={{
                                      margin: "0px 0px 0px 0px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {val.name}
                                  </Typography>
                                }
                              />
                            </Grid>
                          ))}
                          <Grid item xs={1.5}>
                            <DeleteIcon
                              sx={{ marginTop: 1 }}
                              onClick={() => {
                                const data = [...addingDeliveryHubPermissions];
                                const valu = data.filter(
                                  (val) => val.name !== item.name
                                );
                                setAddingDeliveryHubPermissions(valu);
                              }}
                            />
                          </Grid>
                        </>
                      </React.Fragment>
                    ))}
                </Grid>
              </>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Grid container spacing={2}>
                {[
                  {
                    title: "View Performance",
                    value: "viewPerformance",
                    extra: false,
                  },
                ].map((item, i) => (
                  <React.Fragment key={i}>
                    <Grid item xs={4}>
                      {item.title}
                    </Grid>
                    {
                      !item.extra ? (
                        <Grid item xs={6}>
                          <FormControlLabel
                            key={i}
                            control={
                              <IOSSwitch
                                disabled={!editPermissions}
                                sx={{ m: 1 }}
                                checked={performanceMetrics[item.value]}
                                name={item.value}
                                label={null}
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                                onChange={() => {
                                  const ops = { ...performanceMetrics };

                                  ops[item.value] = !ops[item.value];
                                  setPerformanceMetrics(ops);
                                }}
                              />
                            }
                          />
                        </Grid>
                      ) : (
                        <>
                          {[
                            {
                              name: "All",
                              value: "all",
                              onChange: () => {
                                const operat = {
                                  ...performanceMetrics,
                                };
                                operat[item.value].all =
                                  !operat[item.value].all;

                                setPerformanceMetrics(operat);
                              },
                            },
                            {
                              name: "Add",
                              value: "add",
                              onChange: () => {
                                const operat = {
                                  ...performanceMetrics,
                                };
                                operat[item.value].add =
                                  !operat[item.value].add;
                                setPerformanceMetrics(operat);
                              },
                            },
                            {
                              name: "Edit",
                              value: "edit",
                              onChange: () => {
                                const operat = {
                                  ...performanceMetrics,
                                };
                                operat[item.value].edit =
                                  !operat[item.value].edit;
                                setPerformanceMetrics(operat);
                              },
                            },
                            {
                              name: "View",
                              value: "view",
                              onChange: () => {
                                const operat = {
                                  ...performanceMetrics,
                                };
                                operat[item.value].view =
                                  !operat[item.value].view;
                                setPerformanceMetrics(operat);
                              },
                            },
                            {
                              name: "Other",
                              value: "others",
                              onChange: () => {
                                const operat = {
                                  ...performanceMetrics,
                                };
                                operat[item.value].others =
                                  !operat[item.value].others;
                                setPerformanceMetrics(operat);
                              },
                            },
                          ].map((val) => (
                            <Grid item xs={1.5}>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    disabled={!editPermissions}
                                    sx={{ m: 1 }}
                                    checked={
                                      performanceMetrics[item.value][val.value]
                                    }
                                    onChange={val.onChange}
                                  />
                                }
                                label={
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="400"
                                    sx={{
                                      margin: "0px 0px 0px 0px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {val.name}
                                  </Typography>
                                }
                              />
                            </Grid>
                          ))}
                        </>
                      )
                      //   <>
                      // <Grid item xs={1.5}>Hello</Grid>
                      // <Grid item xs={1.5}>Hello</Grid>
                      // <Grid item xs={1.5}>Hello</Grid>
                      // <Grid item xs={1.5}>Hello</Grid>
                      //   </>
                    }
                  </React.Fragment>
                ))}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={6}>
              <>
                <div style={{ display: "flex" }}>
                  <>
                    <FormControl sx={{ width: "40vh" }}>
                      <Autocomplete
                        // disablePortal
                        disableClearable
                        options={VendorList}
                        getOptionLabel={(option) => option.displayName}
                        focused
                        onChange={(event, newValue) => {
                          setVendorSelected(newValue);
                        }}
                        size="small"
                        renderInput={(params) => (
                          <FormAutoComplete
                            {...params}
                            focused
                            placeholder="Select Vendor"
                            variant="outlined"
                            type="text"
                          />
                        )}
                      />
                    </FormControl>
                  </>

                  <ColorButton
                    variant="contained"
                    sx={{
                      marginTop: "0",
                      marginLeft: 1,
                      borderRadius: "15px",
                      padding: 0,
                    }}
                    onClick={() => {
                      console.log("vendorSelected: ", vendorSelected);
                      const data = [...addingVendorPermissions];
                      if (
                        data.find(
                          (item) => item.vendorCode?item.vendorCode === vendorSelected.vendorCode:item.vendorId === vendorSelected.vendorCode
                        )
                      ) {
                        return;
                      }
                      data.push({
                        name: vendorSelected.displayName,
                        add: false,
                        edit: false,
                        view: false,
                        vendorCode: vendorSelected.vendorCode,
                        id: "",
                      });
                      setVendorAddingPermissions(data);
                      console.log(
                        "addingVendorPermissions: ",
                        addingVendorPermissions
                      );
                    }}
                  >
                    <AddIcon />
                  </ColorButton>
                </div>
                <Grid container sx={{ marginTop: 1.5 }} spacing={2}>
                  {addingVendorPermissions &&
                    addingVendorPermissions.map((item, i) => (
                      <React.Fragment>
                        <Grid item xs={4} sx={{ marginTop: 1 }}>
                          {item.vendorId?item.vendorId:item.vendorCode}
                        </Grid>
                        <>
                          {[
                            {
                              name: "Add",
                              value: "add",
                              onChange: () => {
                                const operat = [...addingVendorPermissions];
                                operat[i].add = !operat[i].add;
                                setVendorAddingPermissions(operat);
                              },
                            },
                            {
                              name: "Edit",
                              value: "edit",
                              onChange: () => {
                                const operat = [...addingVendorPermissions];
                                operat[i].edit = !operat[i].edit;
                                setVendorAddingPermissions(operat);
                              },
                            },
                            {
                              name: "View",
                              value: "view",
                              onChange: () => {
                                const operat = [...addingVendorPermissions];
                                operat[i].view = !operat[i].view;
                                setVendorAddingPermissions(operat);
                              },
                            },
                          ].map((val) => (
                            <Grid item xs={1.5}>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    sx={{ m: 1 }}
                                    checked={
                                      addingVendorPermissions[i][val.value]
                                    }
                                    onChange={val.onChange}
                                    disabled={!editPermissions}
                                  />
                                }
                                label={
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="400"
                                    sx={{
                                      margin: "0px 0px 0px 0px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {val.name}
                                  </Typography>
                                }
                              />
                            </Grid>
                          ))}
                          <Grid item xs={1.5}>
                            <DeleteIcon
                              sx={{ marginTop: 1 }}
                              onClick={() => {console.log(">>>Index>>", i);
                                const data = [...addingVendorPermissions];
                                console.log('addingVendorPermissions: ', addingVendorPermissions);
                                console.log('data: ', data);
                                const valu = addingVendorPermissions.filter(item=>item.vendorCode?item.vendorCode!==addingVendorPermissions[i].vendorCode:item.vendorId!==addingVendorPermissions[i].vendorId);
                                console.log('valu: ', valu);
                                setVendorAddingPermissions(valu);
                              }}
                            />
                          </Grid>
                        </>
                      </React.Fragment>
                    ))}
                </Grid>
              </>
            </TabPanel>
            <TabPanel value={value} index={7}>
              <Grid container spacing={2}>
                {[
                  { title: "Employee", value: "employee", extra: true },
                  {
                    title: "Delivery Associate",
                    value: "deliveryAssociate",
                    extra: true,
                  },
                ].map((item, i) => (
                  <React.Fragment key={i}>
                    <Grid item xs={4}>
                      {item.title}
                    </Grid>
                    {
                      !item.extra ? (
                        <Grid item xs={6}>
                          <FormControlLabel
                            key={i}
                            control={
                              <IOSSwitch
                                disabled={!editPermissions}
                                sx={{ m: 1 }}
                                checked={employeeManagement[item.value]}
                                name={item.value}
                                label={null}
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                                onChange={() => {
                                  const ops = { ...employeeManagement };

                                  ops[item.value] = !ops[item.value];
                                  setEmployeeManagement(ops);
                                }}
                              />
                            }
                          />
                        </Grid>
                      ) : (
                        <>
                          {[
                            
                            {
                              name: "Add",
                              value: "add",
                              onChange: () => {
                                const operat = {
                                  ...employeeManagement,
                                };
                                operat[item.value].add =
                                  !operat[item.value].add;
                                setEmployeeManagement(operat);
                              },
                            },
                            {
                              name: "Edit",
                              value: "edit",
                              onChange: () => {
                                const operat = {
                                  ...employeeManagement,
                                };
                                operat[item.value].edit =
                                  !operat[item.value].edit;
                                setEmployeeManagement(operat);
                              },
                            },
                            {
                              name: "View",
                              value: "view",
                              onChange: () => {
                                const operat = {
                                  ...employeeManagement,
                                };
                                operat[item.value].view =
                                  !operat[item.value].view;
                                setEmployeeManagement(operat);
                              },
                            },
                           
                          ].map((val) => (
                            <Grid item xs={1.5}>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    disabled={!editPermissions}
                                    sx={{ m: 1 }}
                                    checked={
                                      employeeManagement[item.value][val.value]
                                    }
                                    onChange={val.onChange}
                                  />
                                }
                                label={
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="400"
                                    sx={{
                                      margin: "0px 0px 0px 0px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {val.name}
                                  </Typography>
                                }
                              />
                            </Grid>
                          ))}
                        </>
                      )
                      //   <>
                      // <Grid item xs={1.5}>Hello</Grid>
                      // <Grid item xs={1.5}>Hello</Grid>
                      // <Grid item xs={1.5}>Hello</Grid>
                      // <Grid item xs={1.5}>Hello</Grid>
                      //   </>
                    }
                  </React.Fragment>
                ))}
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              ></Box>
            </TabPanel>
          </>
          {editPermissions && (
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
              }}
            >
              <ColorButton
                sx={{ marginTop: 2, backgroundColor: "gray", marginRight: 2 }}
                variant="contained"
                onClick={() => {
                  cancelChangePermissions();
                  setEditPermissions(false);
                }}
              >
                Cancel
              </ColorButton>
              <ColorButton
                sx={{ marginTop: 2 }}
                variant="contained"
                onClick={() => updatePermissions()}
              >
                Update
              </ColorButton>
            </Box>
          )}
        </Card>
      </div>
    </>
  );
};

export default ViewTeamMember;
