import http from "./httpService";
import config from "../config.json";

const tokens = localStorage.getItem("token");
const username = localStorage.getItem("username");
const token = JSON.parse(tokens)?.jwttoken;

const head = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "access-control-allow-origin": "*",
    Authorization: `Bearer ${token}`,
  },
};

export function addPacketsManually(data) {
  return http.post(config.apiUrl + "/roverapi/v1/packetmanually", data, head);
}
export function assignRunsheet(data) {
  return http.post(config.apiUrl + "/roverapi/v1/runsheetassign", data, head);
}
export function packetDelivered(data) {
  return http.post(config.apiUrl + "/roverapi/v1/deliveryboydelivered", data, head);
}
export function updateDevOpsInStation(data) {
  return http.post(config.apiUrl + "/roverapi/v1/managerconformationpacketstatus", data, head);
}
export function getAllPackets() {
  return http.get(config.apiUrl + "/roverapi/v1/packetmanually", head);
}
export function getAllRunsheet() {
  return http.get(config.apiUrl + "/roverapi/v1/runsheetAll", head);
}
export function getTodayRunsheet() {
  return http.get(config.apiUrl + "/roverapi/v1/todayrunsheetcount", head);
}
export function getDARunsheet(data) {
  return http.get(config.apiUrl + "/roverapi/v1/userrunsheetcount/"+data, head);
}
export function getRunsheetDetails(data) {
  return http.get(config.apiUrl + "/roverapi/v1/runsheetdetails/"+data, head);
}
export function getStationRunsheetDetails(data) {
  return http.get(config.apiUrl + "/roverapi/v1/todaystationrunsheetcount/"+data, head);
}
export function getStationUnassignedPackets(data) {
  return http.get(config.apiUrl + "/roverapi/v1/stationrunsheetAll/"+data, head);
}
export function getStationPacketDetails(data) {
  return http.get(config.apiUrl + "/roverapi/v1/packetmanuallystation/"+data, head);
}
export function getDeliveryHubRunsheetDetails(data) {
  return http.get(config.apiUrl + "/roverapi/v1/todaydeliveryhubrunsheetcount/"+data, head);
}
export function getDeliveryHubUnassignedPackets(data) {
  return http.get(config.apiUrl + "/roverapi/v1/deliveryhubrunsheetAll/"+data, head);
}
export function getDeliveryHubPacketDetails(data) {
  return http.get(config.apiUrl + "/roverapi/v1/packetmanuallydeliveryhub/"+data, head);
}
export function getPacketsById(data) {
  return http.get(config.apiUrl + "/roverapi/v1/packetmanually/"+data, head);
}
export function getDeliveryBoyPackets() {
  return http.get(config.apiUrl + "/roverapi/v1/delvieryboyrunsheetAll/"+username, head);
}

export function getPacketHistory(data) {
  return http.get(config.apiUrl + "/roverapi/v1/packethistory/"+data, head);
}

export function getRunsheetPacketInformation(runsheet, rover) {
  return http.get(config.apiUrl + "/roverapi/v1/runsheetpacketdetails/"+runsheet+"/"+rover, head);
}
export function cancelDelivery(data) {
  console.log('data: ', data);
  return http.post(config.apiUrl + "/roverapi/v1/deliveryboycancelreason",data, head);
}
export function unableToDeliver(data) {
  console.log('data: ', data);
  return http.post(config.apiUrl + "/roverapi/v1/deliveryboycallstatus",data, head);
}

export function getPickupParticularStationData(station,date){
  return http.get(config.apiUrl+"/roverapi/v1/datestationrunsheetcount/"+date+"/"+station ,head);
}
export function getPickupParticularDHData(station,date){
  return http.get(config.apiUrl+"/roverapi/v1/datedeliveryhubrunsheetcount/"+date+"/"+station ,head);
}
export function getPacketImageDelivery(data){
  return http.get(config.apiUrl+"/roverapi/v1/dauploadedpacketimageurl/"+data ,head);
}