import {
  Card,
  Typography,
  Grid,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  BootstrapInput,
  FormTextField,
  FormAutoComplete,
} from "../../styles/textField";

import { ColorButton } from "../../styles/button";
import { Box } from "@mui/material";
import { viewDeliveryHubServices } from "../../services/deliveryHub";
import {
  addDeliveryHubServices,
  updateDeliveryHubServices,
} from "./../../services/deliveryHub";
import { useNavigate, useParams } from "react-router-dom";
import { viewAllStationServices } from "./../../services/station";
import { toast } from "react-toastify";

const AddDeliveryHub = () => {
  const initialValue = {
    deliveryHubName: "",
    deliveryHubCode: "",
    stationCode: "",
    address: "",
    phoneNumber: "",
    locality: "",
    city: "",
    state: "",
    pincode: "",
    latitude: "",
    longitude: "",
  };

  const validationSchema = yup.object({
    stationCode: yup.object().required("Station Code is required"),
    deliveryHubName: yup.string().required("Delivery Hub Name is required"),
    deliveryHubCode: yup.string().required("Delivery Hub Code is required"),
    address: yup.string().required("Address is required"),
    locality: yup.string().required("Locality is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    pincode: yup.number().required("Pincode is required"),
    latitude: yup.number().required("Latitude is required"),
    longitude: yup.number().required("Longitude is required"),

    // password: yup
    //   .string('Enter your password')
    //   .min(8, 'Password should be of minimum 8 characters length')
    //   .required('Password is required'),
  });

  const [stations, setStations] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    let stationValues;
    const getStations = async () => {
      const { data } = await viewAllStationServices();
      stationValues = data;
      console.log("data: ", data);
      if (data) {
        setStations(data);
        if (!id) return;
        getDeliveryHub();
      }
    };
    getStations();
    const getDeliveryHub = async () => {
      const { data } = await viewDeliveryHubServices(id);
      if (data) {
        const values = data;
        console.log("values:Intialllyyy ", values);
        const station = stationValues.find(
          (item) => item.stationCode === values.stationCode
        );
        console.log("stationValues: ", stationValues);

        console.log("station: ", station);
        values.stationCode = station;
        setDefaultValues(values);
        console.log("values: ", values);
      }
    };
  }, []);

  const navigate = useNavigate();
  const postDeliveryHub = async (formValues) => {
    const val = formValues.stationCode.stationCode;
    console.log("val: ", val);
    formValues.stationCode = val;
    console.log("formValues: ", formValues);

    try {
      const { data } = await addDeliveryHubServices(formValues);
      console.log("data: ", data);
      if (data) {
        success();

        navigate(`/rover/user/deliveryHubList/viewDeliveryHub/${data.id}`);
      }
    } catch (ex) {
      console.log("ex: ", ex);
      if (ex) {
        error();
      }
    }

    // const { data } = await addDeliveryHubServices(formValues);
    // console.log("data: ", data);
    // if (data) {
    //   navigate(`/user/deliveryHubList/viewDeliveryHub/${data.id}`);
    // }
  };
  const success = () => {
    toast.success("Data Submitted Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const updateDeliveryHub = async (formValues) => {
    const val = formValues.stationCode.stationCode;
    console.log("val: ", val);
    formValues.stationCode = val;
    console.log("formValues: ", formValues);

    try {
      const { data } = await updateDeliveryHubServices(formValues, id);
      if (data) {
        success();
        navigate(`/rover/user/deliveryHubList/viewDeliveryHub/${data.id}`);
      }
    } catch (ex) {
      console.log("ex: ", ex);
      if (ex) {
        error();
      }
    }

    // const {data}=await updateDeliveryHubServices(formValues,id)
    // if (data) {
    //   navigate(`/user/deliveryHubList/viewDeliveryHub/${data.id}`);
    // }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: id ? defaultValues : initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      if (id) {
        updateDeliveryHub(values);
      } else {
        postDeliveryHub(values);
      }
    },
  });
  console.log(formik.values);
  return (
    <>
      {id ? (
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ margin: "15px 0px 0px 18px" }}
        >
          Edit Delivery Hub Information
        </Typography>
      ) : (
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ margin: "15px 0px 0px 18px" }}
        >
          Delivery Hub Information
        </Typography>
      )}
      <Card
        sx={{ margin: "8px 15px 15px 15px", py: 3, px: 5, borderRadius: "8px" }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            {[
              { title: "Delivery Hub Name", name: "deliveryHubName", size: 4 },
              {
                title: "Delivery Hub ID/Code",
                name: "deliveryHubCode",
                size: 4,
              },
              {
                title: "Station",
                name: "stationCode",
                size: 4,
                options: stations,
              },
              { title: "Phone Number", name: "phoneNumber", size: 6 },
              { title: "Address", name: "address", size: 6 },
              { title: "Locality", name: "locality", size: 6 },
              { title: "City", name: "city", size: 6 },
              { title: "State", name: "state", size: 6 },
              { title: "Pincode", name: "pincode", size: 6 },
              { title: "Latitude", name: "latitude", size: 6 },
              { title: "Longitude", name: "longitude", size: 6 },
            ].map((item) => {
              return (
                <>
                  {item.options ? (
                    <Grid item xs={item.size}>
                      <Typography
                        mt={1}
                        mb={1}
                        variant="subtitle2"
                        fontWeight="bold"
                      >
                        {item.title}
                      </Typography>
                      {/* <Select
                        fullWidth
                        input={<BootstrapInput />}
                        name={item.value}
                        onChange={formik.handleChange}
                        value={formik.values[item.value]}
                      >
                        {item.options.map((opt) => (
                          <MenuItem value={opt}>{opt.stationName}</MenuItem>
                        ))}
                      </Select> */}
                      <Autocomplete
                        // disablePortal
                        disableClearable
                        options={item.options}
                        getOptionLabel={(option) => option.stationName}
                        focused
                        name={item.name}
                        onChange={(event, value) => {
                          formik.setFieldValue(item.name, value);
                        }}
                        // onChange={formik.handleChange}
                        value={
                          formik.values[item.name]
                            ? formik.values[item.name]
                            : null
                        }
                        // fullWidth
                        size="small"
                        renderInput={(params) => (
                          <FormAutoComplete
                            {...params}
                            focused
                            placeholder={item.title}
                            variant="outlined"
                            type="text"
                            error={
                              formik.touched[item.name] &&
                              Boolean(formik.errors[item.name])
                            }
                            helperText={
                              formik.touched[item.name] &&
                              formik.errors[item.name]
                            }
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={item.size}>
                      <Typography
                        mt={1}
                        mb={1}
                        variant="subtitle2"
                        fontWeight="bold"
                      >
                        {item.title}
                      </Typography>
                      <FormTextField
                        size="small"
                        fullWidth
                        id={item.name}
                        placeholder={item.title}
                        variant="outlined"
                        type="text"
                        value={formik.values[item.name]}
                        name={item.name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched[item.name] &&
                          Boolean(formik.errors[item.name])
                        }
                        helperText={
                          formik.touched[item.name] && formik.errors[item.name]
                        }
                        //   value={values.firstName}
                        //   onChange={(e) => handleChange(e, "firstName")}
                      />

                      {/* {errors.firstName ? (
                  <Typography variant="caption" sx={{ color: "#FF0000" }}>
                    {errors.firstName}
                  </Typography>
                ) : null} */}
                    </Grid>
                  )}
                </>
              );
            })}
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ColorButton variant="contained" type="submit">
              Submit
            </ColorButton>
          </Box>
        </form>
      </Card>
    </>
  );
};

export default AddDeliveryHub;
