import { Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import SockJS from "sockjs-client";
import { over } from "stompjs";
import config from "../config.json"

const QuickTrackingDA = () => {

  const tokens=localStorage.getItem("username")

// const station = JSON.parse(tokens).stationCode;  
// console.log('station: ', station);
// console.log('localStorage.getItem("token"): ', localStorage.getItem("token").toString().stationCode);
var stompClient =null;
const [userData, setUserData] = useState({
  username: '',
  receivername: '',
  connected: false,
  message: '',
  total: ''
});

const [privateChats, setPrivateChats] = useState(new Map());  
const [packets, setPackets] = useState(new Map());
const [pickups, setPickups] = useState(new Map());

const [tab,setTab] =useState(tokens);
   
const onPrivateMessage = (payload)=>{
  console.log("PAYYYYY",payload);
  var payloadData = JSON.parse(payload.body);
  console.log('payloadData: ', payloadData);
  if(payloadData){
  setPackets(payloadData)
}
  if(privateChats.get(payloadData.senderName)){
      privateChats.get(payloadData.senderName).push(payloadData);
      setPrivateChats(new Map(privateChats));
  }else{
      let list =[];
      list.push(payloadData);
      privateChats.set(payloadData.senderName,list);
      setPrivateChats(new Map(privateChats));
  }
}

  const connect =()=>{
    let Sock = new SockJS(config.webSocket);
    stompClient = over(Sock);
    stompClient.connect({},onConnected
      // , onError
      );
}
const onPickupMessage = (payload)=>{
  console.log("PAYYYYY",payload);
  var payloadData = JSON.parse(payload.body);
  console.log('payloadData Pickups: ', payloadData);
  if(payloadData){
  setPickups(payloadData)}
  if(privateChats.get(payloadData.senderName)){
      privateChats.get(payloadData.senderName).push(payloadData);
      setPrivateChats(new Map(privateChats));
  }else{
      let list =[];
      list.push(payloadData);
      privateChats.set(payloadData.senderName,list);
      setPrivateChats(new Map(privateChats));
  }
}

 

useEffect(()=>{
  connect()

},[])


const userJoin=()=>{
  var chatMessage = {
    senderName: userData.username,
    status:"JOIN"
  };
  stompClient.send("/app/message", {}, JSON.stringify(chatMessage));
}


const sendPrivateValue=()=>{

  console.log("Helooooooooooooooooooooooooooooooooooooooo");
  if (stompClient) {
    var chatMessage = {
      senderName: "DATA",
      receiverName:tab,
      message: "DATA",
      status:"MESSAGE"
    };
    
    // if(userData.username !== tab){
    //   privateChats.get(tab).push(chatMessage);
    //   setPrivateChats(new Map(privateChats));
    // }
    stompClient.send("/app/daPacketsCount", {}, JSON.stringify(chatMessage), packets);
     stompClient.send("/app/daPickupCount", {}, JSON.stringify(chatMessage));
    // setUserData({...userData,"message": ""});
    
  }
}

// function packets(payload){
//   console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",payload);
// }

console.log('userData: ', userData);
// setInterval(sendPrivateValue, 10000);
// console.log('userData: ', userData);
function  onConnected() {
  setUserData({...userData,"connected": true});
  // stompClient.subscribe('/count/public', onMessageReceived);
  stompClient.subscribe('/user/'+tokens+'/daPacketsCount', onPrivateMessage);
  stompClient.subscribe('/user/'+tokens+'/daPickupCount', onPickupMessage);
  userJoin();
}


console.log("Packets", packets);


  return (
    <>
      <Box sx={{ margin: "55px 0px 0px 0px", padding:"10px" }} >
        <Grid   container spacing={2}>
            {[
                {"val":packets.totalCount, "desc":"Total Deliveries"},
                {"val":packets.pendingCount, "desc":"Remaining Deliveries"},
                {"val":pickups.totalCount, "desc":"Total Pickups"},
                {"val":pickups.pendingCount, "desc":"Pickup's Remaining"}
        ].map((item,i)=>
        (
            <Grid item xs={3} key={i}>
            <Card
              sx={{
                margin: "0px 0px 0px 0px",
                py: 0.5,
                px: 0.5,
                borderRadius: "8px",
              }}
            >
                <Typography
                variant="h6"
                fontWeight="600"
                sx={{color:"#FF6A1A"}}
              >
                {item.val}
              </Typography>
              <Typography
                variant="caption"
                fontWeight="600"
                gutterBottom
              sx={{lineHeight: 0, margin:0, padding:0  }}
           >
             {item.desc}
              </Typography>
              
            </Card>
          </Grid>)
        
        )}
          
        </Grid>
      </Box>
    </>
  );
};

export default QuickTrackingDA;
