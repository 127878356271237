import http from "./httpService";
import config from "../config.json";


const tokens = localStorage.getItem("token");
const token=JSON.parse(tokens)?.jwttoken;

const head = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      Authorization: `Bearer ${token}`,
    },
  };

export function addDAService(data){
      console.log('token: ', token);
    return http.post(config.apiUrl+"/roverapi/v1/deliveryassociate", data,{
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        "access-control-allow-origin": "*",
        Authorization: `Bearer ${token}`,
      }, 
    });
}

export function viewAllDAService(){
    console.log("Here");
    return http.get (config.apiUrl+"/roverapi/v1/deliveryassociate",head);
}

export function viewDAServices(data){
  return http.get (config.apiUrl+"/roverapi/v1/deliveryassociate/"+data,head);
}
export function viewDAServicesStation(data){
  return http.get (config.apiUrl+"/roverapi/v1/stationdeliveryassociate/"+data,head);
}
export function viewDAServicesDeliveryHub(data){
  return http.get (config.apiUrl+"/roverapi/v1/deliveryhubsdeliveryassociate/"+data,head);
}

export function updateDAServices(data,id){
  return http.put (config.apiUrl+"/roverapi/v1/deliveryassociate/"+id,data,head);
}

export function initiateRTV(data){
  return http.post (config.apiUrl+"/roverapi/v1/initiatertsbyda",data,head);
}
export function closeRTV(data){
  return http.post (config.apiUrl+"/roverapi/v1/setstatusrtv",data,head);
}
export function checkDAUsername(data){
  return http.get(config.apiUrl+"/roverapi/v1/usernamecheck/"+data,head);
}