import React from "react";
import {
  Card,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  Dashboard,
  DirectionsBoatFilled,
  LocalShipping,
  Backpack,
  Dock,
  SportsMotorsports,
  CurrencyRupee,
  Equalizer,
  AddBusiness,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

import RoverLogo from "../images/Rover Logo.svg";

const FireNav = styled(List)(({ theme }) => ({
  "& .Mui-selected": {
    backgroundColor: "#FF6A1A !important",
    borderRadius: "8px",
    color: "#fff",
  },
}));
const CollapseNav = styled(List)(({ theme }) => ({
  "& .Mui-selected": {
    backgroundColor: "#F5F5F5 !important",
    borderRadius: "8px",
    color: "#000",
  },
}));

const DAUILeftBarMenu = ({toggle, anchor}) => {
  const [selected, setSelected] = useState("deliveries");
  const [submenu, setSubmenu] = useState("");
  const [role, setRole] = useState("");
  const [permissions, setPermissions] = useState([]);

  return (
    <>
      <img style={{
        height: "5%",
        width:"80%",
        }}src={RoverLogo} alt="avatar" />
      <FireNav
        component="nav"
        aria-label="main mailbox folders"
        sx={{ padding: 1 }}
      >
        {[
          {
            name: "Deliveries",
            value: "deliveries",
            path: "todayDeliveries",
            icon: (
              <Dock
                color={selected === "deliveries" ? "appbarColor" : "primary"}
              />
            ),
            show: true,
            collapsable: "",
            // (
            //   <Collapse
            //     in={selected === "deliveries"}
            //     timeout="auto"
            //     unmountOnExit
            //   >
            //     {[
            //       {
            //         name: "Today's Deliveries",
            //         value: "todayDeliveries",
            //         path: "todayDeliveries",
            //         icon: <Dock />,
            //         show: true,
            //       },
            //       {
            //         name: "Packet Delivered",
            //         value: "packetsDelivered",
            //         path: "packetsDelivered",
            //         icon: <Dock />,
            //         show: true,
            //       },
            //       {
            //         name: "Packets Undelivered",
            //         value: "packetsUndelivered",
            //         path: "undelivered",
            //         icon: <Dock />,
            //         show: true,
            //       },
            //       {
            //         name: "To Be Delivered",
            //         value: "tobeDelivered",
            //         path: "toBeDelivered",
            //         icon: <Dock />,
            //         show: true,
            //       },
            //     ].map((val) => (
            //       <CollapseNav component="div" disablePadding>
            //         <Link
            //           to={val.path}
            //           className="link-style"
            //           style={{ color: "black" }}
            //         >
            //           <ListItemButton
            //             selected={submenu === val.value}
            //             onClick={() => setSubmenu(val.value)}
            //             sx={{ pl: 4 }}
            //           >
            //             <ListItemIcon>{val?.icon}</ListItemIcon>
            //             <ListItemText primary={val?.name} />
            //           </ListItemButton>
            //         </Link>
            //       </CollapseNav>
            //     ))}
            //   </Collapse>
            // ),
          },
          {
            name: "Pick Ups",
            value: "pickups",
            path: "todayPickups",
            icon: (
              <SportsMotorsports
                color={selected === "associate" ? "appbarColor" : "primary"}
              />
            ),
            show: true,
            collapsable: (
              <Collapse
                in={selected === "associate"}
                timeout="auto"
                unmountOnExit
              >
                {[
                  {
                    name: "Total Pickups",
                    value: "deliveryAssociate",
                    path: "DAList",
                    icon: <SportsMotorsports />,
                    show: permissions.viewDA || role === "Admin",
                  },
                  {
                    name: "Pending Pickups",
                    value: "DA",
                    path: "addDeliveryAssociate",
                    icon: <SportsMotorsports />,
                    show: permissions.addDA || role === "Admin",
                  },
                  {
                    name: "Completed PickUps",
                    value: "unknown",
                    path: "/",
                    icon: <SportsMotorsports />,
                    show: permissions.otherDA || role === "Admin",
                  },
                ].map((val, i) => (
                  <CollapseNav component="div" disablePadding key={i}>
                    <Link
                      to={val?.path}
                      className="link-style"
                      style={{ color: "black" }}
                    >
                      <ListItemButton
                        selected={submenu === val?.value}
                        onClick={() => {
                          setSubmenu(val?.value)
                          toggle(anchor, false)
                        }}
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>{val.icon}</ListItemIcon>
                        <ListItemText primary={val.name} />
                      </ListItemButton>
                    </Link>
                  </CollapseNav>
                ))}
              </Collapse>
            ),
          },
          {
            name: "D2D Orders",
            value: "d2dorers",
            path: "d2dorders",
            icon: (
              <SportsMotorsports
                color={selected === "associate" ? "appbarColor" : "primary"}
              />
            ),
            show: true,
            collapsable: (
              <Collapse
                in={selected === "associate"}
                timeout="auto"
                unmountOnExit
              >
                {[
                  {
                    name: "Total Pickups",
                    value: "deliveryAssociate",
                    path: "DAList",
                    icon: <SportsMotorsports />,
                    show: permissions.viewDA || role === "Admin",
                  },
                  {
                    name: "Pending Pickups",
                    value: "DA",
                    path: "addDeliveryAssociate",
                    icon: <SportsMotorsports />,
                    show: permissions.addDA || role === "Admin",
                  },
                  {
                    name: "Completed PickUps",
                    value: "unknown",
                    path: "/",
                    icon: <SportsMotorsports />,
                    show: permissions.otherDA || role === "Admin",
                  },
                ].map((val, i) => (
                  <CollapseNav component="div" disablePadding key={i}>
                    <Link
                      to={val?.path}
                      className="link-style"
                      style={{ color: "black" }}
                    >
                      <ListItemButton
                        selected={submenu === val?.value}
                        onClick={() => {
                          setSubmenu(val?.value)
                          toggle(anchor, false)
                        }}
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>{val.icon}</ListItemIcon>
                        <ListItemText primary={val.name} />
                      </ListItemButton>
                    </Link>
                  </CollapseNav>
                ))}
              </Collapse>
            ),
          },
          {
            name: "Cash Transactions",
            value: "cashTransaction",
            path: "",
            show: true,
            icon: (
              <AddBusiness
                color={
                  selected === "cashTransaction" ? "appbarColor" : "primary"
                }
              />
            ),
            collapsable: (
              <Collapse
                in={selected === "cashTransaction"}
                timeout="auto"
                unmountOnExit
              >
                {[
                  {
                    name: "Total Cash Projected",
                    value: "totalCashProjected",
                    path: "totalCashProjected",
                    icon: <AddBusiness />,
                    show: true,
                  },
                  {
                    name: "Total Cash Collected",
                    value: "totalCashCollected",
                    path: "totalCashCollected",
                    icon: <AddBusiness />,
                    show: permissions.addVendor || role === "Admin",
                  },
                  {
                    name: "Cash Pending",
                    value: "cashPending",
                    path: "totalCashPending",
                    icon: <AddBusiness />,
                    show: permissions.otherVendor || role === "Admin",
                  },
                  {
                    name: "Misc",
                    value: "unknown",
                    path: "",
                    icon: <AddBusiness />,
                    show: permissions.otherVendor || role === "Admin",
                  },
                ].map((val) => (
                  <CollapseNav component="div" disablePadding>
                    <Link
                      to={val.path}
                      className="link-style"
                      style={{ color: "black" }}
                    >
                      <ListItemButton
                        selected={submenu === val.value}
                        onClick={() =>{ 
                          setSubmenu(val.value)
                          toggle(anchor, true)
                        }}
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>{val.icon}</ListItemIcon>
                        <ListItemText primary={val.name} />
                      </ListItemButton>
                    </Link>
                  </CollapseNav>
                ))}
              </Collapse>
            ),
          },
          {
            name: "Performance Matrix",
            value: "performance",
            path: "/",
            icon: (
              <Equalizer
                color={selected === "performance" ? "appbarColor" : "primary"}
              />
            ),
            collapsable: "",
            show: permissions.viewPerformance || role === "Admin",
          },
        ].map((item, i) => {
          return (
            <React.Fragment key={i}>
              {item?.show && (
                <Link
                  to={item.path}
                  className="link-style"
                  style={{ color: "black" }}
                >
                  <ListItemButton
                    selected={selected === item.value}
                    onClick={() => {
                      setSelected(item.value)
                      toggle(anchor, true)
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <Typography variant="subtitle1" fontWeight={600}>
                      {item.name}
                    </Typography>
                  </ListItemButton>
                </Link>
              )}
              {item.collapsable ? item.collapsable : null}
            </React.Fragment>
          );
        })}
      </FireNav>
    </>
  );
};

export default DAUILeftBarMenu;
