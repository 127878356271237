import React, { useEffect } from "react";
import {
  Card,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  Dashboard,
  DirectionsBoatFilled,
  LocalShipping,
  Backpack,
  Dock,
  SportsMotorsports,
  CurrencyRupee,
  Equalizer,
  AddBusiness
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { getMemberPermission } from "../services/memberAndPermissions";
//!Padding of submenu should be less
//!BackgroundColor of submenu should be light orange or light black similar to hover,
//!font should be 2px less than menu<< Submenu

const FireNav = styled(List)(({ theme }) => ({
  

  "& .Mui-selected": {
    backgroundColor: "#FF6A1A !important",
    borderRadius: "8px",
    color: "#fff",
  },

}));
const CollapseNav = styled(List)(({ theme }) => ({
  

  "& .Mui-selected": {
    backgroundColor: "#F5F5F5 !important",
    borderRadius: "8px",
    color: "#000",
  },

}));


const LeftBar = () => {

  
  const [selected, setSelected] = useState("dashboard");
  const [submenu, setSubmenu] = useState("");
  const [role, setRole] = useState("");
  const [permissions,setPermissions]=useState([])

  useEffect(()=>{

    if(localStorage.getItem("menu")){
      setSelected(localStorage.getItem("menu"))
    }else{
      localStorage.setItem("menu","dashboard")

    }
    
    const tokens = localStorage.getItem("token");
    const token=JSON.parse(tokens);
    console.log('token: ', token);
    setRole(token.role)
    async function memberPermission(){
      const {data} = await getMemberPermission(token.userId)
      if(data){
        console.log('data: ', data);
        setPermissions(data);
      }
    }
    memberPermission();
  },[])
  



  if(permissions.length===0){
    return(
      <>Loading</>
    )
  }


  return (
    <>
      <Card
        sx={{
          minHeight: "87vh",
          padding: "2rem 1.2rem",
          // width:"auto",
          ml: 2,
          my: 2,
          borderRadius: "8px",
          // position:"fixed"
        }}
      >
        <FireNav component="nav" aria-label="main mailbox folders">
          {[
            {
              name: "Dashboard",
              value: "dashboard",
              path:"#",
              icon: (
                <Dashboard
                  color={selected === "dashboard" ? "appbarColor" : "primary"}
                />
              ),
              show:true
            },
            {
              name: "Recieved Shipments",
              value: "received",
              path:"receiveShipment",
              icon: (
                <DirectionsBoatFilled
                  color={selected === "received" ? "appbarColor" : "primary"}
                />
              ),
              show:permissions.receivedShipments||role==="Admin",
              collapsable: "",
            },
            {
              name: "Delivery Operations",
              value: "operations",
              path:"allRunsheets",
              icon: (
                <LocalShipping
                  color={selected === "operations" ? "appbarColor" : "primary"}
                />
              ),
              show:permissions.deliveryOperations||role==="Admin",
              collapsable: (
              
                <Collapse
                in={selected === "operations"}
                timeout="auto"
                unmountOnExit
              >
                {[
                  {
                    name: "Create Runsheet",
                    value: "createRunsheet",
                    path:"createRunsheet",
                    icon: <LocalShipping />,
                    show:permissions?.deliveryOperations||role==="Admin"
                  },
                  {
                    name: "View Runsheet",
                    value: "viewRunsheet",
                    path:"todayRunsheet",
                    icon: <LocalShipping />,
                    show:permissions?.deliveryOperations||role==="Admin"
                  },
                  // {
                  //   name: "Search Packet",
                  //   value: "searchPackage",
                  //   path:"searchPackage",
                  //   icon: <LocalShipping />,
                  //   show:permissions?.deliveryOperations||role==="Admin"
                  // },
                ].map((val) => (
                  <CollapseNav component="div" disablePadding>
                   {val.show&& <Link to={val.path} className="link-style" style={{color:"black"}}>
                    <ListItemButton
                      selected={submenu === val.value}
                      onClick={() => setSubmenu(val.value)}
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>{val?.icon}</ListItemIcon>
                      <ListItemText primary={val?.name}  />
                    </ListItemButton>
                    </Link>}
                  </CollapseNav>
                ))}
              </Collapse>
              ),
            },
            {
              name: "Pick-Up Operations",
              value: "pickup",
              path:"allpickupPackets",
              icon: (
                <Backpack
                  color={selected === "pickup" ? "appbarColor" : "primary"}
                />
              ),
              collapsable: (
              
                <Collapse
                in={selected === "pickup"}
                timeout="auto"
                unmountOnExit
              >
                {[
                  {
                    name: "Create Runsheet",
                    value: "createRunsheet",
                    path:"createRunsheetPickup",
                    icon: <LocalShipping />,
                    show:permissions?.deliveryOperations||role==="Admin"
                  },
                  {
                    name: "View Runsheet",
                    value: "viewRunsheet",
                    path:"todayRunsheetPickup",
                    icon: <LocalShipping />,
                    show:permissions?.deliveryOperations||role==="Admin"
                  },
                  {
                    name: "Search Packet",
                    value: "searchPackagePickup",
                    path:"searchPackagePickup",
                    icon: <LocalShipping />,
                    show:permissions?.deliveryOperations||role==="Admin"
                  },
                ].map((val) => (
                  <CollapseNav component="div" disablePadding>
                   {val.show&& <Link to={val.path} className="link-style" style={{color:"black"}}>
                    <ListItemButton
                      selected={submenu === val.value}
                      onClick={() => setSubmenu(val.value)}
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>{val?.icon}</ListItemIcon>
                      <ListItemText primary={val?.name}  />
                    </ListItemButton>
                    </Link>}
                  </CollapseNav>
                ))}
              </Collapse>
              ),
              show:permissions.pickUpOperations||role==="Admin",
            },
            {
              name: "Station",
              value: "station",
              path:"",
              icon: (
                <Dock
                  color={selected === "station" ? "appbarColor" : "primary"}
                />
              ),
              show:permissions?.roleStationResponse?.length>0||role==="admin",                          
              collapsable: (
                <Collapse
                in={selected === "station"}
                timeout="auto"
                unmountOnExit
              >
                {[
                  {
                    name: "Station List",
                    value: "stationList",
                    path:"stationList",
                    icon: <Dock />,
                    show:permissions?.roleStationResponse?.length>0||role==="admin"
                  },
                  {
                    name: "Add Station",
                    value: "addStation",
                    path:"addStation",
                    icon: <Dock />,
                    show:permissions?.roleStationResponse?.add||role==="admin"
                  },
                  {
                    name: "Delivery Hub",
                    value: "deliveryHub",
                    path:"deliveryHubList",
                    icon: <Dock />,
                    show:permissions?.roleDeliveryHubResponse?.length>0||role==="admin"
                  },
                ].map((val) => (
                  <CollapseNav component="div" disablePadding>
                   {val.show&& <Link to={val.path} className="link-style" style={{color:"black"}}>
                    <ListItemButton
                      selected={submenu === val.value}
                      onClick={() => setSubmenu(val.value)}
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>{val?.icon}</ListItemIcon>
                      <ListItemText primary={val?.name}  />
                    </ListItemButton>
                    </Link>}
                  </CollapseNav>
                ))}
              </Collapse>
              ),
            },
            {
              name: "Associate",
              value: "associate",
              path:"",
              icon: (
                <SportsMotorsports
                  color={selected === "associate" ? "appbarColor" : "primary"}
                />
               
              ),
              show:permissions.addDA||permissions.allDA||permissions.editDA||permissions.othersDA||role==="admin",
              collapsable: (
                <Collapse
                  in={selected === "associate"}
                  timeout="auto"
                  unmountOnExit
                >
                  {[
                    {
                      name: "Delivery Associate",
                      value: "deliveryAssociate",
                      path:"DAList",
                      icon: <SportsMotorsports />,
                      show:permissions.viewDA||role==="admin"
                    },
                  {
                      name: "Add DA",
                      value: "DA",
                      path:"addDeliveryAssociate",
                      icon: <SportsMotorsports />,
                      show:permissions.addDA||role==="admin"
                    },
                    {
                      name: "Unknown",
                      value: "unknown",
                      path:"/",
                      icon: <SportsMotorsports />,
                      show:permissions.otherDA||role==="admin"
                      
                    },
                  ].map((val,i) => (
                <CollapseNav component="div" disablePadding key={i}>

{val.show && <Link to={val?.path} className="link-style" style={{color:"black"}}>
                  <ListItemButton
                        selected={submenu === val?.value}
                        onClick={() => setSubmenu(val?.value)}
                        sx={{ pl: 4 }}
                        >
                        <ListItemIcon>{val.icon}</ListItemIcon>
                        <ListItemText primary={val.name}  />
                      </ListItemButton>
                      </Link>}
                    </CollapseNav>
                        
                  ))}
                </Collapse>
              ),
            },
            {
              name: "Cash Mangement",
              value: "cash",
              path:"daReconciliation",
              icon: (
                <CurrencyRupee
                  color={selected === "cash" ? "appbarColor" : "primary"}
                />
              ),
              show:permissions.viewFinanceData||role==="admin",
              collapsable: ""
              // (
              //   <Collapse
              //     in={selected === "vendor"}
              //     timeout="auto"
              //     unmountOnExit
              //   >
              //     {[
              //       {
              //         name: "DA Reconciliation",
              //         value: "DA Reconciliation",
              //         path:"daReconciliation",
              //         icon: <CurrencyRupee />,
              //         show:permissions.viewFinanceData||role==="admin"
              //       },
              //       {
              //         name: "Cash Deposit List",
              //         value: "Cash Deposit List",
              //         path:"cashDepositList",
              //         icon: <CurrencyRupee />,
              //         show:permissions.viewFinanceData||role==="admin"
              //       },
              //       // {
              //       //   name: "Unknown",
              //       //   value: "unknown",
              //       //   path:"/",
              //       //   icon: <AddBusiness />,
              //       //   show:role==="admin"
              //       // },
              //     ].map((val) => (
              //       <CollapseNav component="div" disablePadding>
              //       {val.show&&  <Link to={val.path} className="link-style" style={{color:"black"}}>
              //         <ListItemButton
              //           selected={submenu === val.value}
              //           onClick={() => setSubmenu(val.value)}
              //           sx={{ pl: 4 }}
              //         >
              //           <ListItemIcon>{val.icon}</ListItemIcon>
              //           <ListItemText primary={val.name}  />
              //         </ListItemButton>
              //         </Link>}
              //       </CollapseNav>
              //     ))}
              //   </Collapse>
              // )
              ,
            },
            {
              name: "Vendor",
              value: "vendor",
              path:"vendorList",
              show:permissions?.roleVendorResponse.length>0||role==="admin",
              icon: (
                <AddBusiness
                  color={selected === "vendor" ? "appbarColor" : "primary"}
                />
              ),
              collapsable: (
                <Collapse
                  in={selected === "vendor"}
                  timeout="auto"
                  unmountOnExit
                >
                  {[
                    {
                      name: "Vendor List",
                      value: "Vendor List",
                      path:"vendorList",
                      icon: <AddBusiness />,
                      show:permissions?.roleVendorResponse.length>0||role==="admin"
                    },
                    {
                      name: "Add Vendor",
                      value: "Add Vendor",
                      path:"addVendor",
                      icon: <AddBusiness />,
                      show:role==="admin"
                    },
                    // {
                    //   name: "Unknown",
                    //   value: "unknown",
                    //   path:"/",
                    //   icon: <AddBusiness />,
                    //   show:role==="Admin"
                    // },
                  ].map((val) => (
                    <CollapseNav component="div" disablePadding>
                    {val.show&&  <Link to={val.path} className="link-style" style={{color:"black"}}>
                      <ListItemButton
                        selected={submenu === val.value}
                        onClick={() => setSubmenu(val.value)}
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>{val.icon}</ListItemIcon>
                        <ListItemText primary={val.name}  />
                      </ListItemButton>
                      </Link>}
                    </CollapseNav>
                  ))}
                </Collapse>
              ),
            },
            {
              name: "Performance Matrix",
              value: "performance",
              path:"/login",
              icon: (
                <Equalizer
                  color={selected === "performance" ? "appbarColor" : "primary"}
                />
              ),
              collapsable: "",
              show:permissions.viewPerformance||role==="Admin"
            },
            {
              name: "D2D Vendors",
              value: "d2dvendor",
              path:"requestandactive",
              show:true,
              icon: (
                <AddBusiness
                  color={selected === "d2dvendor" ? "appbarColor" : "primary"}
                />
              ),
              collapsable: (
                <Collapse
                  in={selected === "d2dvendor"}
                  timeout="auto"
                  unmountOnExit
                >
                  {[
                    {
                      name: "Orders",
                      value: "Orders",
                      path:"requestandactive",
                      icon: <AddBusiness />,
                      show:true
                    },
                    {
                      name: "Create Vendor Packet ",
                      value: "Create Vendor Packet ",
                      path:"vendorPacketFormStation",
                      icon: <AddBusiness />,
                      show:true
                    },
                    // {
                    //   name: "Pending Orders",
                    //   value: "Pending Orders",
                    //   path:"costingSentOrders",
                    //   icon: <AddBusiness />,
                    //   show:true
                    // },
                    {
                      name: "Assign DA",
                      value: "Assign DA",
                      path:"createRunsheetVendor",
                      icon: <AddBusiness />,
                      show:true
                    },
                    // {
                    //   name: "Order History",
                    //   value: "Order History",
                    //   path:"orderHistoryVendorStation",
                    //   icon: <AddBusiness />,
                    //   show:true
                    // },
                    {
                      name: "Cash Reconciliation ",
                      value: "Cash Reconciliation ",
                      path:"vendorDAReconciliation",
                      icon: <AddBusiness />,
                      show:true
                    },
                    {
                      name: "Runsheet Closure ",
                      value: "Runsheet Closure",
                      path:"runsheetClosure",
                      icon: <AddBusiness />,
                      show:true
                    },
                    {
                      name: "Coins and Coupons ",
                      value: "Coins and Coupons",
                      path:"applyingCashback",
                      icon: <AddBusiness />,
                      show:true
                    },
                  ].map((val) => (
                    <CollapseNav component="div" disablePadding>
                    {val.show&&  <Link to={val.path} className="link-style" style={{color:"black"}}>
                      <ListItemButton
                        selected={submenu === val.value}
                        onClick={() => setSubmenu(val.value)}
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>{val.icon}</ListItemIcon>
                        <ListItemText primary={val.name}  />
                      </ListItemButton>
                      </Link>}
                    </CollapseNav>
                  ))}
                </Collapse>
              ),
            },
          ].map((item, i) => {
            return (
              <React.Fragment key={i}>
              {item?.show&&  <Link to={item.path} className="link-style" style={{color:"black"}}>
                <ListItemButton
                  selected={selected === item.value}
                  onClick={() => {
                    setSelected(item.value);
                    localStorage.setItem("menu", item.value)
                  }}
                 
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {item.name}
                  </Typography>
                </ListItemButton>
                </Link>}
                {item.collapsable ? item.collapsable : null}
              </React.Fragment>
            );
          })}
        </FireNav>
      </Card>
    </>
  );
};

export default LeftBar;

